import { SxProps } from '@mui/material';

export const actionButtonsStyle: SxProps = {
  width: 0,
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'absolute',
  right: '20px',
  bottom: '50%',
  transform: 'translateY(50%)',
  background: 'white',
  whiteSpace: 'nowrap',
};

// Add this to row
export const rowSx: SxProps = {
  '& .action-buttons': {
    width: 0,
  },
  '&:hover': {
    '& .action-buttons': {
      width: 'auto',
      overflow: 'visible',
    },
  },
};

// Add this to cell td
export const cellSx: SxProps = {
  position: 'sticky',
  width: 0,
  right: 0,
  minWidth: 0,
  padding: 0,
};

// Add this to cell th
export const headerSx: SxProps = {
  display: 'none',
};
