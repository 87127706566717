import { DragIndicatorOutlined } from '@mui/icons-material';
import { Box, Chip, FormControl, Typography } from '@mui/material';
import React, { DragEventHandler, useMemo, useState } from 'react';

import EnhancedSelect from '../molecules/EnhancedSelect';

interface CustomField {
  label: string;
}

interface CustomSelectOptions {
  [custom_fields_id: string]: {
    fields: Record<string, CustomField>;
  };
}

interface Data {
  customSelectOptions: CustomSelectOptions;
  label: string;
}

interface Field {
  fields?: string[];
  custom_fields_id: string;
}

type DragableSelectProps = {
  data: Data;
  setter: React.Dispatch<React.SetStateAction<Field>>;
  field: Field;
};

type Item = {
  id: string;
  label: string;
  type: string;
};

type DropPosition = {
  dragIndex: number;
  dropIndex: number;
};

const DraggableItem = ({
  item,
  onDrop,
  index,
}: {
  item: Item;
  index: number;
  onDrop: (val: DropPosition) => void;
}) => {
  const [dragging, setDragging] = useState(false);

  const activeClass = 'drag-area-active';
  const dataKey = 'index';

  const ondragstart: DragEventHandler<HTMLDivElement> = (evt) => {
    evt.dataTransfer.setData(dataKey, String(index));
  };

  const findParent = (node: HTMLElement) =>
    node.draggable ? node : node.closest('div[draggable]');

  const ondrop: DragEventHandler<HTMLDivElement> = (evt) => {
    evt.preventDefault();
    const parent = findParent(evt.target as HTMLElement);
    if (parent) {
      parent.className = parent.className.replace(` ${activeClass}`, '');
    }

    const dragIndex = parseInt(evt.dataTransfer.getData(dataKey));
    onDrop({
      dragIndex,
      dropIndex: index,
    });
  };

  const ondragenter: DragEventHandler<HTMLDivElement> = (evt) => {
    evt.preventDefault();
    const node = evt.target as HTMLDivElement;
    const parent = findParent(node);
    if (parent) {
      parent.className += ` ${activeClass}`;
    }
  };

  const ondragleave: DragEventHandler<HTMLDivElement> = (evt) => {
    evt.preventDefault();
    const node = evt.target as HTMLDivElement;
    const parent = findParent(node);
    if (parent) {
      parent.className = parent.className.replace(` ${activeClass}`, '');
    }
  };

  return (
    <Box
      onDragStart={ondragstart}
      onDrag={() => setDragging(true)}
      onDragEnd={() => setDragging(false)}
      onDrop={ondrop}
      onDragOver={(evt) => evt.preventDefault()}
      onDragEnter={ondragenter}
      onDragLeave={ondragleave}
      draggable
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        marginRight: '4px',
        '&:hover': {
          cursor: 'pointer',
        },

        [`&.${activeClass}`]: {
          '&::before': {
            content: '""',
            height: '80%',
            borderLeft: '2px solid',
            left: '-4px',
            position: 'absolute',
          },
        },
      }}
    >
      <Chip
        icon={<DragIndicatorOutlined sx={{ height: 12, width: 12 }} />}
        sx={{
          mr: 0.25,
          mb: 0.25,
          visibility: dragging ? 'hidden' : 'visible',
        }}
        label={item.label}
      />
    </Box>
  );
};

const DragableSelect: React.FC<DragableSelectProps> = ({
  data,
  field,
  setter,
}) => {
  const onDrop = ({ dragIndex, dropIndex }: DropPosition) => {
    const newList = [...items];
    const dragItem = newList.splice(dragIndex, 1);
    newList.splice(dropIndex, 0, ...dragItem);
    return setItems(newList);
  };

  const [items, setItems] = useState<
    { type: string; id: string; label: string }[]
  >(
    field.fields?.map((key) => ({
      id: key,
      label:
        data.customSelectOptions[field.custom_fields_id]?.fields?.[key]
          ?.label || key,
      type: 'item',
    })) || []
  );

  const handleSelectChange = (values: typeof items) => {
    setItems(values);
    setter({ ...field, fields: values.map((item) => item.id) });
  };

  const options = useMemo(() => {
    const fields = Object.entries(
      data.customSelectOptions[field.custom_fields_id]?.fields || {}
    );

    const _options = fields?.map(([key, value]) => {
      const label = value.label || key;
      return { label, id: key, type: 'item' };
    });
    return _options;
  }, [data.customSelectOptions, field.custom_fields_id]);

  return (
    <Box>
      <Typography variant="subtitle2">{data.label}</Typography>
      <FormControl>
        <EnhancedSelect
          sx={{ width: 'auto', minWidth: 250, maxWidth: 400 }}
          multiple
          value={items}
          placeholder="Select"
          onChange={(values) => {
            handleSelectChange(values || []);
          }}
          options={options}
        />
      </FormControl>
      {Array.isArray(items) && items.length > 0 && (
        <Box
          sx={{
            mt: 0.5,
            mb: 1,
            p: 1,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          {items?.map((item, index) => (
            <DraggableItem
              onDrop={onDrop}
              key={item.id}
              item={item}
              index={index}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DragableSelect;
