const convertToNumber = (str: string, setNaNToZero = false) => {
  if (!str) return 0;
  const numberString = str.replace(/[^0-9.]+/g, '').trim();

  let res = numberString;

  if (str.includes('-') || str.includes('(') || str.includes(')')) {
    res = `-${numberString}`;
  }
  const numberValue = parseFloat(res);
  if (Number.isNaN(numberValue) && setNaNToZero) return 0;

  return numberValue;
};

export const desc_convertToNumber = `
Convert a string to a number.
eg:
  convertToNumber('123') => 123;
  convertToNumber('123.45') => 123.45;
  convertToNumber('($123)') => -123;
  convertToNumber('123-') => -123;
  convertToNumber('($123,34.66)') => -12334.66;
`;

export default convertToNumber;
