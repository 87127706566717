import { Button } from '@mui/material';

import FieldAggregationSelector from './FieldAggregationSelector';

const FieldAggregationManager = ({
  fields,
  selectors,
  addSelector,
  removeSelector,
  updateSelector,
}) => {
  return (
    <div>
      {selectors.map((selector) => (
        <FieldAggregationSelector
          key={selector.id}
          fields={fields}
          selectedField={selector}
          onRemove={() => removeSelector(selector.id)}
          onUpdate={(updatedField) => updateSelector(selector.id, updatedField)}
        />
      ))}
      <Button variant="text" onClick={addSelector}>
        Add
      </Button>
    </div>
  );
};

export default FieldAggregationManager;
