const findAllTextLineByIndex = (json, indicesArray) => {
  const resultLines: any = [];

  for (const indices of indicesArray) {
    if (indices.length !== 2) {
      resultLines.push(null);
      continue;
    }

    const [lineIndex, arrayIndex] = indices;
    if (json.Lines[lineIndex] && json.Lines[lineIndex].LinesArray[arrayIndex]) {
      resultLines.push(json.Lines[lineIndex].LinesArray[arrayIndex].Line);
    } else {
      resultLines.push(null);
    }
  }

  return resultLines;
};

export const desc_findAllTextLineByIndex = `
Find all lines that contain the given text in the JSON object by index.
eg:
findAllTextLineByIndex(json, [[0, 1], [1, 2]]) => ['text1', 'text2'];
`;

export default findAllTextLineByIndex;
