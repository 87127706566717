import convertToNumber from './convertToNumber';

const getPercent = (value1, value2) => {
  const number1 = convertToNumber(value1);
  const number2 = convertToNumber(value2);
  if (number2 === 0) return '';
  const percent = ((number1 / number2) * 100).toFixed(2);
  return !Number.isFinite(percent) && !Number.isNaN(percent)
    ? `${percent}%`
    : '';
};

export const desc_getPercent = `
Get the percentage of two numbers.
eg:
  getPercent('123', '456') => '26.97%';
`;

export default getPercent;
