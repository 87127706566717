import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import validator from 'validator';

import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';

const ActionDialog = ({
  open,
  setOpen,
  rowData,
  companies,
  documentProfiles,
}) => {
  const { showSnackbar } = useSnackbar();
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState({
    bank_total_amount: '',
    check_date: null,
    company_str_id: '',
    profile_str_id: '',
    deposit_date: null,
    notes: '',
    status: '',
    type: '',
  });
  const [error, setError] = useState<boolean>(false);

  const statusLis = useMemo(
    () => [
      {
        label: 'New',
        value: 'new',
      },
      {
        label: 'Processed',
        value: 'processed',
      },
    ],
    []
  );
  const typeList = useMemo(
    () => [
      {
        label: 'Statement',
        value: 'statement',
      },
      {
        label: 'Report',
        value: 'report',
      },
    ],
    []
  );

  const apiPutter = API.getMutation('documents', 'PUT');

  useEffect(() => {
    if (rowData) {
      const {
        bank_total_amount,
        check_date,
        company_str_id,
        profile_str_id,
        deposit_date,
        notes,
        status,
        type,
      } = rowData;
      setFormData({
        bank_total_amount,
        check_date,
        company_str_id,
        profile_str_id,
        deposit_date,
        notes,
        status,
        type,
      });
    }
  }, [rowData]);

  const onSubmit = async () => {
    setUploading(true);
    const { id, str_id } = rowData;
    const body = {
      ...formData,
      str_id,
      id,
    };
    try {
      const result = await apiPutter.mutateAsync(body);
      setUploading(false);
      if (result.error) {
        showSnackbar(result.error, 'error');
        return;
      }
      if (result) {
        setOpen(false, 'save');
      }
    } catch (error: any) {
      showSnackbar(error?.message || error, 'error');
      setUploading(false);
    }
  };

  const onFormChange = (key, value: any) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ background: 'transparent', p: 1 }}
      onClose={() => setOpen(false)}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Box className="flex items-center">Edit document</Box>
      </DialogTitle>
      <IconButton
        className="group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600"
        onClick={() => setOpen(false)}
      >
        <Close className="group-hover:rotate-180 transition-all origin-center" />
      </IconButton>
      <Divider />

      <DialogContent
        sx={{
          p: 0,
          backgroundColor: '#fff',
          borderRadius: '4px',
        }}
      >
        <Box sx={{ width: '100%', maxHeight: '700px', p: 2 }}>
          <FormControl fullWidth sx={{ minWidth: 100, mb: 1 }}>
            <InputLabel>Type</InputLabel>
            <Select
              id="select-type"
              value={formData.type}
              label="Type"
              onChange={(e) => {
                onFormChange('type', e.target.value);
              }}
            >
              {typeList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ minWidth: 100, mb: 1 }}>
            <InputLabel>Company</InputLabel>
            <Select
              id="select-carrier"
              value={formData.company_str_id}
              label="Company"
              onChange={(e) => {
                onFormChange('company_str_id', e.target.value);
              }}
            >
              {(companies || []).map((c) => (
                <MenuItem value={c.str_id} key={c.str_id}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                    }}
                  >
                    <Box sx={{ flex: 1 }}>{c.company_name}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ minWidth: 100, mb: 1 }}>
            <InputLabel>Document profiles</InputLabel>
            <Select
              id="select-document-profiles"
              value={formData.profile_str_id}
              label="Document profiles"
              onChange={(e) => {
                onFormChange('profile_str_id', e.target.value);
              }}
            >
              {(documentProfiles || []).map((c) => (
                // TODO: change c.str_id to c.name (Since most of docuemnt_profiles.name is null currently)
                <MenuItem value={c.str_id} key={c.str_id}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                    }}
                  >
                    <Box sx={{ flex: 1 }}>{[c.name, c.str_id].join(' - ')}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ minWidth: 100, mb: 1 }}>
            <InputLabel>Status</InputLabel>
            <Select
              id="select-type"
              value={formData.status}
              label="Status"
              onChange={(e) => {
                onFormChange('status', e.target.value);
              }}
            >
              {statusLis.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ minWidth: 100, mb: 1 }}>
            <TextField
              label="Bank total"
              variant="outlined"
              value={formData.bank_total_amount}
              error={error}
              helperText={error ? 'Invalid currency format' : ''}
              onChange={(e) => {
                const val = e.target.value;
                const isValidCurrency = validator.isCurrency(val, {
                  allow_negatives: true,
                  thousands_separator: ',',
                  decimal_separator: '.',
                  allow_decimal: true,
                  digits_after_decimal: [1, 2],
                });
                if (!isValidCurrency) {
                  setError(true);
                } else {
                  setError(false);
                }
                onFormChange('bank_total_amount', val);
              }}
            />
          </FormControl>
          <Box>
            <BasicDatePicker
              label="Check date"
              value={formData.check_date}
              setValue={(e) => onFormChange('check_date', e)}
              sx={{ width: 250 }}
            />
          </Box>
          <Box>
            <BasicDatePicker
              label="Deposit date"
              value={formData.deposit_date}
              setValue={(e) => onFormChange('deposit_date', e)}
              sx={{ my: 1, width: 250 }}
            />
          </Box>
          <FormControl fullWidth sx={{ minWidth: 100, mb: 1 }}>
            <TextField
              label="Notes"
              variant="outlined"
              multiline
              rows={4}
              value={formData.notes}
              onChange={(e) => {
                onFormChange('notes', e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions sx={{ pt: 0, pb: 2, px: 2 }}>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton
          onClick={onSubmit}
          loading={uploading}
          variant="contained"
          sx={{ width: '100px' }}
          disabled={error}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
