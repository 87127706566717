import { Box, Chip } from '@mui/material';
import React from 'react';
import { DataUpdateRule } from 'common/globalTypes';

import { fieldMatcherActions } from '@/components/molecules/ActionPicker';
import {
  DataEntities,
  Fields,
} from '@/components/ToolsPage/DataUpdateTools/types';
import { fieldMatcherOptions } from '@/components/molecules/FieldMatcher';

interface RuleViewerProps {
  fieldData: DataUpdateRule | DataUpdateRule[];
  rowData: any;
  fields: Fields;
}

const CriteriaActionsViewer: React.FC<RuleViewerProps> = ({
  fieldData,
  rowData,
  fields,
}) => {
  if (!fieldData) {
    return <Chip label="Not available" />;
  }
  const dataEntityFields = fields[rowData.data_entity as DataEntities] || {};
  const getFieldLabel = (fieldId: string) => {
    const field = Object.values(dataEntityFields).find(
      (field) => (field as any).id === fieldId
    );
    return field ? (field as any).label : fieldId;
  };

  const getOperatorLabel = (operator: string) => {
    const operatorLabel = Object.values(fieldMatcherOptions).find(
      (action) => action.id === operator
    );
    return operatorLabel ? operatorLabel.label : operator;
  };

  return (
    <Box>
      {!Array.isArray(fieldData) && fieldData.action ? (
        <Box>
          {fieldData.field && (
            <Box sx={{ mb: 1 }}>
              <Chip label={`${getFieldLabel(fieldData.field)}`} />
            </Box>
          )}
          <Chip
            label={`${
              fieldMatcherActions.find(
                (action) => action.id === fieldData.action
              )?.label
            } `}
          />
          to
          <Chip label={`${fieldData.value}`} />
        </Box>
      ) : Array.isArray(fieldData) && fieldData.length > 0 ? (
        fieldData.map((rule, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            {rule.field && (
              <Chip label={`${getFieldLabel(rule.field)}`} sx={{ mr: 0.5 }} />
            )}
            {rule.op && (
              <Chip label={`${getOperatorLabel(rule.op)}`} sx={{ mr: 0.5 }} />
            )}
            <Chip label={`${rule.value}`} sx={{ mr: 0.5 }} />
          </Box>
        ))
      ) : (
        <Chip label="Not available" />
      )}
    </Box>
  );
};

export default CriteriaActionsViewer;
