import TextField from '@mui/material/TextField';
import Autocomplete, {
  autocompleteClasses,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import type { SelectProps } from './types';
import { ListboxComponent } from './ListBox';

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const EnhancedSelect = <
  T extends object,
  K extends boolean | undefined = false,
>(
  props: SelectProps<T, K>
) => {
  const {
    value,
    multiple,
    options,
    onChange,
    label,
    placeholder,
    loading,
    sx,
    optionKey = 'id',
    optionLabel = 'label',
    limitTags = 3,
  } = props;

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        label={label}
        placeholder={placeholder}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading && <CircularProgress color="inherit" size={20} />}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };

  return (
    <Autocomplete
      sx={{ width: 200, ...sx }}
      limitTags={limitTags}
      value={value}
      disableListWrap
      multiple={multiple}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={options}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionKey={(option) => option[optionKey]}
      isOptionEqualToValue={(option, value) =>
        option[optionKey] === value[optionKey]
      }
      onChange={(e, options) => {
        onChange(options as any);
      }}
      renderInput={renderInput}
      renderOption={(props, option, state) =>
        [
          props,
          option,
          state,
          {
            multiple,
            optionLabel,
          },
        ] as React.ReactNode
      }
      disableCloseOnSelect={multiple}
    />
  );
};

export default EnhancedSelect;
