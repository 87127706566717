import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { useMenuStore } from '@/store';

export const ToggleMenuButton = () => {
  const { menuOpen, setMenuOpen } = useMenuStore();
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={() => setMenuOpen(!menuOpen)}
      edge="start"
    >
      <MenuIcon />
    </IconButton>
  );
};
