import React from 'react';
import { Box, Typography, Grid, Divider, Paper } from '@mui/material';

import CriteriaActionsViewer from './CriteriaActionsViewer';
import { Fields } from './types';

interface DataUpdateGroupViewerProps {
  dataUpdateData: any[];
  fields: Fields;
}

const DataUpdateGroupViewer: React.FC<DataUpdateGroupViewerProps> = ({
  dataUpdateData,
  fields,
}) => {
  const dataEntity = dataUpdateData[0]?.data_entity || '';
  return (
    <>
      <Box sx={{ m: 1 }}>
        <Typography variant="body2">Data update for {dataEntity}</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">Criteria</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Actions</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 1 }} />
        {dataUpdateData.map((data) => (
          <Paper key={data.id} sx={{ mb: 0.5, p: 0.5 }}>
            <Grid container spacing={2}>
              {data.data_update_criteria.map((criterion, index) => (
                <React.Fragment key={criterion.id}>
                  <Grid item xs={6}>
                    <CriteriaActionsViewer
                      fieldData={criterion.data_update_criteria}
                      rowData={{ data_entity: dataEntity }}
                      fields={fields}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {data.data_update_actions[index] && (
                      <CriteriaActionsViewer
                        fieldData={
                          data.data_update_actions[index].data_update_actions
                        }
                        rowData={{ data_entity: dataEntity }}
                        fields={fields}
                      />
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Paper>
        ))}
      </Box>
    </>
  );
};

export default DataUpdateGroupViewer;
