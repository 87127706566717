import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { CompReportViewTypes } from 'common/globalTypes';

import { savedReportsGroupsTemplates } from '@/types';
import Formatter from '@/services/Formatter';

const CommissionPayoutExportConfig = ({
  open,
  setOpen,
  setExportOptions,
  savedReport,
  selectedAccount,
  selectedData,
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [exportType, setExportType] = useState('xlsx');
  const [amountDueThreshold, setAmountDueThreshold] = useState('0');
  const [individualReports, setIndividualReports] = useState(false);
  const [excludeNonPayable, setExcludeNonPayable] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState(false);
  const [grouping, setGrouping] = useState('none');
  const [view, setView] = useState<CompReportViewTypes>(
    CompReportViewTypes.PRODUCER_VIEW
  );

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Generate agent commission payout report</DialogTitle>
      <DialogContent sx={{ width: 450 }}>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <FormControl sx={{ width: '25%' }}>
              <InputLabel id="select-export-type">Export as</InputLabel>
              <Select
                labelId="select-export-type"
                value={exportType}
                label="Export as"
                onChange={(event) => {
                  if (event.target.value === 'csv') {
                    setDisableCheckbox(true);
                  } else {
                    setDisableCheckbox(false);
                  }
                  setExportType(event.target.value as string);
                }}
              >
                <MenuItem value={'xlsx'}>Excel</MenuItem>
                <MenuItem value={'pdf'}>PDF</MenuItem>
                <MenuItem value={'csv'}>CSV</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={individualReports}
                  onChange={(event) =>
                    setIndividualReports(event.target.checked)
                  }
                  disabled={disableCheckbox}
                />
              }
              label="Generate individual reports"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={excludeNonPayable}
                  onChange={(event) =>
                    setExcludeNonPayable(event.target.checked)
                  }
                />
              }
              label="Exclude non-payable reports"
            />
          </Box>
          <Box>
            <ToggleButtonGroup
              sx={{ height: '34px' }}
              exclusive
              orientation={isMobile ? 'vertical' : 'horizontal'}
              color="primary"
              onChange={(event, newView) => setView(newView)}
              value={view}
            >
              <ToggleButton value={CompReportViewTypes.ADMIN_VIEW}>
                Admin view
              </ToggleButton>
              <ToggleButton value={CompReportViewTypes.PRODUCER_VIEW}>
                Producer view
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box sx={{ mt: 1.5 }}>
            <TextField
              label="Amount due threshold"
              value={amountDueThreshold}
              onChange={(event) => {
                setAmountDueThreshold(event.target.value);
              }}
              type="number"
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <ToggleButtonGroup
              sx={{ height: '30px', mb: 1 }}
              exclusive
              color="primary"
              onChange={(event, newGrouping) => {
                setGrouping(newGrouping);
              }}
              value={grouping}
            >
              <ToggleButton
                sx={{
                  borderRadius: '15px',
                }}
                value="policyNumber"
              >
                Group by policy
              </ToggleButton>
              <ToggleButton
                sx={{
                  borderRadius: '15px',
                }}
                value="none"
              >
                Commission data
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pt: 0 }}>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={async () => {
            setLoading(true);
            setExportOptions({
              export_type: exportType,
              fileName:
                savedReport &&
                Array.isArray(savedReport) &&
                savedReport.length > 0
                  ? savedReport[0]?.template ===
                    savedReportsGroupsTemplates.COMMISSION_PAYOUT
                    ? `${selectedAccount?.accountName} - ${Formatter.date(savedReport[0]?.created_at)}`.replace(
                        /&/g,
                        ''
                      )
                    : savedReport[0]?.name.replace(/&/g, '')
                  : '',
              amount_due_threshold: amountDueThreshold,
              individual_reports: individualReports,
              exclude_non_payable: excludeNonPayable,
              view: view,
              selected_data: selectedData.map((data) => ({
                id: data.id,
                str_id: data.str_id,
              })),
              grouping: grouping,
            });
            setLoading(false);
            setOpen(false);
          }}
        >
          Export
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CommissionPayoutExportConfig;
