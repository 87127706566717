const convertMapToArray = (objects) => {
  if (!objects || !objects.length) {
    return { keys: [], valuesArray: [] };
  }
  const keys = Object.keys(objects[0]);

  const valuesArray = objects.map((obj) => keys.map((key) => obj[key]));

  return { keys, values: valuesArray };
};

export const desc_convertMapToArray = `
Convert a map of objects to an array of values.
eg:
  convertMapToArray([{a: 1, b: 2}, {a: 3, b: 4}]) => {keys: ['a', 'b'], values: [[1, 2], [3, 4]]};
`;

export default convertMapToArray;
