import { useParams } from 'react-router';

import DashboardView from '.';
import { DashboardProvider } from './DashboardContext';

const CustomInsights = ({ name, label }) => {
  const { dashboardName } = useParams();
  return (
    <DashboardProvider>
      <DashboardView
        dashboardLabel={label}
        dashboardName={name ?? dashboardName}
      />
    </DashboardProvider>
  );
};
export default CustomInsights;
