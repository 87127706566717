import { AppBar, Avatar, Box, Link, Toolbar, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useAccountStore } from '@/store';
import { ToggleMenuButton } from './ToggleMenuButton';
import { Notification } from './Notification';
import { UserMenu } from './UserMenu';
import { AuthMenu } from './AuthMenu';
import { App } from '@/constants/app';

const Bar = ({
  onHelpClick,
  onSignOutClick,
  onSignUpClick,
  onSignInClick,
  performingAction,
  user,
}: BarProps) => {
  const { selectedAccount, logoUrl } = useAccountStore();

  return (
    <AppBar color="primary" position="fixed" sx={{ zIndex: 1300 }}>
      <Toolbar disableGutters sx={{ mx: 1 }}>
        {user && <ToggleMenuButton />}
        <Link
          color="inherit"
          component={RouterLink}
          to="/"
          underline="none"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
            {logoUrl && <Avatar alt="Logo" src={logoUrl} sx={{ ml: 1 }} />}
            <Typography
              color="inherit"
              variant="h5"
              sx={{ textWrap: 'nowrap', ml: 1 }}
            >
              {selectedAccount?.accountName ?? App.Title}
            </Typography>
          </Box>
        </Link>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            display: 'flex',
          }}
        >
          <Box
            sx={{
              width: 16,
              backgroundImage:
                'linear-gradient(to right, rgba(0,0,0,0), #2196f3)',
            }}
          />
          <Box sx={{ backgroundColor: '#2196f3' }}>
            {user ? (
              <>
                <Notification />
                <UserMenu
                  onHelpClick={onHelpClick}
                  onSignOutClick={onSignOutClick}
                  user={user}
                />
              </>
            ) : (
              <AuthMenu
                onSignUpClick={onSignUpClick}
                onSignInClick={onSignInClick}
                performingAction={performingAction}
              />
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Bar;
