import { Add, Delete, Edit } from '@mui/icons-material';
import { Alert, Button, IconButton } from '@mui/material';
import { useContext, useState } from 'react';

import DataView from '@/components/DataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import ActionModal from '@/components/admin/AdminDocumentsView/PromptsView/ActionModal';
import { BasicDialog } from '@/common';

const PromptsView = ({ variant = '' }) => {
  const [showModal, setShowModal] = useState(false);

  /**
   * @type {Object}
   */
  const [currentRow, setCurrentRow] = useState<any>();
  const [refresh, setRefresh] = useState(0);

  const { setLoadingConfig } = useContext(LoadingContext);

  // show comfirm dialog when deleting
  const [showConfirm, setShowConfirm] = useState(false);

  const deleter = API.getMutation('prompts', 'DELETE');
  const { data: owners = [] } = API.getBasicQuery('users/get_fintary_admins');

  const extraActions = (
    <Button
      variant="contained"
      startIcon={<Add />}
      onClick={() => {
        setCurrentRow(null);
        setShowModal(true);
      }}
    >
      Add
    </Button>
  );

  const dataDesc = {
    table: 'prompts',
    editable: false,
    fields: [
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'access',
        label: 'Access',
        access: 'admin',
      },
      {
        id: 'owner',
        label: 'Owner',
        formatter: (s) => {
          const target = owners?.find((c) => c.uid === s);
          return Formatter.contact(target);
        },
      },
      {
        id: 'status',
        label: 'Status',
        access: 'admin',
      },
      {
        id: 'prompt',
        label: 'Prompt',
      },
      {
        id: 'updated_at',
        label: 'Last updated',
        formatter: (s) => new Date(s).toLocaleString(),
        readOnly: true,
      },
      {
        id: 'id',
        label: 'Actions',
        access: 'admin',
        /**
         *
         * @param {number} id
         * @param {*} row
         * @returns
         */
        formatter: (id, row) => (
          <>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setCurrentRow(row);
                setShowModal(true);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={async () => {
                setCurrentRow(row);
                setShowConfirm(true);
              }}
            >
              <Delete />
            </IconButton>
          </>
        ),
      },
    ],
  };

  const closeModal = (e, reason) => {
    if (reason === 'backdropClick') return;
    setShowModal(false);
    setRefresh(refresh + 1);
  };

  return (
    <>
      <DataView
        dataDesc={dataDesc}
        viewOnly
        extraActions={extraActions}
        refresh={refresh}
        hideExport
        enableAutoRefetch={true}
        variant={variant}
      />
      {showModal && (
        <ActionModal
          rowData={currentRow}
          handleCancel={closeModal}
          open={showModal}
          owners={owners}
        />
      )}
      {showConfirm && (
        <BasicDialog
          open={showConfirm}
          bodyComponent={
            <Alert severity="warning">
              Are you sure you want to delete the following prompt?
              <div className="text-blue-500 mt-4">{currentRow?.name || ''}</div>
            </Alert>
          }
          positiveLabel="Delete"
          title="Delete prompt?"
          onClose={async (val) => {
            setShowConfirm(false);
            if (val) {
              setLoadingConfig({
                loading: true,
                message: 'Deleting...',
              });
              await deleter.mutateAsync({ ids: [currentRow?.id] });
              setRefresh(refresh + 1);
              setLoadingConfig({
                loading: false,
                delayToClose: 1000,
              });
            }
          }}
        />
      )}
    </>
  );
};

export default PromptsView;
