import DataView from '@/components/DataView';
import { adminProcessingLogDataDesc } from '@/dataDesc/adminProcessingLogDataDesc';

export const Activities = () => {
  return (
    <DataView
      enablePagination
      dataDesc={adminProcessingLogDataDesc}
      viewOnly
      hideExport
    />
  );
};
