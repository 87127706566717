import { useCallback, useEffect, useMemo, useState } from 'react';

import { OPTION_VALUE_ALL } from '@/constants/common';

export const useFilterParams = ({
  filters,
}: {
  filters: DataDesc.Filter[];
}) => {
  const [filterValues, setValues] = useState<{
    [key: string]: string | number | undefined;
  }>({});

  const setFilters = useCallback((data: typeof filterValues) => {
    setValues((prev) => ({ ...prev, ...data }));
  }, []);

  /**
   * This is for query string
   */
  const filterParams = useMemo(() => {
    const searchParams = new URLSearchParams({});
    Object.entries(filterValues).forEach(([key, value]) => {
      if (value && value !== OPTION_VALUE_ALL) {
        searchParams.set(key, String(value));
      }
    });
    return searchParams.toString();
  }, [filterValues]);

  useEffect(() => {
    filters?.forEach((filter) => {
      setFilters({ [filter.apiParamKey]: filter.defaultValue });
    });
  }, [filters, setFilters]);

  return { filterValues, setFilters, filterParams };
};
