declare global {
  const __BUILD_TIME__: string;
  const __APP_ENV__: 'development' | 'production' | 'test';
}

export enum States {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
  Invited = 'invited',
}

export enum Roles {
  ACCOUNT_ADMIN = 1,
  PRODUCER = 2,
  FINTARY_ADMIN = 3,
  ACCOUNT_ADMIN_RO = 4,
  PRODUCER_RO = 5,
  FINTARY_ADMIN_RO = 6,
  DATA_SPECIALIST = 7,
  DATA_SPECIALIST_RO = 8,
}

export interface ErrorResponse {
  error: string;
}

export interface UserRolesAccounts {
  // id: number;
  account_id: string;
  // user_id: string;
  role_id: Roles;
  // created_at: Date;
  // created_by: string;
  // updated_at: Date;
  // updated_by: string;
  // state: States;
}

export interface UserCheck {
  userOverallState: States;
  userEmail?: string;
  userOnboardingNeeded: boolean;
  accountOnboardingNeeded: boolean;
  userAccounts: UserRolesAccounts[];
}

export interface AnyObj {
  [key: string]: any;
}

export enum States {
  INVITED = 'invited',
  PENDING = 'pending',
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export interface Field {
  id?: string;
  label: string;
  description?: string;
  table?: string;
  type?: FieldTypes;
  multiple?: boolean;
  options?: string[] | object[];
  matches?: string[];
  enabled?: boolean;
  readOnly?: boolean;
  global?: boolean;
  required?: boolean;
  copyable?: boolean;
  reconciler?: boolean;
  subPolicyDataIfEmpty?: boolean;
  queryParamValue?: string;
  queryParamName?: string;
  normalizer?: (value: any) => any;
  formatter?: (value: any, collectionVals?: any[] | any) => any;
  validator?: (value: any) => boolean;
  tableFormatter?: (value: any, row?: any) => any;
  optionFormatter?: (value: any) => any;
  optionValuer?: (value: any) => any;
  queryFields?: string[];
  render?: (value: any, row?: any, setter?: any) => any;
}

export interface DateRange {
  id?: number;
  start_date: Date | null;
  end_date: Date | null;
  type: DateRangesTypes;
  notes?: string | null;
  name?: string | null;
}

export enum savedReportsGroupsTemplates {
  COMMISSION_PAYOUT = 'commission_payout',
}

export enum FieldTypes {
  BOOLEAN = 'boolean',
  CODE = 'code',
  CURRENCY = 'currency',
  CUSTOM = 'custom',
  DATE = 'date',
  DIVIDER = 'divider',
  DYNAMIC_SELECT = 'dynamic-select',
  FIELD_MATCHER = 'field-matcher',
  HEADING = 'heading',
  INTEGER = 'integer',
  PERCENTAGE = 'percentage',
  RATE_SCHEDULE = 'rate-schedule-annual',
  SELECT = 'select',
  DRAGABLE_SELECT = 'dragable-select',
  SUB_HEADING = 'sub-heading',
  TEXT = 'text',
}

export enum GlobalStateCodes {
  FE_INCOMPATIBLE = 'FEIncompatible',
  FE_OUT_OF_DATE = 'FEOutOfDate',
}

export enum ReferralTypes {
  AGENT = 'agent',
  REGION = 'region',
  RECRUITING = 'recruiting',
  OTHER = 'other',
}

export enum DateRangesTypes {
  COMP_GRID_CRITERION = 'comp_grid_criterion',
  COMP_GRID_RATES = 'comp_grid_rates',
  ANY = 'any',
}

export enum DocumentTypes {
  STATEMENT = 'statement',
  REPORT = 'report',
  COMPGRID = 'compgrid',
}

export enum DocumentPreviewKeys {
  PREVIEW = 'preview',
  OVERRIDE = 'override',
  ORIGINAL = 'original',
}

export enum DocumentProcessActionTypes {
  ADD_MAPPING = 'Add mapping',
  EDIT_MAPPING = 'Edit mapping',
  SELECT_MAPPING = 'Select mapping',
  ADD_DATA = 'Add data',
  EDIT_DATA = 'Edit data',
  DELETE_DATA = 'Delete data',
  SELECT_PROCESSOR = 'Select processor',
  FIX_EXTRACTION = 'Fix extraction',
  SELECT_SPREADSHEET = 'Select spreadsheet',
  SELECT_METHOD = 'Select method',
  SELECT_GEMINI_PROMPT = 'Select Gemini prompt',
  FILTER_DATA = 'Filter data',
  SELECT_COMPANY = 'Select company',
}

export type DocumentProcessTypes =
  | 'Add mapping'
  | 'Edit mapping'
  | 'Select mapping'
  | 'Add data'
  | 'Edit data'
  | 'Delete data'
  | 'Select processor'
  | 'Fix extraction'
  | 'Select spreadsheet'
  | 'Select method'
  | 'Select company'
  | 'Filter data'
  | 'Select Gemini prompt';
