import { getProcessingLogDataDesc } from './processingLogDataDesc';
import Formatter from '@/services/Formatter';

export const adminProcessingLogDataDesc = getProcessingLogDataDesc();

adminProcessingLogDataDesc.table += '?isAdmin=true';
adminProcessingLogDataDesc.fields.unshift({
  id: 'account_id',
  label: 'Account',
  default: '',
  formatter: (val, row, _, caller) => {
    if (caller === 'form') {
      return row.account?.name || '';
    }

    const formatter = Formatter.getLinkChipFormatter(
      'name',
      'str_id',
      '/admin/accounts?id='
    );

    return formatter(row.account);
  },
  readOnly: true,
});
