import DataView from '@/components/DataView';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';

const View = () => {
  const { userRole } = useRoleStore();
  const { data: compGrids, isLoading: isLoadingCompGrids } = API.getBasicQuery(
    'comp-grids',
    'is_dynamic_select=true'
  );

  const dataDesc = {
    label: 'Comp grid levels',
    table: 'comp-grids/levels',
    editable: true,
    copyable: true,
    bulkAdd: true,
    fields: [
      {
        id: 'comp_grid_id',
        label: 'Comp grid',
        type: 'dynamic-select',
        table: 'comp-grids',
        field: 'id',
        formatter: Formatter.getDynamicSelectFormatter(),
        optionFormatter: (o) => o.name,
        optionValuer: (o) => o.id,
        required: true,
        bulkAddSelect: true,
      },
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'effective_date',
        label: 'Effective date',
        type: 'date',
        formatter: Formatter.date,
      },
      {
        id: 'notes',
        label: 'Notes',
      },
      {
        id: 'created_at',
        label: 'Created',
        formatter: Formatter.dateTime,
        condition: (val) => val.id,
      },
      {
        id: 'updated_at',
        label: 'Last updated',
        formatter: Formatter.dateTime,
        condition: (val) => val.id,
      },
    ],
    filterConfigs: {
      comp_grid_id: { type: 'select', label: 'Comp grids', options: {} },
    },
  };

  if (Array.isArray(compGrids) && compGrids.length > 0) {
    compGrids.forEach((compGrid, i) => {
      dataDesc.filterConfigs.comp_grid_id.options[compGrid.id] = {
        id: compGrid.id,
        str_id: compGrid.str_id,
        label: compGrid.name,
        query: { comp_grid_id: compGrid.id },
      };
    });
  }

  const rolesWithEditAccess = [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST];

  return (
    <DataView
      dataDesc={dataDesc}
      readOnly={!userRole || !rolesWithEditAccess.includes(userRole)}
      viewOnly={!userRole || !rolesWithEditAccess.includes(userRole)}
    />
  );
};

export default View;
