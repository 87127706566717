import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';

import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';

type StorageFileParams = {
  path: string;
  file: File;
};

const useUploadStorageFile = () => {
  const [fileParams, setUploadFileParams] = useState<StorageFileParams[]>([]);
  const fileSignedUrlLoader = API.getMutation('storage/getSignedUrl', 'POST');
  const [isUploaded, setIsUploaded] = useState(false);
  const { showSnackbar } = useSnackbar();

  const getSignedUrl = async ({ path, file_type }) => {
    try {
      const res = await fileSignedUrlLoader.mutateAsync({
        file_path: path,
        file_type: file_type,
        action: 'write',
      });
      return res.data;
    } catch (error: any) {
      console.error(error.message || error);
      showSnackbar(error.message || error, 'error');
      return null;
    }
  };

  const uploadFilesBySignedUrl = async () => {
    const signedUrlsPromise = fileParams.map(async ({ path, file }) => {
      const signedUrl = (await getSignedUrl({
        path,
        file_type: file.type,
      })) as string;
      return { signedUrl, file };
    });

    try {
      const signedUrls = await Promise.all(signedUrlsPromise);
      const uploadPromises = signedUrls.map(async ({ signedUrl, file }) => {
        const res = await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
            'Content-Length': file.size.toString(),
          },
          body: file,
        });
        if (!res.ok) {
          showSnackbar('Failed to upload file', 'error');
          setIsUploaded(false);
          return;
        }
        return res;
      });
      await Promise.all(uploadPromises);
      setIsUploaded(true);
    } catch (error: any) {
      console.error(error.message || error);
      showSnackbar(error.message || error, 'error');
      captureException(error);
      setIsUploaded(false);
      return;
    }
  };

  useEffect(() => {
    if (fileParams && fileParams.length) {
      uploadFilesBySignedUrl();
    }
  }, [fileParams]);

  return { setUploadFileParams, isUploaded };
};

export default useUploadStorageFile;
