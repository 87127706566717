import { Box, useMediaQuery } from '@mui/material';
import hotkeys from 'hotkeys-js';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useNavigate } from 'react-router-dom';

import AppDrawer from '@/components/AppDrawer';
import EmptyState from '@/components/EmptyState';
import Onboarding from '@/components/Onboarding';
import LandingImg from '@/illustrations/landing_tp.png';
import { useAccountStore } from '@/store';

const hotkeyDefs = {
  '/admin/accounts': 'g+a',
  '/admin/users': 'g+u',
  '/commissions': 'g+c',
  '/documents': 'g+d',
  '/insights': 'g+i',
  '/policies': 'g+p',
  '/reconciliation': 'g+o',
  '/reports': 'g+r',
  '/settings': 'g+s',
  '/views': 'g+v',
};

export default function Layout(props: any) {
  const { user, Header } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { selectedAccount, userState } = useAccountStore();

  const createHotkey = (hotkey, path) => {
    hotkeys(hotkey, (e) => {
      e.preventDefault();
      navigate(path);
    });
  };

  const createHotkeys = () => {
    Object.entries(hotkeyDefs).forEach(([k, v]) => {
      createHotkey(v, k);
    });
  };

  useEffect(() => {
    createHotkeys();
    return () => {
      hotkeys.unbind();
    };
  }, [navigate]);

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  const slugLabel = window.location.pathname
    .split('/')
    .map(capitalize)
    .map((s) => s.replace('-', ' '))
    .filter((s) => s)
    .join(' - ');

  const appBarHeight = isMobile ? '56px' : '64px';

  return (
    <>
      <Helmet>
        <title>Fintary{slugLabel && ` - ${slugLabel}`}</title>
      </Helmet>
      <Box className="flex flex-col w-screen h-screen overflow-hidden">
        <Box sx={{ height: appBarHeight }}>{Header}</Box>
        {user && userState ? (
          userState.userOverallState !== 'active' ? (
            <Onboarding userState={userState} />
          ) : (
            <>
              {selectedAccount && Object.keys(selectedAccount).length > 0 ? (
                <Box
                  sx={{
                    height: `calc(100vh - ${appBarHeight})`,
                    display: 'flex',
                  }}
                >
                  <AppDrawer />
                  <Outlet />
                </Box>
              ) : null}
            </>
          )
        ) : (
          <EmptyState
            image={<img src={LandingImg} width="100%" />}
            title="Fintary"
            description="One place for all your financial operations"
            size="large"
          />
        )}
      </Box>
    </>
  );
}
