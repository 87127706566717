import React from 'react';

import CriteriaActionsViewer from '@/components/ToolsPage/DataUpdateTools/CriteriaActionsViewer';
import {
  DataEntities,
  Fields,
} from '@/components/ToolsPage/DataUpdateTools/types';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { FieldTypes } from '@/types';
import CriteriaActionsConfig, {
  CriteriaActionsConfigFieldTypes,
} from '@/components/ToolsPage/DataUpdateTools/CriteriaActionsConfig';
import DataUpdateConfigGroups from '@/components/ToolsPage/DataUpdateTools/DataUpdateConfigGroups';

interface DataUpdateConfigProps {
  fields: Fields;
}

const DataUpdateConfig: React.FC<DataUpdateConfigProps> = ({ fields }) => {
  const dataSpec = {
    label: 'Data update config',
    table: 'data-update/config',
    fields: {
      group: {
        id: 'group',
        label: 'Group',
        table: 'data-update/groups',
        type: FieldTypes.CUSTOM,
        render: (field, row, setter, dynamicSelects) => (
          <DataUpdateConfigGroups
            field={field}
            row={row}
            setter={setter}
            dynamicSelects={dynamicSelects}
          />
        ),
        enabled: true,
      },
      data_entity: {
        label: 'Data entity',
        type: FieldTypes.SELECT,
        options: [DataEntities.COMMISSIONS, DataEntities.POLICIES],
        enabled: true,
      },
      name: {
        label: 'Name',
        enabled: true,
      },
      notes: {
        label: 'Notes',
        enabled: true,
      },
      data_update_criteria: {
        id: 'data_update_criteria',
        label: 'Criteria',
        table: 'data-update/criteria',
        type: FieldTypes.CUSTOM,
        render: (field, row, setter, dynamicSelects) => (
          <CriteriaActionsConfig
            data={row}
            field={field}
            type={CriteriaActionsConfigFieldTypes.CRITERIA}
            setter={setter}
            dynamicSelects={dynamicSelects}
            fields={fields}
          />
        ),
        tableFormatter: (field, row) => {
          return field.map((f) => (
            <CriteriaActionsViewer
              fieldData={f.data_update_criteria}
              rowData={row}
              fields={fields}
            />
          ));
        },
        enabled: true,
      },
      data_update_actions: {
        id: 'data_update_actions',
        label: 'Action',
        table: 'data-update/actions',
        type: FieldTypes.CUSTOM,
        render: (field, row, setter, dynamicSelects) => (
          <CriteriaActionsConfig
            data={row}
            field={field}
            type={CriteriaActionsConfigFieldTypes.ACTION}
            setter={setter}
            dynamicSelects={dynamicSelects}
            fields={fields}
          />
        ),
        tableFormatter: (field, row) => {
          return field.map((f) => (
            <CriteriaActionsViewer
              fieldData={f.data_update_actions}
              rowData={row}
              fields={fields}
            />
          ));
        },
        enabled: true,
      },
    },
  };

  return <EnhancedDataView dataSpec={dataSpec} hideExport />;
};

export default DataUpdateConfig;
