import {
  Close,
  DeleteOutline,
  GroupAdd,
  InfoOutlined,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import API from '@/services/API';
import { LoadingContext } from '@/contexts/LoadingContext';
import { IAgents } from './index.d';
import useSnackbar from '@/contexts/useSnackbar';

const AgentsAdd = ({ open, setOpen }) => {
  const [agents, setAgents] = useState<IAgents[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<IAgents[]>([]);

  const { showSnackbar } = useSnackbar();

  const [agentHeadDef] = useState<any[]>([
    {
      field: 'checkbox',
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      showDisabledCheckboxes: true,
      pinned: 'left',
      valueFormatter: () => '',
      width: 48,
      minWidth: 24,
      cellStyle: { padding: '0px 12px' },
    },
    {
      field: 'name',
      headerName: 'Agent Name',
      // check if the value includes "," if so, reverse the order
      valueGetter: (params) => {
        const { first_name, last_name } = params.data;
        return first_name.includes(',')
          ? `${last_name} ${first_name.replace(',', '')}`
          : `${first_name} ${last_name}`;
      },
    },
    {
      field: 'first_name',
      headerName: 'First Name',
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
    },
    {
      field: 'agent_code',
      headerName: 'Agent Code',
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: true,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      editable: true,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      editable: true,
    },
    {
      headerName: 'Actions',
      field: '',
      cellRenderer: (params) => {
        return (
          <IconButton
            onClick={() => {
              const target = params.api.getRowNode(params.rowIndex);
              const { str_id } = target.data;
              setAgents((prev) => {
                const result = [...prev];
                const index = result.findIndex((row) => row.str_id === str_id);
                result.splice(index, 1);
                return result;
              });
            }}
          >
            <DeleteOutline />
          </IconButton>
        );
      },
    },
  ]);

  const { setLoadingConfig } = useContext(LoadingContext);
  const reportDataRef = useRef<AgGridReact<any>>(null);

  const [{ isLoading, data: reportData }] = API.getBasicQueryAll([
    'report_data/agents',
  ]);

  const contactsPoster = API.getMutation('report_data/agents', 'POST');

  useEffect(() => {
    return () => {
      setAgents([]);
      setSelectedAgents([]);
    };
  }, []);

  useEffect(() => {
    setLoadingConfig({
      loading: isLoading,
      message: 'Generating...',
    });
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && reportData.data) {
      // filter out the data which is already generated to agents
      setAgents(reportData.data);
    }
  }, [isLoading, reportData]);

  const handleClose = () => {
    setAgents([]);
    setSelectedAgents([]);
    setOpen(false);
  };

  const handleOk = () => {
    setLoadingConfig({
      loading: true,
      message: 'Creating agents...',
    });
    const params = {
      agents: selectedAgents,
    };

    contactsPoster.mutate(params as any, {
      onSuccess: () => {
        setOpen({
          open: false,
          refresh: true,
        });
        setLoadingConfig({
          loading: false,
          message: '',
        });
      },
      onError: (error: any) => {
        showSnackbar(error, 'error');
        setLoadingConfig({
          loading: false,
          message: '',
        });
      },
    });
  };

  // select rows
  const onSelectionChanged = useCallback(() => {
    const selectedRows = reportDataRef.current?.api.getSelectedRows() || [];
    setSelectedAgents(selectedRows);
  }, []);

  return (
    <Dialog open={open} fullScreen sx={{ padding: '20px' }}>
      <DialogTitle>
        <header className="flex justify-between items-center">
          <Box className="flex justify-between items-center">
            <GroupAdd />
            <Box className="pl-2">Bulk add agents</Box>
          </Box>
          <Box
            className="group p-2 cursor-pointer hover:text-blue-600"
            onClick={handleClose}
          >
            <Close className="group-hover:rotate-180 transition-all origin-center" />
          </Box>
        </header>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              height: '100%',
              flex: 3,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', pb: 2 }}
            >
              Agents from policy data
              {agents.length ? (
                <Tooltip title="Double-click cell to edit" placement="left">
                  <InfoOutlined sx={{ color: '#aaa' }} />
                </Tooltip>
              ) : (
                ''
              )}
            </Box>

            <div
              style={{
                width: '100%',
                height: '100%',
                border: '1px solid #eee',
                flex: 1,
              }}
              className="ag-theme-material"
            >
              <AgGridReact
                headerHeight={40}
                rowData={agents}
                ref={reportDataRef}
                columnDefs={agentHeadDef}
                rowSelection={'multiple'}
                suppressRowClickSelection={true}
                // isRowSelectable={(row) => !row.data.report_contacts}
                onSelectionChanged={onSelectionChanged}
                defaultColDef={{
                  flex: 1,
                  minWidth: 180,
                  resizable: true,
                }}
              />
            </div>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          variant="contained"
          disabled={!selectedAgents.length}
        >
          Add agents
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgentsAdd;
