import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';

const AgentCommissionSplitConfig = ({
  title,
  data,
  contacts,
  split,
  setter,
  setterKey,
  dynamicSelects,
}) => {
  const { selectedAccount } = useAccountStore();

  const totalPct = Object.values(split ?? {}).reduce(
    (acc, cur) =>
      +acc +
      (Number.isNaN(Number.parseFloat(cur)) ? 0 : Number.parseFloat(cur)),
    0
  );

  const contactsFromSplit = new Set(Object.keys(split ?? {}));
  const contactsFromPolicy = new Set(contacts ?? []);
  const contactsWithSplits = Array.from(
    new Set([...contactsFromSplit, ...contactsFromPolicy])
  );

  return (
    <Box sx={{ mb: 1, width: '100%' }}>
      <Typography>{title}</Typography>
      {contactsWithSplits.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {contactsWithSplits.map((contact, i) => (
              <Box sx={{ mr: 1, mt: 1 }}>
                <TextField
                  key={contact}
                  value={split?.[contact] ?? 0}
                  onChange={(e) => {
                    setter({
                      ...data,
                      [setterKey]: {
                        ...(split ?? {}),
                        [contact]: e.target.value,
                      },
                    });
                  }}
                  label={Formatter.contact(
                    dynamicSelects?.find((val) => val.str_id === contact) ?? {},
                    { account_id: selectedAccount?.accountId }
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': !contactsFromPolicy.has(contact)
                      ? { background: '#f0f0f0' }
                      : {},
                  }}
                  helperText={
                    !contactsFromPolicy.has(contact)
                      ? 'Agent not assigend to policy'
                      : null
                  }
                />
                <IconButton
                  onClick={() => {
                    const newData = JSON.parse(JSON.stringify(data));
                    delete newData[setterKey][contact];
                    setter(newData);
                  }}
                  sx={{ mt: 0.4 }}
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Typography
              sx={totalPct === 100 ? {} : { color: 'red', fontWeight: 500 }}
            >{`Total: ${totalPct}%`}</Typography>
            {totalPct !== 100 && (
              <Typography variant="caption">
                Agents split should add up to 100
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Typography variant="body2" sx={{ ml: 1 }}>
          No agents attached to this policy
        </Typography>
      )}
    </Box>
  );
};

export default AgentCommissionSplitConfig;
