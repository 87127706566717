import React from 'react';

import {
  DataEntities,
  Fields,
} from '@/components/ToolsPage/DataUpdateTools/types';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { FieldTypes } from '@/types';
import CriteriaActionsViewer from '@/components/ToolsPage/DataUpdateTools/CriteriaActionsViewer';

interface DataUpdateCriteriaProps {
  fields: Fields;
}

const DataUpdateCriteria: React.FC<DataUpdateCriteriaProps> = ({ fields }) => {
  const dataSpec = {
    label: 'Data update criteria',
    table: 'data-update/criteria',
    fields: {
      data_entity: {
        label: 'Data entity',
        type: FieldTypes.SELECT,
        options: [DataEntities.COMMISSIONS, DataEntities.POLICIES],
        enabled: true,
      },
      name: {
        label: 'Name',
        enabled: true,
      },
      data_update_criteria: {
        label: 'Data update criteria',
        type: FieldTypes.FIELD_MATCHER,
        fieldGetter: (row) => {
          return fields[row.data_entity as DataEntities];
        },
        fields: Object.values(fields),
        tableFormatter: (field, row) => {
          return (
            <CriteriaActionsViewer
              fieldData={field}
              rowData={row}
              fields={fields}
            />
          );
        },
        enabled: true,
      },
      notes: {
        label: 'Notes',
        enabled: true,
      },
    },
  };

  return <EnhancedDataView dataSpec={dataSpec} />;
};

export default DataUpdateCriteria;
