import { LaunchOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DataView from '@/components/DataView';
import ResultsCard from '@/components/molecules/ResultsCard';

const dataDesc = {
  label: 'Reconciliations history',
  table: 'reconciliations',
  editable: true,
  fields: [
    // {
    //   id: 'created_at',
    //   label: 'Time',
    //   formatter: (date) => date.toLocaleString(),
    // },
    {
      id: 'result',
      label: 'Stats',
      formatter: (val) => (
        <ResultsCard key={val?.id} data={val} variant="wide" />
      ),
      readOnly: true,
    },
    {
      id: 'settings',
      label: 'Settings',
      formatter: (val) => <pre>{JSON.stringify(val, null, 2)}</pre>,
      readOnly: true,
    },
    {
      id: 'notes',
      type: 'text-multiline',
      label: 'Notes',
    },
    {
      id: 'str_id',
      type: 'computed',
      label: 'View',
      // formatter: added in react below
    },
    {
      id: 'reconciler_flow_str_id',
      label: 'Reconciler flow',
      readOnly: true,
      // formatter: added in react below
    },
  ],
};

const ReconciliationsHistoryView = () => {
  const getLinkFormatter =
    (route, queryParam = 'id') =>
    (id) => (
      <IconButton component={Link} to={`/${route}?${queryParam}=${id}`}>
        <LaunchOutlined />
      </IconButton>
    );

  const strIdTarget = dataDesc.fields.find((f) => f.id === 'str_id');
  strIdTarget &&
    (strIdTarget.formatter = getLinkFormatter(
      'reconciliation',
      'reconciliation_id'
    ));

  const flowIdTarget = dataDesc.fields.find(
    (f) => f.id === 'reconciler_flow_str_id'
  );
  flowIdTarget &&
    (flowIdTarget.formatter = getLinkFormatter('reconciliation/flows'));

  return <DataView dataDesc={dataDesc} />;
};

ReconciliationsHistoryView.propTypes = {
  user: PropTypes.object,
};

export default ReconciliationsHistoryView;
