import { History } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import 'allotment/dist/style.css';

import useSnackbar from '@/contexts/useSnackbar';

const HistoryDialog = ({
  setShowHistoryList,
  showHistoryList,
  historyList,
  setCurrentCode,
}) => {
  const { showSnackbar } = useSnackbar();
  return (
    <Drawer
      anchor="right"
      sx={{
        zIndex: 9999,
      }}
      open={showHistoryList}
      onClose={() => setShowHistoryList(false)}
    >
      <Box className="flex items-center justify-between p-4">
        <Typography variant="h6">History List</Typography>
      </Box>
      <List>
        {historyList.map((item) => (
          <ListItem key={item.created_at}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: '#2196f3' }}>
                <History />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={
                <>
                  <Typography variant="body2" color="text.secondary">
                    {item.method}-{item.type}
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ ml: 1 }}
                    >
                      {new Date(item.created_at).toLocaleString()}
                    </Typography>
                  </Typography>
                  <Button
                    onClick={() => {
                      setCurrentCode(item.processor);
                      setShowHistoryList(false);
                      showSnackbar('Successfully reverted', 'success');
                    }}
                  >
                    Revert to
                  </Button>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default HistoryDialog;
