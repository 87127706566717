import { Box, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { dateOrDefault } from 'common/helpers';

import MultiSelect from '@/components/molecules/MultiSelect';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import useSnackbar from '@/contexts/useSnackbar';
import DataUpdateGroupViewer from '@/components/ToolsPage/DataUpdateTools/DataUpdateGroupViewer';
import { Fields } from './types';

interface RunDataUpdateProps {
  fields: Fields;
}

const RunDataUpdate: React.FC<RunDataUpdateProps> = ({ fields }) => {
  const { showSnackbar } = useSnackbar();

  const [loading, setloading] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | string | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | string | null>(null);
  const [selectedDocs, setSelectedDocs] = useState<string[]>([]);
  const [selectedDataUpdateGroup, setSelectedDataUpdateGroup] =
    useState<string>('');
  const [filteredDataUpdateInfo, setFilteredDataUpdateInfo] = useState<any[]>(
    []
  );

  const { data: _documents } = API.getBasicQuery(
    'documents',
    'is_dynamic_select=true&limit=300'
  );
  const documents = _documents?.data ?? [];

  const { data: _dataUpdateConfig } = API.getBasicQuery('data-update/config');
  const dataUpdateInfo = useMemo(
    () => _dataUpdateConfig ?? [],
    [_dataUpdateConfig]
  );
  const dataUpdateGroups: string[] = Array.from(
    new Set(dataUpdateInfo.map((data: { group: string }) => data.group))
  );

  const runCompensationTypePoster = API.getMutation(
    'admin/data-update',
    'POST'
  );

  useEffect(() => {
    if (selectedDataUpdateGroup) {
      const filteredInfo = dataUpdateInfo.filter(
        (data: { group: string }) => data.group === selectedDataUpdateGroup
      );
      setFilteredDataUpdateInfo(filteredInfo);
    } else {
      setFilteredDataUpdateInfo([]);
    }
  }, [selectedDataUpdateGroup, dataUpdateInfo]);

  return (
    <>
      <Box sx={{ mt: 1, mb: 1, ml: 1 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Select data update group to run
        </Typography>
        <Select
          label="Data update group"
          value={selectedDataUpdateGroup}
          onChange={(event) => setSelectedDataUpdateGroup(event.target.value)}
          sx={{ width: 200, mt: 1.5 }}
        >
          {dataUpdateGroups.map((group) => (
            <MenuItem key={group} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select>
        <DataUpdateGroupViewer
          dataUpdateData={filteredDataUpdateInfo}
          fields={fields}
        />
      </Box>
      <Box sx={{ mt: 1, mb: 1, ml: 1 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Commission filters
        </Typography>
        <BasicDateRangePicker
          range={{
            startDate: startDate,
            startDateLabel: 'Processing date start',
            endDate: endDate,
            endDateLabel: 'Processing date end',
          }}
          onChange={(range) => {
            setStartDate(range.startDate);
            setEndDate(range.endDate);
          }}
          justify="left"
          width={210}
        />
        <MultiSelect<any, any>
          label="Document filter"
          values={documents}
          valuer={(o) => o.str_id}
          formatter={(o) => {
            return o
              ? `${o?.filename} (${Formatter.date(o?.created_at, { format: 'YYYY/MM/DD hh:mmA' })})`
              : '';
          }}
          selectedValues={selectedDocs}
          setSelectedValues={(values) =>
            setSelectedDocs(values.filter((value) => value !== ''))
          }
          sx={{ width: 200, mt: 1.5 }}
          enableSearch
          paginate
        />
        <Box sx={{ mt: 1 }}>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={async () => {
              setloading(true);
              const params = {
                data_update_group: selectedDataUpdateGroup,
                processing_date_start: dateOrDefault(startDate, undefined),
                processing_date_end: dateOrDefault(endDate, undefined),
                document_str_ids: selectedDocs,
              };

              const response =
                await runCompensationTypePoster.mutateAsync(params);
              if (response.error) {
                showSnackbar(response.error, 'error');
              } else {
                // TODO: Define a set of variables in the response that indicates what fields in which data entity were updated.
                showSnackbar(`Updated ${response.data} records.`, 'success');
              }
              setloading(false);
            }}
            sx={{ mr: 1 }}
          >
            Run data update
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default RunDataUpdate;
