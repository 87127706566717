import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useContext, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { LoadingContext } from 'contexts/LoadingContext';
import { removeLeadingTrailingChar } from 'common/helpers';

import Spreadsheet from '@/services/Spreadsheet';
import FilePreview from '@/common/preview';
import { XLS_CSV_TYPES, SpreadsheetModel } from '@/common/preview/model';
import API from '@/services/API';
import usePreviewParams from '@/contexts/usePreviewParams';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import { DocumentPreviewKeys } from '@/types';

interface FileDialogPreviewProps {
  showPreview: boolean;
  setShowPreview: (val: boolean) => void;
  fileId?: string;
}

const FileDialogPreview: FC<FileDialogPreviewProps> = ({
  fileId,
  showPreview,
  setShowPreview,
}) => {
  const [previewFile, setPreviewFile] = useState<any>();

  const [spreadsheet, setSpreadsheet] = useState<SpreadsheetModel>();

  const { setLoadingConfig } = useContext(LoadingContext);
  const { previewType } = usePreviewParams();

  const { data: documentData, isLoading } = API.getBasicQuery(
    'documents',
    `id=${fileId}`,
    !!fileId
  ) as { data: { data: any[]; count: number }; isLoading: boolean };

  const { downloadFile } = useDownloadStorageFile();

  useEffect(() => {
    setLoadingConfig({
      loading: isLoading,
      message: 'Loading...',
    });
  }, [isLoading]);

  useEffect(() => {
    const readFile = async () => {
      const doc = documentData?.data[0];
      if (isLoading || !doc) return;
      try {
        const file = await downloadFile({
          endpoint: 'documents',
          document_str_id: doc.str_id,
          file_preview_type:
            previewType === DocumentPreviewKeys.OVERRIDE
              ? 'override'
              : 'original',
        });
        setPreviewFile(file);
      } catch (error) {
        console.error(error);
      }
    };
    readFile();
  }, [documentData, isLoading]);

  useEffect(() => {
    // excel, csv need to load the raw data
    const setExcelData = async () => {
      if (previewFile && XLS_CSV_TYPES.includes(previewFile?.type)) {
        const res = (await Spreadsheet.loadSpreadsheet(
          previewFile
        )) as unknown as SpreadsheetModel;
        setSpreadsheet(res);
      }
    };
    setExcelData();
  }, [previewFile]);

  return (
    <Dialog
      open={showPreview}
      onClose={() => {
        setShowPreview(false);
      }}
      fullScreen
      sx={{ p: 2 }}
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Box className="flex justify-between items-center">
          <Box>Document preview</Box>
          <Box
            className="group cursor-pointer hover:text-blue-600"
            onClick={() => {
              setShowPreview(false);
            }}
          >
            <CloseIcon className="group-hover:rotate-180 transition-all origin-center" />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        <FilePreview
          previewFile={previewFile}
          previewWith={window.innerWidth * 0.8}
          spreadsheet={spreadsheet}
          setSpreadsheet={setSpreadsheet}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!previewFile}
          onClick={() => {
            saveAs(
              previewFile,
              removeLeadingTrailingChar(previewFile.name, '"')
            );
          }}
        >
          Download
        </Button>
        <Button
          onClick={() => {
            setShowPreview(false);
          }}
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileDialogPreview;
