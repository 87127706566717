import { parseDate as _parseDate } from 'chrono-node';

/**
 * Parse date string (with natural language) to date object
 * @param dateStr date string
 * @param format date format
 * @returns Date object | string | null
 */
const parseDate = (dateStr: string, format?: string) => {
  if (!dateStr) return null;
  const date = _parseDate(dateStr);

  if (format) {
    const dateStr = date?.toLocaleDateString(format);
    return dateStr;
  }
  return date;
};

export const desc_parseDate = `
Parse date string (with nature language) to date object
eg:
  parseDate('2021-01-01') => Date object;
  parseDate('2021-01-01', 'YYYY-MM-DD') => Date object;
  parseDate('Today is May 25, 2024') => Date object;
  parseDate('Today is May 25, 2024', 'en') => '05/25/2024';
`;

export default parseDate;
