import { Box } from '@mui/material';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useIdleTimer } from 'react-idle-timer';

const TimerCount = ({ count }, ref) => {
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [timeFormat, setTimeFormat] = useState('');
  const [isIdle, setIsIdle] = useState(false);

  const timerRef = useRef<NodeJS.Timeout>();

  const {
    isIdle: isUserIdle,
    getTotalActiveTime,
    pause,
    resume,
  } = useIdleTimer({
    timeout: 30000,
    debounce: 500,
    immediateEvents: ['visibilitychange'],
    onIdle: () => {
      if (isUserIdle()) {
        setIsIdle(true);
      }
    },
    onActive: () => {
      if (!isUserIdle()) {
        setIsIdle(false);
      }
    },
  });

  useEffect(() => {
    if (isIdle) {
      pause();
    } else {
      resume();
    }
  }, [isIdle]);

  useEffect(() => {
    const customSetInterval = (callback: () => void, delay: number) => {
      const repeat = () => {
        callback();
        return setTimeout(repeat, delay);
      };
      return setTimeout(repeat, delay);
    };

    timerRef.current = customSetInterval(() => {
      setTotalSeconds(Math.floor(getTotalActiveTime() / 1000));
    }, 500);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  useEffect(() => {
    let res = '';
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    if (days > 0) {
      res += `${days}d `;
    }
    if (hours > 0) {
      res += `${hours}h `;
    }
    if (minutes > 0) {
      res += `${minutes}m `;
    }
    if (seconds > 0) {
      res += `${seconds}s `;
    }
    setTimeFormat(res);
  }, [totalSeconds]);

  useImperativeHandle(ref, () => ({
    totalSeconds,
  }));

  return (
    <>
      <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
        <Box sx={{ minWidth: '75px' }}>{`Time: ${timeFormat}`}</Box>
        <Box>{`Actions: ${count}`}</Box>
      </Box>
    </>
  );
};

export default forwardRef(TimerCount);
