import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useRef, useState } from 'react';

export default function SplitButton({
  startIcon = <></>,
  options = [],
  disabled = false,
  variant = 'outlined',
  loading = false,
  useLoadingBtn = false,
}) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const anchorRef = useRef(null);

  const handleClick = (option) => {
    try {
      setIsLoading(true);
      option.onClick();
    } catch (e) {
      console.error('Error: ', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        disabled={disabled || isLoading}
        variant={variant}
        disableElevation
        sx={{ height: 'fit-content' }}
      >
        {useLoadingBtn ? (
          <LoadingButton
            onClick={() => handleClick(options[0])}
            startIcon={startIcon}
            loading={loading}
            loadingPosition="start"
          >
            {options[0].label}
          </LoadingButton>
        ) : (
          <Button onClick={() => handleClick(options[0])} startIcon={startIcon}>
            {options[0].label}
          </Button>
        )}
        {options.length > 1 && (
          <Button
            onClick={handleToggle}
            sx={{ pl: 0, pr: 0.2, minWidth: 24, width: 24 }}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10000,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={5}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem sx={{ py: 0 }}>
                  {options
                    .filter((option, i) => i !== 0)
                    .map((option) => (
                      <Tooltip
                        title={option?.options?.tooltip ?? ''}
                        key={option.id}
                      >
                        <span>
                          <MenuItem
                            key={option.id}
                            onClick={() => handleClick(option)}
                            disabled={option?.options?.disabled}
                          >
                            {option.label}
                          </MenuItem>
                        </span>
                      </Tooltip>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
