import { create } from 'zustand';

type UploadStore = {
  uploadProgresses: { [filename: string]: UploadState };
};

type Setters = {
  setUploadProgress: (v: { filename: string; state: UploadState }) => void;
  resetProgresses: () => void;
};

const defaultValues: UploadStore = { uploadProgresses: {} };

const useUploadStore = create<UploadStore & Setters>((set, get) => ({
  ...defaultValues,
  resetProgresses: () => set({ uploadProgresses: {} }),
  setUploadProgress: (v) => {
    set({
      uploadProgresses: {
        ...get().uploadProgresses,
        [v.filename]: v.state,
      },
    });
  },
}));

export default useUploadStore;
