export interface CustomMethodConfig {
  start_date: Date;
  end_date?: Date;
  method: string;
  rate: number;
  payee_id?: number;
  multiplier: number;
  conditions?: CustomMethodCondition[];
}

export enum CustomMethodConditionOperator {
  EQUAL = 'eq',
  NOT_EQUAL = 'neq',
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  GREATER_THAN_OR_EQUAL = 'gte',
  LESS_THAN_OR_EQUAL = 'lte',
}

export enum CustomMethodConditionField {
  ACCOUNT_TYPE = 'account_type',
}

export enum CustomMethodConditionType {
  PAYEE_LEVEL_RATE_DIFFERENCE_THRESHOLD = 'payee_level_rate_difference_threshold',
}

export const CustomMethodConditionTypeLabel = {
  [CustomMethodConditionType.PAYEE_LEVEL_RATE_DIFFERENCE_THRESHOLD]:
    'Payee level rate difference threshold',
};

export const CustomMethodConditionOperatorLabel = {
  [CustomMethodConditionOperator.EQUAL]: 'Equal',
  [CustomMethodConditionOperator.NOT_EQUAL]: 'Not equal',
  [CustomMethodConditionOperator.GREATER_THAN]: 'Greater than',
  [CustomMethodConditionOperator.LESS_THAN]: 'Less than',
  [CustomMethodConditionOperator.GREATER_THAN_OR_EQUAL]:
    'Greater than or equal',
  [CustomMethodConditionOperator.LESS_THAN_OR_EQUAL]: 'Less than or equal',
};

export const CustomMethodOperatorOptions = Object.values(
  CustomMethodConditionOperator
).map((operator) => ({
  value: operator,
  label: CustomMethodConditionOperatorLabel[operator],
}));

export interface CustomMethodCondition {
  type: string;
  value: string;
  operator: CustomMethodConditionOperator;
  field: CustomMethodConditionField;
}
