import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DataProcessingAction } from 'common/globalTypes';

import EnhancedSelect from './EnhancedSelect';

export const fieldMatcherActions = [
  {
    id: DataProcessingAction.SET_FIELD_VALUE,
    label: 'Set field value',
  },
];

interface ActionPickerProps {
  value: any;
  row: any;
  fieldGetter: any;
  setValue: (val: any) => void;
  sx?: SxProps;
}

const ActionPicker: React.FC<ActionPickerProps> = ({
  value: actionObj = { field: '', action: '', value: '' },
  row,
  fieldGetter,
  setValue,
  sx = {},
}) => {
  const [selectedActionField, setSelectedActionField] = useState<string | null>(
    actionObj?.field
  );

  const selectedFields = fieldGetter(row);

  const setActionObject = (value, key) => {
    const updatedActionObj = { ...actionObj, [key]: value };

    setValue((prevData) => {
      return {
        ...prevData,
        data_update_actions: updatedActionObj,
      };
    });
  };

  const valueOptions = useMemo(() => {
    return selectedFields?.find((field) => field.id === selectedActionField)
      ?.options as string[];
  }, [selectedFields, selectedActionField]);

  return (
    <Box
      sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', width: '100%', ...sx }}
    >
      <EnhancedSelect
        label="Action"
        options={fieldMatcherActions}
        onChange={(v) => {
          setActionObject(v?.id, 'action');
        }}
        sx={{ width: 200, mr: 1 }}
      />

      <EnhancedSelect
        label="Field"
        options={selectedFields}
        onChange={(v) => {
          console.log(v);
          setActionObject((v as any)?.id, 'field');
          setSelectedActionField((v as any)?.id);
        }}
        sx={{ width: 200, mr: 1 }}
      />

      {selectedActionField && (
        // TODO: Migrate to EnhancedSelect when it supports string[] options
        <FormControl sx={{ width: 145 }}>
          <InputLabel>Value</InputLabel>
          <Select
            value={actionObj?.value || ''}
            onChange={(e) => setActionObject(e.target.value, 'value')}
            label="Value"
          >
            {(valueOptions ?? []).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default ActionPicker;
