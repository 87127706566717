import { Logout } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { getLocalData } from '@/utils/localStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/account';
import { EnableUpdateToggle } from './EnableUpdateToggle';

export const LoginAsUser = ({
  showAddDocument,
}: {
  showAddDocument: boolean;
}) => {
  const [loginAsUser, setLoginAsUser] = useState<any>();

  useEffect(() => {
    const user = getLocalData(LOCAL_STORAGE_KEYS.customLoginUser);
    if (user) {
      setLoginAsUser(user);
    }
  }, []);

  if (!loginAsUser) return null;

  return (
    <>
      <Box
        sx={{ pt: 0.5, mb: showAddDocument ? 0 : 1 }}
        display="flex"
        flexDirection="column"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box>
              <Divider />
            </Box>
          </Box>
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap', mx: 1 }}>
            Logged in as
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Box>
              <Divider />
            </Box>
          </Box>
        </Box>
        <Box sx={{ px: 1 }} display="flex" flexDirection="column">
          <Typography
            variant="body2"
            sx={{
              width: '100%',
              fontWeight: 'medium',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
          >
            {loginAsUser.email || loginAsUser.delegatorEmail}
          </Typography>
          <EnableUpdateToggle />
          <Button
            color="error"
            startIcon={<Logout />}
            variant="contained"
            onClick={() => {
              localStorage.clear();
              setLoginAsUser(null);
              localStorage.setItem('triggerReload', Date.now().toString());
              window.location.href = '/admin/accounts';
            }}
            sx={{ mt: 0.5 }}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </>
  );
};
