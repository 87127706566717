import React, { useState } from 'react';
import { InfoOutlined, SyncDisabled, SyncLock } from '@mui/icons-material';
import {
  Tooltip,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

import { FieldTypes } from '@/types';

interface SyncEndAdornmentProps {
  syncedFields: string[];
  syncId: string;
  fieldId: string;
  fieldType?: FieldTypes;
  data: any;
  onChange: (newOverrideFields: string[]) => void;
}

export const SyncEndAdornment: React.FC<SyncEndAdornmentProps> = ({
  syncedFields,
  syncId,
  fieldId,
  data,
  fieldType: type,
  onChange,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  if (!syncId || !syncedFields?.includes(fieldId)) {
    return null;
  }

  const isOverridden = data?.config?.overrideFields?.includes(fieldId);
  const icon = isOverridden ? <SyncDisabled /> : <SyncLock />;

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDialog(true);
  };

  const handleConfirm = () => {
    const overrideFields = data.config?.overrideFields ?? [];
    let newOverrideFields;
    if (overrideFields.includes(fieldId)) {
      newOverrideFields = overrideFields.filter((id) => id !== fieldId);
    } else {
      newOverrideFields = [...overrideFields, fieldId];
    }
    onChange(newOverrideFields);
    setOpenDialog(false);
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Tooltip
        title={
          isOverridden
            ? 'Sync is disabled for this field, click to enable. Current value will be replaced in the next sync.'
            : 'Sync is enabled for this field, click to disable. You will be able to manually set value.'
        }
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          onClick={handleClick}
          style={{
            position: 'relative',
            left: type === FieldTypes.DATE ? 0 : -16,
            cursor: 'pointer',
          }}
        >
          {icon}
        </Box>
      </Tooltip>

      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isOverridden ? 'Enable sync?' : 'Disable sync?'}
          <Tooltip
            title={
              isOverridden
                ? 'Existing will be replaced in the next sync'
                : 'You can resume syncing by toggling the sync button on this field'
            }
          >
            <InfoOutlined sx={{ color: '#aaa' }} />
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          Are you sure you want to {isOverridden ? 'enable' : 'disable'} sync
          for{' '}
          <Typography component="span" sx={{ fontWeight: 500 }}>
            {fieldId}
          </Typography>
          ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus variant="contained">
            {isOverridden ? 'Enable' : 'Disable'} sync
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
