import { camelCase } from 'lodash-es';
import { createTheme } from '@mui/material/styles';
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey as gray,
  blueGrey as blueGray,
} from '@mui/material/colors';

import { analytics, auth, firestore } from '@/firebase';

const colors = {
  red: {
    id: 'red',
    name: 'Red',
    import: red,
  },

  pink: {
    id: 'pink',
    name: 'Pink',
    import: pink,
  },

  purple: {
    id: 'purple',
    name: 'Purple',
    import: purple,
  },

  deepPurple: {
    id: 'deep-purple',
    name: 'Deep Purple',
    import: deepPurple,
  },

  indigo: {
    id: 'indigo',
    name: 'Indigo',
    import: indigo,
  },

  blue: {
    id: 'blue',
    name: 'Blue',
    import: blue,
  },

  lightBlue: {
    id: 'light-blue',
    name: 'Light Blue',
    import: lightBlue,
  },

  cyan: {
    id: 'cyan',
    name: 'Cyan',
    import: cyan,
  },

  teal: {
    id: 'teal',
    name: 'Teal',
    import: teal,
  },

  green: {
    id: 'green',
    name: 'Green',
    import: green,
  },

  lightGreen: {
    id: 'light-green',
    name: 'Light Green',
    import: lightGreen,
  },

  lime: {
    id: 'lime',
    name: 'Lime',
    import: lime,
  },

  yellow: {
    id: 'yellow',
    name: 'Yellow',
    import: yellow,
  },

  amber: {
    id: 'amber',
    name: 'Amber',
    import: amber,
  },

  orange: {
    id: 'orange',
    name: 'Orange',
    import: orange,
  },

  deepOrange: {
    id: 'deep-orange',
    name: 'Deep Orange',
    import: deepOrange,
  },

  brown: {
    id: 'brown',
    name: 'Brown',
    import: brown,
  },

  gray: {
    id: 'gray',
    name: 'Gray',
    import: gray,
  },

  blueGray: {
    id: 'blue-gray',
    name: 'Blue Gray',
    import: blueGray,
  },
};

const getColor = (colorId) => {
  if (!colorId) {
    return null;
  }

  const formatColorId = camelCase(colorId);

  return colors[formatColorId];
};

const defaultPrimaryColor = getColor(process.env.REACT_APP_THEME_PRIMARY_COLOR);
const defaultSecondaryColor = getColor(
  process.env.REACT_APP_THEME_SECONDARY_COLOR
);
const defaultDark = process.env.REACT_APP_THEME_DARK === 'true';

const defaultTheme = createTheme({
  palette: {
    primary: defaultPrimaryColor.import,
    secondary: defaultSecondaryColor.import,
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: { borderRadius: 24, minHeight: 34 },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: { borderRadius: 24 },
        grouped: { minWidth: 24 },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: { px: 3 },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { borderRadius: 24 },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      // styleOverrides: {
      //   select: {
      //     paddingTop: 6,
      //     paddingBottom: 5,
      //   },
      // },
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTableCell: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTablePagination: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiToggleButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: { borderRadius: 24 },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
  },
});

const appearance = {};

appearance.colors = colors;

appearance.defaultPrimaryColor = defaultPrimaryColor;
appearance.defaultSecondaryColor = defaultSecondaryColor;
appearance.defaultDark = defaultDark;

appearance.defaultTheme = defaultTheme;

appearance.isDefaultTheme = (theme) => {
  if (!theme) {
    return false;
  }

  if (
    theme.primaryColor.id === defaultPrimaryColor.id &&
    theme.secondaryColor.id === defaultSecondaryColor.id &&
    theme.dark === defaultDark
  ) {
    return true;
  }

  return false;
};

appearance.createTheme = (theme) => {
  if (!theme) {
    return null;
  }

  let { primaryColor } = theme;
  let { secondaryColor } = theme;

  if (!primaryColor || !secondaryColor) {
    return null;
  }

  primaryColor = getColor(primaryColor);
  secondaryColor = getColor(secondaryColor);

  if (!primaryColor || !secondaryColor) {
    return null;
  }

  const newTheme = createTheme({
    palette: {
      primary: primaryColor.import,
      secondary: secondaryColor.import,
    },
  });

  return newTheme;
};

appearance.changeTheme = (theme) => {
  return new Promise((resolve, reject) => {
    if (!theme) {
      reject(new Error('No theme'));

      return;
    }

    let { primaryColor } = theme;
    let { secondaryColor } = theme;
    const { dark } = theme;

    if (!primaryColor || !secondaryColor) {
      reject(new Error('No primary color or secondary color'));

      return;
    }

    primaryColor = getColor(primaryColor);
    secondaryColor = getColor(secondaryColor);

    if (!primaryColor || !secondaryColor) {
      reject(new Error('No primary color or secondary color'));

      return;
    }

    const { currentUser } = auth;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const { uid } = currentUser;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    const userDocumentReference = firestore.collection('users').doc(uid);

    userDocumentReference
      .update({
        theme: {
          primaryColor: primaryColor.id,
          secondaryColor: secondaryColor.id,
          dark,
        },
      })
      .then((value) => {
        analytics.logEvent('change_theme', {
          theme,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

appearance.changePrimaryColor = (primaryColor) => {
  return new Promise((resolve, reject) => {
    if (!primaryColor) {
      reject(new Error('No primary color'));

      return;
    }

    primaryColor = getColor(primaryColor);

    if (!primaryColor) {
      reject(new Error('No primary color'));

      return;
    }

    const { currentUser } = auth;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const { uid } = currentUser;

    if (!uid) {
      reject(new Error('No current user'));

      return;
    }

    const userDocumentReference = firestore.collection('users').doc(uid);

    userDocumentReference
      .update({
        'theme.primaryColor': primaryColor.id,
      })
      .then((value) => {
        analytics.logEvent('change_primary_color', {
          primaryColor: primaryColor.id,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

appearance.changeSecondaryColor = (secondaryColor) => {
  return new Promise((resolve, reject) => {
    if (!secondaryColor) {
      reject(new Error('No secondary color'));

      return;
    }

    secondaryColor = getColor(secondaryColor);

    if (!secondaryColor) {
      reject(new Error('No secondary color'));

      return;
    }

    const { currentUser } = auth;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const { uid } = currentUser;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    const userDocumentReference = firestore.collection('users').doc(uid);

    userDocumentReference
      .update({
        'theme.secondaryColor': secondaryColor.id,
      })
      .then((value) => {
        analytics.logEvent('change_secondary_color', {
          secondaryColor: secondaryColor.id,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

appearance.changeDark = (dark) => {
  return new Promise((resolve, reject) => {
    const { currentUser } = auth;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const { uid } = currentUser;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    const userDocumentReference = firestore.collection('users').doc(uid);

    userDocumentReference
      .update({
        'theme.dark': dark,
      })
      .then((value) => {
        analytics.logEvent('change_dark', {
          dark,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

appearance.changeSyncAppearance = (syncAppearance) => {
  return new Promise((resolve, reject) => {
    const { currentUser } = auth;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const { uid } = currentUser;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    const userDocumentReference = firestore.collection('users').doc(uid);

    userDocumentReference
      .update({
        'theme.syncAppearance': syncAppearance,
      })
      .then((value) => {
        analytics.logEvent('change_sync_appearance', {
          syncAppearance,
        });

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

appearance.resetTheme = () => {
  return new Promise((resolve, reject) => {
    const { currentUser } = auth;

    if (!currentUser) {
      reject(new Error('No current user'));

      return;
    }

    const { uid } = currentUser;

    if (!uid) {
      reject(new Error('No UID'));

      return;
    }

    const userDocumentReference = firestore.collection('users').doc(uid);

    userDocumentReference
      .update({
        // @ts-ignore
        theme: firestore.FieldValue.delete(),
      })
      .then((value) => {
        analytics.logEvent('reset_theme');

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export default appearance;
