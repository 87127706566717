import { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';

import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';

const useStorageSignedUrl = () => {
  const [filePath, setStorageFilePath] = useState<string>('');

  const [signedUrl, setSignedUrl] = useState<string | null>(null);
  const getSignedUrlPoster = API.getMutation('storage/getSignedUrl', 'POST');
  const { showSnackbar } = useSnackbar();

  const getSignedUrl = useCallback(async (filePath: string) => {
    try {
      if (!filePath) {
        setSignedUrl(null);
        return null;
      }
      const res = await getSignedUrlPoster.mutateAsync({
        file_path: filePath,
      });
      setSignedUrl(res.data);
      return res.data;
    } catch (error: any) {
      console.log(error.message || error);
      showSnackbar(error.message || error, 'error');
      setSignedUrl(null);
      captureException(error);
      return null;
    }
  }, []);

  useEffect(() => {
    if (!filePath) return;
    getSignedUrl(filePath);
  }, [filePath]);

  return { signedUrl, setStorageFilePath, getSignedUrl };
};

export default useStorageSignedUrl;
