import { Chip, Skeleton, Tooltip, Typography } from '@mui/material';

import UILabels from '@/services/UILabels';
import Formatter from '@/services/Formatter';
import DataTransformation from '@/services/DataTransformation';

const Normalizer = DataTransformation;

class Reconciliations {
  #mode = 'default';

  #strings = new UILabels(this.#mode);

  label = 'Reconciliation';

  table = 'reconciliation_data';

  defaultOrderBy = 'reconciled';

  defaultSort = 'asc';
  outstandingFieldsInMobileView = [];

  options = {};

  constructor(mode, role, options = {}) {
    this.#mode = mode;
    this.#strings = new UILabels(mode);
    this.options = options;

    this.filters = {
      agent_name: {
        label: 'Agent name',
      },
      carrier_name: {
        label: 'Paying entity',
      },
      policy_status: {
        label: 'Status',
      },
      product_name: {
        label: 'Product name',
      },
      product_type: {
        label: 'Product type',
      },
      report_data_id: {
        label: 'Policy document',
      },
      writing_carrier_name: {
        label: 'Carrier/MGA',
      },
      statements: {
        label: 'Statement documents',
      },
      compensation_type: {
        label: 'Compensation type',
      },
    };
    this.outstandingFieldsInMobileView = [
      'policy_id',
      'customer_name',
      'amount_paid',
      'effective_date',
    ];
    this.fields = {
      policy_id: {
        label: this.#strings.getLabel('reconciliation', 'salesId'),
        enabled: true,
        copyable: true,
        formatter: (val, rowData = {}) => {
          // @ts-ignore
          if (rowData.report_data_parent) {
            return (
              <Tooltip
                // @ts-ignore
                key={`${rowData.str_id}-MasterPolicyTooltip`}
                title={
                  <>
                    <Typography variant="subtitle2">Master policy</Typography>
                    <Chip
                      variant="outlined"
                      key={val.str_id}
                      label={rowData.report_data_parent.policy_id}
                      clickable
                      component="a"
                      // @ts-ignore
                      href={`/policies?id=${rowData.report_data_parent.str_id}`}
                      target="_blank"
                      sx={{ mb: 0.5 }}
                      style={{ backgroundColor: 'white' }}
                    />
                  </>
                }
              >
                <span>{`${val}*`}</span>
              </Tooltip>
            );
            // @ts-ignore
          } else if (rowData.children_report_data_ids) {
            return (
              <Tooltip
                // @ts-ignore
                key={`${rowData.str_id}-linkedPoliciesTooltip`}
                title={
                  <>
                    <Typography variant="subtitle2">Linked policies</Typography>
                    {rowData.children_report_data_ids.map((childId) => (
                      <Chip
                        variant="outlined"
                        style={{ backgroundColor: 'white' }}
                        key={`${childId.str_id}-childPolicy`}
                        label={childId.policy_id}
                        clickable
                        component="a"
                        // @ts-ignore
                        href={`/policies?id=${childId.str_id}`}
                        target="_blank"
                        sx={{ m: 0.2 }}
                      />
                    ))}
                  </>
                }
              >
                <span>{`${val} 🗂️`}</span>
              </Tooltip>
            );
          } else return val;
        },
        readOnly: true,
      },
      compensation_type: {
        label: 'Compensation type',
        enabled: true,
        readOnly: true,
      },
      report_str_id: {
        label: 'Linked policy',
        enabled: true,
        // type: FieldTypes.DYNAMIC_SELECT,
        formatter: (val, row) => {
          return val ? (
            <Chip
              key={val}
              label={val}
              clickable
              component="a"
              href={`/policies?id=${val}`}
              target="_blank"
              sx={{ m: 0.1 }}
            />
          ) : null;
        },
      },
      writing_carrier_name: {
        label: this.#strings.getLabel('reconciliation', 'entity'),
        enabled: this.#mode === 'insurance',
        global: true,
        readOnly: true,
      },
      carrier_name: {
        label: this.#strings.getLabel('reconciliation', 'payingEntity'),
        enabled: true,
        global: true,
        readOnly: true,
      },
      customer_name: {
        label: this.#strings.getLabel('reconciliation', 'customerName'),
        enabled: true,
        readOnly: true,
      },
      product_type: {
        label: 'Product type',
        enabled: true,
        readOnly: true,
      },
      product_sub_type: {
        label: 'Product sub type',
        enabled: true,
        readOnly: true,
      },
      product_name: {
        label: 'Product name',
        enabled: true,
        readOnly: true,
      },
      effective_date: {
        label: this.#strings.getLabel('reconciliation', 'startDate'),
        enabled: true,
        formatter: Normalizer.formatDate,
        nomalizer: Normalizer.normalizeDate,
        type: 'date',
        readOnly: true,
      },
      premium_amount: {
        label: this.#strings.getLabel('reconciliation', 'annualizedRevenue'),
        enabled: this.#mode === 'insurance',
        formatter: Normalizer.formatCurrency,
        nomalizer: Normalizer.normalizeCurrency,
        type: 'currency',
        readOnly: true,
      },
      commissionable_premium_amount: {
        label: 'Target premium',
        matches: ['target premium', 'commissionable premium'],
        enabled: true,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: 'currency',
        readOnly: true,
      },
      commissions_expected: {
        label: 'Commissions due',
        description:
          'Commission amount due. Caclulated from commission schedules when available.',
        matches: [
          'agency commission total',
          'commissions expected',
          'commission expected',
        ],
        enabled: this.#mode === 'insurance',
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: 'currency',
        infoIcon: true,
        readOnly: true,
      },
      cancellation_date: {
        label: 'Cancellation date',
        enabled: this.#mode === 'insurance',
        formatter: Normalizer.formatDate,
        nomalizer: Normalizer.normalizeDate,
        type: 'date',
        defaultTableHidden: true,
        readOnly: true,
      },
      reinstatement_date: {
        label: 'Reinstatement date',
        enabled: this.#mode === 'insurance',
        formatter: Normalizer.formatDate,
        nomalizer: Normalizer.normalizeDate,
        type: 'date',
        defaultTableHidden: true,
        readOnly: true,
      },
      agent_name: {
        label: 'Agent name',
        enabled: this.#mode === 'insurance',
        defaultTableHidden: true,
        readOnly: true,
      },
      contacts: {
        label: 'Agents',
        enabled: this.#mode === 'insurance',
        type: 'dynamic-select',
        multiple: true,
        table: 'contacts',
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const datum = collectionVals?.filter(
              (datum) => datum.str_id === val
            )?.[0];
            return datum ? (
              <Chip
                key={val}
                label={Formatter.contact(datum, {
                  account_id: this.options.account_id,
                })}
                clickable
                component="a"
                href={`/agents/list?id=${val}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val; // not formatting when collectionVals is not available
        },
        optionFormatter: (option) =>
          Formatter.contact(option, { account_id: this.options.account_id }),
        optionValuer: (option) => option.str_id,
        readOnly: true,
      },
      amount_paid: {
        label: this.#strings.getLabel('reconciliation', 'cashPaid'),
        id2: 'amountPaid',
        description:
          'Commission amount paid as recorded from commission statements',
        infoIcon: true,
        enabled: true,
        formatter: Normalizer.formatCurrency,
        type: 'currency',
        getter: (row) => {
          let childAmounts = 0;
          if (row?.children_reconciliation_data?.length > 0) {
            childAmounts = row.children_reconciliation_data.reduce(
              (acc, cur) => {
                const curAmount = Number.isNaN(
                  +cur.amount_paid?.amount_paid?.amount_paid
                )
                  ? 0
                  : +cur.amount_paid?.amount_paid?.amount_paid;
                return acc + curAmount;
              },
              0
            );
          }
          return Number.isNaN(+row.amount_paid?.amount_paid?.amount_paid)
            ? 0
            : row.amount_paid?.amount_paid?.amount_paid + childAmounts;
        },
        // getter: (row) => {
        //   return (
        //     row.amount_paid?.amount_paid?.amount_paid ??
        //     row.amount_paid?.amountPaid?.amountPaid
        //   );
        // },
        readOnly: true,
      },
      amount_paid_commissionable_premium_amount_pct: {
        label: '% Target premium',
        enabled: true,
        type: 'percentage',
        formatter: Formatter.percentage,
        readOnly: true,
      },
      amount_paid_premium_amount_pct: {
        label: '% Premium',
        enabled: true,
        type: 'percentage',
        formatter: Formatter.percentage,
        readOnly: true,
      },
      balance: {
        // type: 'computed',
        label: this.#strings.getLabel('reconciliation', 'balance'),
        enabled: true,
        formatter: Normalizer.formatCurrency,
        type: 'currency',
        getter: (row) => {
          let bal;
          if (row?.children_reconciliation_data?.length > 0) {
            row.children_reconciliation_data.forEach((child) => {
              if (child.balance) bal = +child.balance + (bal ?? 0);
            });
            if (bal) bal = bal + +row.balance;
          }
          return bal ?? row.balance;
        },
        compute: (v) => {
          return (v.commissions_expected ?? 0) - (v.amount_paid ?? 0);
        },
        readOnly: true,
      },
      policy_status: {
        type: 'field',
        fieldId: 'policy_status',
        label: this.#strings.getLabel('reconciliation', 'status'),
        infoIcon: false,
        enabled: true,
        // visible: false,
        readOnly: true,
      },
      issue_age: {
        label: 'Issue age',
        enabled: true,
        normalizer: Normalizer.normalizeInt,
        matches: ['issue age'],
        type: 'integer',
        readOnly: true,
      },
      group_id: {
        label: 'Group id',
        enabled: true,
        readOnly: true,
      },
      internal_id: {
        label: 'Internal id',
        enabled: true,
        readOnly: true,
      },
      notes: {
        type: 'field',
        fieldId: 'notes',
        label: 'Notes',
        visible: true,
        enabled: true,
      },
      reconciled: {
        label: this.#strings.getLabel('reconciliation', 'reconciliationStatus'),
        readOnly: true,
        enabled: true,
      },
      commission_amount_monthly: {
        // type: 'aggregate',
        visible: false,
        // fieldId: 'commission_amount',
        // id2: 'amount_paid_monthly',
        enabled: true,
        type: 'currency',
        readOnly: true,
      },
      customer_paid_premium_amount: {
        label: 'Customer paid premium',
        enabled: true,
        matches: ['Basis', 'customer paid premium'],
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
      },
      agent_commission_amount: {
        label: 'Agent commission',
        enabled: true,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
      },
      agent_commission_amount_pct: {
        label: '% Agent commission',
        enabled: true,
        formatter: Formatter.percentage,
      },
      report_data: {
        label: 'Agent split',
        table: 'contacts',
        tableFormatter: (val, row = {}, collectionVals) => {
          if (row.report_data?.contacts_split && !collectionVals)
            return <Skeleton variant="rectangular" />;
          return row.report_data?.contacts_split
            ? Object.entries(row.report_data?.contacts_split).map(([k, v]) => (
                <Chip
                  label={`${
                    Formatter.contact(
                      collectionVals?.find((e) => e.str_id === k) ?? {},
                      { account_id: this.options.account_id }
                    ) ?? `${k} (Not found)`
                  }: ${v}%`}
                  key={k}
                />
              ))
            : '';
        },
        enabled: true,
      },
      log: {
        type: 'field',
        fieldId: 'log',
        label: 'Log 🔒',
        visible: true,
        enabled: role === 'admin',
        readOnly: true,
      },
      statements: {
        type: 'field',
        label: 'Statement documents',
        enabled: true,
        visible: true,
        readOnly: true,
        formatter: (val) => {
          if (val && val.document) {
            return (
              <Chip
                key={val.document.str_id}
                label={`${val.document.filename}`}
                clickable
                component="a"
                href={`/documents?id=${val.document.str_id}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            );
          } else {
            return 'Document not available';
          }
        },
      },
      report_data_id: {
        visible: true,
        label: 'Policy document',
        type: 'field',
        enabled: true,
        readOnly: true,
        formatter: (val, collectionVals = []) => {
          if (val === '') return '';
          if (collectionVals.report_data?.document) {
            return (
              <Chip
                key={collectionVals.report_data?.document.str_id}
                label={`${collectionVals.report_data?.document.filename}`}
                clickable
                component="a"
                href={`/documents?id=${collectionVals.report_data?.document.str_id}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            );
          } else {
            return '';
          }
        },
      },
      statement_str_ids: {
        type: 'field',
        fieldId: 'statement_str_ids',
        label: 'Statements 🔒',
        enabled: role === 'admin',
        itemOnClick: (item) => {
          window.open(`/commissions?id=${item}`, '_blank');
        },
        formatter: (val) =>
          val && typeof val === 'string' ? `${val?.substring(0, 5)}...` : null,
        readOnly: true,
      },
    };

    this.queryChips = {
      all: {
        id: 'all',
        label: 'All',
        query: {},
      },
      unreconciled: {
        id: 'unreconciled',
        label: 'Unreconciled',
        query: {
          reconciled: [
            '⛔️ Commissions shortfall',
            '⚠️ Missing policy data',
            '⚠️ Missing commissions data',
          ],
        },
      },
      reconciled: {
        id: 'reconciled',
        label: '✅ Reconciled',
        query: {
          reconciled: [
            '✅ Excess commissions',
            '✅ Reconciled',
            '✅ Reconciled (negligible balance)',
            '✅ Commission received',
          ],
        },
        more: true,
      },
      missing_policy_data: {
        id: 'missing_policy_data',
        label: '⚠️ Missing policy data',
        query: {
          reconciled: '⚠️ Missing policy data',
        },
        more: true,
      },
      missing_commissions_data: {
        id: 'missing_commissions_data',
        label: '⚠️ Missing commissions data',
        query: {
          reconciled: '⚠️ Missing commissions data',
        },
        more: true,
      },
      commissions_shortfall: {
        id: 'commissions_shortfall',
        label: '⛔️ Commissions shortfall',
        query: {
          reconciled: '⛔️ Commissions shortfall',
        },
        more: true,
      },
    };

    this.fieldsCollapsed = Object.values(this.fields)
      .filter((field) => field.enabled)
      .map((field) => field.matches)
      .flat();
  }
}

export default Reconciliations;
