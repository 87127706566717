import { Box, FormControlLabel, Switch } from '@mui/material';
import { useEffect } from 'react';
import { USER_SUB_TYPES } from 'common/user.constants';

import useAppStore from '@/store/appStore';
import { useIsImpersonatedUser } from '@/hooks/useIsImpersonatedUser';
import { useFintaryAdmin } from '@/hooks/useFintaryAdmin';

export const EnableUpdateToggle = () => {
  const { sub_type } = useFintaryAdmin();
  const isImpUser = useIsImpersonatedUser();
  const setState = useAppStore((s) => s.setState);
  const updatesEnabled = useAppStore((s) => s.updatesEnabled);

  const onChange = () => setState({ updatesEnabled: !updatesEnabled });

  useEffect(() => {
    if (isImpUser) {
      setState({ updatesEnabled: sub_type === USER_SUB_TYPES.csm });
    }
  }, [isImpUser, setState, sub_type]);

  if (!isImpUser) return null;
  if (sub_type === USER_SUB_TYPES.csm) return null;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 0.25 }}>
      <FormControlLabel
        control={
          <Switch onChange={onChange} checked={updatesEnabled} size="small" />
        }
        label="Enable updates"
      />
    </Box>
  );
};
