export const documentAICode = `
/**
 * libs: {document: current file document data, tools: {key: function}} All function name are listed in the top
 */
(json, libs) => {
  const tableRows = json.table.reduce((acc, val) => {
    const filterData = val.filter((row) => row.length > 4);
    return acc.concat(filterData);
  }, []);

  console.log(tableRows)

  const fieldMap = {
    "Policy #": 'policy_id',
    "Insured Name": 'customer_name',
    "PREMIUM": 'premium_amount',
    "RATE": 'commission_rate',
    "COMM": 'commission_amount',
  }
  
  const resultList = [];
  const processDate = new Date().toLocaleDateString('en');

  const { tableHeaders, tableData } = libs.tools.pickData(fieldMap, tableRows);
  if (tableHeaders.length) {
      const res = libs.tools.convertArrayToMap(tableHeaders, tableData);
      resultList.push(...res)
  }

  const _temp = resultList
  .map((item) => {
    item['carrier_name'] = 'Foresters';
    item['writing_carrier_name'] = 'Foresters';
    item['processing_date'] = processDate;
    return item;
  })
  console.log(_temp);
  const {keys, values} = libs.tools.convertMapToArray(_temp);

  return {
    fields: keys,
    data: values,
    version: 'documentAI',
  };
}

`;

export const spreadSheetCode = `
/**
 * libs: {document: current file document data, tools: {key: function}} All function name are listed in the top
 */
(data, libs) => {
  data = JSON.parse(JSON.stringify(data));
  const idx = data.findIndex((row) => row[2] === 'Carrier Name');
  let filterData = [];
  if (idx > -1) {
      filterData = data.slice(idx)
  }
  const fieldMap = {
    "Policy #": 'policy_id',
    "Insured Name": 'customer_name',
    "Plan Code": 'product_name',

    "Writing Producer #": 'agent_id',
    "Writing Producer Name": 'agent_name',

    "Type": 'transaction_type',

    "Effective Date (mm/dd/yyyy)": 'effective_date',
    "Period Date (mm/dd/yyyy)": 'period_date',
    "Transaction Date (mm/dd/yyyy)": 'payment_date',

    "Compensation Basis Amount": 'premium_amount',
    "Comp Rate %": 'commission_rate',
    "Amount Due": 'commission_amount',
  }
  
  const resultList = [];
  const processDate = new Date().toLocaleDateString('en');

  if (!filterData.length) {
    console.log('No valid data found');
    return;
  }

  const { tableHeaders, tableData } = libs.tools.pickData(fieldMap, filterData);
  if (tableHeaders.length) {
      const res = libs.tools.convertArrayToMap(tableHeaders, tableData);
      resultList.push(...res)
  }

  const _temp = resultList
  .map((item) => {
    item['carrier_name'] = 'Foresters';
    item['writing_carrier_name'] = 'Foresters';
    item['processing_date'] = processDate;
    return item;
  })
  console.log(_temp);
  const {keys, values} = libs.tools.convertMapToArray(_temp);
  return {
    fields: keys,
    data: values,
    version: 'SpreadSheet',
  };
}

`;

export const extractTableCode = `
(json, libs) => {
  const tableData = json.Tables
  let resultObj = {};
  let result = [];
  let preTableHeader = [];
  if (tableData) {
      tableData.forEach((table, index) => {
        const dataArray = libs.tools.mapToArray(table.TableJson);
        const [header, ...r] = dataArray;
        //TODO Use header length to filter table data
        if (header.length > 7){
            const _k = table.Page + '-' + index + '-' + header.length;
            const filterData = dataArray;
            if(filterData.length < 2){
              return;
            }
            preTableHeader = header;
            resultObj[_k] = filterData;
        }
    });
  } else if (Object.prototype.toString.call(json) === '[object Array]') {
    resultObj['0-0-0'] = json;
  }
  console.log(resultObj);
  const fieldMap = {
    "Policy #": 'policy_id',
    "Insured Name": 'customer_name',
    "Plan Code": 'product_name',

    "Writing Producer #": 'agent_id',
    "Writing Producer Name": 'agent_name',

    "Type": 'transaction_type',

    "Effective Date (mm/dd/yyyy)": 'effective_date',
    "Period Date (mm/dd/yyyy)": 'period_date',
    "Transaction Date (mm/dd/yyyy)": 'payment_date',

    "Compensation Basis Amount": 'premium_amount',
    "Comp Rate %": 'commission_rate',
    "Amount Due": 'commission_amount',
  }

  const resultList = [];
  const processDate = new Date().toLocaleDateString('en');
  const invoiceDate = libs.tools.findTextLine(json, 'Statement for');

  Object.entries(resultObj).forEach(([k, v]) => {
    const { tableHeaders, tableData } = libs.tools.pickData(fieldMap, resultObj[k]);
    const res = libs.tools.convertArrayToMap(tableHeaders, tableData);
    resultList.push(...res)
  })

  const _temp = resultList
  .filter((row) => row['payment_date'] && row['policy_id'])
  .map((item) => {
    //TODO add field
    item['carrier_name'] = 'Foresters';
    item['writing_carrier_name'] = 'Foresters';
    item['processing_date'] = processDate;
    return item;
  })
  console.log(_temp);
  // convet item to array
  const {keys, values} = libs.tools.convertMapToArray(_temp);
  return {
    fields: keys,
    data: values,
    version: 'extractTable',
  };
}

`;
