import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Formatter from 'common/Formatter';
import { useEffect, useState } from 'react';

import API from '@/services/API';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
};

const ReviewerSelector = ({ open, setOpen, setReviewer }) => {
  const [dataReady, setDataReady] = useState(false);
  const [reviewerSelected, setReviewerSelected] = useState({
    uid: null,
    first_name: null,
    last_name: null,
    email: null,
  });
  const { data } = API.getBasicQuery('users/get_fintary_admins');

  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setReviewerSelected(JSON.parse(event.target.value));
  };

  const handleSelect = () => {
    setReviewer(reviewerSelected);
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      data.forEach((elem) => {
        if (elem.email === 'yu@fintary.com') {
          setReviewerSelected(elem);
        }
      });
      setDataReady(true);
    }
  }, [data]);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Select reviewer
          </Typography>
          <Box sx={{ mt: 2 }}>
            {dataReady ? (
              <FormControl fullWidth>
                <InputLabel id="select-reviewer">Reviewer</InputLabel>
                <Select
                  labelId="select-reviewer"
                  value={JSON.stringify(reviewerSelected)}
                  label="Reviewer"
                  onChange={handleChange}
                >
                  {data.map((elem) => (
                    <MenuItem
                      key={elem.str_id || elem.uid}
                      value={JSON.stringify(elem)}
                    >
                      {Formatter.contact(elem, { incl_email: true })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              'Loading...'
            )}
          </Box>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={(e) => handleClose(e, null)}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSelect}>
              Select
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ReviewerSelector;
