const findTextLineIndex = (json, str) => {
  const txt: number[] = [];
  json.Lines?.find((lineItem, index) => {
    const _idx = lineItem.LinesArray.findIndex((line) =>
      line.Line.includes(str)
    );
    if (_idx > -1) {
      txt.push(index, _idx);
    }
    return _idx > -1;
  });
  return txt;
};

export const desc_findTextLineIndex = `
Find the index of the first line that contains the given text in the JSON object.
eg:
findTextLineIndex(json, 'text') => [0, 1];
`;

export default findTextLineIndex;
