const findAllTextLine = (json, str) => {
  return (
    json.Lines?.flatMap((lineItem: any) =>
      lineItem.LinesArray.filter((line: any) => line.Line.includes(str)).map(
        (line) => line.Line
      )
    ) || []
  );
};

export const desc_findAllTextLine = `
Find all lines that contain the given text in the JSON object.
eg:
  findAllTextLine(json, 'text') => ['text1', 'text2'];
`;

export default findAllTextLine;
