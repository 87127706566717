const convertArrayToMap = (keys, valuesArray) => {
  return valuesArray.map((values) => {
    const obj = {};
    keys.forEach((key, index) => {
      obj[key] = values[index];
    });
    return obj;
  });
};

export const desc_convertArrayToMap = `
Convert an array of values to a map of objects.
eg:
  convertArrayToMap(['a', 'b'], [[1, 2], [3, 4]]) => [{a: 1, b: 2}, {a: 3, b: 4}];
`;

export default convertArrayToMap;
