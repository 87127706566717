import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { AgentCommissionsStatusesLabels } from 'common/globalTypes';

import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import MultiSelectV2, {
  MultiSelectItem,
} from '@/components/molecules/MultiSelectV2';

interface PayoutStatusEditProps {
  data: {
    agent_commissions: Record<string, any>;
    agent_commissions_status2: Record<string, number>;
    agent_commissions_status2_only_update_existing?: boolean;
  };
  setter: (fn: (prevData: any) => any) => void;
  field: any;
  dynamicSelects: any;
  filterAgentsByEarning?: boolean;
}

const PayoutStatusEdit: React.FC<PayoutStatusEditProps> = ({
  data,
  field,
  setter,
  dynamicSelects = [],
}) => {
  console.log('data', data);
  const agentCommissionsStatus = data?.agent_commissions_status2 ?? {};
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [query, setQuery] = React.useState('');
  const { selectedAccount } = useAccountStore();
  const availableAgentStrIds = Object.keys(data.agent_commissions ?? {});
  const availableAgents =
    Object.keys(availableAgentStrIds).length > 0
      ? dynamicSelects.filter((agent) =>
          availableAgentStrIds.includes(agent.str_id)
        )
      : dynamicSelects;

  const updateAgentCommissionsStatus = (agentId: string, value: string) => {
    setter((prevData) => {
      const newAgentCommissionsStatus = {
        ...prevData.agent_commissions_status2,
      };
      newAgentCommissionsStatus[agentId] = value;

      return {
        ...prevData,
        agent_commissions_status2: newAgentCommissionsStatus,
      };
    });
  };

  const removeAgentCommissionsStatus = (agentId: string) => {
    setter((prevData) => {
      const newAgentCommissionsStatus = {
        ...prevData.agent_commissions_status2,
      };
      delete newAgentCommissionsStatus[agentId];

      return {
        ...prevData,
        agent_commissions_status2: newAgentCommissionsStatus,
      };
    });
  };

  const handleAddSelected = () => {
    selectedAgents.forEach((agentId) => {
      if (!agentCommissionsStatus[agentId]) {
        updateAgentCommissionsStatus(agentId, '');
      }
    });
    setSelectedAgents([]);
  };

  const filterOptions = (options: any[], query: string) => {
    if (!query) return options;
    const lowerQuery = query?.toLowerCase();
    return options.filter(
      (option) =>
        option.first_name?.toLowerCase()?.includes(lowerQuery) ||
        option.last_name?.toLowerCase()?.includes(lowerQuery)
    );
  };

  return (
    <Box>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            p: 0.5,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
            {agentCommissionsStatus &&
              Object.entries(agentCommissionsStatus).map(([k, v]) => {
                const contact = availableAgents?.find(
                  (e) => e?.str_id === k
                ) ?? { id: undefined };
                return contact.id ? (
                  <Box
                    key={`${contact.id}`}
                    sx={{
                      m: 0.5,
                      p: 1,
                      borderStyle: 'solid',
                      borderColor: 'silver',
                      borderWidth: 1,
                      borderRadius: 4,
                      display: 'inline-block',
                      width: 180,
                      backgroundColor: '#2196f30a',
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      position="relative"
                      sx={{ ml: 0.5 }}
                    >
                      <Typography variant="body2">
                        {Formatter.contact(contact, {
                          account_id: selectedAccount?.accountId,
                        })}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          removeAgentCommissionsStatus(contact.str_id);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <MultiSelectV2
                        options={
                          Object.values(AgentCommissionsStatusesLabels) || []
                        }
                        value={{
                          value: v?.toString() || '',
                          label: v?.toString() || '',
                          data: v?.toString() || '',
                        }}
                        label="Payout status"
                        formatter={(o) => o as string}
                        onChange={(value) => {
                          updateAgentCommissionsStatus(
                            contact.str_id,
                            (value as MultiSelectItem)?.value
                          );
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Skeleton key={k} />
                );
              })}
            <Box
              display="flex"
              justifyContent="space-between"
              position="relative"
            >
              <FormControl key={field.id} sx={{ m: 0.5, width: 145 }}>
                <InputLabel id={`${field.id}-label`}>Add</InputLabel>
                <Select
                  labelId={`${field.id}-label`}
                  id={field.id}
                  label="Add"
                  multiple
                  value={selectedAgents}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onClose={handleAddSelected}
                  onChange={(e) => {
                    setSelectedAgents(
                      Array.isArray(e.target.value)
                        ? e.target.value.filter(Boolean)
                        : [e.target.value]
                    );
                  }}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#333',
                    },
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {Array.isArray(selected) &&
                        (selected as string[]).map((value) => {
                          const item = availableAgents.find(
                            (option) => option.id === value
                          );
                          return (
                            <Chip
                              key={item ? item.str_id : value}
                              label={item ? item.name : value}
                            />
                          );
                        })}
                    </Box>
                  )}
                >
                  <Box
                    sx={{
                      mb: 0.5,
                      mx: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <TextField
                      sx={{ flex: 1, mr: 1 }}
                      label="Search"
                      value={query}
                      onChange={(e) => {
                        setQuery(e.target.value);
                      }}
                    />
                  </Box>
                  <Button
                    sx={{ ml: 1 }}
                    variant="outlined"
                    onClick={handleAddSelected}
                  >
                    Add selected
                  </Button>
                  {field.nullable && (
                    <MenuItem value={''} key="null">
                      &nbsp;
                    </MenuItem>
                  )}
                  {filterOptions(availableAgents, query).map((option: any) => (
                    <MenuItem value={option.str_id} key={option.id}>
                      {Formatter.contact(option, {
                        account_id: selectedAccount?.accountId,
                      })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {/* TODO: Find a proper way to determine whether we're in bulk edit modal or editing a commission */}
          {Object.keys(data).filter(
            (k) =>
              ![
                'agent_commissions_status2_only_update_existing',
                'agent_commissions_status2',
              ].includes(k)
          ).length === 0 && (
            <FormGroup sx={{ ml: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      data.agent_commissions_status2_only_update_existing
                    }
                    onChange={(e) =>
                      setter((prevData) => {
                        return {
                          ...prevData,
                          agent_commissions_status2_only_update_existing:
                            e.target.checked,
                        };
                      })
                    }
                  />
                }
                label="Only update existing payout status (otherwise will add)"
              />
              {/* <FormControlLabel control={<Checkbox checked={} onChange={}/>} label="Clear data" /> */}
            </FormGroup>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PayoutStatusEdit;
