import { javascript } from '@codemirror/lang-javascript';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import ReactCodeMirror from '@uiw/react-codemirror';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import Formatter from 'common/Formatter';
import useWindowSize from 'contexts/useWindowSize';
import { useContext, useEffect, useState } from 'react';

import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import DocPreview from './DocPreview';
import useStorageSignedUrl from '@/contexts/useStorageSignedUrl';

const defaultPrompt = `
Extract the following information from the given pdf:
  1-Find the statement date mentioned in the text and convert it to this format MM/DD/YYYY.
  2-Locate the data entries containing policy number which is Unique identifier of insurance policy (it might not exist and it might be called customer no.), commission which is the Commission received according to the carrier commission statement amount and customer name by identifying the lines that have the required information.
  3-Extract the following with the data entries if exists: Agent name, Carrier/MGA, Paying entity, Premium amount, Commission rate, Effective date, Period date, Transaction type, Product type, Product name, Compensation type, Statement number, Agent id and Group number.
  4-For any field that doesn't exist and is not 0, use null.

Format the output as follows in correct JSON format without any extra chars:
  1-For each valid data entry extract the following statement_date , policy_number, commission_amount, customer_name, agent_name, carrier_name, paying_entity, premium_amount, commission_rate, effective_date, period_date, transaction_type, product_type, product_name, compensation_type, statement_number, agent_id and group_number.
  2-Convert all dates to this format MM/DD/YYYY.
  3-If the period has 2 dates choose the end date.

  ***Do not add '''json ''' to the output***
  ***If any field is written once in the document but it goes with multiple rows, you should add to all rows in the json output.***
  
`;

const PromptsAction = ({ rowData, handleCancel, open, owners }) => {
  const [formData, setFormData] = useState({
    name: '',
    method: '',
    fileName: '',
    fileType: '',
    selectedSheet: '',
    document: '',
    prompt: '',
    owner: '',
    company_str_id: '',
  });

  const [curDocument, setCurDocument] = useState<any>();
  const [uploading, setUploading] = useState(false);
  const [previewFile, setPreviewFile] = useState<File | null>(null);

  const [aiLoading, setAiLoading] = useState(false);
  const [testResult, setTestResult] = useState<any[]>([]);

  const { width: winWidth } = useWindowSize();
  const [previewWidth, setPreviewWidth] = useState(winWidth * 0.35);

  const [documentList, setDocumentList] = useState<
    { label: string; value: string; data?: any }[]
  >([]);
  const { setLoadingConfig } = useContext(LoadingContext);
  const { getSignedUrl } = useStorageSignedUrl();

  const { data: documents, isLoading } = API.getBasicQuery('documents');
  const { data: companies } = API.getBasicQuery('companies');
  const vertexPoster = API.getMutation('gpt/doc', 'POST');
  const promptPoster = API.getMutation('prompts', 'POST');
  const promptPatcher = API.getMutation('prompts', 'PATCH');

  useEffect(() => {
    setLoadingConfig({
      loading: isLoading,
      message: 'Page init...',
    });
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && documents?.data.length) {
      const list = documents.data.map((item) => {
        return {
          label: item.filename,
          value: item.file_path,
          data: item,
        };
      });
      setDocumentList(list);
    }
  }, [documents, isLoading]);

  useEffect(() => {
    if (rowData && documents) {
      const { document_str_id } = rowData;
      const target = documents.data.find(
        (item) => item?.str_id === document_str_id
      );
      if (target) {
        setFormData((prev) => ({
          ...prev,
          document: target.file_path,
          name: rowData.name,
          prompt: rowData.prompt,
          owner: rowData.owner,
          company_str_id: rowData.company_str_id || target.company_str_id,
        }));
      }
    }
  }, [rowData, documents]);

  useEffect(() => {
    if (formData.document && documentList.length) {
      const target = documentList.find(
        (item) => item.value === formData.document
      );
      if (target) {
        setCurDocument(target.data);
      }
    }
  }, [formData.document, documentList]);

  useEffect(() => {
    if (curDocument) {
      onFormChange('prompt', defaultPrompt);
      onFormChange('company_str_id', curDocument.company_str_id);
      if (!formData.name) {
        const _name = `${curDocument?.companies?.company_name} ${curDocument.file_type} prompt`;
        onFormChange('name', _name);
      }
    }
  }, [curDocument]);

  const submit = async () => {
    setUploading(true);
    const params: any = {
      name: formData.name,
      prompt: formData.prompt,
      document_str_id: curDocument.str_id,
      profile_str_id: curDocument.profile_str_id,
      status: 'draft',
      owner: formData.owner,
      access: 'global',
      company_str_id: formData.company_str_id,
    };
    try {
      const api = rowData ? promptPatcher : promptPoster;
      if (rowData) {
        params.id = rowData.id;
      }
      const res = await api.mutateAsync(params);
      setUploading(false);
      if (res.error) {
        console.log(res.error);
        return;
      }
      handleCancel(true);
    } catch (error) {
      setUploading(false);
      console.log(error);
    }
  };

  const onDragFinished = (size) => {
    setPreviewWidth(size[0]);
  };
  const onFormChange = (key: string, value: any) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const fixIncompleteJSONString = (jsonString) => {
    // Check if the string ends with a closing square bracket ']'
    if (jsonString.endsWith('""')) {
      jsonString += '}]';
    } else if (jsonString.endsWith('"')) {
      // Add the missing closing bracket
      jsonString += '"}]';
    } else if (jsonString.endsWith('"}')) {
      jsonString += ']';
    }

    return jsonString;
  };

  const runVertex = async () => {
    if (!formData.document) {
      return;
    }
    setAiLoading(true);
    const _url = await getSignedUrl(formData.document);
    const params = {
      url: _url,
      type: previewFile?.type,
      document_id: curDocument.id,
      force_run: false,
      prompt: formData.prompt,
      result_id: rowData?.result_id,
      prompt_id: rowData?.id,
    };
    try {
      const resp = await vertexPoster.mutateAsync(params);
      setAiLoading(false);
      if (resp.error) {
        console.log(resp.error);
        return;
      }

      let res: any[] = [];
      resp.data.forEach((objStr) => {
        const target = JSON.parse(objStr);
        const list = target.parts.map((item) => {
          const fixStr = fixIncompleteJSONString(item.text);
          return JSON.parse(fixStr);
        }) as any[];
        res = res.concat(...list);
      });
      setTestResult(res);
    } catch (error) {
      setAiLoading(false);
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{ background: 'transparent', p: 1 }}
      onClose={handleCancel}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Box className="flex items-center">
          <Box className="pl-2">Prompt Editor</Box>
        </Box>
      </DialogTitle>
      <IconButton
        className="group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600"
        onClick={handleCancel}
      >
        <Close className="group-hover:rotate-180 transition-all origin-center" />
      </IconButton>
      <Divider />

      <DialogContent
        sx={{
          p: 0,
          backgroundColor: '#fff',
          borderRadius: '4px',
        }}
      >
        <section className="flex justify-between px-2 relative h-full">
          <Allotment defaultSizes={[35, 65]} onDragEnd={onDragFinished}>
            <Allotment.Pane>
              <Box className="h-full pr-3">
                {previewWidth && formData.document ? (
                  <DocPreview
                    path={formData.document}
                    previewWidth={previewWidth}
                    setPreviewFile={setPreviewFile}
                    previewFile={previewFile}
                    rowData={curDocument}
                  />
                ) : null}
              </Box>
            </Allotment.Pane>
            <Allotment.Pane>
              <Box className="flex-1 flex flex-col h-full overflow-auto pl-3">
                <Box
                  sx={{
                    flex: 1,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ p: 1, pt: 2, display: 'flex', gap: 2 }}>
                    <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
                      <InputLabel>Documents</InputLabel>
                      <Select
                        id="select-document"
                        value={formData?.document}
                        label="Documents"
                        onChange={(e) => {
                          onFormChange('document', e.target.value);
                        }}
                      >
                        {documentList.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
                      <TextField
                        label="Name"
                        variant="outlined"
                        value={formData?.name}
                        onChange={(e) => {
                          onFormChange('name', e.target.value);
                        }}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
                      <InputLabel>Owner</InputLabel>
                      <Select
                        id="select-owner"
                        value={formData?.owner}
                        label="Owner"
                        onChange={(e) => onFormChange('owner', e.target.value)}
                      >
                        {(owners || []).map((c) => (
                          <MenuItem value={c.uid} key={c.uid}>
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                              }}
                            >
                              <Box sx={{ flex: 1 }}>{Formatter.contact(c)}</Box>
                              <Box sx={{ color: '#666' }}>{c.email}</Box>
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
                      <InputLabel>Carrier</InputLabel>
                      <Select
                        id="select-carrier"
                        value={formData?.company_str_id}
                        label="Carrier"
                        onChange={(e) => {
                          onFormChange('company_str_id', e.target.value);
                        }}
                      >
                        {(companies?.data || []).map((c) => (
                          <MenuItem value={c.str_id} key={c.str_id}>
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                              }}
                            >
                              <Box sx={{ flex: 1 }}>{c.company_name}</Box>
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ flex: 1, p: 1 }}>
                    <FormControl sx={{ mb: 2, width: '100%' }}>
                      <TextField
                        label="Prompt"
                        variant="outlined"
                        multiline
                        minRows={16}
                        value={formData?.prompt}
                        onChange={(e) => {
                          onFormChange('prompt', e.target.value);
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton
                      onClick={runVertex}
                      loading={aiLoading}
                      variant="contained"
                    >
                      Test Gemini
                    </LoadingButton>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      overflow: 'auto',
                      border: '1px solid #fefefe',
                    }}
                  >
                    <ReactCodeMirror
                      value={
                        typeof testResult !== 'string'
                          ? JSON.stringify(testResult, null, 2)
                          : testResult
                      }
                      width="100%"
                      height="100%"
                      readOnly={true}
                      editable={false}
                      basicSetup={{
                        lineNumbers: true,
                        foldGutter: true,
                      }}
                      extensions={[javascript({ jsx: true })]}
                    />
                  </Box>
                </Box>
              </Box>
            </Allotment.Pane>
          </Allotment>
        </section>
      </DialogContent>

      <DialogActions sx={{ pb: 2, px: 2 }}>
        <Button onClick={handleCancel}>Cancel</Button>
        <LoadingButton
          onClick={submit}
          loading={uploading}
          variant="contained"
          sx={{ width: '100px' }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

PromptsAction.displayName = 'PromptsAction';

export default PromptsAction;
