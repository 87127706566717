import axios, { AxiosError, AxiosResponse } from 'axios';

import { LOCAL_STORAGE_KEYS } from '@/constants/account';
import { auth } from '@/firebase';
import { getLocalData } from '@/utils/localStorage';
import { getAccountAndUserKeys } from './getAccountAndUserKeys';
import { useFeVersionStore } from '@/store';
import { GlobalStateCodes } from '@/types';

axios.interceptors.request.use(async (config) => {
  config.headers = await getHeaders();
  config.signal = getAbortSignal();
  return config;
});

axios.interceptors.response.use(
  async (res) => {
    checkFEOutOfDate(res);
    return res;
  },
  (err: AxiosError<{ error: string }>) => {
    checkFEIncompatitable(err);
  }
);

const checkFEIncompatitable = (res: AxiosError<{ error: string }>) => {
  if (res.response?.data?.error === GlobalStateCodes.FE_INCOMPATIBLE) {
    useFeVersionStore.getState().setFeVersion({
      stateCode: GlobalStateCodes.FE_INCOMPATIBLE,
      message:
        'A new version of Fintary is required. Refresh this page to update.',
    });
  }
};

const checkFEOutOfDate = (res: AxiosResponse) => {
  const outOfDate = res.headers['out_of_date'];
  if (outOfDate && outOfDate === GlobalStateCodes.FE_OUT_OF_DATE) {
    useFeVersionStore.getState().setFeVersion({
      stateCode: GlobalStateCodes.FE_OUT_OF_DATE,
      message:
        'A new version of Fintary is available. Refresh this page to update.',
    });
  }
};

const getAbortSignal = () => {
  const controller = new AbortController();
  const feVersion = useFeVersionStore.getState().feVersion;
  if (
    feVersion?.stateCode === GlobalStateCodes.FE_OUT_OF_DATE ||
    feVersion?.stateCode === GlobalStateCodes.FE_INCOMPATIBLE
  ) {
    controller.abort();
  }

  return controller.signal;
};

const getHeaders = async () => {
  const idToken = await auth.currentUser?.getIdToken();
  const ssoToken = getLocalData(LOCAL_STORAGE_KEYS.ssoToken);

  return {
    authentication: `Bearer ${idToken ? idToken : ssoToken}`,
    feversion: __BUILD_TIME__,
    ...getAccountAndUserKeys(),
  };
};
