import getIndices from './getIndices';

const removeColsWithName = (array: string[][], colNames: string[]) => {
  const indicesToRemove = getIndices(array, colNames);
  const newArray = JSON.parse(JSON.stringify(array));
  indicesToRemove.forEach((idx) => {
    newArray.forEach((row) => row.splice(idx, 1));
  });
  return newArray;
};

export const desc_removeColsWithName = `
 Remove columns from a 2D array based on the column names provided.
 eg: removeColsWithName([['a', 'b', 'c'], [1, 2, 3], [4, 5, 6]], ['a', 'c']) => [['b'], [2], [5]]
`;

export default removeColsWithName;
