import {
  AdminPanelSettingsOutlined,
  ArrowForwardIosSharp,
  CloudUploadOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled, useTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import BaseModal from '@/components/UploadModal';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { auth } from '@/firebase';
import API from '@/services/API';
import { hasAccess } from '@/services/helpers';
import { useAccountStore, useMenuStore, useRoleStore } from '@/store';
import { Roles } from '@/types';
import { getRouteConfig } from '@/components/AppDrawer/routeConfig';
import useSnackbar from '@/contexts/useSnackbar';
import { LoginAsUser } from './LoginAsUser';

const FintaryAdminOnlyIcon = ({ access }) => {
  return Array.isArray(access) &&
    access?.length === 1 &&
    access?.includes(Roles.FINTARY_ADMIN) ? (
    <span
      style={{
        color: 'transparent',
        textShadow: '0 0 0 #e8e8e8',
      }}
    >
      🔒
    </span>
  ) : null;
};

const AppDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [menuItems, setMenuItems] = useState<any[]>([[]]);
  const { menuOpen, setMenuOpen } = useMenuStore();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const drawerWidth = 200;
  const theme = useTheme();

  const { userRole } = useRoleStore();
  const { selectedAccount } = useAccountStore();
  const { showSnackbar } = useSnackbar();
  const mode = selectedAccount?.accountMode;

  const { data: accountSettings, isFetched: accountSettingsReady } =
    API.getBasicQuery(`accounts/settings`);

  const { data: dashboardNames } = API.getBasicQuery(
    `insights/accountDashboardNames`
  );

  const { refetch: refetchUser } = API.getBasicQuery('admin/check');

  const {
    role: [role, setRole],
    appDrawerExpanded: [expanded, setExpanded],
  } = useContext(UIStateContext);

  const documentViewSettings = accountSettings?.pages_settings?.add_documents;
  const showAddDocument =
    documentViewSettings?.show_page ??
    (userRole &&
      [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST].includes(userRole));

  useEffect(() => {
    const routeConfig = getRouteConfig(
      mode,
      selectedAccount,
      accountSettings,
      dashboardNames
    );
    setMenuItems(routeConfig);
  }, [mode, role, userRole, accountSettings, selectedAccount, dashboardNames]);

  useEffect(() => {
    setMenuOpen(isDesktop);
  }, [isDesktop, setMenuOpen]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (
        user?.email &&
        (user.email.endsWith('@fintary.com') ||
          user.email.endsWith('@fintary.co'))
      ) {
        if (['demo@fintary.com'].includes(user.email)) {
          setRole('demo');
        } else {
          const { data: resJson } = await refetchUser();
          if (resJson && resJson?.data?.isAdmin) {
            setRole('admin');
          }
        }
      }
    });
  }, [setRole, refetchUser]);

  const handleClose = (result) => {
    setShowUploadModal(false);
    if (result) {
      showSnackbar(result);
    }
  };

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme: t }) => ({
    border: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        isDesktop ? <ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} /> : null
      }
      sx={{
        pl: 0.5,
        borderRadius: '0 24px 24px 0',
        '&:hover': { backgroundColor: 'whitesmoke' },
      }}
      {...props}
    />
  ))(({ theme: t }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginTop: 0,
      marginBottom: 0,
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    borderTop: 0,
  }));

  const filteredMenuItems = menuItems
    .filter((section) => hasAccess(section.access, userRole, role === 'admin'))
    .map((section) =>
      section
        .filter((item) => hasAccess(item?.access, userRole, role === 'admin'))
        .map((item) => ({
          ...item,
          target: Array.isArray(item.target)
            ? item.target.filter((t) =>
                hasAccess(t?.access, userRole, role === 'admin')
              )
            : item.target,
        }))
    )
    .filter((section) => section.length > 0);

  return (
    <>
      <Drawer
        variant={isDesktop ? 'persistent' : 'temporary'}
        open={menuOpen}
        sx={{ width: menuOpen ? drawerWidth : 0 }}
        onClose={isDesktop ? undefined : () => setMenuOpen(false)}
      >
        <Toolbar />
        <Box
          sx={{
            flexGrow: 1,
            width: drawerWidth,
            overflow: 'auto',
            overflowX: 'hidden',
          }}
        >
          {accountSettingsReady &&
            filteredMenuItems.map((section, i) => (
              <div key={i}>
                <Divider />
                <List>
                  {section.map(
                    ({
                      label,
                      icon,
                      target,
                      access,
                      endIcon,
                      disabled,
                      tooltip,
                    }) =>
                      Array.isArray(target) ? (
                        <Accordion expanded={expanded[label]} key={label}>
                          <AccordionSummary
                            onClick={() => {
                              if (!expanded[label]) {
                                navigate(target[0].target);
                              }
                              setExpanded({
                                ...expanded,
                                [label]: !expanded[label],
                              });
                            }}
                            sx={{ pr: 1, minHeight: 40 }}
                          >
                            <ListItem
                              button
                              disabled={disabled}
                              sx={{
                                pl: 1,
                                pr: 0,
                                py: 0.5,
                                '&:hover': { backgroundColor: 'unset' },
                              }}
                            >
                              <ListItemIcon
                                style={{
                                  minWidth: 40,
                                  color: 'black',
                                }}
                              >
                                {icon}
                              </ListItemIcon>
                              <ListItemText primary={label} />
                              <FintaryAdminOnlyIcon access={access} />
                            </ListItem>
                          </AccordionSummary>
                          <AccordionDetails>
                            {target.map((t) => (
                              <Tooltip
                                title={t.tooltip ?? ''}
                                placement="right"
                                key={t.label}
                              >
                                <ListItem
                                  key={t.label}
                                  onClick={() => {
                                    if (!isDesktop) setMenuOpen(false);
                                  }}
                                  component={Link}
                                  to={t.target}
                                  sx={{
                                    ...{ pl: 2.5, py: 0.25, opacity: 0.8 },
                                    ...(location.pathname ===
                                    t.target.split('?')[0]
                                      ? {
                                          backgroundColor: '#2096f322',
                                          borderRadius: '0 24px 24px 0',
                                        }
                                      : {}),
                                    color: 'unset',
                                  }}
                                  disabled={t.disabled}
                                >
                                  <ListItemIcon
                                    style={{
                                      color:
                                        location.pathname ===
                                        t.target.split('?')[0]
                                          ? theme.palette.primary.main
                                          : 'unset',
                                      minWidth: 40,
                                    }}
                                  >
                                    {t.icon}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={t.label}
                                    primaryTypographyProps={
                                      location.pathname === t.target
                                        ? {
                                            color: theme.palette.primary.main,
                                            fontWeight: 600,
                                            fontSize: 15,
                                          }
                                        : { fontSize: 15 }
                                    }
                                  />

                                  <FintaryAdminOnlyIcon access={t.access} />
                                </ListItem>
                              </Tooltip>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <Tooltip
                          title={tooltip ?? ''}
                          placement="right"
                          enterDelay={1000}
                          key={target}
                        >
                          <ListItem
                            key={target}
                            onClick={() => {
                              if (!isDesktop) setMenuOpen(false);
                            }}
                            component={Link}
                            to={target}
                            sx={
                              location.pathname === target
                                ? {
                                    color: 'unset',
                                    backgroundColor: '#2096f322',
                                    borderRadius: '0 24px 24px 0',
                                    px: 1.5,
                                    py: 0.5,
                                  }
                                : {
                                    color: 'unset',
                                    px: 1.5,
                                    py: 0.5,
                                    '&:hover': {
                                      borderRadius: '0 24px 24px 0',
                                    },
                                  }
                            }
                            secondaryAction={
                              access === 'admin' && (
                                <IconButton
                                  edge="end"
                                  disabled
                                  sx={{ opacity: 0.5, mr: -2 }}
                                >
                                  {endIcon ?? <AdminPanelSettingsOutlined />}
                                </IconButton>
                              )
                            }
                            disabled={disabled}
                          >
                            <ListItemIcon
                              style={{
                                color:
                                  location.pathname === target
                                    ? theme.palette.primary.main
                                    : 'unset',
                                minWidth: 40,
                              }}
                            >
                              {icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={label}
                              primaryTypographyProps={
                                location.pathname === target
                                  ? {
                                      color: theme.palette.primary.main,
                                      fontWeight: 600,
                                    }
                                  : {}
                              }
                              sx={
                                location.pathname === target
                                  ? {
                                      color: 'white',
                                      fontWeight: 600,
                                      '&:hover': {
                                        color: 'black',
                                      },
                                    }
                                  : {}
                              }
                            />
                            <FintaryAdminOnlyIcon access={access} />
                          </ListItem>
                        </Tooltip>
                      )
                  )}
                </List>
              </div>
            ))}
        </Box>
        <Box
          sx={{
            width: drawerWidth,
            zIndex: 1,
          }}
        >
          <LoginAsUser showAddDocument={showAddDocument} />
          {showAddDocument && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Tooltip title="You can also email statements to documents@fintary.com for processing">
                <Button
                  variant="contained"
                  startIcon={<CloudUploadOutlined />}
                  onClick={() => {
                    setShowUploadModal(true);
                  }}
                  sx={{
                    width: 180,
                    mt: 1,
                    mb: 1.5,
                    boxShadow: 0,
                    backgroundColor: '#e5f1fd',
                    color: '#2196f3',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#e5f1fd',
                      color: '#2196f3',
                      boxShadow: 0,
                    },
                  }}
                >
                  Upload data
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Drawer>
      <BaseModal open={showUploadModal} handleClose={handleClose} />
    </>
  );
};

export default AppDrawer;
