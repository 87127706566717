import { create } from 'zustand';

type AppStore = {
  updatesEnabled: boolean;
};

type Setters = {
  setState: (v: Partial<AppStore>) => void;
};

const defaultValues: AppStore = { updatesEnabled: false };

const useAppStore = create<AppStore & Setters>((set) => ({
  ...defaultValues,
  setState: (v) => set(v),
}));

export default useAppStore;
