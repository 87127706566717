import CommonFormatter from 'common/Formatter';
import { DataProcessingTypes } from 'common/dataProcessingType';
import { capitalize } from 'lodash-es';

import Formatter from '@/services/Formatter';

const processingTypes = {};

for (const type of Object.values(DataProcessingTypes)) {
  processingTypes[type] = {
    id: type,
    label: capitalize(type.replaceAll('_', ' ')),
  };
}

export const getProcessingLogDataDesc = (): DataDesc.Props => ({
  label: 'Activity logs',
  table: 'v2/data_processing',
  editable: true,
  fields: [
    {
      id: 'created_at',
      label: 'Date',
      formatter: Formatter.dateTime,
      readOnly: true,
    },
    {
      id: 'status',
      label: 'Status',
      tableFormatter: Formatter.status,
      readOnly: true,
    },
    { id: 'type', label: 'Type', readOnly: true },
    { id: 'params', label: 'Params', readOnly: true, disableSort: true },
    {
      id: 'duration',
      label: 'Duration',
      tableFormatter: CommonFormatter.duration,
      readOnly: true,
    },
    { id: 'notes', label: 'Notes', disableSort: true },
    {
      id: 'stats',
      label: 'Stats',
      readOnly: true,
      formatter: Formatter.jsonToggleFormatted,
    },
    {
      id: 'created_by',
      label: 'User',
      formatter: (val, row) =>
        `${Formatter.contact(row.user)}${row.proxy_user ? ` (via ${Formatter.contact(row.proxy_user)})` : ''}`,
      readOnly: true,
    },
  ],
  queryChips: {
    all: {
      id: 'all',
      label: 'All',
      query: {},
    },
    ...processingTypes,
  },
});
