import { DeleteOutline, PersonAdd } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Formatter from 'common/Formatter';
import { useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import validator from 'validator';

import BasicDialog from '@/common/BasicDialog';
import DataView from '@/components/DataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import { auth } from '@/firebase';
import API from '@/services/API';
import { useAccountStore } from '@/store';
import useSnackbar from '@/contexts/useSnackbar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 4,
};

interface InviteUserData {
  accountId: string;
  accountName: string;
  email: string;
  roleId: number;
  agentId: number;
}

const UserManager = () => {
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [inviteReady, setInviteReady] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  // Email related states
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');
  // Role related states
  const [roleSelected, setRoleSelected] = useState('');
  const [dataReady, setDataReady] = useState(false);
  // Agent related states
  const [fetchAgents, setFetchAgents] = useState(false);
  const [showAgents, setShowAgents] = useState(false);
  const [agentSelected, setAgentSelected] = useState('');

  const handleClose = () => {
    setUserEmail('');
    setRoleSelected('');
    setFetchAgents(false);
    setShowAgents(false);
    setAgentSelected('');
    setOpen(false);
  };

  const { showSnackbar } = useSnackbar();
  const { setLoadingConfig } = useContext(LoadingContext);
  const { selectedAccount } = useAccountStore();

  const { data } = API.getBasicQuery('roles');
  const { data: agentData } = API.getBasicQuery(
    'contacts/contacts_without_user',
    '',
    fetchAgents
  );
  const poster = API.getMutation('users/invite_new_user', 'POST');
  const usersDeleter = API.getMutation('users', 'DELETE');

  const userId = auth?.currentUser?.uid;

  // Hook for managing the role selection and email validation
  useEffect(() => {
    // Check if the user is producer and display Agents list
    if (roleSelected === '2') {
      if (!fetchAgents) setFetchAgents(true);
      if (agentData) setShowAgents(true);
    } else {
      setShowAgents(false);
      setAgentSelected('');
    }

    if (
      !emailError &&
      roleSelected !== '' &&
      roleSelected !== '2' &&
      userEmail !== ''
    ) {
      setInviteReady(false);
    } else {
      if (roleSelected === '2') {
        if (agentSelected !== '' && !emailError && userEmail !== '') {
          setInviteReady(false);
        } else {
          setInviteReady(true);
        }
      } else {
        setInviteReady(true);
      }
    }
  }, [emailError, roleSelected, userEmail, agentSelected]);

  // Hook for handling the agent data
  useEffect(() => {
    if (agentData) setShowAgents(true);
  }, [agentData]);

  // Hook for handling the selected agent
  useEffect(() => {
    if (agentSelected) {
      // Search in agentData for the selected agent id
      const agent = agentData.find(
        (agent) => agent.id === parseInt(agentSelected)
      );
      if (agent.email) {
        setUserEmail(agent.email);
        validateEmail(agent.email);
      }
    }
  }, [agentSelected]);

  const handleChange = (event: SelectChangeEvent) => {
    setRoleSelected(event.target.value as string);
  };

  const handleAgentChange = (event: SelectChangeEvent) => {
    setAgentSelected(event.target.value as string);
  };

  const handleInvite = async () => {
    if (
      selectedAccount &&
      selectedAccount.accountId &&
      selectedAccount.accountName
    ) {
      setLoadingConfig({
        loading: true,
        message: 'Inviting new user...',
      });
      const requestBody: InviteUserData = {
        accountId: selectedAccount.accountId,
        accountName: selectedAccount.accountName,
        email: userEmail,
        roleId: parseInt(roleSelected),
        agentId: parseInt(agentSelected),
      };
      const response = await poster.mutateAsync(requestBody as any);

      if (!response.error) {
        handleClose();
        setRefresh(refresh + 1);
        setLoadingConfig({
          loading: false,
          message: '',
          delayToClose: 1000,
        });
        showSnackbar('User invited');
      } else {
        setLoadingConfig({
          loading: false,
          message: '',
          delayToClose: 1000,
        });
        console.error(response.error);
        showSnackbar(`An error occurred: ${response.error}`, 'error');
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setDataReady(true);
    }
  }, [data]);

  const validateEmail = (email) => {
    if (validator.isEmail(email)) {
      setEmailError(false);
      setEmailErrorText('');
    } else {
      setEmailError(true);
      setEmailErrorText('Invalid email');
    }
  };

  const dataDesc = {
    label: 'Users',
    // @ts-ignore
    table: `users/get_account_users?accId=${selectedAccount.accountId}`,
    editable: true,
    fields: [
      {
        id: 'first_name',
        label: 'First name',
      },
      {
        id: 'last_name',
        label: 'Last name',
      },
      {
        id: 'email',
        label: 'Email',
        readOnly: true,
      },
      {
        id: 'role_id',
        label: 'Role',
        type: 'dynamic-select',
        table: 'roles',
        field: 'name',
        formatter: (val, collectionVals = []) => {
          if (val === '') return '';
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const record: any = collectionVals?.filter(
              (datum: any) => datum.id === val
            )?.[0];
            return record?.name ?? '';
          }
          return val;
        },
        optionFormatter: (option) => option?.name,
        optionValuer: (option) => option?.id,
      },
      {
        id: 'user_contact_id',
        label: 'Agent name',
        type: 'dynamic-select',
        table: 'contacts',
        queryParamName: 'user_contact',
        queryParamValue: 'str_id',
        field: 'name',
        formatter: (val, collectionVals = []) => {
          if (val === '') return '';
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const record: any = collectionVals?.filter(
              (datum: any) => datum.str_id === val
            )?.[0];
            return record ? `${record.first_name} ${record.last_name}` : '';
          }
          return val;
        },
        optionFormatter: (option) => `${option.first_name} ${option.last_name}`,
        optionValuer: (option) => option?.str_id,
        access: 'admin',
      },
      {
        id: 'invite_status',
        label: 'Status',
        readOnly: true,
      },
      {
        id: 'invite_date',
        label: 'Invited on',
        readOnly: true,
        formatter: (date) => new Date(date).toLocaleString(),
      },
      {
        id: 'id',
        label: 'Actions',
        condition: (r) => false,
        formatter: (i, row) => {
          return (
            <IconButton
              className="ml-1"
              onClick={async () => {
                setCurrentRow(row);
                setShowDelete(true);
              }}
              disabled={userId === row.uid ? true : false}
            >
              <DeleteOutline />
            </IconButton>
          );
        },
      },
    ],
  };
  const DelConfirmComp = ({ row }) => {
    return (
      <BasicDialog
        title="Delete user"
        open={showDelete}
        onClose={(val) => {
          if (val) {
            setLoadingConfig({
              loading: true,
              message: 'Deleting...',
            });
            const params = { id: row.id, strId: row.str_id } as any;
            usersDeleter
              .mutateAsync(params)
              .then(() => {
                setRefresh(refresh + 1);
                setLoadingConfig({
                  loading: false,
                  message: '',
                  delayToClose: 1000,
                });
                setCurrentRow(null);
              })
              .catch((err) => {
                showSnackbar(err, 'error');
                setLoadingConfig({
                  loading: false,
                  message: '',
                });
              });
          } else {
            setShowDelete(false);
            setCurrentRow(null);
          }
        }}
        bodyComponent={
          <Alert severity="warning">
            Are you sure you want to delete this user?
            <br />
            <br />
            {Formatter.contact(row, {
              incl_email: true,
              account_id: selectedAccount?.accountId,
            })}
          </Alert>
        }
        positiveLabel="Delete"
      />
    );
  };
  return (
    <>
      <DataView
        dataDesc={dataDesc}
        viewOnly
        sx={{ width: '100%' }}
        refresh={refresh}
        extraActions={
          <Button
            variant="contained"
            onClick={() => setOpen(true)}
            startIcon={<PersonAdd />}
          >
            Invite user
          </Button>
        }
        hideExport
        embed
      />

      {showDelete && currentRow && <DelConfirmComp row={currentRow} />}

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Invite user
          </Typography>
          <Box sx={{ mt: 2 }}>
            {dataReady ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="roles-select-label">Role</InputLabel>
                <Select
                  labelId="roles-select-label"
                  id="demo-simple-select"
                  value={roleSelected}
                  label="Role"
                  onChange={handleChange}
                >
                  {data
                    .filter((role) => ![3, 6].includes(role.id))
                    .map((role) => (
                      <MenuItem key={role.id} value={role.id.toString()}>
                        {role.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : (
              'Loading...'
            )}
            {showAgents ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="agent-select-label">Agent</InputLabel>
                <Select
                  labelId="agent-select-label"
                  id="agent-simple-select"
                  value={agentSelected}
                  label="Agent"
                  onChange={handleAgentChange}
                >
                  {agentData.map((agent) => (
                    <MenuItem
                      key={`Agent ${agent.id}`}
                      value={agent.id.toString()}
                    >
                      {Formatter.contact(agent, {
                        account_id: selectedAccount?.accountId,
                      })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <TextField
              id="email-field"
              label="Email"
              variant="outlined"
              value={userEmail}
              fullWidth
              onChange={(e) => {
                validateEmail(e.target.value);
                setUserEmail(e.target.value);
              }}
              helperText={emailErrorText}
              error={emailError}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              disabled={inviteReady}
              variant="contained"
              onClick={handleInvite}
            >
              Invite user
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UserManager;
