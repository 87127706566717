import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isValidElement, useMemo, useState } from 'react';

const applyFunc = (func, arg) => (typeof func === 'function' ? func(arg) : arg);

const DynamicSelect = ({
  field,
  readOnly = false,
  formattedData = {},
  oldData = {},
  endAdornment = null,
  setNewData,
  formState = undefined,
  setFormState = undefined,
  dynamicSelects,
  disabled = false,
  fullWidth = false,
  dataFormMode = true,
  sx = {},
  addMode = false,
  onChange = (e, row) => {},
}) => {
  const [query, setQuery] = useState('');
  const [opened, setOpened] = useState(false);
  const [visibleCount, setVisibleCount] = useState(20);

  const isControlled = formState && typeof setFormState === 'function';

  const handleSelectAll = (e, options) => {
    e.stopPropagation();
    const selected = options
      .map((option) => applyFunc(field.optionValuer, option))
      .filter((option) => option);
    if (dataFormMode) {
      setNewData({
        ...formattedData,
        [field.id]: selected,
      });
    } else {
      setNewData(selected);
    }
  };

  const filteredOptions = useMemo(
    () =>
      applyFunc(
        field.sorter,
        dynamicSelects[field.table]?.data ?? dynamicSelects[field.table]
      )
        ?.sort((a, b) =>
          applyFunc(field.optionFormatter, a) <
          applyFunc(field.optionFormatter, b)
            ? -1
            : 1
        )
        ?.reduce((unique, option) => {
          const optionValue = applyFunc(field.optionValuer, option);
          return unique.some(
            (item) => applyFunc(field.optionValuer, item) === optionValue
          )
            ? unique
            : [...unique, option];
        }, [])
        ?.filter((option) => {
          const trimmedQuery = query.trim().toLowerCase();
          const itemName = option?.name?.toLowerCase() ?? '';
          const formattedOptionResult = applyFunc(
            field.optionFormatter,
            option
          );
          const formattedOption =
            typeof formattedOptionResult === 'string'
              ? formattedOptionResult.toLowerCase()
              : '';

          if (trimmedQuery.startsWith('"') && trimmedQuery.endsWith('"')) {
            const exactMatchRegex = new RegExp(
              `\\b${trimmedQuery.slice(1, -1)}\\b`
            );
            return (
              exactMatchRegex.test(itemName) ||
              exactMatchRegex.test(formattedOption)
            );
          }

          const queryFieldsValues = (
            Array.isArray(field.queryFields) ? field.queryFields : []
          )
            .map((field) => option[field]?.toLowerCase() ?? '')
            .join(' ');

          return (
            trimmedQuery === '' ||
            typeof formattedOption !== 'string' ||
            trimmedQuery.split(' ').every((word) => {
              return (
                formattedOption.includes(word) ||
                itemName.includes(word) ||
                queryFieldsValues.includes(word)
              );
            })
          );
        }),
    [dynamicSelects, field.optionFormatter, field.sorter, field.table, query]
  );

  const fieldValue = Array.isArray(formattedData?.[field.id])
    ? typeof field.optionValuer === 'function'
      ? (formattedData?.[field.id] ?? []).map((item) =>
          typeof item === 'object' ? field.optionValuer(item) : item
        )
      : formattedData?.[field.id]
    : field.multiple
      ? []
      : (formattedData?.[field.id] ?? '');

  // Ensure that the selected values show up in list of options
  const filteredOptionsSelected =
    filteredOptions?.filter((o) => {
      const optionValue = applyFunc(field.optionValuer, o);
      return Array.isArray(fieldValue)
        ? fieldValue?.includes(optionValue)
        : fieldValue === optionValue;
    }) ?? [];
  const filteredOptionsOther =
    filteredOptions?.filter((o) => {
      const optionValue = applyFunc(field.optionValuer, o);
      return !(Array.isArray(fieldValue)
        ? fieldValue?.includes(optionValue)
        : fieldValue === optionValue);
    }) ?? [];
  const filteredOptionsVisible = [
    ...filteredOptionsSelected,
    ...filteredOptionsOther,
  ]?.slice(0, visibleCount);
  // const filteredOptionsVisible = filteredOptions?.slice(0, visibleCount);

  const isLoading = !(
    dynamicSelects[field.table]?.data ?? dynamicSelects[field.table]
  );

  return (
    <Tooltip key={field.id} title={field.tip} placement="right">
      <FormControl
        key={field.id}
        fullWidth={fullWidth}
        disabled={
          readOnly ||
          disabled ||
          (typeof field.readOnly === 'function'
            ? field.readOnly(formattedData, oldData)
            : field.readOnly)
        }
        sx={{ minWidth: 100, ...sx }}
      >
        <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
        <Select
          labelId={`${field.id}-label`}
          id={field.id}
          open={isControlled ? formState?.[field.id] || false : opened}
          endAdornment={
            typeof field.endAdornment === 'function'
              ? field.endAdornment(formattedData, field, setNewData)
              : endAdornment
          }
          onOpen={() => {
            isControlled
              ? // @ts-ignore
                setFormState({ ...formState, [field.id]: true })
              : setOpened(true);
          }}
          onClose={() => {
            isControlled
              ? // @ts-ignore
                setFormState({ ...formState, [field.id]: false })
              : setOpened(false);
            setVisibleCount(20);
          }}
          multiple={field.multiple ?? false}
          disabled={
            typeof field.readOnly === 'function'
              ? field.readOnly(formattedData, oldData)
              : field.readOnly
          }
          value={isLoading ? (field.multiple ? [] : '') : fieldValue}
          label={field.label}
          onChange={
            dataFormMode
              ? (e) => {
                  if (field.onChange) field.onChange(e, formattedData);
                  const setter = (val) => {
                    if (typeof field.preSetter === 'function') {
                      val = field.preSetter(val);
                    }
                    setNewData(val);
                  };
                  if (['contacts'].includes(field.id)) {
                    let updatedData = { ...formattedData };
                    if (Array.isArray(e.target.value)) {
                      // Create a map to count the occurrences of each id in e.target.value
                      const idCounts = e.target.value.reduce((counts, item) => {
                        if (item) counts[item.id] = (counts[item.id] || 0) + 1;
                        return counts;
                      }, {});

                      // Find the ids that occur more than once
                      const duplicateIds = new Set(
                        Object.keys(idCounts).filter((id) => idCounts[id] > 1)
                      );
                      const duplicateIdsAsInts = new Set(
                        Array.from(duplicateIds, (id) => Number(id))
                      );

                      // Filter out the objects in e.target.value whose ids are in duplicateIds
                      const uniqueValues = e.target.value.filter(
                        (item) => item && !duplicateIdsAsInts.has(item.id)
                      );

                      // Update updatedData[field.id] with the unique values
                      updatedData[field.id] = uniqueValues;
                    } else {
                      // If it's not an array, just set the value
                      updatedData[field.id] =
                        e.target.value === '' ? null : e.target.value;
                    }
                    setter(updatedData);
                  } else {
                    setter({
                      ...formattedData,
                      [field.id]:
                        e.target.value === ''
                          ? null
                          : Array.isArray(e.target.value)
                            ? e.target.value?.filter((item) => item)
                            : e.target.value,
                    });
                  }
                }
              : (e) => {
                  if (field.onChange) field.onChange(e, formattedData);
                  setNewData(e);
                }
          }
          renderValue={(selected) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
              }}
            >
              {field.multiple
                ? selected?.map((selectedItem, i) => {
                    const value = applyFunc(field.optionValuer, selectedItem);
                    const formattedVal =
                      typeof field.formatter === 'function'
                        ? field.formatter(
                            selectedItem,
                            dynamicSelects[field.table]?.data ??
                              dynamicSelects[field.table]
                          )
                        : selectedItem;
                    return (
                      <Box key={value?.id ?? value ?? selectedItem}>
                        {isValidElement(formattedVal) ? (
                          formattedVal
                        ) : (
                          <Chip key={value} label={formattedVal} />
                        )}
                        {field.delimiter && i < selected.length - 1 && (
                          <Typography sx={{ mx: 0.5 }}>
                            {field.delimiter}
                          </Typography>
                        )}
                      </Box>
                    );
                  })
                : typeof field.formatter === 'function'
                  ? field.formatter(
                      selected,
                      dynamicSelects[field.table]
                        ? (dynamicSelects[field.table]?.data ??
                            dynamicSelects[field.table])
                        : []
                    )
                  : selected}
            </Box>
          )}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#333',
            },
          }}
        >
          <Box
            sx={{
              mb: 0.5,
              mx: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <TextField
              sx={{ flex: 1 }}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              onClickCapture={(e) => {
                console.log(e);
                e.stopPropagation();
              }}
              placeholder="Search"
              // TODO: Fix issues with focus and propagation with the following
              // InputProps={{
              //   startAdornment: <Search sx={{ opacity: 0.5 }} />,
              //   endAdornment: (
              //     <IconButton
              //       onClick={() => {
              //         console.log('clickity');
              //         setQuery('');
              //       }}
              //       sx={{ pr: 0 }}
              //     >
              //       <Clear sx={{ opacity: 0.5 }} />
              //     </IconButton>
              //   ),
              //   style: { height: '34px' },
              // }}
            />
            {addMode ? (
              <Box>
                <Button
                  variant="outlined"
                  onClick={(e) => handleSelectAll(e, filteredOptions)}
                  sx={{ ml: 1 }}
                >
                  Add all ({filteredOptions?.length})
                </Button>
              </Box>
            ) : (
              field.multiple && (
                <Box>
                  <Button
                    variant="outlined"
                    onClick={(e) => handleSelectAll(e, filteredOptions)}
                    sx={{ ml: 1 }}
                  >
                    Select all
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={(e) => handleSelectAll(e, [])}
                    sx={{ ml: 1 }}
                  >
                    Clear
                  </Button>
                </Box>
              )
            )}
          </Box>
          {field.nullable && (
            <MenuItem value={''} key="null">
              <Box>&nbsp;</Box>
            </MenuItem>
          )}
          {filteredOptionsVisible?.map((option) => (
            <MenuItem
              value={applyFunc(field.optionValuer, option)}
              key={applyFunc(field.optionValuer, option)}
            >
              {typeof field.optionFormatter === 'function'
                ? field.optionFormatter(option)
                : typeof field.formatter === 'function'
                  ? field.formatter(option)
                  : typeof option === 'object'
                    ? JSON.stringify(option)
                    : option}
            </MenuItem>
          ))}
          {filteredOptions?.length > visibleCount && (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              <Button
                onClick={() => {
                  setVisibleCount(visibleCount + 100);
                }}
              >
                Show more ({filteredOptions?.length - visibleCount})
              </Button>
            </Box>
          )}
        </Select>
      </FormControl>
    </Tooltip>
  );
};

export default DynamicSelect;
