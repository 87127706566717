import React, { createContext, useState, ReactNode } from 'react';

export const DashboardContext = createContext<any>({
  predefinedDashboardName: null,
  predefinedWidgetSettings: null, // Fixed typo
  setPredefinedDashboardName: () => {},
  setPredefinedWidgetSettings: () => {}, // Fixed typo
});

export const DashboardProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [predefinedDashboardName, setPredefinedDashboardName] = useState<
    string | null
  >(null);
  const [predefinedWidgetSettings, setPredefinedWidgetSettings] = useState<
    any | null
  >(null); // Fixed typo

  return (
    <DashboardContext.Provider
      value={{
        predefinedDashboardName,
        predefinedWidgetSettings, // Fixed typo
        setPredefinedDashboardName,
        setPredefinedWidgetSettings,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
