import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';

import { BasicDialog } from '@/common';
import DataView from '@/components/DataView';
import API from '@/services/API';
import ActionModal from '@/components/documents/MappingsView/ActionModal';
import {
  cellSx,
  headerSx,
} from '@/components/HoverActionButtonContainer/styles';
import { HoverActionButtonContainer } from '@/components/HoverActionButtonContainer';

const MappingsView = () => {
  const [refresh, setRefresh] = useState<number>(0);
  const [current, setCurrent] = useState<any>(null);

  const [showDel, setShowDel] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const mappingDel = API.getMutation('mappings', 'DELETE');
  const { data: companies } = API.getBasicQuery('companies');

  const deleteMapping = async (id) => {
    try {
      await mappingDel.mutateAsync({
        ids: [id],
      });
      setRefresh(refresh + 1);
      setCurrent(null);
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = (v) => {
    if (v) {
      deleteMapping(current.id);
    } else {
      setCurrent(null);
    }
    setShowDel(false);
  };

  const dataDesc = {
    label: 'Mappings',
    table: 'mappings',
    editable: false,
    fields: [
      {
        id: 'created_at',
        label: 'Created at',
        formatter: (s) => new Date(s).toLocaleString(),
        readOnly: true,
      },
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'type',
        label: 'Type',
        type: 'select',
        options: ['statement', 'report'],
        readOnly: true,
      },
      {
        id: 'carrier',
        label: 'Carrier',
        formatter: (s) => (typeof s === 'object' ? s?.company_name : s),
        normalizer: (s) => (typeof s === 'string' ? JSON.parse(s) : s),
        readOnly: true,
      },
      {
        id: 'mapping',
        label: 'Mapping',
        // type: 'text-multiline',
        formatter: (s) =>
          typeof s === 'object' ? JSON.stringify(s, null, 2) : s,
        normalizer: (s) => (typeof s === 'string' ? JSON.parse(s) : s),
        readOnly: true,
      },

      {
        id: 'access',
        label: 'Access',
        type: 'select',
        options: ['global', 'account'],
        access: 'admin',
      },
      {
        id: 'id',
        label: 'Actions',
        sx: cellSx,
        headerSx,
        formatter: (v, row) => {
          return (
            <HoverActionButtonContainer>
              <IconButton
                className="ml-1"
                onClick={() => {
                  setCurrent(row);
                  setShowEdit(true);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                className="ml-1"
                onClick={() => {
                  setCurrent(row);
                  setShowDel(true);
                }}
              >
                <Delete />
              </IconButton>
            </HoverActionButtonContainer>
          );
        },
      },
    ],
  };
  return (
    <>
      <DataView dataDesc={dataDesc} viewOnly refresh={refresh} />
      {showEdit && current && (
        <ActionModal
          open={showEdit}
          setOpen={setShowEdit}
          rowData={current}
          companies={companies?.data ?? []}
        />
      )}
      {showDel && current && (
        <BasicDialog
          title="Delete mapping"
          positiveLabel="Delete"
          open={showDel}
          onClose={onClose}
          bodyComponent={
            <Box>
              <h4>Are you sure you want to delete the mapping?</h4>
              <p className="text-sm">
                <span className="text-gray-500">Name:</span>
                <span className="pl-2">{current.name}</span>
              </p>
              {current.carrier && (
                <p className="text-sm">
                  <span className="text-gray-500">Carrier:</span>
                  <span className="pl-2">{current.carrier?.company_name}</span>
                </p>
              )}
            </Box>
          }
        />
      )}
    </>
  );
};

export default MappingsView;
