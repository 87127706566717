import { AutoFixHigh, AutoFixNormal } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { UIStateContext } from 'contexts/UIStateProvider';
import didYouMean, { ReturnTypeEnums } from 'didyoumean2';
import { useContext, useEffect, useMemo, useState } from 'react';

import LoadingCircle from '@/components/atoms/LoadingCircle';
import RowCard from '@/components/molecules/RowCard';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import API from '@/services/API';
import Reports from '@/services/Reports';
import Statements from '@/services/Statements';
import UILabels from '@/services/UILabels';
import { useAccountStore, useRoleStore } from '@/store';

const filters = {
  startDate: {
    label: 'Start date',
    type: 'date',
    field: 'effectiveDate',
    initialize: () =>
      new Date(
        new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(
          0,
          0,
          0,
          0
        )
      ),
    filterFunc: (val, filterVal) => val >= filterVal,
  },
  endDate: {
    label: 'End date',
    type: 'date',
    field: 'effectiveDate',
    initialize: () => new Date(new Date().setHours(0, 0, 0, 0)),
    filterFunc: (val, filterVal) => val <= filterVal,
  },
  carrier: {
    label: 'Carrier/MGA',
    type: 'multiSelect',
    field: 'writingCarrierName',
    initialize: (data) =>
      Array.from(
        new Set(
          Object.values(data)
            .filter((datum) => datum.writingCarrierName)
            .map((datum) => datum.writingCarrierName)
        )
      ),
  },
  mga: {
    label: 'Paying entity',
    type: 'multiSelect',
    field: 'carrierName',
    initialize: (data) =>
      Array.from(
        new Set(
          Object.values(data)
            .filter((datum) => datum.carrierName)
            .map((datum) => datum.carrierName)
        )
      ),
  },
};

// TODO: Temp mockup for demo, need to refactor/rewrite
const ReconcilerView = () => {
  const {
    commissions: [commissionsUI, setCommissionsUI],
    role: [role],
  } = useContext(UIStateContext);
  const { data: generalSettings } = API.getUser();
  const mode = generalSettings?.mode ?? 'default';
  const { selectedAccount } = useAccountStore();
  const { userRole } = useRoleStore();
  const statements = useMemo(
    () =>
      new Statements(mode, role, userRole, {
        account_id: selectedAccount?.accountId,
      }),
    [mode]
  );
  const reports = useMemo(
    () => new Reports(mode, { account_id: selectedAccount?.accountId }),
    [mode]
  );

  const labels = new UILabels(mode || 'default');

  const [filter, setFilter] = useState(commissionsUI.filter ?? 1);
  const [method, setMethod] = useState('string');
  const [query, setQuery] = useState('');
  const [filteredValues, setFilteredValues] = useState(
    commissionsUI.filteredValues ?? {}
  );
  const [filteredFields, setFilteredFields] = useState([]);
  const [availableValues, setAvailableValues] = useState({});

  const [dataKeys, setDataKeys] = useState([]);
  // const [reconciliationData, setReconciliationData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState([]);
  // const [statementDataDupes, setStatementDataDupes] = useState([]);
  const [updating, setUpdating] = useState(false);

  const { data: reconciliationResults, isLoading } = API.getBasicQuery(
    'reconciliation_data'
  );

  const reconciliationData = reconciliationResults?.data;

  useEffect(() => {
    const convertedFilteredValues = { ...filteredValues };
    // If multiSelect and all selected, save as 'all', since selected varies from filter to filter.
    Object.entries(filters).forEach(([k, v]) => {
      if (v.type === 'multiSelect') {
        if (
          availableValues[k] &&
          filteredValues[k] &&
          availableValues[k]?.length === filteredValues[k]?.length
        ) {
          convertedFilteredValues[k] = 'all';
        }
      }
    });
    setCommissionsUI({
      ...commissionsUI,
      filteredValues: convertedFilteredValues,
    });
  }, [filteredValues]);

  const statementIds = selectedData.map((row) => row.id);
  const { data: similarReportsData } = API.getBasicQuery(
    'report_data/similar',
    `statement_ids=${statementIds.join(',')}`
  );
  const similarReports = similarReportsData?.similarReports ?? [];

  if (!reconciliationData) {
    return null;
  }

  const orphanedCommissions = reconciliationData.filter(
    (v) => v.reconciled === '⚠️ Missing policy data'
  );

  const mapPolicyIdToNormalized = {};
  reconciliationData
    .filter((v) => v.reconciled !== '⚠️ Missing policy data')
    .forEach((row) => {
      mapPolicyIdToNormalized[row.policy_id] = row.normalized_id;
    });

  const policyIds = reconciliationData
    .filter((v) => v.reconciled !== '⚠️ Missing policy data')
    .map((row) => row.policy_id);

  const reconciliationDataMap = Object.fromEntries(
    reconciliationData.map((row) => [row.normalized_id, row])
  );

  orphanedCommissions.forEach((e) => {
    const res = didYouMean(e.policy_id?.toString() ?? '', policyIds, {
      returnType: ReturnTypeEnums.ALL_SORTED_MATCHES,
      threshold: 0.5,
    });
    if (res.length > 0) {
      res.forEach((e2) => {
        if (
          !reconciliationDataMap[mapPolicyIdToNormalized[e2]].possibleMatches
        ) {
          reconciliationDataMap[mapPolicyIdToNormalized[e2]].possibleMatches =
            [];
        }
        console.log('found possible match', e, e2);
        reconciliationDataMap[mapPolicyIdToNormalized[e2]].possibleMatches.push(
          e
        );
      });
      e.possibleMatches = res;
    }
  });

  const dataProcessedFiltered = reconciliationData.filter((row) => {
    if (filter === 0) {
      return true;
    }
    if (filter === 1) {
      return (
        !row.reconciled?.includes('✅') &&
        (typeof row.policy_status !== 'string' ||
          ![
            'inactive',
            'cancelled',
            'canceled',
            'non-renewed',
            'lapsed',
          ].includes(row.policy_status.toLowerCase()))
      );
    }
    if (filter === 2) {
      return row.reconciled?.includes('⛔️');
    }
    if (filter === 3) {
      return row.reconciled?.includes('✅');
    }
    return true;
  });
  const commissionsMissingPolicies = reconciliationData.filter((row) => {
    return row.reconciled === '⚠️ Missing policy data';
  });

  const commissionIdsMissingPolicies = commissionsMissingPolicies.map(
    (row) => row.statement_id
  );

  const rowCardFields = [
    statements.fields.writing_carrier_name,
    statements.fields.effective_date,
    statements.fields.premium_amount,
    statements.fields.commission_amount,
  ].filter((field) => field);

  console.log('selectedData', selectedData);

  const suggestedResultsStringMatch = [
    ...new Set(
      selectedData.reduce((acc, cur) => {
        const possibleMatches =
          dataProcessedFiltered.find((e) => e.statement_id === String(cur.id))
            .possibleMatches ?? [];
        console.log('possibleMatches', possibleMatches);
        if (possibleMatches) {
          possibleMatches.forEach((match) => {
            return acc.push(match);
          });
        }
        return acc;
      }, [])
    ),
  ];

  const suggestedResults =
    method === 'string'
      ? suggestedResultsStringMatch
      : similarReports.map((e) => e.policy_id);
  console.log('suggestedResults', suggestedResults, similarReports);

  return (
    <Box mt={2} mx={2} sx={{ width: 'calc(100vw - 232px)' }}>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Reconciliation</Typography>
        </Box>
        <Box
          sx={{
            my: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Chip
            sx={{ mr: 0.5, cursor: 'pointer' }}
            color={filter === 1 ? 'primary' : 'default'}
            label="Missing policies"
            variant={filter === 1 ? 'filled' : 'outlined'}
            onClick={() => {}}
          />
          {/* <Chip
            sx={{ mr: 0.5, cursor: 'pointer' }}
            color={filter === 0 ? 'primary' : 'default'}
            variant={filter === 0 ? 'filled' : 'outlined'}
            label="All"
            onClick={() => {
              setFilter(0);
              setCommissionsUI({ ...commissionsUI, filter: 0 });
            }}
          />
          <Chip
            sx={{ mr: 0.5, cursor: 'pointer' }}
            color={filter === 1 ? 'primary' : 'default'}
            label="Needs Attn"
            variant={filter === 1 ? 'filled' : 'outlined'}
            onClick={() => {
              setFilter(1);
              setCommissionsUI({ ...commissionsUI, filter: 1 });
            }}
          /> */}
          {/* <Chip
              sx={{ mr: 0.5, cursor: 'pointer' }}
              color={filter === 2 ? 'primary' : 'default'}
              label="Missing Comm"
              variant={filter === 2 ? 'filled' : 'outlined'}
              onClick={() => {
                setFilter(2);
                setCommissionsUI({ ...commissionsUI, filter: 2 });
              }}
            /> */}
          {/* <Chip
            sx={{ mr: 0.5, cursor: 'pointer' }}
            color={filter === 3 ? 'primary' : 'default'}
            label="Reconciled"
            variant={filter === 3 ? 'filled' : 'outlined'}
            onClick={() => {
              setFilter(3);
              setCommissionsUI({ ...commissionsUI, filter: 3 });
            }}
          /> */}
        </Box>
        {isLoading && <LoadingCircle />}
        {!isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <EnhancedDataView
              dataSpec={statements}
              options={{
                mode: 'reconciler',
              }}
              prefilter={(row) =>
                commissionIdsMissingPolicies.includes(String(row.id))
              }
              setSelectedData={setSelectedData}
              hideSelectedCount
            />
            <Box
              sx={{
                width: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // justifyContent: 'center',
                mx: 1,
                mt: 4,
              }}
            >
              <ToggleButtonGroup
                orientation="vertical"
                value={method}
                exclusive
                onChange={(e, newVal) => {
                  console.log('method', newVal);
                  if (newVal) setMethod(newVal);
                }}
                sx={{ mb: 4 }}
              >
                <ToggleButton value="string">
                  <Tooltip title="Basic magic" placement="right">
                    <AutoFixNormal />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="embedding">
                  <Tooltip title="Super magic" placement="right">
                    <AutoFixHigh />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              {selectedData.map((row) => (
                <RowCard
                  key={row.id}
                  data={row}
                  fields={rowCardFields}
                  colSize={12}
                  style={{
                    bgcolor: (theme) =>
                      alpha(
                        theme.palette.primary.main,
                        theme.palette.action.activatedOpacity
                      ),
                  }}
                />
              ))}
              <LoadingButton
                sx={{ mt: 2 }}
                variant="contained"
                disabled={!selectedData[0] || !selectedPolicy[0]}
                onClick={async () => {
                  setUpdating(true);
                  setTimeout(() => {
                    setUpdating(false);
                  }, 1000);
                }}
                loading={updating}
                sx={{ width: '112px' }}
              >
                Reconcile
              </LoadingButton>
            </Box>
            <EnhancedDataView
              dataSpec={reports}
              options={{
                mode: 'reconciler',
                radio: true,
              }}
              // TODO: in this case these should be values (policy id strings) not objects (row)...need to reconcile statements vs policy so they're both either values or objects.
              suggested={suggestedResults}
              // prefilter={(row) => selectedPolicy[0]?.policyId}
              setSelectedData={setSelectedPolicy}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReconcilerView;
