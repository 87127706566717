import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { nanoid } from 'nanoid';
import { enumToSelectOptions } from 'common/utils';
import { TransactionStatuses } from 'common/constants';
import currency from 'currency.js';

import BasicDatePicker from '@/components/molecules/BasicDatePicker';

type TransactionDetail = {
  id: string;
  amount: string;
  status: string;
  date: Date | null;
  notes: string;
};

type Transaction = {
  id: string;
  amount: string;
  status: string;
  date: Date | null;
  notes: string;
  accounting_transaction_details?: TransactionDetail[];
};

type Field = {
  id: string;
  label: string;
};

type ContactsTransactionsAddProps = {
  data: {
    accounting_transactions: Transaction[];
    balance: number;
  };
  setter: (value: any) => void;
  field: Field;
};

const defaultTransaction: Omit<Transaction, 'id'> = {
  amount: '',
  status: TransactionStatuses.Draft,
  date: null,
  notes: '',
  accounting_transaction_details: [],
};

const defaultTransactionDetails: Omit<TransactionDetail, 'id'> = {
  amount: '',
  status: TransactionStatuses.Draft,
  date: null,
  notes: '',
};

const calculateTotalAmount = (details: TransactionDetail[]): string => {
  return currency(
    details.reduce(
      (sum, detail) => sum.add(currency(detail.amount || '0')),
      currency(0)
    )
  ).toString();
};

const updateTransaction = (
  transactions: Transaction[],
  transactionId: string,
  updateFn: (transaction: Transaction) => Transaction
): Transaction[] => {
  return transactions.map((transaction) =>
    transaction.id === transactionId ? updateFn(transaction) : transaction
  );
};

const updateTransactionDetail = (
  details: TransactionDetail[],
  detailId: string,
  updateFn: (detail: TransactionDetail) => TransactionDetail
): TransactionDetail[] => {
  return details.map((detail) =>
    detail.id === detailId ? updateFn(detail) : detail
  );
};

const ContactsTransactionsAdd: React.FC<ContactsTransactionsAddProps> = ({
  data,
  field,
  setter,
}) => {
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});

  const handleToggle = (id: string) => {
    setOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const contactTransactions = data?.accounting_transactions ?? [];

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle2">{field.label}</Typography>
      <TableContainer>
        <Table>
          {contactTransactions.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Date</TableCell>
                <TableCell>Total amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell sx={{ width: 0 }} />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {contactTransactions.map((contactTransaction) => (
              <React.Fragment key={contactTransaction.id}>
                <TableRow>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleToggle(contactTransaction.id)}
                    >
                      {open[contactTransaction.id] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <BasicDatePicker
                      label="Date"
                      value={contactTransaction.date}
                      setValue={(e) => {
                        setter({
                          ...data,
                          accounting_transactions: updateTransaction(
                            data.accounting_transactions,
                            contactTransaction.id,
                            (item) => ({ ...item, date: e })
                          ),
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="Total amount"
                      variant="outlined"
                      value={calculateTotalAmount(
                        contactTransaction.accounting_transaction_details || []
                      )}
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={contactTransaction.status ?? ''}
                        label="Status"
                        onChange={(e) => {
                          setter({
                            ...data,
                            accounting_transactions: updateTransaction(
                              data.accounting_transactions,
                              contactTransaction.id,
                              (item) => ({
                                ...item,
                                status: e.target.value,
                                accounting_transaction_details: (
                                  item.accounting_transaction_details ?? []
                                ).map((td) => ({
                                  ...td,
                                  status: e.target.value,
                                })),
                              })
                            ),
                          });
                        }}
                      >
                        {enumToSelectOptions(TransactionStatuses).map(
                          (option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.label as string}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="Notes"
                      variant="outlined"
                      value={contactTransaction.notes ?? ''}
                      onChange={(e) => {
                        setter({
                          ...data,
                          accounting_transactions: updateTransaction(
                            data.accounting_transactions,
                            contactTransaction.id,
                            (item) => ({ ...item, notes: e.target.value })
                          ),
                        });
                      }}
                      multiline
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setter({
                          ...data,
                          accounting_transactions: contactTransactions.filter(
                            (cl) => cl.id !== contactTransaction.id
                          ),
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={open[contactTransaction.id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ width: '100%', ml: 1 }}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          component="div"
                        >
                          Details
                        </Typography>
                        <Table size="small">
                          <TableHead>
                            {contactTransaction.accounting_transaction_details &&
                              contactTransaction.accounting_transaction_details
                                .length > 0 && (
                                <TableRow>
                                  <TableCell>Date</TableCell>
                                  <TableCell>Amount</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell>Notes</TableCell>
                                  <TableCell sx={{ width: 0 }} />
                                </TableRow>
                              )}
                          </TableHead>
                          <TableBody>
                            {contactTransaction.accounting_transaction_details?.map(
                              (transactionDetail) => (
                                <TableRow key={transactionDetail.id}>
                                  <TableCell>
                                    <BasicDatePicker
                                      label="Date"
                                      value={transactionDetail.date}
                                      setValue={(e) => {
                                        setter({
                                          ...data,
                                          accounting_transactions:
                                            updateTransaction(
                                              data.accounting_transactions,
                                              contactTransaction.id,
                                              (item) => ({
                                                ...item,
                                                accounting_transaction_details:
                                                  updateTransactionDetail(
                                                    item.accounting_transaction_details ??
                                                      [],
                                                    transactionDetail.id,
                                                    (detailItem) => ({
                                                      ...detailItem,
                                                      date: e,
                                                    })
                                                  ),
                                                amount: calculateTotalAmount(
                                                  item.accounting_transaction_details ??
                                                    []
                                                ),
                                              })
                                            ),
                                        });
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      label="Amount"
                                      variant="outlined"
                                      value={transactionDetail.amount ?? ''}
                                      onChange={(e) => {
                                        const newAmount = e.target.value;
                                        const updatedTransactions =
                                          updateTransaction(
                                            data.accounting_transactions,
                                            contactTransaction.id,
                                            (item) => ({
                                              ...item,
                                              accounting_transaction_details:
                                                updateTransactionDetail(
                                                  item.accounting_transaction_details ??
                                                    [],
                                                  transactionDetail.id,
                                                  (detailItem) => ({
                                                    ...detailItem,
                                                    amount: newAmount,
                                                  })
                                                ),
                                              amount: calculateTotalAmount(
                                                item.accounting_transaction_details ??
                                                  []
                                              ),
                                            })
                                          );

                                        setter({
                                          ...data,
                                          accounting_transactions:
                                            updatedTransactions,
                                        });
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormControl>
                                      <InputLabel>Status</InputLabel>
                                      <Select
                                        value={transactionDetail.status ?? ''}
                                        label="Status"
                                        onChange={(e) => {
                                          const newStatus = e.target.value;
                                          const updatedTransactions =
                                            updateTransaction(
                                              data.accounting_transactions,
                                              contactTransaction.id,
                                              (item) => ({
                                                ...item,
                                                accounting_transaction_details:
                                                  updateTransactionDetail(
                                                    item.accounting_transaction_details ??
                                                      [],
                                                    transactionDetail.id,
                                                    (detailItem) => ({
                                                      ...detailItem,
                                                      status: newStatus,
                                                    })
                                                  ),
                                                amount: calculateTotalAmount(
                                                  item.accounting_transaction_details ??
                                                    []
                                                ),
                                              })
                                            );

                                          setter({
                                            ...data,
                                            accounting_transactions:
                                              updatedTransactions,
                                          });
                                        }}
                                      >
                                        {enumToSelectOptions(
                                          TransactionStatuses
                                        ).map((option) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.label as string}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      label="Notes"
                                      variant="outlined"
                                      value={transactionDetail.notes ?? ''}
                                      onChange={(e) => {
                                        setter({
                                          ...data,
                                          accounting_transactions:
                                            updateTransaction(
                                              data.accounting_transactions,
                                              contactTransaction.id,
                                              (item) => ({
                                                ...item,
                                                accounting_transaction_details:
                                                  updateTransactionDetail(
                                                    item.accounting_transaction_details ??
                                                      [],
                                                    transactionDetail.id,
                                                    (detailItem) => ({
                                                      ...detailItem,
                                                      notes: e.target.value,
                                                    })
                                                  ),
                                              })
                                            ),
                                        });
                                      }}
                                      multiline
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      onClick={() => {
                                        setter({
                                          ...data,
                                          accounting_transactions:
                                            updateTransaction(
                                              data.accounting_transactions,
                                              contactTransaction.id,
                                              (item) => ({
                                                ...item,
                                                accounting_transaction_details:
                                                  item.accounting_transaction_details?.filter(
                                                    (detailItem) =>
                                                      detailItem.id !==
                                                      transactionDetail.id
                                                  ),
                                                amount: calculateTotalAmount(
                                                  item.accounting_transaction_details ??
                                                    []
                                                ),
                                              })
                                            ),
                                        });
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                            <TableRow>
                              <TableCell colSpan={4}>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setter({
                                      ...data,
                                      accounting_transactions:
                                        updateTransaction(
                                          data.accounting_transactions,
                                          contactTransaction.id,
                                          (item) => ({
                                            ...item,
                                            accounting_transaction_details: [
                                              ...(item.accounting_transaction_details ??
                                                []),
                                              {
                                                id: nanoid(),
                                                ...defaultTransactionDetails,
                                              },
                                            ],
                                            amount: calculateTotalAmount([
                                              ...(item.accounting_transaction_details ??
                                                []),
                                              {
                                                id: nanoid(),
                                                ...defaultTransactionDetails,
                                              },
                                            ]),
                                          })
                                        ),
                                    });
                                  }}
                                >
                                  Add detail
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
            <TableRow>
              <TableCell colSpan={6}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setter({
                      ...data,
                      accounting_transactions: [
                        ...(data.accounting_transactions ?? []),
                        { id: nanoid(), ...defaultTransaction },
                      ],
                    });
                  }}
                >
                  Add transaction
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ContactsTransactionsAdd;
