export enum DataProcessingTypes {
  AGENT_COMMISSION_CALC = 'agent_commission_calc',
  GROUPING = 'grouping',
  RECONCILIATION = 'reconciliation',
  DATA_SYNC = 'data_sync',
  SYNC = 'sync',

  DOCUMENT_PROCESSING = 'document_processing',
  DOCUMENT_EXTRACTION = 'document_extraction',
}
