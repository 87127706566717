import { useContext } from 'react';
import { Snackbar, Box, Alert } from '@mui/material';

import { GlobalSnackbarContext } from '@/contexts/SnackbarContext';

const GlobalSnackbar = () => {
  const { globalSnackbarConfig, setGlobalSnackbarConfig } = useContext(
    GlobalSnackbarContext
  );
  return (
    <Snackbar
      open={globalSnackbarConfig.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={globalSnackbarConfig.autoHideDuration || 8000}
      onClose={() => {
        setGlobalSnackbarConfig({
          ...globalSnackbarConfig,
          open: false,
        });
      }}
    >
      {typeof globalSnackbarConfig.message === 'object' ? (
        globalSnackbarConfig.message
      ) : (
        <Alert severity={globalSnackbarConfig.severity as any}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            {globalSnackbarConfig.message}
          </Box>
        </Alert>
      )}
    </Snackbar>
  );
};

export default GlobalSnackbar;
