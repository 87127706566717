import { Launch, VerticalSplit } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { getFilenameFromPath } from 'common/helpers';
import { AgentCommissionsStatusesLabels } from 'common/globalTypes';

import AgentCommissionsEdit from '@/components/CommissionsDataView/AgentCommissionsEdit';
import CommissionCalcLog from '@/components/molecules/CommissionCalcLog';
import { ComparisonContext } from '@/contexts/ReconciliationConfirmProvider';
import states from '@/data/states.json';
import API from '@/services/API';
import DataTransformation from '@/services/DataTransformation';
import Formatter from '@/services/Formatter';
import UILabels from '@/services/UILabels';
import { Field, FieldTypes, Roles } from '@/types';
import PayoutStatusEdit from '@/components/CommissionsDataView/PayoutStatusEdit';

const Normalizer = DataTransformation;

const policyDataIfEmpty =
  (field, formatter = (s) => s) =>
  (val, row) => {
    const policyVal = row?.report?.[field];
    return val
      ? formatter(val)
      : policyVal && (
          <Tooltip title="Sourced from policy data">
            <span>{formatter(policyVal)}*</span>
          </Tooltip>
        );
  };

class Statements {
  #mode = 'default';

  #strings = new UILabels(this.#mode);

  label = 'Statements data';
  labelSimple = 'Statements data';

  table = 'statement_data';

  copyable = true;

  filters: { [key: string]: any } = {};

  fields: { [key: string]: Field } = {};

  actions: Record<string, any>[] = [];

  outstandingFieldsInMobileView: any[] = [];

  dateFilters: any[] = [];

  fieldsCollapsed: any[] = [];

  options: Record<string, any> = {};

  constructor(
    mode?: string | null,
    role?: any,
    userRole?: Roles | null,
    options?: any
  ) {
    this.#mode = mode ?? this.#mode;
    this.#strings = new UILabels(mode);
    this.options = options;
    this.label = this.#strings.getLabel('cash', 'title');
    this.labelSimple = this.#strings.getLabel('cash', 'titleSimple');
    this.copyable = true;
    this.filters = {
      agent_name: {
        label: 'Agent name',
      },
      carrier_name: {
        label: 'Paying entity',
      },
      compensation_type: {
        label: 'Compensation type',
      },
      contacts: {
        label: 'Agents',
      },
      document_id: {
        label: 'Document',
      },
      payment_status: {
        label: 'Payment status',
      },
      product_name: {
        label: 'Product name',
      },
      product_type: {
        label: 'Product type',
      },
      status: {
        label: 'Status',
      },
      writing_carrier_name: {
        label: 'Carrier/MGA',
      },
      premium_type: {
        label: 'Premium type',
      },
      account_type: {
        label: 'Account type',
      },
      agent_commissions_status: {
        label: 'Payout status',
      },
      agent_commissions_status2: {
        label: 'Payout status*',
      },
      report_data_id: {
        label: 'Reconciliation',
      },
      // reconciliation_status: {
      //   label: 'Reconciliation status',
      // },
    };
    this.fields = {
      policy_id: {
        // string
        label: this.#strings.getLabel('cash', 'transactionId'),
        matches: [
          'policy id',
          'policy no',
          'policy no.',
          'policy number',
          'policy #',
          'policy',
          'contract #',
          'policy_number',
          'policyid',
        ],
        reconciler: true,
        enabled: true,
        normalizer: (s) => s?.toString(),
        subPolicyDataIfEmpty: true,
        tableFormatter: policyDataIfEmpty('policy_id', (val) =>
          Formatter.policyNumber(val, { account_id: this.options?.account_id })
        ),
        copyable: true,
      },
      // totalAmount: {
      //   label: 'Total amount',
      //   matches: ['total', 'total amount', 'total amt', 'amount'],
      //   enabled: true,
      //   global: true,
      //   formatter: (s) => (s?.toString()?.startsWith('$') ? s : `$${s}`),
      // },
      commission_amount: {
        label: this.#strings.getLabel('cash', 'amount'),
        matches: [
          'commission',
          'commission amount',
          'gross commission',
          'gross comm earned',
          'commission earned',
          'commission due',
          'amount',
          'comm amount',
          'comm amt',
          'commissionamount',
        ],
        enabled: true,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,

        type: FieldTypes.CURRENCY,
      },
      commission_paid_amount: {
        label: 'Commissions paid',
        matches: ['commissions paid out', 'agent commission'],
        enabled: true,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: FieldTypes.CURRENCY,
      },
      customer_name: {
        label: this.#strings.getLabel('cash', 'customerName'),
        matches: [
          'customer name',
          'customer',
          'insuree',
          'insured',
          'client',
          'client name',
          'insured name',
          'policy holder',
          'policy holder name',
          'subscriber',
          'name of insured',
          'consumer name contract #',
          'named insured',
          'customername',
          'policyholder name',
          'individual name',
        ],
        reconciler: true,
        enabled: true,
      },
      invoice_date: {
        label: this.#strings.getLabel('cash', 'invoiceDate'),
        matches: ['invoice date', 'invoice received', 'invoiceDate'],
        enabled: true,
        global: true,
        type: FieldTypes.DATE,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
      },
      payment_date: {
        label: this.#strings.getLabel('cash', 'paymentDate'),
        matches: [
          'payment date',
          'payment received',
          'statement date',
          'month billed',
          'paymentdate',
        ],
        enabled: true,
        global: true,
        type: FieldTypes.DATE,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
      },
      processing_date: {
        label: this.#strings.getLabel('cash', 'processingDate'),
        matches: ['processing date', 'processingDate'],
        enabled: true,
        global: true,
        type: FieldTypes.DATE,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
      },
      agent_name: {
        label: this.#strings.getLabel('cash', 'repName'),
        matches: [
          'assigned agent',
          'agt',
          'writing agent',
          'writing agt',
          'agent name',
          'agt name',
          'assigned_agent',
          'agent',
          'producer name',
        ],
        enabled: this.#mode === 'insurance',
        global: true,
      },
      statement_number: {
        // new, renewal, cancellation, rewrite, fees
        label: this.#strings.getLabel('cash', 'invoiceNumber'),
        matches: [
          'statement number',
          'commission statement number',
          'statementnumber',
        ],
        enabled: true,
      },
      transaction_type: {
        // new, renewal, cancellation, rewrite, fees
        label: this.#strings.getLabel('cash', 'transactionType'),
        matches: [
          'transaction type',
          'commission type',
          'policy type',
          'transactiontype',
        ],
        subPolicyDataIfEmpty: true,
        formatter: (val, row) => {
          return val
            ? val
            : row?.report?.transaction_type && (
                <Tooltip title="Sourced from policy data">
                  <span>{row?.report?.transaction_type}*</span>
                </Tooltip>
              );
        },
        enabled: true,
        tableFormatter: policyDataIfEmpty('transaction_type'),
      },
      writing_carrier_name: {
        // string
        label: this.#strings.getLabel('cash', 'entity'),
        matches: ['carrier name', 'carrier', 'writingcarriername'],
        enabled: this.#mode === 'insurance',
        reconciler: true,
        global: true,
        type: FieldTypes.DYNAMIC_SELECT,
        table: 'companies',
        formatter: (val, collectionVals = []) => {
          if (
            val &&
            Array.isArray(collectionVals) &&
            collectionVals.length > 0
          ) {
            const datum = collectionVals?.filter(
              (company) => company.company_name === val
            )?.[0];
            return datum ? (
              <Chip
                key={val}
                label={`${datum.company_name}`}
                clickable
                component="a"
                href={`/companies?q=${val}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val;
        },
        optionFormatter: (option) => option.company_name,
        optionValuer: (option) => option.company_name,
      },
      premium_type: {
        label: this.#strings.getLabel('cash', 'premiumType'),
        type: FieldTypes.SELECT,
        matches: [],
        options: ['policy', 'split'],
        reconciler: true,
        enabled: this.#mode === 'insurance',
        global: true,
      },
      carrier_name: {
        // string
        label: this.#strings.getLabel('cash', 'payingEntity'),
        matches: ['carrier name', 'carrier', 'carriername'],
        enabled: true,
        global: true,
        type: FieldTypes.DYNAMIC_SELECT,

        table: 'companies',
        formatter: (val, collectionVals = []) => {
          if (
            val &&
            Array.isArray(collectionVals) &&
            collectionVals.length > 0
          ) {
            const datum = collectionVals?.filter(
              (company) => company.company_name === val
            )?.[0];
            return datum ? (
              <Chip
                key={val}
                label={`${datum.company_name}`}
                clickable
                component="a"
                href={`/companies?q=${val}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val;
        },
        optionFormatter: (option) => option.company_name,
        optionValuer: (option) => option.company_name,
      },
      agent_id: {
        label: this.#strings.getLabel('cash', 'repId'),
        matches: [
          'agent id',
          'agt id',
          'agent no',
          'agt no',
          'writing agent #',
          'writing agent no',
          'writing agent id',
          'writing agt #',
          'writing agt no',
          'writing agt id',
          'agentid',
        ],
        enabled: this.#mode === 'insurance',
      },
      premium_amount: {
        label: this.#strings.getLabel('cash', 'annualizedPremium'),
        description:
          'Annualized premium amount as recorded in commission statements',
        matches: [
          'premium amount',
          'premium amt',
          'premium paid',
          'premium',
          'premium - annualized',
          'annualized_premium',
          'annualized_premium2',
          'premiumamount',
          'policy premium',
          'Paid Prem',
        ],
        enabled: this.#mode === 'insurance',
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,

        type: FieldTypes.CURRENCY,
      },
      expected_result: {
        label: 'Expected result',
        enabled: true,
        readOnly: true,
      },
      commission_rate: {
        // number
        label: this.#strings.getLabel('cash', 'rate'),
        matches: [
          'commission rate',
          'commission pct',
          'commission %',
          'commission perentage',
          'comm %',
          'commissionrate',
        ],
        enabled: this.#mode === 'insurance',
        formatter: Normalizer.formatPercentage,
        normalizer: Normalizer.normalizePercentage,
        type: FieldTypes.PERCENTAGE,
      },
      carrier_rate: {
        label: 'Carrier rate',
        matches: [],
        enabled: this.#mode === 'insurance',
        formatter: Normalizer.formatPercentage,
        normalizer: Normalizer.normalizePercentage,
        type: FieldTypes.PERCENTAGE,
      },
      // Rows - Optional
      effective_date: {
        // date
        label: this.#strings.getLabel('cash', 'startDate'),
        matches: [
          'policy effective date',
          'effective date',
          'effective',
          'policy effective',
          'in force date',
          'eff date',
          'effective_date',
          'effective_date2',
          'effectivedate',
        ],
        enabled: true,
        type: FieldTypes.DATE,
        tableFormatter: policyDataIfEmpty(
          'effective_date',
          Normalizer.formatDate
        ),
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
      },
      product_type: {
        label: this.#strings.getLabel('cash', 'productType'),
        matches: ['product type', 'product line', 'producttype'],
        reconciler: true,
        enabled: this.#mode === 'insurance',
        global: true,
        tableFormatter: policyDataIfEmpty('product_type'),
      },
      product_sub_type: {
        label: 'Product sub type',
        matches: [],
        reconciler: true,
        enabled: this.#mode === 'insurance',
        tableFormatter: policyDataIfEmpty('product_sub_type'),
        global: true,
      },
      product_name: {
        label: this.#strings.getLabel('cash', 'productName'),
        matches: ['product name', 'product', 'policy name'],
        enabled: this.#mode === 'insurance',
        subPolicyDataIfEmpty: true,
        tableFormatter: policyDataIfEmpty('product_name'),
      },
      product_option_name: {
        label: 'Product option name',
        matches: ['option', 'product option'],
        enabled: this.#mode === 'insurance',
        subPolicyDataIfEmpty: true,
        tableFormatter: (val, row) => {
          return val
            ? val
            : row?.report?.product_option_name && (
                <Tooltip title="Sourced from policy data">
                  <span>{row.report?.product_option_name}*</span>
                </Tooltip>
              );
        },
      },
      fees: {
        label: this.#strings.getLabel('cash', 'fees'),
        matches: ['fees'],
        enabled: this.#mode === 'insurance',
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: FieldTypes.CURRENCY,
      },
      group_id: {
        label: this.#strings.getLabel('cash', 'groupId'),
        matches: [
          'group id',
          'group number',
          'group no',
          'grp #',
          'grp number',
          'groupid',
        ],
        enabled: this.#mode === 'insurance',
      },
      internal_id: {
        label: 'Internal Id',
        enabled: true,
        subPolicyDataIfEmpty: true,
        tableFormatter: policyDataIfEmpty('internal_id'),
      },
      period_date: {
        label: this.#strings.getLabel('cash', 'periodDate'),
        matches: ['period', 'coverage period', 'perioddate', 'due date'],
        enabled: this.#mode === 'insurance',
        type: FieldTypes.DATE,
        global: true,
        formatter: Normalizer.formatDate,
        normalizer: Normalizer.normalizeDate,
      },
      status: {
        label: 'Status',
        enabled: true,
      },
      performance_bonus: {
        label: 'Performance bonus',
        matches: ['performance bonus', 'performancebonus'],
        enabled: false,
      },
      override: { label: 'Override', matches: ['override'], enabled: false },
      payee: {
        label: 'Payee',
        matches: ['payee', 'payee name'],
        enabled: false,
      },
      payee_id: {
        label: 'Payee Id',
        matches: ['payee id', 'payee no', 'payeeid'],
        enabled: false,
      },
      agent_level: {
        label: 'Agent level',
        matches: ['agent level', 'writing agent level', 'agentlevel'],
        enabled: false,
      },
      agency: { label: 'Agency', matches: ['agency'], enabled: false }, // string
      policy_issue_date: {
        label: 'Policy issue date',
        matches: ['policy issue date', 'issue date', 'policyissuedate'],
        type: FieldTypes.DATE,
        enabled: false,
      },
      policy_amount: {
        label: 'Policy amount',
        matches: [
          'policy amount',
          'original face amount',
          'face amount',
          'policyamount',
        ],
        enabled: false,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: FieldTypes.CURRENCY,
      },
      policy_term: {
        label: 'Policy term',
        matches: ['policy term', 'policyterm'],
        enabled: false,
      },
      premium_received: {
        // number
        label: 'Premium received',
        matches: ['premium received', 'premiumreceived'],
        enabled: false,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: FieldTypes.CURRENCY,
      },
      compensation_type: {
        label: 'Compensation type',
        matches: ['compensation type', 'compensationtype'],
        enabled: this.#mode === 'insurance',
      },
      income_class: {
        label: 'Income class',
        matches: ['income class', 'tax form', 'incomeclass'],
        enabled: false,
      },
      billing_frequency: {
        label: 'Billing frequency',
        matches: ['billing frequency', 'billingfrequency'],
        enabled: false,
      },
      premium_transaction: {
        label: 'Premium transaction',
        matches: ['premium transaction', 'premiumtransaction'],
        enabled: false,
      },
      commissionable_premium_amount: {
        label: 'Target premium',
        matches: ['target premium', 'commissionable premium'],
        enabled: true,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        type: FieldTypes.CURRENCY,
      },
      account_type: {
        label: 'Account type',
        enabled: true,
        matches: ['account type', 'accounttype'],
      },
      issue_age: {
        label: 'Issue age',
        enabled: true,
        normalizer: Normalizer.normalizeInt,
        matches: ['issue age'],
        type: FieldTypes.INTEGER,
      },
      customer_paid_premium_amount: {
        label: 'Customer Paid Premium Amount',
        enabled: true,
        matches: ['Basis', 'customer paid premium amount'],
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
      },
      bill_mode: {
        label: 'Bill mode',
        enabled: true,
        matches: ['bill mode'],
      },
      geo_state: {
        label: 'State',
        type: FieldTypes.SELECT,
        options: states,
        matches: [],
        enabled: true,
      },
      split_percentage: {
        label: 'Split percentage',
        enabled: true,
        matches: ['split percentage'],
        tableFormatter: (val, row) =>
          val ? (
            Normalizer.formatPercentage(val)
          ) : row.contacts?.length === 1 &&
            row.report?.contacts_split?.[row.contacts[0]] ? (
            <Tooltip title="Sourced from policy data">
              <span>
                {Normalizer.formatPercentage(
                  row.contacts?.length === 1 &&
                    row.report?.contacts_split?.[row.contacts[0]]
                )}
                *
              </span>
            </Tooltip>
          ) : (
            ''
          ),
        formatter: Normalizer.formatPercentage,
        normalizer: Normalizer.normalizePercentage,
        type: FieldTypes.PERCENTAGE,
      },
      group_name: {
        label: 'Group Name',
        matches: ['group name'],
        required: false,
        enabled: true,
      },
      payment_mode: {
        label: 'Payment Mode',
        matches: ['payment mode'],
        required: false,
        enabled: true,
      },
      aggregation_id: {
        label: 'Aggregation Id',
        matches: [],
        enabled: true,
        subPolicyDataIfEmpty: true,
        tableFormatter: (val, row) => {
          return val
            ? val
            : row?.report?.policy_id && row.report?.aggregation_id && (
                <Tooltip title="Sourced from policy data">
                  <span>{row.report?.aggregation_id}*</span>
                </Tooltip>
              );
        },
        copyable: true,
      },
      member_count: {
        label: 'Member count',
        matches: ['member count'],
        enabled: true,
        type: FieldTypes.INTEGER,
        normalizer: Normalizer.normalizeInt,
      },
      commission_basis: {
        label: 'Commission basis',
        matches: ['commission basis'],
        enabled: true,
      },
      standardized_customer_name: {
        label: 'Standardized customer name',
        matches: ['standardized customer name'],
        enabled: true,
      },
      contacts: {
        label: 'Agents',
        enabled: true,
        type: FieldTypes.DYNAMIC_SELECT,
        multiple: true,
        table: 'contacts',
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const datum = collectionVals?.filter(
              (datum) => datum.str_id === val
            )?.[0];
            return datum ? (
              <Chip
                key={val}
                label={Formatter.contact(datum, {
                  account_id: this.options?.account_id,
                })}
                clickable
                component="a"
                href={`/agents/list?id=${val}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val; // not formatting when collectionVals is not available
        },
        optionFormatter: (o) =>
          Formatter.contact(o, { account_id: this.options?.account_id }),
        optionValuer: (option) => option.str_id,
      },
      agent_commissions: {
        label: 'Agent commissions',
        enabled: true,
        table: 'contacts',
        type: FieldTypes.CUSTOM,
        render: (
          field,
          row,
          setter,
          collectionVals: { id: number; str_id: string }[] = []
        ) => {
          return (
            <AgentCommissionsEdit
              data={row}
              setter={setter}
              field={field}
              dynamicSelects={collectionVals}
              key="agent_commissions"
            />
          );
        },
        tableFormatter: (
          val,
          row,
          collectionVals: { id: number; str_id: string }[] = []
        ) => {
          if (!val || !Array.isArray(collectionVals)) return '';
          return (
            <Box>
              {Object.entries(val)
                .filter(([k, v]) => k !== 'total')
                .map(([k, v]) => {
                  const contact = collectionVals?.find(
                    (e) => e?.str_id === k
                  ) ?? { id: undefined };
                  return contact.id ? (
                    <Chip
                      key={contact?.id}
                      label={`${Formatter.contact(contact, {
                        account_id: this.options?.account_id,
                      })}: ${Formatter.currency(v)}`}
                      sx={{ m: 0.1 }}
                    />
                  ) : (
                    <Skeleton key={k} />
                  );
                })}
            </Box>
          );
        },
        readOnly: true,
      },
      agent_commissions_v2: {
        label: 'Agent commissions V2',
        enabled: true,
        table: 'contacts',
        type: FieldTypes.CUSTOM,
        render: (
          field,
          row,
          setter,
          collectionVals: { id: number; str_id: string }[] = []
        ) => {
          return (
            <AgentCommissionsEdit
              data={row}
              setter={setter}
              field={field}
              dynamicSelects={collectionVals}
              isV2={true}
              key="agent_commissions_v2"
            />
          );
        },
        tableFormatter: (
          val,
          row,
          collectionVals: { id: number; str_id: string }[] = []
        ) => {
          if (!val || !Array.isArray(collectionVals)) return '';
          return (
            <Box>
              {Object.entries(val)
                .filter(([k, v]) => k !== 'total')
                .map(([k, v]) => {
                  const contact = collectionVals?.find(
                    (e) => e?.str_id === k
                  ) ?? { id: undefined };
                  return contact.id ? (
                    <Chip
                      key={contact?.id}
                      label={`${Formatter.contact(contact, {
                        account_id: this.options?.account_id,
                      })}: ${Formatter.currency(v)}`}
                      sx={{ m: 0.1 }}
                    />
                  ) : (
                    <Skeleton key={k} />
                  );
                })}
            </Box>
          );
        },
        readOnly: true,
      },
      agent_commissions_log: {
        label: 'Agent commissions log',
        enabled: true,
        table: 'contacts',
        formatter: (val, collectionVals = []) => {
          if (!val || !Array.isArray(collectionVals)) return '';
          return (
            <Box>
              {Object.entries(val)
                .filter(([k, v]) => k !== 'total')
                .map(([k, v]: [string, any]) => {
                  const contact =
                    collectionVals?.find((e) => e.str_id === k) ?? {};
                  // After grouping if is not an array means that grouped data is not the same so we render ***
                  if (!Array.isArray(v)) {
                    return <Box key={k}>***</Box>;
                  }
                  return (
                    <Box key={k}>
                      {Formatter.contact(contact, {
                        account_id: this.options?.account_id,
                      })}
                      <br />
                      {v.map((e, i) => (
                        <CommissionCalcLog commissionProfile={e} key={i} />
                      ))}
                    </Box>
                  );
                })}
            </Box>
          );
        },
        readOnly: true,
        // formatter: (val) => (val ? JSON.stringify(val) : ''),
      },
      agent_commissions_status: {
        label: 'Payout status',
        enabled: true,
        type: FieldTypes.SELECT,
        options: Object.values(AgentCommissionsStatusesLabels),
      },
      agent_commissions_status2: {
        label: 'Payout status*',
        enabled: true,
        type: FieldTypes.CUSTOM,
        table: 'contacts',
        render: (
          field,
          row,
          setter,
          collectionVals: { id: number; str_id: string }[] = []
        ) => {
          return (
            <PayoutStatusEdit
              data={row}
              setter={setter}
              field={field}
              dynamicSelects={collectionVals}
              key="agent_commissions_status2"
            />
          );
        },
        tableFormatter: (
          val,
          row,
          collectionVals: { id: number; str_id: string }[] = []
        ) => {
          if (!val || !Array.isArray(collectionVals)) return '';
          return (
            <Box>
              {Object.entries(val).map(([k, v]) => {
                const contact = collectionVals?.find(
                  (e) => e?.str_id === k
                ) ?? { id: undefined };
                return contact.id ? (
                  <Chip
                    key={contact?.id}
                    label={`${Formatter.contact(contact, {
                      account_id: this.options?.account_id,
                    })}: ${v}`}
                    sx={{ m: 0.1 }}
                  />
                ) : (
                  <Skeleton key={k} />
                );
              })}
            </Box>
          );
        },
      },
      notes: {
        label: 'Notes',
        enabled: true,
        matches: ['RevCd', 'notes'],
        subPolicyDataIfEmpty: true,
        tableFormatter: policyDataIfEmpty('notes'),
      },
      tags: {
        label: 'Tags',
        enabled: true,
        normalizer: (val) => {
          if (Array.isArray(val) && val.length === 0) return [];
          if (typeof val === 'string')
            return val?.split(',').map((s) => s.trim()) ?? [];
          return val;
        },
        formatter: (val) => (Array.isArray(val) ? val.join(', ') : val),
      },
      document_id: {
        label: 'Document',
        matches: ['document id', 'document'],
        enabled: true,
        required: false,
        type: FieldTypes.DYNAMIC_SELECT,
        table: `documents`,
        queryParamValue: 'statement',
        queryParamName: 'type',
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const datum = collectionVals?.find((datum) => datum.str_id === val);
            return datum ? (
              <Chip
                clickable
                component="a"
                href={`/documents?id=${datum?.str_id}`}
                target="_blank"
                label={`${datum.file_path.endsWith(datum.filename) ? datum.filename : `${getFilenameFromPath(datum.file_path)}`}`}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val;
        },
        optionFormatter: (option) =>
          `${option.file_path.endsWith(option.filename) ? option.filename : `${getFilenameFromPath(option.file_path)}`}`,
        optionValuer: (option) => option.str_id,
      },
      processing_status: {
        label: 'Processing status',
        options: ['new', 'processed', 'frozen'],
        matches: [],
        type: FieldTypes.SELECT,
        enabled: true,
      },
      children_data: {
        label: 'Linked records',
        enabled: true,
        type: FieldTypes.CUSTOM,
        tableFormatter: (vals) => (
          <Box key="children_data">
            {vals?.map((val) => (
              <Chip
                key={val.str_id}
                label={val.str_id}
                clickable
                component="a"
                href={`/commissions?id=${val.str_id}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ))}
          </Box>
        ),
        render: (field, row) => {
          return row && Array.isArray(row.children_report_data) ? (
            <Box key="children_report_data">
              <Typography>Linked records</Typography>
              {row.children_report_data.map((val) => (
                <Chip
                  key={val.str_id}
                  label={val.str_id}
                  clickable
                  component="a"
                  href={`/commissions?id=${val.str_id}`}
                  target="_blank"
                  sx={{ m: 0.1 }}
                />
              ))}
            </Box>
          ) : null;
        },
        readOnly: true,
      },
      payment_status: {
        label: 'Payment status',
        enabled: true,
      },
      agent_payout_rate: {
        label: 'Agent payout rate',
        table: 'contacts',
        enabled: true,

        formatter: (val, collectionVals = []) => {
          if (!val || !Array.isArray(collectionVals)) return '';
          return (
            <Box>
              {Object.entries(val)
                .filter(([k, v]) => k !== 'total')
                .map(([k, v]) => {
                  const contact =
                    collectionVals?.find((e) => e.str_id === k) ?? {};
                  return (
                    <Chip
                      key={contact.id}
                      label={`${Formatter.contact(contact, {
                        account_id: this.options?.account_id,
                      })}: ${Formatter.percentage(v)}`}
                      sx={{ m: 0.1 }}
                    />
                  );
                })}
            </Box>
          );
        },
        readOnly: true,
      },
      agent_commission_payout_rate: {
        label: 'Agent commission payout rate',
        table: 'contacts',
        enabled: true,
        formatter: (val, collectionVals = []) => {
          if (!val || !Array.isArray(collectionVals)) return '';
          return (
            <Box>
              {Object.entries(val)
                .filter(([k, v]) => k !== 'total')
                .map(([k, v]) => {
                  const contact =
                    collectionVals?.find((e) => e.str_id === k) ?? {};
                  return (
                    <Chip
                      key={contact.id}
                      label={`${Formatter.contact(contact, {
                        account_id: this.options?.account_id,
                      })}: ${Formatter.percentage(v) ?? 'n/a'}`}
                      sx={{ m: 0.1 }}
                    />
                  );
                })}
            </Box>
          );
        },
        readOnly: true,
      },
      report_data_id: {
        label: 'Linked policy',
        enabled: true,
        readOnly: true,
        type: FieldTypes.CUSTOM,
        render(field, row) {
          return row[field.id] ? (
            <Box sx={{ m: 0.1 }}>
              <Chip
                label="Linked policy"
                component={Link}
                to={`/policies?id=${row?.report?.str_id}`}
                target="_blank"
                clickable
              />
            </Box>
          ) : (
            <Box sx={{ m: 0.1 }}>
              <Chip label="No linked policy" sx={{ cursor: 'default' }} />
            </Box>
          );
        },
        tableFormatter(val, row) {
          const ctx = useContext(ComparisonContext);
          return val ? (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                onClick={() => {
                  ctx.setData({
                    statements: [row],
                    policy: row.report,
                    defaultOpenStatement: row.str_id,
                  });
                  ctx.setShow(true);
                }}
              >
                <VerticalSplit />
              </IconButton>
              <IconButton
                component={Link}
                to={`/policies?id=${row?.report?.str_id}`}
                target="_blank"
              >
                <Launch />
              </IconButton>
            </Box>
          ) : null;
        },
      },
      reconciliation_method: {
        label: 'Reconciler 🔒',
        enabled: role === 'admin',
        readOnly: true,
        type: FieldTypes.DYNAMIC_SELECT,
        table: 'reconcilers',
        formatter: (val, collectionVals = []) => {
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const datum = collectionVals?.find((datum) => datum.id === +val);
            return datum ? (
              <Chip
                key={val}
                label={datum.name}
                clickable
                component={Link}
                to={`/reconciliation/reconcilers?id=${datum.str_id}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            ) : (
              `${val} (not found in Fintary)`
            );
          }
          return val;
        },
      },
    };
    this.actions =
      userRole === Roles.ACCOUNT_ADMIN
        ? [
            {
              id: 'comp_calc',
              label: 'Calculate comp',
              onClick: async (row) => {
                // TODO: Temp implementation for TG demo. Figure out how to use API for the call. If not, replace with Axios.
                const headers = await API.getHeaders();
                await fetch(
                  `${process.env.REACT_APP_API}/api/data_processing/commissions/agents`,
                  {
                    method: 'post',
                    headers,
                    body: JSON.stringify({
                      isSync: true,
                      useGroupedCommissions: true,
                      id: row.id,
                    }),
                  }
                );
              },
            },
          ]
        : [];
    this.dateFilters = [
      {
        filterFieldName: 'Payment date',
        filterFieldId: 'payment_date',
        filters: [
          {
            label: 'Payment date start',
            filterKey: 'payment_date_start',
          },
          {
            label: 'Payment date end',
            filterKey: 'payment_date_end',
          },
          { filterKey: 'payment_date_empty', type: FieldTypes.BOOLEAN },
        ],
      },
      {
        filterFieldName: 'Processing date',
        filterFieldId: 'processing_date',
        filters: [
          {
            label: 'Processing date start',
            filterKey: 'processing_date_start',
          },
          {
            label: 'Processing date end',
            filterKey: 'processing_date_end',
          },
          { filterKey: 'processing_date_empty', type: FieldTypes.BOOLEAN },
        ],
      },
      {
        filterFieldName: 'Statement date',
        filterFieldId: 'invoice_date',
        filters: [
          {
            label: 'Statement date start',
            filterKey: 'invoice_date_start',
          },
          {
            label: 'Statement date end',
            filterKey: 'invoice_date_end',
          },
          { filterKey: 'invoice_date_empty', type: FieldTypes.BOOLEAN },
        ],
      },
      {
        filterFieldName: 'Effective date',
        filterFieldId: 'effective_date',
        filters: [
          {
            label: 'Effective date start',
            filterKey: 'effective_date_start',
          },
          {
            label: 'Effective date end',
            filterKey: 'effective_date_end',
          },
          { filterKey: 'effective_date_empty', type: FieldTypes.BOOLEAN },
        ],
      },
    ];
    this.outstandingFieldsInMobileView = [
      'policy_id',
      'commission_amount',
      'customer_name',
      'payment_date',
      'carrier_name',
      'premium_amount',
      'agent_payout_rate',
    ];

    // Add field key as field property
    // Delete fields that are not enabled
    Object.entries(this.fields).forEach(([k, v]) => {
      this.fields[k].id = k;
      if (!v.enabled) {
        delete this.fields[k];
      }
    });

    const allFields = Object.values(this.fields)
      .map((field) => {
        const rowMatches = field.id
          ? [field.id, ...(field.matches ?? [])]
          : (field.matches ?? []);
        return rowMatches.filter(Boolean);
      })
      .flat();
    this.fieldsCollapsed = [
      ...new Set(allFields.map((field) => field?.toString().toLowerCase())),
    ];
  }

  getDataInfo = (tableData: (string | number)[][], topN = 8) => {
    const rowData: any[] = [];
    const ranges: { start: number; end: number }[] = [];
    let curRange: { start: number; end: number } | null = {
      start: 0,
      end: 999,
    };

    const filteredTableData = tableData.filter((row) => Array.isArray(row));
    filteredTableData.forEach((row, i) => {
      let matches = 0;
      let filled = 0;

      const dateStyleRegex =
        /(?:\d{1,2})(?:\/|-)(?:\d{1,2})(?:\/|-)(?:\d{1,4})/;
      const hasDate = dateStyleRegex.test(row.join(' '));

      const hasCurrencyValue = row.filter((cell) =>
        cell?.toString().match(/\$\d{1,}/)
      ).length;

      const hasTotalTitle = row.toString().toLowerCase().includes('total:');

      row.forEach((cell) => {
        if (cell || cell === 0) {
          filled += 1;
        }
        if (this.fieldsCollapsed.includes(cell?.toString()?.toLowerCase())) {
          matches += 1;
        }
      });
      if (filled === 0 && curRange !== null) {
        curRange.end = i - 1;
        ranges.push(curRange);
        curRange = null;
      } else if (filled > 0 && curRange === null) {
        curRange = { start: i, end: 999 };
      }
      if (!hasDate && !hasCurrencyValue && !hasTotalTitle) {
        rowData.push({ index: i, matches, filled, length: row.length });
      }
    });
    if (curRange !== null) {
      ranges.push({ ...curRange, end: filteredTableData.length - 1 });
    }

    let rowDataMatches;
    // No matches - use top n rows
    if (rowData.filter((row) => row.matches > 0).length === 0) {
      rowDataMatches = rowData.splice(0, Math.min(topN, rowData.length));
    } else {
      rowDataMatches = rowData
        .sort((a, b) => b.matches - a.matches)
        .filter((row) => row.matches > 0)
        .splice(
          0,
          Math.min(
            topN,
            rowData.filter((row) => row.matches > 0).length,
            rowData.length
          )
        );
    }

    const rangeData = rowDataMatches.map((row) => ({
      index: row.index,
      count: row.matches,
      fields: filteredTableData[row.index],
      data: filteredTableData.filter(
        (dataRow, i) =>
          i >
            ranges.filter((a) => a.start <= row.index)[
              ranges.filter((a) => a.start <= row.index).length - 1
            ]?.start &&
          i <=
            ranges.filter((a) => a.start <= row.index)[
              ranges.filter((a) => a.start <= row.index).length - 1
            ]?.end &&
          i > row.index
      ),
    }));
    return { rowData, ranges, rangeData };
  };
}

export default Statements;
