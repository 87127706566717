const getIndices = (array: string[][], colNames: string[]) => {
  const indices: number[] = [];
  const headers = array[0];

  colNames.forEach((colName) => {
    const idx = headers.indexOf(colName);
    if (idx >= 0) indices.push(idx);
    else console.warn(`Could not find a column named ${colName} in header row`);
  });

  indices.sort((a, b) => b - a); // Work backwards so indices don't change

  return indices;
};

export default getIndices;
