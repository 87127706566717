import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import FieldRow from './FieldRow';
import NullCheckbox from './NullCheckbox'; // Make sure to import NullCheckbox from the correct path

const FieldComponent = ({
  field,
  newData,
  formattedData,
  setNewData,
  readOnly,
  navigate,
  oldData,
}) => {
  const [localValue, setLocalValue] = useState(
    typeof field.formatter === 'function'
      ? field.formatter(
          newData?.[field.id] || field.default || '',
          newData,
          navigate,
          'form'
        )
      : ![null, undefined].includes(newData?.[field.id])
        ? newData?.[field.id]
        : field.default || ''
  );

  useEffect(() => {
    setLocalValue(
      typeof field.formatter === 'function'
        ? field.formatter(
            newData?.[field.id] || field.default || '',
            newData,
            navigate,
            'form'
          )
        : ![null, undefined].includes(newData?.[field.id])
          ? newData?.[field.id]
          : field.default || ''
    );
  }, [newData?.[field.id]]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setLocalValue(value);
  };

  return (
    <FieldRow key={field.id}>
      <Tooltip title={field.tip} placement="right">
        <TextField
          fullWidth
          id={field.id}
          key={field.id}
          label={field.label}
          sx={{
            display: field.hidden ? 'none' : 'inherit',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#555',
            },
          }}
          value={localValue}
          required={field.required}
          disabled={
            typeof field.readOnly === 'function'
              ? field.readOnly(newData)
              : readOnly ||
                field.readOnly ||
                formattedData?.[`${field.id}-null`]
          }
          onChange={handleInputChange}
          onBlur={(e) => {
            setNewData({
              ...formattedData,
              [e.target.id]:
                typeof field.onBlurFormatter === 'function'
                  ? field.onBlurFormatter(e.target.value)
                  : typeof field.formatter === 'function'
                    ? field.formatter(e.target.value)
                    : e.target.value,
            });
          }}
          error={
            (field.validator instanceof Function &&
              !field.validator(formattedData?.[field.id] || '')) ||
            (field.isUnique &&
              field.existingVals.includes(formattedData?.[field.id]) &&
              formattedData?.[field.id] !== oldData?.[field.id])
          }
          helperText={
            field.validator instanceof Function &&
            !field.validator(formattedData?.[field.id] || '')
              ? `Invalid ${field.label}`
              : field.isUnique &&
                  field.existingVals.includes(formattedData?.[field.id]) &&
                  formattedData?.[field.id] !== oldData?.[field.id]
                ? 'Already exists'
                : null
          }
          multiline={field.type === 'text-multiline'}
          InputProps={{
            ...(field.endAdornment && {
              endAdornment:
                typeof field.endAdornment === 'function'
                  ? field.endAdornment(formattedData, field, setNewData)
                  : field.endAdornment,
            }),
            ...(field.access === 'admin' && {
              endAdornment: (
                <InputAdornment position="end">
                  {typeof field.endAdornment === 'function'
                    ? field.endAdornment(formattedData, field, setNewData)
                    : field.endAdornment}
                  <IconButton disabled>
                    <AdminPanelSettings />
                  </IconButton>
                </InputAdornment>
              ),
            }),
            ...(formattedData?.[`${field.id}-null`] && {
              sx: {
                textDecoration: 'line-through',
              },
            }),
          }}
        />
      </Tooltip>
      {field.enableNullCheckbox && (
        <NullCheckbox
          readOnly={readOnly}
          field={field}
          formattedData={formattedData}
          setNewData={setNewData}
        />
      )}
    </FieldRow>
  );
};

export default FieldComponent;
