import React, { useState } from 'react';
import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import CriteriaActionsViewer from './CriteriaActionsViewer';
import { Fields } from './types';

export enum CriteriaActionsConfigFieldTypes {
  CRITERIA = 'Criteria',
  ACTION = 'Action',
}

type CriteriaActionsDatum = {
  id: string;
  name: string;
  data_update_criteria?: any;
  data_update_actions?: any;
  notes: string;
};

type CriteriaActionsData = {
  data_update_criteria?: CriteriaActionsDatum[];
  data_update_actions?: CriteriaActionsDatum[];
};

type CriteriaActionsConfigProps = {
  data: CriteriaActionsData;
  field: any;
  type: CriteriaActionsConfigFieldTypes;
  setter: (value: any) => void;
  dynamicSelects: any;
  fields: Fields;
};

const CriteriaActionsConfig: React.FC<CriteriaActionsConfigProps> = ({
  data,
  field,
  type,
  setter,
  dynamicSelects,
  fields,
}) => {
  const [selectedActionCriteria, setSelectedActionCriteria] = useState<any>([]);
  const [query, setQuery] = useState('');

  let dataUpdateData;
  if (type === CriteriaActionsConfigFieldTypes.CRITERIA) {
    dataUpdateData = data?.data_update_criteria ?? [];
  } else if (type === CriteriaActionsConfigFieldTypes.ACTION) {
    dataUpdateData = data?.data_update_actions ?? [];
  }

  const handleSelectAll = (event) => {
    event.stopPropagation();
    const filteredOptions = filterOptions(dynamicSelects, query);
    setSelectedActionCriteria(filteredOptions.map((option: any) => option.id));
  };

  const handleAddSelected = () => {
    const newData = selectedActionCriteria.map((dataId) =>
      dynamicSelects.find((item) => item.id === dataId)
    );

    setter({
      ...data,
      [field.id]: [...(data[field.id] ?? []), ...newData],
    });

    setSelectedActionCriteria([]);
  };

  const filterOptions = (options = [], query) => {
    const trimmedQuery = query.trim().toLowerCase();

    return options.filter((item: any) => {
      const itemName = item?.name?.toLowerCase() ?? '';

      if (trimmedQuery.startsWith('"') && trimmedQuery.endsWith('"')) {
        const exactMatchRegex = new RegExp(
          `\\b${trimmedQuery.slice(1, -1)}\\b`
        );
        return exactMatchRegex.test(itemName);
      }

      return itemName.includes(trimmedQuery);
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle2">{field.label}</Typography>
      <TableContainer>
        <Table>
          {dataUpdateData.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{type}</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell sx={{ width: 0 }} />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {dataUpdateData.map((datum) => (
              <React.Fragment key={datum.id}>
                <TableRow>
                  <TableCell>
                    <TextField
                      label="Name"
                      variant="outlined"
                      value={datum.name}
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <CriteriaActionsViewer
                      fieldData={datum[field.id]}
                      rowData={data}
                      fields={fields}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="Notes"
                      variant="outlined"
                      value={datum.notes}
                      disabled
                    />
                    `
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setter({
                          ...data,
                          [field.id]: dataUpdateData.filter(
                            (d) => d.id !== datum.id
                          ),
                        });
                      }}
                    >
                      <Close />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
            <TableRow>
              <TableCell colSpan={6}>
                <FormControl sx={{ m: 0.5, width: 145 }}>
                  <InputLabel>Add</InputLabel>
                  <Select
                    label="Add"
                    multiple
                    value={selectedActionCriteria}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onClose={handleAddSelected}
                    onChange={(e) => {
                      setSelectedActionCriteria(
                        Array.isArray(e.target.value)
                          ? e.target.value.filter(Boolean)
                          : e.target.value
                      );
                    }}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#333',
                      },
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {Array.isArray(selected) &&
                          (selected as string[]).map((value) => {
                            const item = dynamicSelects.find(
                              (option) => option.id === value
                            );
                            return (
                              <Chip
                                key={item ? item.str_id : value}
                                label={item ? item.name : value}
                              />
                            );
                          })}
                      </Box>
                    )}
                  >
                    <Box
                      sx={{
                        mb: 0.5,
                        mx: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <TextField
                        sx={{ flex: 1, mr: 1 }}
                        label="Search"
                        value={query}
                        onChange={(e) => {
                          setQuery(e.target.value);
                        }}
                      />
                      <Button
                        variant="outlined"
                        onClick={(e) => handleSelectAll(e)}
                        sx={{ mr: 1 }}
                      >
                        Select all
                      </Button>
                      <Button variant="outlined" onClick={handleAddSelected}>
                        Add selected
                      </Button>
                    </Box>
                    {field.nullable && (
                      <MenuItem value={''} key="null">
                        &nbsp;
                      </MenuItem>
                    )}
                    {filterOptions(dynamicSelects, query).map((option: any) => (
                      <MenuItem
                        value={option.id}
                        key={`${option.id}-option-item`}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CriteriaActionsConfig;
