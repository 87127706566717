import { LOCAL_STORAGE_KEYS } from '@/constants/account';
import { impUser } from '@/services/helpers';
import { getLocalData } from '@/utils/localStorage';

export const getAccountAndUserKeys = () => {
  const selectedAccountId = getLocalData(
    LOCAL_STORAGE_KEYS.selectedAccount
  )?.accountId;

  const userRole = getLocalData(LOCAL_STORAGE_KEYS.userRole);

  return impUser({
    // TODO: The api will reject request without accountid header even
    // with login api
    // Should refactor the api, after that we can remove undefined here
    accountid: selectedAccountId || 'undefined',
    userrole: userRole,
  });
};
