import React from 'react';

import ActionPicker from '@/components/molecules/ActionPicker';
import {
  DataEntities,
  Fields,
} from '@/components/ToolsPage/DataUpdateTools/types';
import { FieldTypes } from '@/types';
import CriteriaActionsViewer from '@/components/ToolsPage/DataUpdateTools/CriteriaActionsViewer';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';

interface DataUpdateActionsProps {
  fields: Fields;
}

const DataUpdateActions: React.FC<DataUpdateActionsProps> = ({ fields }) => {
  const dataSpec = {
    label: 'Data update actions',
    table: 'data-update/actions',
    fields: {
      data_entity: {
        label: 'Data entity',
        type: FieldTypes.SELECT,
        options: [DataEntities.COMMISSIONS, DataEntities.POLICIES],
        enabled: true,
      },
      name: {
        label: 'Name',
        enabled: true,
      },
      data_update_actions: {
        id: 'data_update_actions',
        keyAs: 'data_update_actions',
        label: 'Data update actions',
        enabled: true,
        type: FieldTypes.CUSTOM,
        render: (field, row, setter) => {
          return (
            <ActionPicker
              row={row}
              fieldGetter={(row) => {
                return fields[row.data_entity as DataEntities];
              }}
              value={row.data_update_actions}
              setValue={setter}
              sx={{ mb: 1 }}
            />
          );
        },
        tableFormatter: (field, row) => {
          return (
            <CriteriaActionsViewer
              fieldData={field}
              rowData={row}
              fields={fields}
            />
          );
        },
      },
      notes: {
        label: 'Notes',
        enabled: true,
      },
    },
  };

  return (
    <EnhancedDataView dataSpec={dataSpec} hideSelectedCount hideExport={true} />
  );
};

export default DataUpdateActions;
