import CloseIcon from '@mui/icons-material/Close';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSetOriginFile, useSetOverrideFile } from 'store/excelStore';

import ProcessUploads from '@/components/UploadModal/processFlow/ProcessUploads';
import API from '@/services/API';
import TimerCount from '@/components/UploadModal/processFlow/TimerCount';
import FeedbackDialog from '@/components/documents/DocumentsView/FeedbackDialog';
import useSnackbar from '@/contexts/useSnackbar';

const UpdateProcessData = ({ open, handleClose, rowData, setRowData }) => {
  const [uploading, setUploading] = useState(false);
  const [baseQueryLoading, setBaseQueryLoading] = useState(false); // [mappings, processors
  const processFileRef = useRef();
  const [mappingsAndProcessors, setMappingsAndProcessors] = useState({
    mappings: [],
    processors: [],
  });

  const [actionCount, setActionCount] = useState(0);
  const timerCountRef = useRef(null);

  const [feedback, setFeedback] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);

  const queryClient = useQueryClient();

  const setOriginFile = useSetOriginFile();
  const setOverrideFile = useSetOverrideFile();
  const { showSnackbar } = useSnackbar();

  const handleCancel = () => {
    handleClose(false);
    setOriginFile(null);
    setOverrideFile(null);
  };

  const handleFeedbackOpen = () => setShowFeedback(true);
  const handleFeedbackClose = () => setShowFeedback(false);

  const handleFeedbackSubmit = (feedback) => {
    setFeedback(feedback);
    handleFeedbackClose();
  };

  const { data: mappingsList, isLoading: mappingLoading } = API.getBasicQuery(
    'mappings',
    `company_id=${rowData.company_str_id}&type=${rowData.type}`,
    !!rowData.company_str_id
  );

  const { data: processorsList, isLoading: processorsLoading } =
    API.getBasicQuery('processors');

  useEffect(() => {
    setBaseQueryLoading(processorsLoading || mappingLoading);
    if (mappingsList && processorsList) {
      setMappingsAndProcessors({
        mappings: mappingsList,
        processors: processorsList,
      });
    }
  }, [processorsLoading, mappingLoading]);

  const submit = async () => {
    setUploading(true);
    const res = await processFileRef.current?.submit();
    setUploading(false);
    if (res && !res.error && !res.upload) {
      showSnackbar('Import successful', 'success');
      queryClient.invalidateQueries();
    } else {
      showSnackbar(res?.error || res?.upload || 'Import failed', 'error');
      setUploading(false);
      return;
    }
    handleCancel();
  };

  let touchLevel = 'low';
  // @ts-ignore
  if (actionCount > 20 || timerCountRef?.current?.totalSeconds > 300)
    touchLevel = 'medium';
  // @ts-ignore
  if (actionCount > 50 || timerCountRef?.current?.totalSeconds > 600)
    touchLevel = 'high';

  return (
    <div>
      <Dialog
        open={open}
        fullScreen
        maxWidth="100%"
        sx={{ background: 'transparent', p: 2 }}
        PaperProps={{ sx: { borderRadius: 2 } }}
      >
        <DialogTitle sx={{ p: 2 }}>
          <header className="flex justify-between items-center">
            <Box className="flex justify-between items-center">
              <CloudSyncIcon />
              <Box className="pl-2">
                <span>Process file</span>
                {baseQueryLoading && (
                  <span className="ml-2 text-sm text-gray-700">
                    Loading companies and processors...
                  </span>
                )}
              </Box>
            </Box>
            <Box
              className="flex group cursor-pointer hover:text-blue-600"
              onClick={handleCancel}
            >
              <CloseIcon className="group-hover:rotate-180 transition-all origin-center" />
            </Box>
          </header>
        </DialogTitle>
        <Divider />

        <DialogContent
          sx={{
            padding: 1,
            backgroundColor: '#fff',
          }}
        >
          <ProcessUploads
            ref={processFileRef}
            rowData={rowData}
            setRowData={setRowData}
            mpData={mappingsAndProcessors}
            actionCount={actionCount}
            setActionCount={setActionCount}
            timerCountRef={timerCountRef}
            feedback={feedback}
          />
        </DialogContent>

        <DialogActions sx={{ display: 'flex', width: '100%' }}>
          <Box>
            <Tooltip title="Having trouble with document processing? Let the Fintary eng team know.">
              <Button
                onClick={handleFeedbackOpen}
                variant={
                  ['medium', 'high'].includes(touchLevel)
                    ? 'contained'
                    : 'outlined'
                }
                color={
                  touchLevel === 'high'
                    ? 'error'
                    : touchLevel === 'medium'
                      ? 'primary'
                      : 'inherit'
                }
                sx={
                  ['medium', 'high'].includes(touchLevel)
                    ? {}
                    : { color: '#555', borderColor: '#555' }
                }
              >
                Add feedback
              </Button>
            </Tooltip>
          </Box>

          <Box sx={{ flex: 1, mt: -1 }}>
            <TimerCount count={actionCount} ref={timerCountRef} />
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <LoadingButton
              loading={uploading}
              sx={{ width: '100px' }}
              onClick={submit}
              variant="contained"
            >
              Import
            </LoadingButton>
          </Box>
        </DialogActions>

        <FeedbackDialog
          open={showFeedback}
          onClose={handleFeedbackClose}
          onSubmit={handleFeedbackSubmit}
        />
      </Dialog>
    </div>
  );
};

UpdateProcessData.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  rowData: PropTypes.object,
  mpData: PropTypes.object,
};

export default UpdateProcessData;
