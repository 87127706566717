import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  Stack,
  Typography,
} from '@mui/material';
import { createContext, useState } from 'react';
import { ExpandMoreOutlined } from '@mui/icons-material';

import DataForm from '@/components/DataForm';
import Statements from '@/services/Statements';
import Reports from '@/services/Reports';

export const ComparisonContext = createContext<{
  setShow: (show: boolean) => void;
  setData: (data) => void;
}>({} as any);

export const ReconciliationConfirmProvider = ({ mode, children }) => {
  const [show, setShow] = useState(false);
  const statementList = new Statements(mode);
  const [data, setData] = useState({
    policy: {},
    statements: [],
    defaultOpenStatement: '',
  });
  const report = new Reports(mode);
  const context = {
    setShow,
    setData,
  };

  const dataDesc = {
    label: statementList.label,
    table: statementList.table,
    editable: false,
    fields: Object.entries(statementList.fields)
      .filter(([k, v]) => v.enabled)
      .reduce((acc: any, [k, v]) => [...acc, { ...v, id: k }], []),
  };
  const policyDesc = {
    label: report.label,
    table: report.table,
    editable: false,
    fields: Object.entries(report.fields)
      .filter(([k, v]) => v.enabled)
      .reduce((acc: any, [k, v]) => [...acc, { ...v, id: k }], []),
  };
  return (
    <ComparisonContext.Provider value={context}>
      <>
        {children}
        <Drawer anchor="right" open={show} onClose={() => setShow(false)}>
          <Stack
            spacing={2}
            direction="row"
            sx={{ width: '80vw', padding: 1, paddingTop: '72px' }}
          >
            <Box
              sx={{
                width: '50%',
                height: 'calc(100vh - 80px)',
                overflow: 'scroll',
              }}
            >
              <Accordion expanded>
                <AccordionSummary
                  sx={{ '.MuiAccordionSummary-content': { my: 0 } }}
                >
                  <Typography variant="h5">Policy</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ py: 0 }}>
                  <DataForm
                    dataDesc={policyDesc}
                    fields={Object.values(policyDesc.fields)}
                    newData={data.policy}
                    readOnly={true}
                    formModeOnly={true}
                    oldData={{}}
                    setNewData={null}
                    onCancel={null}
                    onDelete={null}
                    onSave={null}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box
              sx={{
                width: '50%',
                height: 'calc(100vh - 80px)',
                overflow: 'scroll',
              }}
            >
              {data.statements.map((statement: any, index) => (
                <Accordion
                  defaultExpanded={
                    data.defaultOpenStatement === statement.str_id
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ '.MuiAccordionSummary-content': { my: 0 } }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        mr: 1,
                      }}
                    >
                      <Typography variant="h5">Commission</Typography>
                      <Typography variant="body2">
                        {statement.str_id}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ py: 0 }}>
                    <DataForm
                      dataDesc={dataDesc}
                      fields={Object.values(dataDesc.fields)}
                      newData={statement}
                      readOnly={true}
                      formModeOnly={true}
                      oldData={{}}
                      setNewData={null}
                      onCancel={null}
                      onDelete={null}
                      onSave={null}
                    />
                  </AccordionDetails>
                  <AccordionActions>
                    <Button>Confirm</Button>
                    <Button>Reject</Button>
                  </AccordionActions>
                </Accordion>
              ))}
            </Box>
          </Stack>
        </Drawer>
      </>
    </ComparisonContext.Provider>
  );
};
