const findTextLineAllIndex = (json, searchText) => {
  const allIndices: any = [];
  for (let i = 0; i < json.Lines.length; i++) {
    const linesArray = json.Lines[i].LinesArray;
    for (let j = 0; j < linesArray.length; j++) {
      if (linesArray[j].Line.includes(searchText)) {
        allIndices.push([i, j]);
      }
    }
  }
  return allIndices;
};

export const desc_findTextLineAllIndex = `
Find all indices of lines that contain the given text in the JSON object.
eg:
findTextLineAllIndex(json, 'text') => [[0, 1], [1, 2]];
`;

export default findTextLineAllIndex;
