import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from 'contexts/LoadingContext';

import Spreadsheet from '@/services/Spreadsheet';
import FilePreview from '@/common/preview';
import { SpreadsheetProps, XLS_CSV_TYPES } from '@/common/preview/model';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';

const DocPreview = ({
  path,
  previewWidth,
  previewFile,
  setPreviewFile,
  rowData,
}) => {
  const [spreadsheet, setSpreadsheet] = useState<SpreadsheetProps>();

  const { setLoadingConfig } = useContext(LoadingContext);
  const { downloadFile } = useDownloadStorageFile();

  useEffect(() => {
    const readFile = async () => {
      if (!path) return null;
      setLoadingConfig({
        loading: true,
      });
      const file = await downloadFile({
        document_str_id: rowData?.str_id,
        file_preview_type: 'original',
        endpoint: 'documents',
      });
      setPreviewFile(file);
      setLoadingConfig({ loading: false });
    };
    if (path) {
      readFile();
    }
  }, [path, rowData]);

  useEffect(() => {
    const setExcelData = async () => {
      if (previewFile && XLS_CSV_TYPES.includes(previewFile.type)) {
        const res = await Spreadsheet.loadSpreadsheet(previewFile);
        setSpreadsheet(res as any);
      }
    };
    setExcelData();
  }, [previewFile]);

  return (
    <FilePreview
      previewFile={previewFile}
      previewWith={previewWidth}
      // @ts-ignore
      spreadsheet={spreadsheet}
      // @ts-ignore
      setSpreadsheet={setSpreadsheet}
    />
  );
};
export default DocPreview;
