import { createContext, useState, useMemo } from 'react';

interface GlobalSnackbarConfigProps {
  open: boolean;
  message?: string | React.ReactElement;
  severity?: 'info' | 'success' | 'error' | 'warning';
  autoHideDuration?: number;
}

interface GlobalSnackbarContextProps {
  globalSnackbarConfig: GlobalSnackbarConfigProps;
  setGlobalSnackbarConfig: (e: GlobalSnackbarConfigProps) => void;
}

export const GlobalSnackbarContext = createContext<GlobalSnackbarContextProps>({
  globalSnackbarConfig: {
    open: false,
    message: '',
    severity: 'info',
    autoHideDuration: 8000,
  },
  setGlobalSnackbarConfig: (e: GlobalSnackbarConfigProps) => {},
});

const GlobalSnackbarProvider = ({ children }) => {
  const [globalSnackbarConfig, setGlobalSnackbarConfig] = useState({
    open: false,
    message: '',
    severity: 'info',
    autoHideDuration: 8000,
  });

  const store = useMemo(() => {
    return {
      globalSnackbarConfig,
      setGlobalSnackbarConfig,
    };
  }, [globalSnackbarConfig]) as any;

  return (
    <GlobalSnackbarContext.Provider value={store}>
      {children}
    </GlobalSnackbarContext.Provider>
  );
};

export default GlobalSnackbarProvider;
