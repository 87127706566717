import { USER_TYPES } from 'common/user.constants';

import API from '@/services/API';

export const useFintaryAdmin = () => {
  const { data } = API.getBasicQuery<ApiRes<AdminUser>>('admin/check');

  const { isAdmin, ...rest } = data?.data || ({} as AdminUser);
  const isFintaryAdmin = isAdmin && rest?.type === USER_TYPES.fintary;

  return {
    isFintaryAdmin,
    ...rest,
  };
};
