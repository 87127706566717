import { useEffect, useState, FC } from 'react';
import { nanoid } from 'nanoid';
import { Box } from '@mui/material';

import FactoryFileUpload from '@/common/Uplaod/FactoryFileUpload';
import useUploadStorageFile from '@/contexts/useUploadStorageFile';
import useStorageSignedUrl from '@/contexts/useStorageSignedUrl';

interface UploadAccountLogoProps {
  onChange: (value: any) => void;
  data: any;
}

const UploadAccountLogo: FC<UploadAccountLogoProps> = ({ onChange, data }) => {
  const [logoUrl, setLogoUrl] = useState('');
  const [path, setPath] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const { setUploadFileParams, isUploaded } = useUploadStorageFile();
  const { getSignedUrl } = useStorageSignedUrl();

  useEffect(() => {
    const getSetUrl = async (filePath) => {
      try {
        const url = await getSignedUrl(filePath);
        setLogoUrl(url);
      } catch (e) {
        console.error('Error getting logo url', e);
      }
    };
    if (data.logo_url) {
      getSetUrl(data.logo_url);
    }
  }, [data]);

  const handleFileChange = async (files) => {
    const file = files[0];
    const imgPath = `accountLogos/${nanoid()}-${file.name}`;
    setPath(imgPath);
    try {
      setUploadFileParams([{ path: imgPath, file }]);
    } catch (error) {
      console.error('Failed to upload file:', error);
    }
  };

  useEffect(() => {
    setFileUploaded(isUploaded);
  }, [isUploaded]);

  useEffect(() => {
    if (fileUploaded) {
      onChange(path);
    }
  }, [fileUploaded]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        ml: 4,
      }}
      key="accountLogo"
    >
      {logoUrl && (
        <Box sx={{ mr: 4 }}>
          <img src={logoUrl} alt="logo" style={{ maxHeight: '100px' }} />
        </Box>
      )}
      <Box>
        <FactoryFileUpload onChange={handleFileChange} imageOnly={true} />
      </Box>
    </Box>
  );
};

export default UploadAccountLogo;
