import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Checkbox, Popover, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import { FieldTypes } from '@/types';

const MoreDateFilters = ({ title, filters, values, onSetValue }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMore = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        id="more-date-filters-btn"
        onClick={handleClick}
        sx={{ pl: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id="more-date-filter-view"
        open={openMore}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ ml: -36, mt: 1 }}
      >
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography
              variant="caption"
              sx={{ maxWidth: 60, textAlign: 'right', lineHeight: 1.2 }}
            >
              Include blanks
            </Typography>
          </Box>
          <Box>
            {filters.map((filter) => (
              <Box key={filter.filterFieldId} sx={{ mt: 1 }}>
                {filter.filters.map((filter) =>
                  filter.type === FieldTypes.BOOLEAN ? (
                    <Checkbox
                      checked={values.get(filter.filterKey) === 'true'}
                      onChange={(e) => {
                        onSetValue(
                          filter.filterKey,
                          e.target.checked ? 'true' : undefined
                        );
                      }}
                      key={filter.filterKey}
                    />
                  ) : (
                    <BasicDatePicker
                      label={filter.label}
                      key={filter.filterKey}
                      value={values.get(filter.filterKey) || null}
                      setValue={(e) => onSetValue(filter.filterKey, e)}
                      sx={{ mx: 0.5, width: 200 }}
                    />
                  )
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default MoreDateFilters;
