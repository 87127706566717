import { Add, RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { clone } from 'lodash-es';
import { useMemo, useState } from 'react';

import LoadingCircle from '@/components/atoms/LoadingCircle';
import FieldMatcher from '@/components/molecules/FieldMatcher';
import API from '@/services/API';
import Statements from '@/services/Statements';
import Validator from '@/services/Validator';
import { isNill } from '@/services/tools';
import { useAccountStore } from '@/store';
import Formatter from '@/services/Formatter';
import DynamicSelect from '@/components/DataForm/DynamicSelect';

const emptyRow = {
  comp_grid_criteria_id: '',
  product_id: '',
  issue_age_min: '',
  issue_age_max: '',
  transaction_type: '',
  compensation_type: '',
  commission_rate: '',
  notes_filter: '',
  notes_filter_method: '',
  payer_level_name: '',
  payee_level_name: '',
  payer_level_rate: '',
  payee_level_rate: '',
  carrier_grid_level: '',
  carrier_grid_commission_rate: '',
  calculation_method: '',
  limit_to_received: false,
};

const AgentCommissionScheduleConfig = ({
  fieldId,
  companyId,
  compGridId,
  payerLevelId,
  payeeLevelId,
  data: rowData,
  setter,
  readOnly = false,
}) => {
  const [criteriaAddMode, setCriteriaAddMode] = useState('single');
  const { selectedAccount } = useAccountStore();
  const { data: contactsData } = API.getBasicQuery('contacts');
  const { data: incentiveTiers } = API.getBasicQuery(
    'schedules/agents/incentive_tiers',
    `company_id=${companyId ?? ''}`
  );
  const { data: criteria } = API.getBasicQuery(
    'comp-grids/criteria',
    `${companyId ? `&company_id=${companyId}` : ''}${compGridId ? `&comp_grid_id=${compGridId}` : ''}&is_dynamic_select=true`
  );
  const { data: payerRates } = API.getBasicQuery(
    'comp-grids/rates',
    `comp_grid_level_id=${payerLevelId ?? ''}&is_dynamic_select=true`
  );
  const { data: payeeRates } = API.getBasicQuery(
    'comp-grids/rates',
    `comp_grid_level_id=${payeeLevelId ?? ''}&is_dynamic_select=true`
  );

  const isNewCompGrids = selectedAccount?.compGridsEnabled;

  const statements = new Statements('insurance');
  const statementFields = Object.values(statements.fields);

  // const incentiveTiers = [
  //   { name: 'Tier 1', min: 0, max: 100000, rate: 0.1 },
  //   { name: 'Tier 2', min: 100001, max: 200000, rate: 0.15 },
  //   { name: 'Tier 2', min: 100001, max: 200000, rate: 0.2 },
  // ];

  const calcMethods = useMemo(
    () => ({
      compGrid: {
        name: 'Pay commission to grid level',
        description: '',
        fields: isNewCompGrids
          ? []
          : [
              {
                id: 'commission_rate',
                label: 'Commission rate',
                tip: 'Commission rate for agent',
                validator: Validator.commissionRate,
                dataType: 'percentage',
              },
              {
                id: 'receivable_rate',
                label: 'Receivable rate',
                tip: 'Receivable rate for agency',
                validator: Validator.commissionRate,
                dataType: 'percentage',
              },
            ],
      },
      compGridLevel: isNewCompGrids
        ? {
            name: 'Share override to grid level',
            description:
              'Payout % is set to be the difference between override received and override set by carriers between levels in commission grids.',
            fields: [],
          }
        : {
            name: 'Share override to grid level',
            description:
              'Payout % is set to be the difference between override received and override set by carriers between levels in commission grids.',
            fields: [
              [
                {
                  id: 'payer_level_name',
                  label: 'Carrier grid level',
                  dataType: 'text',
                },
                {
                  id: 'payer_level_rate',
                  label: 'Payer rate',
                  validator: Validator.commissionRate,
                  dataType: 'percentage',
                },
              ],
              [
                {
                  id: 'payee_level_name',
                  label: 'House payout grid level',
                  dataType: 'text',
                },
                {
                  id: 'payee_level_rate',
                  label: 'Payee rate',
                  validator: Validator.commissionRate,
                  dataType: 'percentage',
                },
              ],
              {
                id: 'carrier_grid_override_rate',
                label: 'Carrier grid override rate',
                validator: Validator.commissionRate,
                dataType: 'percentage',
                // readOnly: true,
                // compute: (row) => +row.payer_level_rate - +row.payee_level_rate,
              },
            ],
          },
      payoutRate: {
        name: 'Pay a set rate',
        description:
          'Payout rate is a fixed number or up to a fixed number. For example, brokerage pays 15% regardless of received override.',
        fields: [
          {
            id: 'payout_rate',
            label: 'Payout rate',
            tip: '',
            validator: Validator.commissionRate,
            dataType: 'percentage',
          },
          {
            id: 'limit_to_received',
            label: 'Up to received commission',
            tip: '',
            type: 'boolean',
          },
        ],
      },
      payHouseRate: {
        name: 'Pay house rate',
        description: '',
        fields: [],
      },
      payOverrideUpToTotalRate: {
        name: 'Pay override up to total rate',
        description: '',
        fields: [],
      },
      keepRate: isNewCompGrids
        ? {
            name: 'Keep a set rate',
            description:
              'Brokerage keeps a set rate and/or a percentage of received commission and pays out the remainder from received override.',
            fields: [
              {
                id: 'basis',
                label: 'Calculation basis',
                type: 'select',
                options: [
                  { id: 'commission_grid', name: 'Commission grid' },
                  { id: 'received_rate', name: 'Received rate' },
                ],
                validator: (v) => v,
              },
              // [
              //   {
              //     id: 'payer_level_rate',
              //     label: 'Payer rate',
              //     validator: Validator.commissionRate,
              //     dataType: 'percentage',
              //     readOnly: true,
              //     condition: (row) => row.basis === 'commission_grid',
              //   },
              //   {
              //     id: 'payee_level_rate',
              //     label: 'Payee rate',
              //     validator: Validator.commissionRate,
              //     dataType: 'percentage',
              //     readOnly: true,
              //     condition: (row) => row.basis === 'commission_grid',
              //   },
              // ],
              // {
              //   id: 'carrier_grid_override_rate',
              //   label: 'Carrier grid override rate',
              //   validator: Validator.commissionRate,
              //   dataType: 'percentage',
              //   readOnly: true,
              //   condition: (row) => row.basis === 'commission_grid',
              //   compute: (row) => +row.payer_level_rate - +row.payee_level_rate,
              // },
              {
                id: 'basis_divider',
                field: <Divider />,
                type: 'custom',
                condition: (row) => row.basis === 'commission_grid',
              },
              {
                id: 'keep_rate',
                label: 'Flat rate',
                validator: Validator.commissionRate,
                dataType: 'percentage',
              },
              { id: 'operator', field: <Add />, type: 'custom' },
              [
                {
                  id: 'plus_rate',
                  label: 'Percentage',
                  validator: Validator.commissionRate,
                  dataType: 'percentage',
                },
                {
                  id: 'percent_method',
                  label: 'Method',
                  dataType: 'text',
                  type: 'select',
                  validator: (v) => v,
                  options: [
                    { id: 'received', name: 'of received' },
                    { id: 'remainder', name: 'of remainder' },
                  ],
                },
              ],
            ],
          }
        : {
            name: 'Keep a set rate',
            description:
              'Brokerage keeps a set rate and/or a percentage of received commission and pays out the remainder from received override.',
            fields: [
              {
                id: 'basis',
                label: 'Calculation basis',
                type: 'select',
                options: [
                  { id: 'commission_grid', name: 'Commission grid' },
                  { id: 'received_rate', name: 'Received rate' },
                ],
                validator: (v) => v,
              },
              [
                {
                  id: 'payer_level_name',
                  label: 'Carrier grid level',
                  dataType: 'text',
                  condition: (row) => row.basis === 'commission_grid',
                },
                {
                  id: 'payer_level_rate',
                  label: 'Payer rate',
                  validator: Validator.commissionRate,
                  dataType: 'percentage',
                  condition: (row) => row.basis === 'commission_grid',
                },
              ],
              [
                {
                  id: 'payee_level_name',
                  label: 'House payout grid level',
                  dataType: 'text',
                  condition: (row) => row.basis === 'commission_grid',
                },
                {
                  id: 'payee_level_rate',
                  label: 'Payee rate',
                  validator: Validator.commissionRate,
                  dataType: 'percentage',
                  condition: (row) => row.basis === 'commission_grid',
                },
              ],
              {
                id: 'carrier_grid_override_rate',
                label: 'Carrier grid override rate',
                validator: Validator.commissionRate,
                dataType: 'percentage',
                condition: (row) => row.basis === 'commission_grid',
                // readOnly: true,
                // compute: (row) => +row.payer_level_rate - +row.payee_level_rate,
              },
              {
                id: 'basis_divider',
                field: <Divider />,
                type: 'custom',
                condition: (row) => row.basis === 'commission_grid',
              },
              {
                id: 'keep_rate',
                label: 'Flat rate',
                validator: Validator.commissionRate,
                dataType: 'percentage',
              },
              { id: 'operator', field: <Add />, type: 'custom' },
              [
                {
                  id: 'plus_rate',
                  label: 'Percentage',
                  validator: Validator.commissionRate,
                  dataType: 'percentage',
                },
                {
                  id: 'percent_method',
                  label: 'Method',
                  dataType: 'text',
                  type: 'select',
                  validator: (v) => v,
                  options: [
                    { id: 'received', name: 'of received' },
                    { id: 'remainder', name: 'of remainder' },
                  ],
                },
              ],
            ],
          },
      overrideSplit: {
        name: 'Split percentage',
        description:
          'Brokerage splits the override with agencies. For example, a 60/40 split means 60% of override is shared to downlines and the brokerage keeps 40%.',
        fields: [
          {
            id: 'split',
            label: 'Split percentage',
            validator: Validator.commissionRate,
            dataType: 'percentage',
          },
        ],
      },
      carrierGridSplitRemainder: {
        name: 'Pay to a grid level then split percentage',
        description:
          'Brokerage pays producers up to level indicated in carrier grids then splits the rest of the override with agency.',
        fields: [
          {
            id: 'agent',
            label: 'Agent',
            type: 'select',
            options: contactsData?.data ?? [],
          },
          {
            id: 'carrier_grid_level',
            label: 'Carrier grid level',
          },
          {
            id: 'split',
            label: 'Split percentage',
            validator: Validator.commissionRate,
            dataType: 'percentage',
          },
          {
            id: 'carrier_grid_override_rate',
            label: 'Carrier grid override rate',
            validator: Validator.commissionRate,
            dataType: 'percentage',
          },
        ],
      },
      payoutRateIncentiveTiers: {
        name: 'Pay based on incentive tiers',
        description: '',
        fields: incentiveTiers?.map((tier, i) => ({
          id: tier.name,
          label: `${tier.name} rate`,
          dataType: 'percentage',
        })),
      },
    }),
    [incentiveTiers, contactsData]
  );

  const {
    data: _productData,
    isLoading: isLoadingProducts,
    isFetched: isFetchedProducts,
  } = API.getBasicQuery(
    'companies/products',
    companyId ? `company_id=${companyId}` : undefined
  );
  const productData = _productData?.data;
  const {
    data: productOptionData,
    isLoading: isLoadingProductOptions,
    isFetched: isFetchedProductOptions,
  } = API.getBasicQuery(
    'companies/products/options',
    companyId ? `company_id=${companyId}` : undefined
  );

  const {
    data: compensationTypes,
    isLoading: isLoadingCompensationTypes,
    isFetched: isFetchedCompensationTypes,
  } = API.getBasicQuery('statement_data/compensation_type');

  const {
    data: statementTransactionTypes,
    isLoading: isLoadingStatementTransactionTypes,
    isFetched: isFetchedStatementTransactionTypes,
  } = API.getBasicQuery('statement_data/transaction_type');
  const {
    data: reportTransactionTypes,
    isLoading: isLoadingReportTransactionTypes,
    isFetched: isFetchedReportTransactionTypes,
  } = API.getBasicQuery('report_data/transaction_type');

  const transactionTypes = Array.from(
    new Set([
      ...(statementTransactionTypes?.values ?? []),
      ...(reportTransactionTypes?.values ?? []),
    ])
  );

  const data =
    !rowData[fieldId] ||
    (Array.isArray(rowData[fieldId]) && rowData[fieldId].length === 0)
      ? [clone(emptyRow)]
      : rowData[fieldId];

  data.forEach((datum) => {
    if (
      isNewCompGrids &&
      Array.isArray(payerRates?.data) &&
      Array.isArray(payeeRates?.data)
    ) {
      const payerRate = payerRates.data.find(
        (r) => r.comp_grid_criterion.id === datum.comp_grid_criteria_id?.[0]
      );
      if (payerRate) {
        datum.payer_level_rate = payerRate.rate;
      }
      const payeeRate = payeeRates.data.find(
        (r) => r.comp_grid_criterion.id === datum.comp_grid_criteria_id?.[0]
      );
      if (payeeRate) {
        datum.payee_level_rate = payeeRate.rate;
      }
      if (payerRate && payeeRate) {
        datum.carrier_grid_override_rate = payerRate.rate - payeeRate.rate;
      }
    }
  });

  const unusedCriteria = Array.isArray(criteria?.data)
    ? (criteria?.data?.filter(
        (criterion) =>
          !data?.find((datum) =>
            Array.isArray(datum.comp_grid_criteria_id)
              ? datum.comp_grid_criteria_id.includes(criterion.id)
              : datum.comp_grid_criteria_id === criterion.id
          )
      ) ?? [])
    : [];

  const getHandleChange =
    (
      index,
      subFieldId,
      opts: {
        isDynamicSelect?: boolean;
        multiple?: boolean;
        boolean?: boolean;
      } = {
        isDynamicSelect: false,
        multiple: false,
        boolean: false,
      }
    ) =>
    (e) => {
      const newDatums = [...data];
      const value = opts.isDynamicSelect
        ? e?.[subFieldId]
        : (e?.target?.value ?? e);
      if (opts.multiple) {
        if (value.includes('')) {
          newDatums[index][subFieldId] = '';
        } else if (typeof value === 'string') {
          newDatums[index][subFieldId] = value.split(',');
        } else {
          newDatums[index][subFieldId] = value;
        }
      } else {
        newDatums[index][subFieldId] = opts.boolean ? e.target.checked : value;
      }

      if (
        ['payee_level_rate', 'payer_level_rate'].includes(subFieldId) &&
        !isNill(newDatums[index].payee_level_rate) &&
        !isNill(newDatums[index].payer_level_rate) &&
        !Number.isNaN(+newDatums[index].payee_level_rate) &&
        !Number.isNaN(+newDatums[index].payer_level_rate)
      ) {
        const newCarrierGridOverrideRate =
          +newDatums[index].payer_level_rate -
          +newDatums[index].payee_level_rate;
        newDatums[index].carrier_grid_override_rate =
          newCarrierGridOverrideRate;
      }

      setter({ ...rowData, [fieldId]: newDatums });
    };

  const getCalcFormField = (
    datum,
    i,
    field,
    payerRates,
    payeeRates,
    width = 270,
    readOnly = false
  ) =>
    typeof field.condition === 'function' && !field.condition(datum) ? null : (
      <Tooltip title={field.tip} key={field.id}>
        {field.type === 'custom' ? (
          <Box
            sx={{
              mt: 1,
              width,
              textAlign: 'center',
            }}
          >
            {field.field}
          </Box>
        ) : field.type === 'boolean' ? (
          <Box sx={{ mb: 1, ml: 1 }} key={field.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={datum[field.id]}
                  onChange={getHandleChange(i, field.id, {
                    multiple: false,
                    boolean: true,
                  })}
                  readOnly={field.readOnly}
                />
              }
              label={field.label}
              disabled={readOnly || field.readOnly}
            />
          </Box>
        ) : field.type === 'select' ? (
          <FormControl
            sx={{ mr: 1, width, mt: 1 }}
            error={
              (typeof field.validator === 'function' &&
                !field.validator(datum[field.id])) ??
              false
            }
            disabled={readOnly || field.readOnly}
          >
            <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
            <Select
              labelId={`${field.id}-label`}
              label={field.label}
              value={datum[field.id]}
              onChange={getHandleChange(i, field.id)}
              readOnly={field.readOnly}
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {field.options?.sort().map((v) => (
                <MenuItem key={v.id} value={v.id}>
                  {v.name ??
                    Formatter.contact(v, {
                      account_id: selectedAccount?.accountId,
                    })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            label={field.label}
            value={datum[field.id]}
            onChange={getHandleChange(i, field.id)}
            error={
              typeof field.validator === 'function' &&
              !field.validator(datum[field.id], {
                required: false,
              })
            }
            sx={{ mr: 1, width, mt: 1 }}
            InputProps={
              datum[field.id]
                ? {
                    endAdornment: (
                      <InputAdornment position="end" sx={{ ml: 0 }}>
                        {field.dataType === 'percentage' ? '%' : ''}
                      </InputAdornment>
                    ),
                  }
                : {}
            }
            disabled={readOnly || field.readOnly}
            // disabled={field.readOnly}
          />
        )}
      </Tooltip>
    );

  return isLoadingProducts ||
    isLoadingProductOptions ||
    isLoadingStatementTransactionTypes ||
    isLoadingReportTransactionTypes ||
    isLoadingCompensationTypes ? (
    <LoadingCircle size={32} />
  ) : isFetchedProducts &&
    isFetchedProductOptions &&
    isFetchedCompensationTypes &&
    isFetchedStatementTransactionTypes &&
    isFetchedReportTransactionTypes ? (
    <Box sx={{ mb: 1, width: '100%' }} key={fieldId}>
      {data?.map((datum, i) => (
        <Box
          key={i}
          sx={{
            width: '100%',
            mb: 0.5,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              mt: 0.25,
              pl: 1.5,
              pt: 0.5,
              pb: 0,
              display: 'flex',
              flexDirection: 'column',
              borderStyle: 'solid',
              borderColor: 'silver',
              borderWidth: 1,
              borderRadius: 4,
              width: '100%',
              backgroundColor: '#2196f30a',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                mb: 1,
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ minWidth: 575 }}>
                  <Typography variant="body2">Match criteria</Typography>
                  {isNewCompGrids && Array.isArray(criteria?.data) && (
                    <Box sx={{ my: 1 }}>
                      <DynamicSelect
                        field={{
                          id: 'comp_grid_criteria_id',
                          label: 'Comp grid criteria',
                          table: 'comp-grids/criteria',
                          multiple: true,
                          formatter: Formatter.getDynamicSelectFormatter(
                            Formatter.compGridCriterion
                          ),
                          optionFormatter: Formatter.compGridCriterion,
                          optionValuer: (o) => o.id,
                          type: 'dynamic-select',
                        }}
                        formattedData={datum}
                        oldData={{}}
                        setNewData={getHandleChange(
                          i,
                          'comp_grid_criteria_id',
                          { isDynamicSelect: true, multiple: true }
                        )}
                        dynamicSelects={{ 'comp-grids/criteria': criteria }}
                        readOnly={readOnly}
                        disabled={readOnly}
                        fullWidth={true}
                      />
                      {/* <FormControl
                        sx={{ mr: 1, width: 600 }}
                        
                        disabled={readOnly}
                      >
                        <InputLabel id="comp-grid-criteria">
                          Comp grid criteria
                        </InputLabel>
                        <Select
                          label="Comp grid criteria"
                          labelId="comp-grid-criteria"
                          value={
                            Array.isArray(datum.comp_grid_criteria_id)
                              ? datum.comp_grid_criteria_id
                              : []
                          }
                          onChange={getHandleChange(
                            i,
                            'comp_grid_criteria_id',
                            {
                              multiple: true,
                            }
                          )}
                          multiple
                        >
                          <MenuItem value={''}>&nbsp;</MenuItem>
                          {(criteria ?? [])?.map((criterion) => (
                            <MenuItem key={criterion.id} value={criterion.id}>
                              {Formatter.compGridCriterion(criterion)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                    </Box>
                  )}
                  {!isNewCompGrids && (
                    <Box>
                      <Box sx={{ display: 'flex', mt: 0.5 }}>
                        <Tooltip
                          title="Carrier product. If unspecified, filter does not apply."
                          placement="left"
                        >
                          <FormControl
                            sx={{ mr: 1, width: 240 }}
                            disabled={readOnly}
                          >
                            <InputLabel id={datum.id}>Product</InputLabel>
                            <Select
                              label="Product"
                              labelId={datum.id}
                              value={
                                Array.isArray(datum.product_id)
                                  ? datum.product_id
                                  : []
                              }
                              onChange={getHandleChange(i, 'product_id', {
                                multiple: true,
                              })}
                              // sx={{ width: 260 }}
                              multiple
                            >
                              <MenuItem value={''}>&nbsp;</MenuItem>
                              {productData?.map((product) => (
                                <MenuItem key={product.id} value={product.id}>
                                  {product.product_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Tooltip>
                        <Tooltip
                          title="Carrier product option. If unspecified, filter does not apply."
                          placement="left"
                        >
                          <FormControl
                            sx={{ mr: 1, width: 240 }}
                            disabled={readOnly}
                          >
                            <InputLabel id={datum.id}>Option</InputLabel>
                            <Select
                              label="Option"
                              labelId={datum.id}
                              value={
                                Array.isArray(datum.product_option_id)
                                  ? datum.product_option_id
                                  : []
                              }
                              onChange={getHandleChange(
                                i,
                                'product_option_id',
                                {
                                  multiple: true,
                                }
                              )}
                              // sx={{ width: 260 }}
                              multiple
                            >
                              <MenuItem value={''}>&nbsp;</MenuItem>
                              {productOptionData?.data?.map(
                                (product_option) => (
                                  <MenuItem
                                    key={product_option.id}
                                    value={product_option.id}
                                  >
                                    {`${product_option?.product?.product_name} - ${product_option.name}`}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Tooltip>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Tooltip title="Minimum issue age, inclusive. If unspecified, filter does not apply.">
                          <TextField
                            label="Min age"
                            value={datum.issue_age_min}
                            onChange={getHandleChange(i, 'issue_age_min')}
                            error={
                              !Validator.age(datum.issue_age_min, {
                                required: false,
                              })
                            }
                            sx={{ width: 90 }}
                            InputProps={{
                              sx: {
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            }}
                            disabled={readOnly}
                          />
                        </Tooltip>
                        <Tooltip title="Maximum issue age, inclusive. If unspecified, filter does not apply.">
                          <TextField
                            label="Max age"
                            value={datum.issue_age_max}
                            onChange={getHandleChange(i, 'issue_age_max')}
                            error={
                              !Validator.age(datum.issue_age_max, {
                                required: false,
                              })
                            }
                            sx={{ ml: '-1px', mr: 1, width: 90 }}
                            InputProps={{
                              sx: {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              },
                            }}
                            disabled={readOnly}
                          />
                        </Tooltip>
                        <Tooltip
                          title="Transaction type in commission records. If unspecified, filter does not apply. '(Blank)' matches commission records with blank transaction type."
                          placement="right"
                        >
                          {transactionTypes?.length > 0 ? (
                            <FormControl sx={{ mr: 1 }} disabled={readOnly}>
                              <InputLabel id={`${datum.id}-transactionType`}>
                                Transaction type
                              </InputLabel>
                              <Select
                                label="Transaction type"
                                labelId={`${datum.id}-transactionType`}
                                value={datum.transaction_type}
                                onChange={getHandleChange(
                                  i,
                                  'transaction_type'
                                )}
                                sx={{ minWidth: 160, width: 160 }}
                              >
                                <MenuItem value={''}>&nbsp;</MenuItem>
                                <MenuItem value={'(Blank)'}>(Blank)</MenuItem>
                                {datum.transaction_type &&
                                  !transactionTypes?.includes(
                                    datum.transaction_type
                                  ) && (
                                    <MenuItem value={datum.transaction_type}>
                                      <Tooltip
                                        title="This value is not present in any commission records"
                                        placement="right"
                                      >
                                        <span>{`${datum.transaction_type}*`}</span>
                                      </Tooltip>
                                    </MenuItem>
                                  )}
                                {transactionTypes?.map((transactionType) => (
                                  <MenuItem
                                    key={transactionType}
                                    value={transactionType}
                                  >
                                    {transactionType}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <TextField
                              label="Transaction type"
                              value={datum.transaction_type}
                              onChange={getHandleChange(i, 'transaction_type')}
                              sx={{ mr: 1, width: 160 }}
                              disabled={readOnly}
                            />
                          )}
                        </Tooltip>
                        <Tooltip
                          title="Compensation type in commission records. If unspecified, filter does not apply. '(Blank)' matches commission records with blank compensation type."
                          placement="right"
                        >
                          {compensationTypes?.values?.length > 0 ? (
                            <FormControl sx={{ mr: 1 }} disabled={readOnly}>
                              <InputLabel id={`${datum.id}-compensationTypes`}>
                                Compensation type
                              </InputLabel>
                              <Select
                                label="Compensation type  "
                                labelId={`${datum.id}-compensationTypes`}
                                value={datum.compensation_type}
                                onChange={getHandleChange(
                                  i,
                                  'compensation_type'
                                )}
                                sx={{ minWidth: 160, width: 160 }}
                              >
                                <MenuItem value={''}>&nbsp;</MenuItem>
                                <MenuItem value={'(Blank)'}>(Blank)</MenuItem>
                                {datum.compensation_type &&
                                  !compensationTypes?.values?.includes(
                                    datum.compensation_type
                                  ) && (
                                    <MenuItem value={datum.compensation_type}>
                                      <Tooltip
                                        title="This value is not present in any commission records"
                                        placement="right"
                                      >
                                        <span>{`${datum.compensation_type}*`}</span>
                                      </Tooltip>
                                    </MenuItem>
                                  )}
                                {compensationTypes?.values?.map(
                                  (compensationType) => (
                                    <MenuItem
                                      key={compensationType}
                                      value={compensationType}
                                    >
                                      {compensationType}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          ) : (
                            <TextField
                              label="Compensation type"
                              value={datum.compensation_type}
                              onChange={getHandleChange(i, 'compensation_type')}
                              sx={{ mr: 1, width: 160 }}
                              disabled={readOnly}
                            />
                          )}
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                  {!readOnly && (
                    <Box sx={{ mt: 1, maxWidth: 800 }}>
                      <FieldMatcher
                        value={datum.match_criteria}
                        setValue={(e) => {
                          getHandleChange(i, 'match_criteria')(e);
                        }}
                        fields={statementFields}
                        addLabel="Add criteria"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 2 }} />
              <Box sx={{ minWidth: 280 }}>
                <Box>
                  <Typography variant="body2">Payout calculation</Typography>
                  <Box sx={{ mt: 0.5, mr: 1 }}>
                    <Tooltip title="" placement="left">
                      <FormControl
                        sx={{ mr: 1, width: '100%' }}
                        disabled={readOnly}
                      >
                        <InputLabel id="calcMethodLabel">
                          Calculation method
                        </InputLabel>
                        <Select
                          label="Calculation method"
                          labelId="calcMethodLabel"
                          value={datum.calculation_method}
                          onChange={getHandleChange(i, 'calculation_method')}
                        >
                          <MenuItem value={''}>&nbsp;</MenuItem>
                          {Object.entries(calcMethods).map(([k, v]) => (
                            <MenuItem key={k} value={k}>
                              <Tooltip
                                placement="right"
                                title={v.description}
                                key={k}
                              >
                                <span>{v.name}</span>
                              </Tooltip>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </Box>
                  <Divider sx={{ mt: 1, ml: 1, mr: 2 }} />
                  <Box>
                    {datum.calculation_method &&
                      calcMethods[datum.calculation_method]?.fields?.map(
                        (field, fieldIndex) =>
                          Array.isArray(field) ? (
                            <Box key={fieldIndex}>
                              {field
                                .map((subField) =>
                                  getCalcFormField(
                                    datum,
                                    i,
                                    subField,
                                    payerRates,
                                    payeeRates,
                                    130,
                                    readOnly
                                  )
                                )
                                .filter((v) => v)}
                            </Box>
                          ) : (
                            getCalcFormField(
                              datum,
                              i,
                              field,
                              payerRates,
                              payeeRates,
                              undefined,
                              readOnly
                            )
                          )
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                const newdatums = [...data];
                newdatums.splice(i, 1);
                setter({ ...rowData, [fieldId]: newdatums });
              }}
              disabled={readOnly}
            >
              <RemoveCircleOutline />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Button
        onClick={() => {
          setter((prevState) => {
            const updatedArray = prevState[fieldId]
              ? [...prevState[fieldId], clone(emptyRow)]
              : [clone(emptyRow)];
            return { ...prevState, [fieldId]: updatedArray };
          });
        }}
      >
        Add rule
      </Button>
      {companyId && unusedCriteria.length > 0 && (
        <Box sx={{ mt: 0.5 }}>
          <Typography variant="body2">
            💡 Comp grid criteria without rules ({unusedCriteria.length})
          </Typography>
          <Box sx={{ ml: 4 }}>
            <Typography variant="caption">
              {unusedCriteria.map((criterion) => (
                <Box>{Formatter.compGridCriterion(criterion)}</Box>
              ))}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ToggleButtonGroup
                color="primary"
                exclusive
                sx={{ height: 32, mr: 1 }}
                value={criteriaAddMode}
                onChange={(e, v) => setCriteriaAddMode(v)}
              >
                <ToggleButton
                  value="single"
                  sx={{ borderRadius: 16, lineHeight: 'unset' }}
                >
                  As single rule
                </ToggleButton>
                <ToggleButton
                  value="separate"
                  sx={{ borderRadius: 16, lineHeight: 'unset' }}
                >
                  As separate rules
                </ToggleButton>
              </ToggleButtonGroup>
              <Button
                onClick={() => {
                  const criteriaSeparateRules = unusedCriteria.map(
                    (criterion) => ({
                      ...clone(emptyRow),
                      comp_grid_criteria_id: [criterion.id],
                    })
                  );
                  const criteriaSingleRule = [
                    {
                      ...clone(emptyRow),
                      comp_grid_criteria_id: unusedCriteria.map((c) => c.id),
                    },
                  ];
                  if (criteriaAddMode === 'separate') {
                    setter((prevState) => {
                      const updatedArray = prevState[fieldId]
                        ? [...prevState[fieldId], ...criteriaSeparateRules]
                        : criteriaSeparateRules;
                      return { ...prevState, [fieldId]: updatedArray };
                    });
                  } else if (criteriaAddMode === 'single') {
                    setter((prevState) => {
                      const updatedArray = prevState[fieldId]
                        ? [...prevState[fieldId], ...criteriaSingleRule]
                        : criteriaSingleRule;
                      return { ...prevState, [fieldId]: updatedArray };
                    });
                  }
                }}
              >
                Add unused criteria
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  ) : (
    <Box>Error loading agent commission schedule rule</Box>
  );
};
export default AgentCommissionScheduleConfig;
