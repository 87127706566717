import { useEffect } from 'react';

import { useAccountStore } from '@/store';
import { mapAccountKeys } from '@/utils/account';

export const useUpdateSelectedAccount = () => {
  const { selectedAccount, setSelectedAccount, userState } = useAccountStore();

  const selectedAccId = selectedAccount?.accountId;
  const accounts = userState?.userAccounts;

  // Set selected account
  useEffect(() => {
    if (!accounts?.length || selectedAccId) return;

    const account = accounts[0].account;

    setSelectedAccount(mapAccountKeys(account));
  }, [selectedAccId, setSelectedAccount, accounts]);

  // Update name or logo displayed
  // TODO: should remove this logic in next refactor
  // We should only store accountId
  // and with useEffect above, we can get the account info to update the store
  useEffect(() => {
    if (!accounts?.length) return;
    const account = accounts.find(
      (acc) => acc.account_id === selectedAccId
    )?.account;

    if (account) {
      setSelectedAccount(mapAccountKeys(account));
    }
  }, [selectedAccId, setSelectedAccount, accounts]);
};
