// @ts-nocheck
import { ContentCopy, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { isValidElement, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getFilenameFromPath } from 'common/helpers';

import { HoverActionButtonContainer } from '@/components/HoverActionButtonContainer';
import { cellSx, rowSx } from '@/components/HoverActionButtonContainer/styles';
import EnhancedTableToolbar from '@/components/molecules/EnhancedTableToolbar';
import ExpandableData from '@/components/molecules/ExpandableData';
import MoreMenu from '@/components/molecules/MoreMenu';
import TableRowCard from '@/components/molecules/TableRowCard';
import { UIStateContext } from '@/contexts/UIStateProvider';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { hasAccess, requiresFintaryAdmin } from '@/services/helpers';
import { useAccountStore, useRoleStore } from '@/store';

const APP_BAR_HEIGHT = 64;

function descendingComparator(a, b, orderBy) {
  // TODO: Remove this hack
  if (
    window.location.pathname.startsWith('/reports/summary') &&
    a?.totals?.agent_commissions &&
    b?.totals?.agent_commissions
  ) {
    const _a = Object.values(a.totals.agent_commissions ?? {})?.[0] ?? 0;
    const _b = Object.values(b.totals.agent_commissions ?? {})?.[0] ?? 0;
    if (_b < _a) {
      return -1;
    }
    if (_b > _a) {
      return 1;
    }
    return 0;
  }

  if (
    window.location.pathname.startsWith('/documents') &&
    orderBy === 'file_path'
  ) {
    return getFilenameFromPath(b[orderBy]).localeCompare(
      getFilenameFromPath(a[orderBy])
    );
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const SelectDbValsShowMore = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Box>
      {data.slice(0, 6).map((item, i) => (
        <Box key={i}>{item}</Box>
      ))}
      {showMore && data.slice(6).map((item, i) => <Box key={i}>{item}</Box>)}
      {data.length > 6 && (
        <Button onClick={() => setShowMore(!showMore)} sx={{ color: '#555' }}>
          {showMore ? 'Show less' : `Show ${data.length - 6} more`}
        </Button>
      )}
    </Box>
  );
};

const EnhancedTableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  onDelete,
  actionsCol,
  options = {},
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {onDelete && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'Select all',
              }}
            />
          </TableCell>
        )}
        {options.radio && <TableCell />}
        {headCells.map((headCell) => (
          <TableCell
            key={`${headCell.source ?? ''}${headCell.id}${headCell.id2 ?? ''}`}
            align={headCell.numeric ? 'right' : 'left'}
            padding="none"
            sx={{
              p: 1,
              whiteSpace: 'nowrap',
              display:
                headCell.visible === false || headCell.hidden === true
                  ? 'none'
                  : 'table-cell',
              ...headCell.sx,
              ...headCell.headerSx,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSort ? (
              <Tooltip title={headCell.description} placement="top">
                <span>
                  {headCell.label}
                  {headCell.infoIcon && ' ⓘ'}
                  {requiresFintaryAdmin(headCell.access) && ' 🔒'}
                </span>
              </Tooltip>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                key={headCell.id}
                onClick={(evt) => {
                  if (headCell.disableSort) return;
                  createSortHandler(headCell.id)(evt);
                }}
              >
                <Tooltip title={headCell.description} placement="top">
                  <span>
                    {headCell.label}
                    {headCell.infoIcon && ' ⓘ'}
                    {requiresFintaryAdmin(headCell.access) && ' 🔒'}
                  </span>
                </Tooltip>
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {actionsCol && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTable = ({
  title,
  outstandingFieldsInMobileView,
  headers: _headers,
  rows,
  rowKey,
  dense,
  readOnly = false,
  onEdit,
  onBulkEdit = () => {},
  onBulkSync = undefined,
  onDelete, // original behavior is only onDelete
  onClick, // adding onClick while preserving default onDelete
  actions = [] as any[],
  stickyHeader,
  actionsEnabled = () => false,
  paginated = false,
  controlledOrdering,
  controlledPagination,
  setSelectedData = () => {}, // TODO: Should be controlled selection...hack for now
  options = {},
  refetch = () => {},
  showTotals = false,
  totals = {},
  customHeaderActions = false,
  nonSelectableOnMobile = false,
  headingOffset = 109,
}) => {
  const navigate = useNavigate();
  const { selectedAccount } = useAccountStore();
  const [dynamicSelects, setDynamicSelects] = useState({});
  const [selected, setSelected] = useState([]);
  const [actionLoading, setActionLoading] = useState({});
  const getSetActionLoadingByRowId = (id) => (state) => {
    setActionLoading({
      ...actionLoading,
      [id]: state,
    });
    if (!state) setTimeout(refetch, 250);
  };
  const [sums, setSums] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(paginated ? 50 : 999999);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [innerOrderControl, setInnerOrderControl] = useState({
    order,
    orderBy,
    setOrder,
    setOrderBy,
  });

  const { userRole } = useRoleStore();
  const {
    role: [role],
  } = useContext(UIStateContext);

  const headers = _headers?.flat() ?? [];

  const dynamicSelectsPoster = API.getMutation('dynamic_selects', 'POST', {
    gcTime: 1,
  });

  useEffect(() => {
    return () => {
      dynamicSelectsPoster.abort();
    };
  }, []);

  useEffect(() => {
    const _order = order || (controlledOrdering && controlledOrdering.order);
    if (_order) {
      setInnerOrderControl((pre) => {
        return {
          ...pre,
          order: _order,
        };
      });
    }
    const _orderBy =
      orderBy || (controlledOrdering && controlledOrdering.orderBy);
    if (_orderBy) {
      setInnerOrderControl((pre) => {
        return {
          ...pre,
          orderBy: _orderBy,
        };
      });
    }
  }, [order, orderBy, controlledOrdering]);

  // Reset list of selected items if the underlying dataset changes. Using length as proxy for now.
  useEffect(() => {
    setSelected([]);
    setSelectedData([]);
  }, [rows?.length]); // If setSelectedData is added as a dependency, it will cause an infinite loop.

  useEffect(() => {
    const dynamicSelectVals = headers
      .filter((field) => field.table)
      .map((field) => ({
        table: field.table,
        queryParamTable: field.queryParamTable,
        queryParamNameTable: field.queryParamNameTable,
      }));

    const uniqueDynamicSelectVals = Array.from(
      new Set(dynamicSelectVals.map(JSON.stringify))
    ).map(JSON.parse);

    getDynamicSelects(uniqueDynamicSelectVals);
  }, []);

  const getDynamicSelects = async (dynamicSelectVals) => {
    try {
      const data = await dynamicSelectsPoster.mutateAsync(dynamicSelectVals);
      if (Array.isArray(data) && data.length > 0) {
        data.forEach((field) => {
          setDynamicSelects((prev) => ({
            ...prev,
            ...field,
          }));
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const headersFiltered = headers
    .filter((h) => hasAccess(h.access, userRole, role === 'admin'))
    .filter((h) => !Array.isArray(h.visible) || h.visible.includes('table'))
    .filter((h) => !['heading', 'divider'].includes(h.type));
  // Logic for calculating totals for certain columns based on the headers
  const columns = headersFiltered.map((header) => header.id);
  useEffect(() => {
    if (totals) {
      const newSums = columns.map((col) => {
        return totals[col] ? totals[col] : undefined;
      });
      if (JSON.stringify(newSums) !== JSON.stringify(sums)) {
        setSums(newSums);
      }
    }
  }, [columns, sums, totals]);

  if (!Array.isArray(rows)) {
    return null;
  }

  const handleRequestSort = (event, property) => {
    const isAsc =
      innerOrderControl.orderBy === property &&
      innerOrderControl.order === 'asc';
    innerOrderControl.setOrder(isAsc ? 'desc' : 'asc');
    if (
      controlledOrdering?.setOrder &&
      typeof controlledOrdering?.setOrder === 'function'
    ) {
      controlledOrdering.setOrder(isAsc ? 'desc' : 'asc');
    }
    innerOrderControl.setOrderBy(property);
    if (
      controlledOrdering?.setOrderBy &&
      typeof controlledOrdering?.setOrderBy === 'function'
    ) {
      controlledOrdering.setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }
    setSelected([]);
    setSelectedData([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (options.radio) {
      newSelected = [name];
    } else if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setSelectedData(rows.filter((row) => newSelected.includes(row.id)));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const rowsPerPageToUse = controlledPagination
    ? controlledPagination.rowsPerPage
    : rowsPerPage;

  const orderToUse = innerOrderControl.order; // controlledOrdering ? controlledOrdering.order : order;
  // const orderByToUse = controlledOrdering
  //   ? controlledOrdering.orderBy
  //   : orderBy;
  const orderByToUse = innerOrderControl.orderBy;

  const rowsSorted = controlledPagination
    ? rows
    : rows.sort(getComparator(orderToUse, orderByToUse));

  const deleteResetSelected = async (selected) => {
    await onDelete(selected);
    setSelected([]);
    setSelectedData([]);
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{ width: '100%' }}>
      {!options.hideSelectedCount && (
        <EnhancedTableToolbar
          title={title}
          selected={selected}
          setSelected={setSelected}
          onDelete={
            !options.radio
              ? (selected) => {
                  deleteResetSelected(selected);
                }
              : undefined
          }
          headers={headersFiltered}
          onEdit={
            !options.radio
              ? async (selected, updateData) => {
                  await onBulkEdit(selected, updateData);
                }
              : undefined
          }
          onSync={
            onBulkSync
              ? async (selected, updateData) =>
                  await onBulkSync(selected, updateData)
              : undefined
          }
          actionsCol={actions?.length > 0}
          customActions={customHeaderActions}
        />
      )}
      <TableContainer
        sx={{
          height: `calc(100vh - ${(paginated ? 42 : 0) + headingOffset + APP_BAR_HEIGHT}px - ${
            !options.hideSelectedCount && selected.length > 0 ? 48 : 0
          }px)`,
          overflowX: isMobile ? 'clip' : 'auto',
          overflowY: 'auto',
        }}
      >
        <Table
          sx={{
            tableLayout: isMobile ? 'fixed' : 'auto',
          }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          stickyHeader={stickyHeader}
          sx={
            isMobile
              ? {
                  '.MuiTableCell-root': {
                    borderBottom: 'none',
                  },
                }
              : {}
          }
        >
          {!isMobile && (
            <EnhancedTableHead
              numSelected={selected.length}
              order={orderToUse}
              orderBy={orderByToUse}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={[
                ...headersFiltered,
                typeof onEdit === 'function' || actions?.length > 0
                  ? {
                      id: 'actions',
                      disablePadding: false,
                      label: '',
                      disableSort: true,
                      visible: true,
                      sx: { minWidth: 80 },
                    }
                  : undefined,
              ].filter((header) => header !== undefined)}
              onDelete={!options.radio ? onDelete : undefined}
              options={options}
            />
          )}
          <TableBody>
            {rowsSorted
              .slice(
                page * rowsPerPageToUse,
                page * rowsPerPageToUse + rowsPerPageToUse
              )
              .map((row, index) => {
                const isItemSelected = row.id
                  ? isSelected(row.id)
                  : isSelected(Object.values(row).join('-'));
                const labelId = `enhanced-table-checkbox-${index}`;

                return isMobile ? (
                  <TableRowCard
                    row={row}
                    headersFiltered={headersFiltered}
                    outstandingFieldsInMobileView={
                      outstandingFieldsInMobileView
                    }
                    isItemSelected={isItemSelected}
                    handleClick={handleClick}
                    onDelete={onDelete}
                    onClick={onClick}
                    onEdit={onEdit}
                    readOnly={readOnly}
                    options={options}
                    dynamicSelects={dynamicSelects}
                    navigate={navigate}
                    labelId={labelId}
                    nonSelectable={nonSelectableOnMobile}
                    key={index}
                  />
                ) : (
                  <TableRow
                    hover
                    onClick={(event) => {
                      if (onDelete instanceof Function) {
                        handleClick(
                          event,
                          row.id || Object.values(row).join('-')
                        );
                      } else if (onClick instanceof Function && !onDelete) {
                        onClick(row);
                      }
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id || row.uid || row.str_id || row[rowKey]}
                    selected={isItemSelected}
                    sx={{
                      ...rowSx,
                      '& .action-buttons-visible': {
                        visibility: 'visible',
                      },
                      position: 'relative',
                      ...(row.state === 'grouped'
                        ? {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            opacity: 0.65,
                          }
                        : {}),
                    }}
                  >
                    {onDelete && (
                      <TableCell padding="checkbox">
                        {options.radio ? (
                          <Radio checked={isItemSelected} />
                        ) : (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        )}
                      </TableCell>
                    )}
                    {headersFiltered.map((header) => {
                      const headerKey = header.keyAs ?? header.id;
                      const formatter = (x, dynamicSelects) => {
                        if (header.tableFormatter instanceof Function) {
                          return header.tableFormatter(
                            x,
                            row,
                            dynamicSelects,
                            header
                          );
                        }
                        if (header.formatter instanceof Function) {
                          if (dynamicSelects) {
                            // TODO: Allow formatters to be applied when values are null
                            if (!x) return null;
                            if (isValidElement(x)) return x;
                            if (
                              isValidElement(
                                header.formatter(x, dynamicSelects)
                              )
                            )
                              return header.formatter(x, dynamicSelects);
                            return (
                              <Chip
                                label={header.formatter(x, dynamicSelects)}
                                sx={{ m: 0.25 }}
                                clickable={typeof header.linker === 'function'}
                                component={
                                  typeof header.linker === 'function'
                                    ? Link
                                    : 'div'
                                }
                                to={
                                  typeof header.linker === 'function'
                                    ? header.linker(x)
                                    : undefined
                                }
                              />
                            );
                          }
                          if (x instanceof Object) {
                            // return JSON.stringify(x);
                            const res = header.formatter(x, row, navigate);
                            if (res === null) return null;
                            return typeof res === 'object' &&
                              !isValidElement(res)
                              ? '---'
                              : res;
                          }
                          return header.formatter(x, row, navigate);
                        }
                        if (x instanceof Object && !isValidElement(x)) {
                          return JSON.stringify(x);
                        }
                        if (header.type === 'boolean') {
                          return x ? 'Yes' : 'No';
                        }
                        return x;
                      };
                      let getter = header.getter;
                      if (!getter) {
                        getter =
                          header.type === 'select' &&
                          typeof header.options?.[0] === 'object'
                            ? (x) =>
                                header.options.find(
                                  (option) => option.id === x[headerKey]
                                )?.label
                            : (x) => x[headerKey];
                      }

                      return (
                        <TableCell
                          align="left"
                          key={`${header.source ?? ''}${header.id}${
                            header.id2 ?? ''
                          }`}
                          sx={{
                            p: 1,
                            minWidth: row?.[header.id] ? 100 : 0,
                            display:
                              header.visible === false || header.hidden === true
                                ? 'none'
                                : 'table-cell',
                            ...header.sx,
                          }}
                        >
                          <Box
                            sx={{
                              whiteSpace: header.copyable ? 'nowrap' : 'normal',
                              cursor: header.onClick ? 'pointer' : 'default',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              header.onClick instanceof Function &&
                                header.onClick(row);
                            }}
                          >
                            <Box
                              sx={{
                                display: header.copyable
                                  ? 'inline-flex'
                                  : 'inherit',
                              }}
                            >
                              {row[headerKey] !== undefined ? (
                                Array.isArray(row[header.id]) &&
                                !header.tableFormatter ? (
                                  header.id === 'notes' ? (
                                    <Tooltip
                                      title={
                                        <Box>
                                          {row[header.id].map((item) => (
                                            <Box key={item} sx={{ my: 0.5 }}>
                                              {item}
                                            </Box>
                                          ))}
                                        </Box>
                                      }
                                    >
                                      <span style={{ whiteSpace: 'nowrap' }}>
                                        {row[header.id].length
                                          ? `⚠️ ${
                                              row[header.id].length
                                            } conflicts`
                                          : null}
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <SelectDbValsShowMore
                                      data={row[header.id].map((item, i) => {
                                        const formattedVal = formatter(
                                          item,
                                          dynamicSelects[header.table]?.data ??
                                            dynamicSelects[header.table],
                                          dynamicSelects[header.table]?.count ??
                                            undefined
                                        );
                                        if (formattedVal === null) return null;
                                        return (
                                          <span
                                            key={
                                              typeof item === 'object'
                                                ? JSON.stringify(item)
                                                : item
                                            }
                                          >
                                            {/* If array, but not a dynamic select (already chipped), chipify */}
                                            {header.type ===
                                            'dynamic-select' ? (
                                              formattedVal
                                            ) : isValidElement(formattedVal) ? (
                                              formattedVal
                                            ) : (
                                              <Chip
                                                key={item}
                                                label={formattedVal}
                                                sx={{
                                                  m: 0.25,
                                                  cursor:
                                                    header.itemOnClick ||
                                                    header.linker
                                                      ? 'pointer'
                                                      : 'default',
                                                  maxWidth: 500,
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  typeof header.itemOnClick ===
                                                    'function' &&
                                                    header.itemOnClick(item);
                                                }}
                                                clickable={
                                                  typeof header.linker ===
                                                  'function'
                                                }
                                                component={
                                                  typeof header.linker ===
                                                  'function'
                                                    ? Link
                                                    : 'div'
                                                }
                                                to={
                                                  typeof header.linker ===
                                                  'function'
                                                    ? header.linker(item)
                                                    : undefined
                                                }
                                              />
                                            )}
                                            {header.delimiter &&
                                              i < row[header.id].length - 1 &&
                                              header.delimiter}
                                          </span>
                                        );
                                      })}
                                    />
                                  )
                                ) : (
                                  formatter(
                                    header.id2 &&
                                      !(row[headerKey] instanceof Object)
                                      ? (row[headerKey]?.[header.id2]?.[
                                          headerKey
                                        ] ??
                                          row[headerKey]?.[header.id2]?.[
                                            header.id2
                                          ])
                                      : getter(row),
                                    header.table
                                      ? (dynamicSelects[header.table]?.data ??
                                          dynamicSelects[header.table])
                                      : undefined,
                                    header.table
                                      ? (dynamicSelects[header.table]?.count ??
                                          undefined)
                                      : undefined
                                  )
                                )
                              ) : (
                                ''
                              )}
                            </Box>
                            {header.copyable && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const valUnformatted =
                                    header.id2 &&
                                    !(row[headerKey] instanceof Object)
                                      ? (row[headerKey]?.[header.id2]?.[
                                          headerKey
                                        ] ??
                                        row[headerKey]?.[header.id2]?.[
                                          header.id2
                                        ])
                                      : getter(row);
                                  const valFormatted =
                                    formatter(valUnformatted);
                                  if (typeof valFormatted === 'string') {
                                    copy(valFormatted);
                                  } else {
                                    copy(valUnformatted);
                                  }
                                }}
                                sx={{
                                  opacity: 0,
                                  '&:hover': { opacity: 1 },
                                }}
                              >
                                <ContentCopy />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      );
                    })}
                    {!readOnly && (
                      <TableCell sx={cellSx}>
                        {actionLoading[row.id] ? (
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Box
                              sx={{
                                borderRadius: 8,
                                backdropFilter: 'blur(8px)',
                                display: 'flex',
                              }}
                            >
                              <CircularProgress
                                disableShrink
                                size={22}
                                thickness={4}
                              />
                            </Box>
                          </Box>
                        ) : (
                          <HoverActionButtonContainer
                            className={
                              actionLoading[row.id]
                                ? 'action-buttons-visible'
                                : ''
                            }
                          >
                            {typeof onEdit === 'function' && (
                              <IconButton
                                onClick={() => {
                                  onEdit(row);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            )}
                            {actions?.length > 0 && actionsEnabled(row) && (
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                {actions
                                  ?.filter(
                                    (action) =>
                                      [
                                        'button',
                                        'iconButton',
                                        'icon',
                                        'custom',
                                      ].includes(action.type) &&
                                      (!action.enabled ||
                                        (typeof action.enabled === 'function'
                                          ? action.enabled(row)
                                          : action.enabled))
                                  )
                                  ?.map((action) => {
                                    const setRowActionLoading =
                                      getSetActionLoadingByRowId(row.id);
                                    switch (action.type) {
                                      case 'button':
                                        return (
                                          <Button
                                            variant="text"
                                            key={action.label}
                                            onClick={async (e) => {
                                              setRowActionLoading(true);
                                              await action.onClick(row, e);
                                              setRowActionLoading(false);
                                            }}
                                          >
                                            {action.label}
                                          </Button>
                                        );
                                      case 'iconButton':
                                        return (
                                          <IconButton
                                            // sx={{ p: 0 }}
                                            key={action.label}
                                            onClick={async (e) => {
                                              setRowActionLoading(true);
                                              await action.onClick(row, e);
                                              setRowActionLoading(false);
                                            }}
                                          >
                                            {action.icon}
                                          </IconButton>
                                        );
                                      case 'icon':
                                        return (
                                          <Box
                                            key={action.label}
                                            sx={{
                                              height: 32,
                                              display: 'flex',
                                            }}
                                          >
                                            {action.icon}
                                          </Box>
                                        );
                                      case 'custom':
                                        return (
                                          <Box
                                            key={action.id}
                                            sx={{
                                              height: 32,
                                              display: 'flex',
                                            }}
                                          >
                                            {action.getComponent(row)}
                                          </Box>
                                        );
                                      default:
                                        return null;
                                    }
                                  })}
                                {actions?.filter(
                                  (action) =>
                                    ![
                                      'button',
                                      'iconButton',
                                      'icon',
                                      'custom',
                                    ].includes(action.type)
                                ).length > 0 && (
                                  <MoreMenu
                                    actions={actions.filter(
                                      (action) =>
                                        ![
                                          'button',
                                          'iconButton',
                                          'icon',
                                          'custom',
                                        ].includes(action.type)
                                    )}
                                    setActionLoading={getSetActionLoadingByRowId(
                                      row.id
                                    )}
                                    data={row}
                                  />
                                )}
                              </Box>
                            )}
                          </HoverActionButtonContainer>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {/* Totals row */}
            {showTotals && !isMobile && (
              <TableRow>
                <TableCell sx={{ pr: 0.5 }}>
                  <strong>Totals</strong>
                </TableCell>
                {columns.map((column, index) => (
                  <TableCell key={`Sum-${column}-${index}`} sx={{ px: 1 }}>
                    {sums[index] ? (
                      typeof sums[index] === 'object' ? (
                        <Box sx={{ minWidth: 250 }}>
                          <ExpandableData
                            data={Object.entries(sums[index]).map(
                              ([k, v]) =>
                                `${Formatter.contact(
                                  dynamicSelects?.contacts?.find(
                                    (c) => c.str_id === k
                                  ) ?? {},
                                  { account_id: selectedAccount?.accountId }
                                )}: ${Formatter.currency(v)}`
                            )}
                            header={headersFiltered[index]}
                          />
                        </Box>
                      ) : (
                        Formatter.currency(sums[index])
                      )
                    ) : null}
                  </TableCell>
                ))}
                {(typeof onEdit === 'function' || actions?.length > 0) &&
                  !readOnly && <TableCell sx={{ padding: 0, width: 0 }} />}
              </TableRow>
            )}
            {emptyRows > 0 && !isMobile && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginated &&
        (controlledPagination ? (
          <TablePagination
            rowsPerPageOptions={[50, 250, 500, 1000]}
            component="div"
            count={controlledPagination.count}
            rowsPerPage={controlledPagination.rowsPerPage}
            page={controlledPagination.page}
            onPageChange={controlledPagination.onPageChange}
            onRowsPerPageChange={controlledPagination.onRowsPerPageChange}
            sx={{
              '&.MuiTablePagination-root': {
                position: isMobile ? 'fixed' : 'static',
                bottom: 0,
                background: 'white',
              },
              '& .MuiTablePagination-toolbar': {
                minHeight: 42,
                height: 42,
              },
            }}
          />
        ) : (
          <TablePagination
            rowsPerPageOptions={[50, 250, 500, 1000]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              '&.MuiTablePagination-root': {
                position: isMobile ? 'fixed' : 'static',
                bottom: 0,
                background: 'white',
              },
              '& .MuiTablePagination-toolbar': {
                minHeight: 42,
                height: 42,
              },
            }}
          />
        ))}
    </Box>
  );
};

export default EnhancedTable;
