import { TransactionStatuses } from 'common/constants';
import { enumToSelectOptions } from 'common/utils';

import DataView from '@/components/DataView';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore, useRoleStore } from '@/store';
import { FieldTypes, Roles } from '@/types';

const View = () => {
  const { userRole } = useRoleStore();
  const { selectedAccount } = useAccountStore();

  const { data: contactsData, isLoading: isLoadingContacts } =
    API.getBasicQuery('contacts', 'is_dynamic_select=true');
  const contacts = contactsData?.data ?? [];

  const dataDesc = {
    label: 'Transactions',
    table: 'accounting/transactions',
    editable: true,
    copyable: true,
    bulkAdd: true,
    fields: [
      {
        id: 'str_id',
        label: 'ID',
        readOnly: true,
        condition: (val) => val?.str_id,
        visible: false,
      },
      {
        id: 'date',
        label: 'Date',
        type: FieldTypes.DATE,
        formatter: Formatter.date,
      },
      {
        id: 'contact_id',
        label: 'Agent name',
        type: 'dynamic-select',
        table: 'contacts',
        formatter: (val, collectionVals = []) => {
          if (val === '') return '';
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const record = collectionVals?.filter(
              (datum) => datum.id === val
            )?.[0];
            return record ? `${record.first_name} ${record.last_name}` : '';
          }
          return val;
        },
        optionFormatter: (option) => `${option.first_name} ${option.last_name}`,
        optionValuer: (option) => option?.id,
      },
      { id: 'amount', label: 'Amount', type: FieldTypes.CURRENCY },
      { id: 'notes', label: 'Notes' },
      {
        id: 'status',
        label: 'Status',
        type: FieldTypes.SELECT,
        options: enumToSelectOptions(TransactionStatuses),
      },
      {
        id: 'created_at',
        label: 'Created',
        formatter: Formatter.dateTime,
        condition: (val) => val.id,
        readOnly: true,
      },
      {
        id: 'updated_at',
        label: 'Last updated',
        formatter: Formatter.dateTime,
        condition: (val) => val.id,
        readOnly: true,
      },
    ],
    queryChips: {},
    queryChipsType: 'select',
  };

  if (Array.isArray(contacts) && contacts.length > 0) {
    contacts.forEach((contact, i) => {
      dataDesc.queryChips[contact.id] = {
        id: contact.id,
        label: Formatter.contact(contact, {
          account_id: selectedAccount?.accountId,
        }),
        query: { contact_id: contact.id },
      };
    });
  }

  const rolesWithEditAccess = [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST];

  return (
    <DataView
      dataDesc={dataDesc}
      readOnly={!userRole || !rolesWithEditAccess.includes(userRole)}
      viewOnly={!userRole || !rolesWithEditAccess.includes(userRole)}
    />
  );
};

export default View;
