import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import { IFieldsProps } from './process.d';

interface AddFieldsDialogProp {
  open: boolean;
  onClose: (v) => void;
  onConfirm: (e: IFieldsProps | undefined) => void;
  fieldsSource: IFieldsProps[];
}

const AddFieldsDialog = ({
  open,
  onClose,
  onConfirm,
  fieldsSource,
}: AddFieldsDialogProp) => {
  const [selectedFields, setSelectedFields] = useState<IFieldsProps>();

  const handleSelectionChange = (event: SelectChangeEvent<string>) => {
    const val = event.target.value;
    const item = fieldsSource.find((item) => item.key === val);
    setSelectedFields(item);
  };

  const handleSubmit = () => {
    onConfirm(selectedFields);
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Fields Manually</DialogTitle>
      <DialogContent>
        <Box>
          <Select
            fullWidth
            value={selectedFields?.key}
            onChange={handleSelectionChange}
          >
            {fieldsSource.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>{item.label}</Box>
                  <Box sx={{ color: '#aaa', fontSize: '12px' }}>{item.key}</Box>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={!selectedFields}
          variant="contained"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFieldsDialog;
