import { create } from 'zustand';

type State = {
  loginParam?: LoginBody;
};

type Setter = {
  setLoginParam: (param: LoginBody) => void;
};

const useLoginStore = create<State & Setter>((set) => ({
  setLoginParam: (p) => set({ loginParam: p }),
}));

export default useLoginStore;
