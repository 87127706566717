const findTextLineByIndex = (json, indexArr) => {
  return json.Lines[indexArr[0]].LinesArray[indexArr[1]].Line;
};

export const desc_findTextLineByIndex = `
Find the line that contains the given text in the JSON object by index.
eg:
findTextLineByIndex(json, [0, 1]) => 'text';
`;

export default findTextLineByIndex;
