import { GpsFixedOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  TextField,
  useMediaQuery,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 4;

interface MultiSelectProps<T, U> {
  label: string;
  values: T[];
  selectedValues: U[];
  setSelectedValues: (values: U[]) => void;
  formatter?: (val: T, isOpen: boolean) => string;
  valuer?: (val: T) => any;
  sx?: object;
  noall?: boolean;
  enableSearch?: boolean;
  paginate?: boolean;
  paginateStep?: number;
  sorted?: boolean;
}

const defaultFormatter = <T,>(val: T, isOpen: boolean) => String(val);
const defaultValuer = <T,>(val: T) => val;

const MultiSelect = <T, U>({
  label,
  values: _values = [],
  selectedValues = [],
  setSelectedValues,
  formatter = defaultFormatter,
  valuer = defaultValuer,
  sx = {},
  noall = false,
  enableSearch = false,
  paginate = false,
  paginateStep = 100,
  sorted = false,
}: MultiSelectProps<T, U>) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 18.5 + ITEM_PADDING_TOP,
        minWidth: 240,
        maxWidth: isMobile ? undefined : 640,
      },
    },
    MenuListProps: {
      style: {
        paddingBottom: 4,
      },
    },
    autoFocus: false,
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [visibleCount, setVisibleCount] = useState<number>(
    paginate ? paginateStep : 10000
  );

  const values = useMemo(() => {
    if (sorted) {
      return _values.sort((a, b) => {
        const aVal = formatter(a, isOpen);
        const bVal = formatter(b, isOpen);
        return aVal.localeCompare(bVal);
      });
    }
    return _values;
  }, [_values, formatter, isOpen, sorted]);

  const valuesMap = useMemo(
    () =>
      typeof values[0] === 'object'
        ? values.reduce((acc, cur) => {
            acc.set(valuer(cur), cur);
            return acc;
          }, new Map())
        : null,
    [values, valuer]
  );

  const valuesFiltered = useMemo(
    () =>
      values.filter((value) => {
        if (!query) return true;
        const valueFormatted = formatter(value, isOpen).toLowerCase();
        return query
          .toLowerCase()
          .split(' ')
          .map((q) => q.trim())
          .every((q) => valueFormatted.includes(q));
      }),
    [values, query, formatter, isOpen]
  );

  const valuesFilteredVisible = valuesFiltered?.slice(0, visibleCount);

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      if (value[value.length - 1] === 'all') {
        setSelectedValues(
          selectedValues.filter((val) => val !== '').length === values.length
            ? ['']
            : valuesMap
              ? Array.from(valuesMap.keys())
              : values
        );
      } else {
        setSelectedValues(typeof value === 'string' ? value.split(',') : value);
      }
    },
    [selectedValues, setSelectedValues, values, valuesMap]
  );

  const pickMePickMe = useCallback(
    (e, value) => {
      e.stopPropagation();
      setSelectedValues([value]);
    },
    [setSelectedValues]
  );

  return (
    <div style={{ width: '100%' }}>
      <FormControl
        sx={{
          ...sx,
          '.MuiInputLabel-shrink': { top: 0 },
        }}
      >
        <InputLabel
          id="multiple-checkbox-label"
          sx={{
            top: -3,
            ...(selectedValues.length === values.length
              ? {}
              : { color: '#2196f3' }),
          }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={
            Array.isArray(selectedValues)
              ? selectedValues.filter((val) => val !== '')
              : [selectedValues]
          }
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              const selectedWithoutEmpty = selected.filter((val) => val !== '');
              return selectedWithoutEmpty.length === values.length
                ? `All (${selectedWithoutEmpty.length})`
                : selectedWithoutEmpty.length === 0
                  ? 'None'
                  : `(${selectedWithoutEmpty.length}) ${
                      valuesMap
                        ? selectedWithoutEmpty
                            .map((item) =>
                              formatter(valuesMap.get(item), isOpen)
                            )
                            .join(', ')
                        : selectedWithoutEmpty.join(', ')
                    }`;
            }
            return selected;
          }}
          MenuProps={MenuProps}
          sx={{
            '.MuiSelect-select': {
              py: 0.75,
              px: 1.5,
            },
            ...(selectedValues.length === values.length
              ? {}
              : {
                  color: '#2196f3',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#2196f3',
                  },
                  '.MuiSvgIcon-root ': { fill: '#2196f3' },
                }),
          }}
        >
          {enableSearch && (
            <Box
              sx={{
                mb: 0.5,
                mx: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              <TextField
                sx={{ flex: 1 }}
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onClickCapture={(e) => {
                  e.stopPropagation();
                }}
                placeholder="Search"
              />
            </Box>
          )}
          {!noall && (
            <MenuItem value="all" dense>
              <Checkbox
                checked={selectedValues.length === values.length}
                sx={{ height: 24 }}
              />
              <ListItemText primary="All" />
            </MenuItem>
          )}
          {valuesFilteredVisible.map((value) => {
            return (
              <MenuItem key={valuer(value)} value={valuer(value)} dense>
                <Checkbox
                  checked={selectedValues.indexOf(valuer(value)) > -1}
                  sx={{ height: 24 }}
                />
                <ListItemText primary={formatter(value, isOpen)} />
                <IconButton
                  sx={{
                    ml: 1,
                    opacity: 0,
                    '&:hover': { opacity: 1, color: '#2196f3' },
                  }}
                  onClick={(e) => pickMePickMe(e, valuer(value))}
                >
                  <GpsFixedOutlined sx={{ width: 16, height: 16 }} />
                </IconButton>
              </MenuItem>
            );
          })}
          {valuesFiltered?.length > visibleCount && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                mt: 0.5,
              }}
            >
              <Button
                onClick={() => {
                  setVisibleCount(visibleCount + paginateStep);
                }}
              >
                Show more ({valuesFiltered?.length - visibleCount})
              </Button>
            </Box>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultiSelect;
