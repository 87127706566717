import { useState } from 'react';
import { captureException } from '@sentry/react';

import API from '@/services/API';

export const useUploadStorageFileV2 = () => {
  const fileSignedUrlLoader = API.getMutation('storage/getSignedUrl', 'POST');
  const [uploadStatus, setUploadStatus] = useState<{
    [filename: string]: UploadState;
  }>({});

  const uploadFile = async ({
    file: { path, file },
  }: {
    file: UploadFileInfo;
  }) => {
    try {
      setUploadStatus((prev) => ({ ...prev, [file.name]: 'uploading' }));
      const { data: signedUrl } = await fileSignedUrlLoader.mutateAsync({
        file_path: path,
        file_type: file.type,
        action: 'write',
      });

      await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          'Content-Length': file.size.toString(),
        },
        body: file,
      });
      setUploadStatus((prev) => ({ ...prev, [file.name]: 'done' }));
    } catch (error: any) {
      captureException(error);
      setUploadStatus((prev) => ({ ...prev, [file.name]: 'failed' }));
      throw error;
    }
  };

  return { uploadFile, uploadStatus };
};
