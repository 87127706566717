import { Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { AccountIds } from 'common/constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAccountStore, useRoleStore } from '@/store';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import Statements from '@/services/Statements';
import { Roles } from '@/types';
import API from '@/services/API';
import { ReconciliationConfirmProvider } from '@/contexts/ReconciliationConfirmProvider';
import { UIStateContext } from '@/contexts/UIStateProvider';

const exportOptions = [
  {
    id: 'export',
    label: 'Export',
    options: {},
  },
  {
    id: 'export-producer-view',
    label: 'Export producer view',
    options: {
      producer_view: true,
      disabled: true,
      tooltip: 'Please select an Agent in the Agents filter to enable.',
    },
  },
];

const CommissionsDataView = ({ reportId = null }) => {
  const {
    role: [role],
  } = useContext(UIStateContext);
  const { selectedAccount } = useAccountStore();
  const { userRole } = useRoleStore();
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);
  const mode = selectedAccount?.accountMode;
  const statements = new Statements(mode, role, userRole, {
    account_id: selectedAccount?.accountId,
  });
  const [filterCacheKey, setFilterCacheKey] = useState([]);
  const queryClient = useQueryClient();
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  const pageSettingFields = isMobile
    ? accountSettings?.pages_settings?.commissions?.outstandingMobileFields
    : accountSettings?.pages_settings?.commissions?.fields;

  const newFields = (pageSettingFields ?? []).reduce((acc, cur) => {
    acc[cur] = {
      ...statements.fields[cur],
    };
    if (
      role === 'admin' &&
      selectedAccount?.accountId === AccountIds.TRANSGLOBAL
    ) {
      if (cur == 'agent_commissions_log') {
        acc.expected_result = statements.fields.expected_result;
      }
    }
    return acc;
  }, {});

  if (Object.keys(newFields).length > 0) statements.fields = newFields;

  statements.dateFilters = statements.dateFilters.filter((filter) => {
    return pageSettingFields?.includes(filter.filterFieldId);
  });

  if (accountSettings?.pages_settings?.commissions?.page_label) {
    statements.label = accountSettings?.pages_settings?.commissions?.page_label;
  }

  if (
    isFetchedAccountSettings &&
    accountSettings?.pages_settings?.commissions?.show_page === false
  ) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/policies" />
      // <Box sx={{ textAlign: 'center', mt: 6, width: '100%' }}>
      //   <Typography variant="h5">No results</Typography>
      // </Box>
    );
  }
  const [searchParams, _] = useSearchParams();
  useEffect(() => {
    // Cancel previous query when searchParams or selectedAccount changes, avoid previous query from returning stale data and causing UI mismatch
    setFilterCacheKey((prev) => {
      const cacheKey = [
        selectedAccount?.accountId,
        // Filter out pagination & order params from searchParams
        new URLSearchParams(
          Array.from(searchParams.entries()).filter(
            ([key]) => !['limit', 'page', 'sort', 'orderBy'].includes(key)
          )
        ).toString(),
      ];
      if (prev.length > 0 && prev.join() !== cacheKey.join()) {
        queryClient.cancelQueries({ queryKey: prev });
      }
      return cacheKey;
    });
  }, [queryClient, searchParams, selectedAccount?.accountId]);
  const { data: filters } = useQuery({
    queryKey: filterCacheKey,
    queryFn: async () => {
      const url = `${process.env.REACT_APP_API}/api/statement_data/filters?${new URLSearchParams(searchParams).toString()}`;
      const res = await fetch(url, {
        method: 'GET',
        headers: await API.getHeaders(),
      });
      return res.json();
    },
  });

  const handleCreatePolicy = (commissionData) => {
    // Extract relevant fields from commission data to pre-populate report
    const reportData = {
      agent_name: commissionData.agent_name,
      customer_name: commissionData.customer_name,
      contacts: commissionData.contacts,
      policy_id: commissionData.policy_id,
      effective_date: commissionData.effective_date,
      geo_state: commissionData.geo_state,
      issue_age: commissionData.issue_age,
      notes: commissionData.notes,
      payment_mode: commissionData.payment_mode,
      product_name: commissionData.product_name,
      product_type: commissionData.product_type,
      premium_amount: commissionData.premium_amount,
      transaction_type: commissionData.transaction_type,
      writing_carrier_name: commissionData.writing_carrier_name,
      commissionable_premium_amount:
        commissionData.commissionable_premium_amount,
      // Add any other fields that should be copied from commission to report
    };

    // Navigate to reports page with pre-populated data
    navigate('/policies?m=edit', {
      state: {
        mode: 'edit',
        prefillData: reportData,
      },
    });
  };

  return selectedAccount && isFetchedAccountSettings ? (
    <ReconciliationConfirmProvider mode={mode}>
      <EnhancedDataView
        reportId={reportId}
        dataSpec={statements}
        actions={statements.actions}
        actionsEnabled={() => true}
        filters={filters}
        exportOptions={exportOptions}
        outstandingMobileFields={
          accountSettings?.pages_settings?.commissions?.outstandingMobileFields
        }
        enableSaves
        showTotals
        // TODO (frank.santillan): Move to settings after we migrate reconciliation / commissions / policies to pages_settings.
        readOnly={userRole === Roles.PRODUCER}
        extraFormActions={[
          {
            label: 'Create policy',
            onClick: handleCreatePolicy,
            variant: 'text',
            showInEdit: true, // Only show in edit mode
          },
        ]}
      />
    </ReconciliationConfirmProvider>
  ) : null;
};

export default CommissionsDataView;
