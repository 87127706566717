import React, { useCallback, useContext } from 'react';

import { GlobalSnackbarContext } from '@/contexts/SnackbarContext';

const useSnackbar = () => {
  const { setGlobalSnackbarConfig } = useContext(GlobalSnackbarContext);

  const showSnackbar = useCallback(
    (
      message: string | React.ReactElement,
      severity?: 'info' | 'success' | 'error' | 'warning',
      autoHideDuration?: number
    ) => {
      setGlobalSnackbarConfig({
        open: true,
        message,
        severity,
        autoHideDuration: autoHideDuration || 8000,
      });
    },
    [setGlobalSnackbarConfig]
  );

  return { showSnackbar };
};

export default useSnackbar;
