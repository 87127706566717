import React, { useState } from 'react';
import {
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Autocomplete,
  Box,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const FieldAggregationSelector = ({
  fields,
  selectedField,
  onRemove,
  onUpdate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFormatter, setAnchorElFormatter] = useState(null);
  const [selectedAggregation, setSelectedAggregation] = useState(
    selectedField.aggregation_method || ''
  );
  const [selectedFieldValue, setSelectedFieldValue] = useState(
    selectedField.field || ''
  );

  const [selectedFieldFormatter, setSelectedFieldFormatter] = useState(
    selectedField.formatter || ''
  );

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFormatterMenuOpen = (event) => {
    setAnchorElFormatter(event.currentTarget);
  };

  const handleFormatterMenuClose = () => {
    setAnchorElFormatter(null);
  };

  const handleAggregationChange = (method) => {
    setSelectedAggregation(method);
    onUpdate({ ...selectedField, aggregation_method: method });
    handleMenuClose();
  };

  const handleFormatterChange = (method) => {
    setSelectedFieldFormatter(method);
    onUpdate({ ...selectedField, formatter: method });
  };

  const handleFieldChange = (event, value) => {
    setSelectedFieldValue(value);
    onUpdate({ ...selectedField, field: value });
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '5px',
      }}
    >
      <Autocomplete
        options={fields.map((field) => field.name)}
        value={selectedFieldValue}
        style={{ width: '60%' }}
        onChange={handleFieldChange}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" size="small" />
        )}
      />

      <Box ml={1} display={'flex'} alignItems={'center'}>
        {selectedAggregation}
        <Tooltip title="Aggregation method">
          <IconButton onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {selectedFieldFormatter}
        <Tooltip title="Result formatter">
          <IconButton onClick={handleFormatterMenuOpen}>
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
        <IconButton onClick={onRemove}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleAggregationChange('Sum')}>Sum</MenuItem>
        <MenuItem onClick={() => handleAggregationChange('Average')}>
          Average
        </MenuItem>
        <MenuItem onClick={() => handleAggregationChange('Count')}>
          Count
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorElFormatter}
        open={Boolean(anchorElFormatter)}
        onClose={handleFormatterMenuClose}
      >
        <MenuItem onClick={() => handleFormatterChange('currency')}>
          Currency
        </MenuItem>
        <MenuItem onClick={() => handleFormatterChange('percentage')}>
          Percentage
        </MenuItem>
        <MenuItem onClick={() => handleFormatterChange('number')}>
          Number
        </MenuItem>
      </Menu>
    </div>
  );
};

export default FieldAggregationSelector;
