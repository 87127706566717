import { Add, Delete, Edit } from '@mui/icons-material';
import { Button, Chip, IconButton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';

import { BasicDialog } from '@/common';
import DataView from '@/components/DataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import ProcessorModal from '@/views/ProcessorPlayground';
import Formatter from '@/services/Formatter';
import {
  headerSx,
  cellSx,
} from '@/components/HoverActionButtonContainer/styles';
import { HoverActionButtonContainer } from '@/components/HoverActionButtonContainer';

const ProcessorsView = ({ variant = '' }) => {
  const [showModal, setShowModal] = useState(false);

  /**
   * @type {Object}
   */
  const [currentRow, setCurrentRow] = useState(null);
  const [refresh, setRefresh] = useState(0);

  // show comfirm dialog when deleting
  const [showConfirm, setShowConfirm] = useState(false);

  const deleter = API.getMutation('processors', 'DELETE');
  const { data: owners = [] } = API.getBasicQuery('users/get_fintary_admins');

  const { setLoadingConfig } = useContext(LoadingContext);

  const statusFormatter = (s, row) => {
    const statusChip = Formatter.statusChip(s, {
      mapping: {
        approved: 'green',
        in_review: 'yellow',
        need_update: 'red',
      },
    });
    return (
      <>
        {statusChip}
        {row.processor_status === 'processed' && (
          <Tooltip title="This processor is already used to process a document. If you update it now, we will create a new processor for you so that it will not affect the already processed document.">
            <Chip
              sx={{
                color: '#014361',
                backgroundColor: '#e5f1fd',
                borderRadius: 1,
                m: 0.25,
              }}
              size="small"
              label="In use"
            />
          </Tooltip>
        )}
      </>
    );
  };

  const extraActions = (
    <Button
      variant="contained"
      startIcon={<Add />}
      onClick={() => {
        setCurrentRow(null);
        setShowModal(true);
      }}
    >
      Add
    </Button>
  );

  const dataDesc = {
    label: 'Processors',
    table: 'processors',
    editable: false,
    fields: [
      {
        id: 'type',
        label: 'Type',
        type: 'select',
        options: ['statement', 'report'],
      },
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'company_id',
        label: 'Company',
        formatter: (s, row) => (s ? row.companies?.company_name : ''),
      },
      {
        id: 'access',
        label: 'Access',
        access: 'admin',
      },
      {
        id: 'owner',
        label: 'Owner',
        formatter: (s) => {
          const target = owners?.find((c) => c.uid === s);
          return Formatter.contact(target);
        },
      },
      {
        id: 'status',
        label: 'Status',
        access: 'admin',
        formatter: statusFormatter,
      },
      {
        id: 'reviewer_str_id',
        label: 'Reviewer',
        access: 'admin',
        formatter: (s, row) =>
          s ? Formatter.contact(row.users_processors_reviewed_byTousers) : '',
      },
      {
        id: 'updated_at',
        label: 'Last updated',
        formatter: (s) => Formatter.date(s, { format: 'MM/DD/YY hh:mmA' }),
        readOnly: true,
      },
      {
        id: 'id',
        label: 'Actions',
        access: 'admin',
        sx: cellSx,
        headerSx,

        /**
         *
         * @param {number} id
         * @param {*} row
         * @returns
         */
        formatter: (id, row) => (
          <HoverActionButtonContainer>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setCurrentRow(row);
                setShowModal(true);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={async () => {
                setCurrentRow(row);
                setShowConfirm(true);
              }}
            >
              <Delete />
            </IconButton>
          </HoverActionButtonContainer>
        ),
      },
    ],
  };

  const closeModal = (e, reason) => {
    if (reason === 'backdropClick') return;
    setShowModal(false);
    setRefresh(refresh + 1);
  };

  return (
    <>
      <DataView
        dataDesc={dataDesc}
        viewOnly
        extraActions={extraActions}
        refresh={refresh}
        enableAutoRefetch={true}
        variant={variant}
      />
      {showModal && (
        <ProcessorModal
          handleCancel={closeModal}
          open={showModal}
          rowData={currentRow}
        />
      )}
      {showConfirm && (
        <BasicDialog
          open={showConfirm}
          bodyComponent={
            <div>
              <h4>
                Are you sure you want to delete the processor code{' '}
                <span className="text-blue-500 pr-1">
                  {currentRow?.name || ''}
                </span>
                ?
              </h4>
              <p className="text-sm">❗❗❗ This action cannot be undone.</p>
            </div>
          }
          title="Processor Delete"
          onClose={async (val) => {
            setShowConfirm(false);
            if (val) {
              setLoadingConfig({
                loading: true,
                message: 'Deleting...',
              });
              await deleter.mutateAsync({ ids: [currentRow.id] });
              setRefresh(refresh + 1);
              setLoadingConfig({
                loading: false,
                delayToClose: 1000,
              });
            }
          }}
        />
      )}
    </>
  );
};

export default ProcessorsView;
