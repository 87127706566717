import { ButtonGroup, SxProps } from '@mui/material';

import { actionButtonsStyle } from './styles';

export const HoverActionButtonContainer = ({
  children,
  className,
  alwaysShow,
}: {
  children: React.ReactNode;
  className?: string;
  alwaysShow?: boolean;
}) => {
  let defaultClassName = 'action-buttons';
  let sx: SxProps = {};
  if (alwaysShow) {
    defaultClassName = '';
    sx = {
      width: 'auto',
      overflow: 'visible',
    };
  }

  return (
    <ButtonGroup
      variant="contained"
      className={`${className || ''} flex ${defaultClassName}`}
      sx={{ ...actionButtonsStyle, ...(sx as any) }}
    >
      {children}
    </ButtonGroup>
  );
};
