import { Theme } from '@mui/material/styles';

import SignInDialog from '@/components/SignInDialog';
import SignUpDialog from '@/components/SignUpDialog';
import EmailVerificationDialog from '@/components/EmailVerificationDialog';

interface DialogProps {
  dialogProps: {
    onClose: (callback?: () => void) => void;
    open: boolean;
    email?: string;
  };
}

interface Dialogs {
  signUpDialog: DialogProps;
  signInDialog: DialogProps;
  emailVerificationDialog: DialogProps;
}

interface User {
  email: string;
  uid: string;
  emailVerified: boolean;
}

interface DialogHostProps {
  theme: Theme;
  user: User | null;
  dialogs: Dialogs;
}

const DialogHost: React.FC<DialogHostProps> = ({ theme, user, dialogs }) => {
  const { signUpDialog } = dialogs;
  const { signInDialog } = dialogs;
  const { emailVerificationDialog } = dialogs;

  return (
    <>
      {!user && (
        <>
          <SignUpDialog dialogProps={signUpDialog.dialogProps} theme={theme} />

          <SignInDialog dialogProps={signInDialog.dialogProps} theme={theme} />
        </>
      )}
      {!user?.emailVerified && (
        <>
          <EmailVerificationDialog
            dialogProps={emailVerificationDialog.dialogProps}
            theme={theme}
          />
        </>
      )}
    </>
  );
};

export default DialogHost;
