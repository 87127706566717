import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ReactElement, useState } from 'react';
import validate from 'validate.js';
import { Theme } from '@mui/material/styles';

import AuthProviderList from '@/components/AuthProviderList';
import constraints from '@/data/constraints';
import authentication from '@/services/authentication';
import { removeURLQueryParameters } from '@/services/helpers';
import useSnackbar from '@/contexts/useSnackbar';

type Provider = 'google'; // Add more providers as needed

interface User {
  email: string;
  uid: string;
}

interface State {
  performingAction: boolean;
  emailAddress: string;
  password: string;
  emailForSSOValidated: boolean;
  errors: {
    emailAddress?: string;
    password?: string;
  } | null;
}

interface SignInDialogProps {
  dialogProps: {
    onClose: (callback?: () => void) => void;
    open: boolean;
    email?: string;
  };
  theme: Theme;
}

type InputEvent = React.ChangeEvent<HTMLInputElement>;
type KeyPressEvent = React.KeyboardEvent<HTMLDivElement>;

const SignInDialog = ({ dialogProps, theme }: SignInDialogProps) => {
  const initialState: State = {
    performingAction: false,
    emailForSSOValidated: false,
    emailAddress: dialogProps.email || '',
    password: '',
    errors: null,
  };
  const [state, setState] = useState<State>(initialState);
  const userLoginEmail = dialogProps.email || '';

  const StyledIconButton = styled(IconButton)({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  });

  const StyledDivider = styled(Divider)({
    margin: 'auto',
  });

  const { showSnackbar } = useSnackbar();

  const getSignInButton = (): ReactElement => {
    const { emailAddress, password, performingAction } = state;

    if (emailAddress && !password) {
      return (
        <Button
          color="primary"
          disabled={!emailAddress || performingAction}
          variant="contained"
          onClick={sendSignInLinkToEmail}
        >
          Send sign-in link
        </Button>
      );
    }

    return (
      <Button
        color="primary"
        disabled={!emailAddress || performingAction}
        variant="contained"
        onClick={signIn}
      >
        Sign in
      </Button>
    );
  };

  const resetPassword = () => {
    const { emailAddress } = state;

    const errors = validateEmailAndPassword(emailAddress);

    if (errors) {
      setState({ ...state, errors });
    } else {
      setState({ ...state, errors: null });

      authentication
        .resetPassword(emailAddress)
        .then((v) => {
          showSnackbar(`Sent password reset e-mail to ${emailAddress}`, 'info');
        })
        .catch((reason) => {
          const { message } = reason;

          showSnackbar(message, 'error');
        })
        .finally(() => {
          setState({ ...state, performingAction: false });
        });
    }
  };

  const validateEmailAndPassword = (
    emailAddress: string,
    password?: string
  ) => {
    if (password) {
      return validate(
        {
          emailAddress,
          password,
        },
        {
          emailAddress: constraints.emailAddress,
          password: constraints.password,
        }
      );
    }
    return validate(
      {
        emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );
  };

  const signIn = (): void => {
    const { emailAddress, password } = state;

    const errors = validateEmailAndPassword(emailAddress, password);

    if (errors) {
      setState({ ...state, errors });
    } else {
      setState((prevState) => ({
        ...prevState,
        performingAction: true,
        errors: null,
      }));

      authentication
        .signIn(emailAddress, password)
        .then((user: User) => {
          removeURLQueryParameters(['login-type', 'email']);

          dialogProps.onClose(() => {
            const { email } = user;
            showSnackbar(`Signed in as ${email}`, 'success');
          });
        })
        .catch((reason) => {
          const { message } = reason;
          showSnackbar(
            message?.replace('Firebase: ', '') || 'An error occurred',
            'error'
          );
        })
        .finally(() => {
          setState((prevState) => ({
            ...prevState,
            performingAction: false,
          }));
        });
    }
  };

  const sendSignInLinkToEmail = () => {
    const { emailAddress } = state;

    const errors = validateEmailAndPassword(emailAddress);

    if (errors) {
      setState({ ...state, errors });
      return;
    }

    setState({ ...state, performingAction: true, errors: null });

    authentication
      .signInLinkToEmail(emailAddress)
      .then(() => {
        dialogProps.onClose(() => {
          showSnackbar(`Sent sign-in e-mail to ${emailAddress}`);
        });
      })
      .catch((reason) => {
        const { message } = reason;
        showSnackbar(message, 'error');
      })
      .finally(() => {
        setState({ ...state, performingAction: false });
      });
  };

  const signInWithAuthProvider = (provider: Provider) => {
    setState({ ...state, performingAction: true });
    authentication
      .signInWithAuthProvider(provider)
      .then((user: User) => {
        dialogProps.onClose(() => {
          showSnackbar(`Signed in as ${user.email}`, 'success');
        });
      })
      .catch((reason) => {
        const { message } = reason;
        showSnackbar(message, 'error');
      })
      .finally(() => {
        setState({ ...state, performingAction: false });
      });
  };

  const handleKeyPress = (event: KeyPressEvent) => {
    const { emailAddress, password } = state;

    if (!emailAddress && !password) {
      return;
    }

    const { key } = event;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      if (!emailForSSOValidated && emailAddress) {
        validateEmailForSSO();
      } else if (emailForSSOValidated && emailAddress && !password) {
        sendSignInLinkToEmail();
      } else {
        signIn();
      }
    }
  };

  const handleExited = () => {
    setState(initialState);
  };

  const handleEmailAddressChange = (event: InputEvent) => {
    const emailAddress = event.target.value;
    setState((prev) => ({
      ...prev,
      emailAddress,
    }));
  };

  const validateEmailForSSO = () => {
    // todo: redirect to SSO login page if email is sso enabled
    setState((prev) => ({
      ...prev,
      emailForSSOValidated: true,
    }));
  };

  const handlePasswordChange = (event: InputEvent) => {
    const password = event.target.value;
    setState((prev) => ({
      ...prev,
      password,
    }));
  };

  // Dialog Properties
  const {
    performingAction,
    emailAddress,
    password,
    emailForSSOValidated,
    errors,
  } = state;

  return (
    <Dialog
      open={dialogProps.open}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown={performingAction}
      onKeyPress={handleKeyPress}
      TransitionProps={{
        onExited: handleExited,
      }}
      onClose={() => {
        dialogProps.onClose();
      }}
      sx={{
        [theme.breakpoints.down('sm')]: {
          '& .MuiDialog-container .MuiDialog-paper': {
            margin: 2,
            width: '100%',
          },
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6" component="div">
          Sign in to your account
        </Typography>

        <Tooltip title="Close">
          <span>
            <StyledIconButton
              disabled={performingAction}
              onClick={(event) => {
                event.preventDefault();
                dialogProps.onClose();
              }}
              size="large"
            >
              <CloseIcon />
            </StyledIconButton>
          </span>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="row" sx={{ mt: 1 }}>
          <Grid item xs={4}>
            <AuthProviderList
              performingAction={performingAction}
              onAuthProviderClick={signInWithAuthProvider}
            />
          </Grid>

          <Grid item xs={1} sx={{ display: 'flex' }}>
            <StyledDivider orientation="vertical" />
          </Grid>

          <Grid item xs={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs>
                <TextField
                  autoComplete="email"
                  disabled={!!(performingAction || userLoginEmail)}
                  error={!!(errors && errors.emailAddress)}
                  fullWidth
                  helperText={
                    errors && errors.emailAddress ? errors.emailAddress[0] : ''
                  }
                  label="E-mail address"
                  placeholder="john@doe.com"
                  required
                  type="email"
                  value={emailAddress}
                  InputLabelProps={{ required: false }}
                  onChange={handleEmailAddressChange}
                />
              </Grid>
              {emailForSSOValidated && (
                <Grid item xs>
                  <TextField
                    autoComplete="current-password"
                    disabled={performingAction}
                    error={!!(errors && errors.password)}
                    fullWidth
                    helperText={
                      errors && errors.password ? errors.password[0] : ''
                    }
                    label="Password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    type="password"
                    value={password}
                    InputLabelProps={{ required: false }}
                    onChange={handlePasswordChange}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {emailForSSOValidated ? (
          <>
            <Button
              color="primary"
              disabled={!emailAddress || performingAction}
              variant="outlined"
              onClick={resetPassword}
            >
              Reset password
            </Button>

            {getSignInButton()}
          </>
        ) : (
          <Button
            color="primary"
            variant="contained"
            disabled={!emailAddress || performingAction}
            onClick={validateEmailForSSO}
          >
            Continue
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

SignInDialog.propTypes = {
  dialogProps: PropTypes.shape({
    onClose: PropTypes.func.isRequired,
  }).isRequired,
  theme: PropTypes.object.isRequired as React.Validator<Theme>,
};

export default SignInDialog;
