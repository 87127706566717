import { Clear, Search } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import EmptyState from '@/components/EmptyState';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import EnhancedTable from '@/components/molecules/EnhancedTable';
import { ReactComponent as NoDataIllustration } from '@/illustrations/no-data.svg';
import API from '@/services/API';
import Datastore from '@/services/Datastore';
import useSnackbar from '@/contexts/useSnackbar';

const dataDesc = {
  label: 'Transactions',
  collection: 'bankingRecords',
  editable: false,
  fields: [
    { label: 'Date', id: 'date' },
    { label: 'Name', id: 'name' },
    { label: 'Currency', id: 'iosCurrencyCode' },
    { label: 'Amount', id: 'amount' },
    { label: 'Payment Channel', id: 'paymenntChannel' },
    { label: 'Transaction ID', id: 'transactionId' },
    {
      label: 'Pending',
      id: 'pending',
      formatter: (val) => (val || val === 'true' ? 'Yes' : 'No'),
    },
  ],
};

const TransactionsListView = () => {
  const [query, setQuery] = useState('');
  const [linkToken, setLinkToken] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(true);
  const [fetchPlaidLinkToken, setFetchPlaidLinkToken] = useState(false);
  const { data: generalSettings } = API.getUser();
  const { data: linkTokenData } = API.getBasicQuery(
    'transactions/plaid/linkToken',
    '',
    fetchPlaidLinkToken
  );
  const exchangePublicToken = API.getMutation(
    'transactions/plaid/exchangePublicToken',
    'POST'
  );
  const plaidAuth = API.getMutation('transactions/plaid/auth', 'POST');
  const plaidTransactions = API.getMutation(
    'transactions/plaid/transactions',
    'POST'
  );

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    setLinkToken(linkTokenData.data);
  }, [linkTokenData]);

  const parseTransactionsForSaving = (transactions, userId) => {
    const parsedTransactions = [];
    transactions.forEach((transaction) => {
      parsedTransactions.push({
        id: transaction.transaction_id,
        transactionId: transaction.transaction_id,
        accountId: transaction.account_id,
        uid: userId,
        amount: transaction.amount,
        date: transaction.date,
        name: transaction.name,
        category: transaction.category,
        categoryId: transaction.category_id,
        pending: transaction.pending,
        pendingTransactionId: transaction.pending_transaction_id,
        accountOwner: transaction.account_owner,
        iosCurrencyCode: transaction.iso_currency_code,
        merchantName: transaction.merchant_name,
        paymenntChannel: transaction.payment_channel,
        transactionType: transaction.transaction_type,
        raw: JSON.stringify(transaction),
      });
    });
    return parsedTransactions;
  };

  const getTransactionsFromPlaid = useCallback(
    async (accessToken, userId) => {
      try {
        const res = await plaidTransactions.mutateAsync({
          access_token: accessToken,
          start_date: '2022-01-01',
          end_date: new Date().toISOString().substring(0, 10),
        });
        const parsed = parseTransactionsForSaving(res.data.data, userId);
        const promises = [];
        parsed.forEach((transaction) => {
          promises.push(
            Datastore.addOrUpdateData('bankingRecords', transaction)
          );
        });
        await Promise.all(promises);
        setTransactions(parsed);
      } catch (e) {
        console.error('Error retrieving transctions', e);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [plaidTransactions]
  );

  const getPlaidAuthAndAccounts = async (accessToken) => {
    await plaidAuth.mutateAsync({ access_token: accessToken });
  };

  const exchangePlaidPublicTokenForPermanentAccessToken = async (
    publicToken
  ) => {
    const res = await exchangePublicToken.mutateAsync({
      public_token: publicToken,
    });
    const accessToken = res.data.data;
    Datastore.updateData('settings', {
      id: userId,
      plaidAccessToken: accessToken,
    });
    await getPlaidAuthAndAccounts(accessToken);
    await getTransactionsFromPlaid(accessToken);
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token) => {
      setLoading(true);
      exchangePlaidPublicTokenForPermanentAccessToken(public_token);
    },
  });

  const getPlaidLinkToken = () => {
    setFetchPlaidLinkToken(true);
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      getPlaidLinkToken();
      // TRY TO GET DATA FROM FIRESTORE
      // const data = await Datastore.getData('bankingRecords', ['all']);
      const data = [];
      if (data && data.length > 0) {
        setTransactions(data);
        setFilteredTransactions(data);
        setLoading(false);
      } else if (generalSettings) {
        try {
          // CHECK FOR GENERAL SETTINGS
          setUserId(generalSettings.id); // SER USER ID
          if (generalSettings.plaidAccessToken) {
            // GET DATA FROM PLAIN
            await getTransactionsFromPlaid(
              generalSettings.plaidAccessToken,
              generalSettings.id
            );
          } else {
            // OTHER WISE START PLAID LINK
            await getPlaidLinkToken();
            setLoading(false);
          }
        } catch (e) {
          console.error('Error retrieving transactions', e);
          showSnackbar('Error retrieving transactions', 'error');
        } finally {
          setLoading(false);
        }
      }
    };
    init();
  }, [generalSettings, getTransactionsFromPlaid]);

  useEffect(() => {
    setFilteredTransactions(
      query
        ? transactions.filter((transaction) => {
            return transaction.name.toLowerCase().includes(query.toLowerCase());
          })
        : transactions
    );
  }, [query, transactions]);

  return (
    <Box mx={2} mt={2} sx={{ width: 'calc(100vw - 232px)' }}>
      <Box>
        <Box
          display="flex"
          marginBottom={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">{dataDesc.label}</Typography>
          <Box display="flex" alignItems="center">
            <TextField
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{ mr: 1 }}
              InputProps={{
                startAdornment: <Search sx={{ opacity: 0.5 }} />,
                endAdornment: (
                  <IconButton onClick={() => setQuery('')}>
                    <Clear sx={{ opacity: 0.5 }} />
                  </IconButton>
                ),
              }}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={() => open()}
              disabled={!ready}
            >
              Sync Transactions
            </Button>
          </Box>
        </Box>
        {loading && <LoadingCircle />}

        {!loading &&
          filteredTransactions &&
          filteredTransactions.length > 0 && (
            <EnhancedTable
              dense
              headers={dataDesc.fields}
              rows={filteredTransactions}
              stickyHeader
              actionsEnabled={(v) => false}
            />
          )}

        {!loading &&
          (!filteredTransactions ||
            (filteredTransactions.length <= 0 && (
              <EmptyState
                type="page"
                size="large"
                image={
                  <NoDataIllustration
                    style={{ height: '300', width: '300', margin: 'auto' }}
                  />
                }
                title="No Transactions"
                description="Looks like you haven't linked your bank account to Fintary yet. Please click the button below to link your bank account and allow us to securely fetch your transactions using Plaid."
                button={
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => open()}
                    disabled={!ready}
                    style={{ marginTop: 20 }}
                  >
                    Link Bank Account
                  </Button>
                }
              />
            )))}
      </Box>
    </Box>
  );
};

export default TransactionsListView;
