import { BookmarkBorder, Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';

const SaveReport = ({
  sx = {},
  queryData,
  getDataUrl,
  reportId = null,
  table,
  setSavingReport,
}) => {
  const { showSnackbar } = useSnackbar();

  const [anchorElView, setAnchorElView] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportName, setReportName] = useState('');
  const [viewName, setViewName] = useState('');
  const [page, setPage] = useState('');
  const [params, setParams] = useState({
    params: '',
    queryData: '',
  });

  const openView = Boolean(anchorElView);
  const idView = openView ? 'simple-popover' : undefined;

  const handleClickView = (event) => {
    setAnchorElView(event.currentTarget);
  };
  const handleCloseView = () => {
    setAnchorElView(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const poster = API.getMutation(`saved_reports`, 'POST');

  useEffect(() => {
    setParams({
      params: window.location.search,
      queryData: JSON.stringify(queryData),
    });

    if (table === 'statement_data') {
      setPage('commissions');
    } else if (table === 'report_data') {
      setPage('policies');
    } else if (table === 'reconciliation_data') {
      setPage('reconciliation');
    }
  }, [window.location.search, window.location.pathname, queryData]);

  const onSave = async (type) => {
    if (type === 'report') {
      setSavingReport(true);
      showSnackbar('Saving report...');
      const queryDataSend = params.queryData;
      const res = await fetch(
        `${process.env.REACT_APP_API}/api/saved_reports?${
          params.params.split('?')[1]
        }`,
        {
          method: 'POST',
          headers: await API.getHeaders(),
          body: JSON.stringify({
            reportId: reportId,
            getDataUrl,
            page,
            queryData: queryDataSend,
            name: reportName,
            type: 'report',
          }),
        }
      );
      setSavingReport(false);
      if (res.ok) {
        const data = await res.json();
        if (data.updatedReport) {
          showSnackbar('Report data updated', 'success');

          window.location.pathname = `/reports/${reportId}`;
        } else {
          showSnackbar('Report saved', 'success');
        }
      } else {
        showSnackbar('An error occurred', 'error');
      }

      handleClose();
    } else if (type === 'view') {
      setSavingReport(true);
      showSnackbar('Saving view...');

      const paramsSend = params.params;
      const res = await poster.mutateAsync({
        page,
        params: paramsSend,
        name: viewName,
        type: 'view',
      });
      setSavingReport(false);
      if (res.error) {
        showSnackbar(res.error, 'error');
      } else {
        showSnackbar('View saved', 'success');
      }
      handleClose();
    }
  };

  return (
    <>
      <Box sx={sx}>
        <Popover
          id={idView}
          open={openView}
          anchorEl={anchorElView}
          onClose={handleCloseView}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 1 }}>
            <TextField
              InputProps={{
                style: { height: '34px' },
              }}
              sx={{ width: 200 }}
              value={viewName}
              onChange={(e) => setViewName(e.target.value)}
            ></TextField>
            <IconButton color="primary" onClick={() => onSave('view')}>
              <BookmarkBorder />
            </IconButton>
          </Box>
        </Popover>
        <Tooltip
          title={
            params.params === ''
              ? 'Customize filters to save view'
              : 'Save current view - this saves the current configuration of filters.'
          }
        >
          <span>
            <IconButton onClick={handleClickView} color="primary">
              <BookmarkBorder />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box sx={sx}>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
            {reportId === null ? (
              <TextField
                InputProps={{
                  style: { height: '34px' },
                }}
                sx={{ width: 200 }}
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
              ></TextField>
            ) : (
              <Typography>Update report</Typography>
            )}

            <IconButton color="primary" onClick={() => onSave('report')}>
              <Save />
            </IconButton>
          </Box>
        </Popover>
        <Tooltip title={'Save a snapshot of this data'}>
          <span>
            <IconButton onClick={handleClick} color="primary">
              <Save />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </>
  );
};

export default SaveReport;
