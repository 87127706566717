import { useEffect } from 'react';

import { useAccountStore, useRoleStore } from '@/store';

export const useUpdateUserRole = () => {
  const { selectedAccount, userState } = useAccountStore();
  const { setUserRole } = useRoleStore();

  useEffect(() => {
    if (selectedAccount) {
      const userAccount = userState?.userAccounts?.find(
        (account) => account.account_id === selectedAccount.accountId
      );

      if (userAccount) {
        setUserRole(userAccount.role_id);
      }
    }
  }, [selectedAccount, setUserRole, userState?.userAccounts]);
};
