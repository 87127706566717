import { create } from 'zustand';

import {
  getLocalData,
  removeLocalData,
  setLocalData,
} from '@/utils/localStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/account';

type Account = {
  accountId: string | null;
  accountName: string | null;
  accountMode: string | null;
  compGridsEnabled: boolean | null;
  accountingTransactionsEnabled: boolean | null;
  whiteLabelMode: boolean | null;
  logoUrl: string | null;
  uid: string | null;
};

type AccountStore = {
  logoUrl?: string;
  selectedAccount: Account | null | undefined;
  userState?: UserState;
};

type Setter = {
  setUserState: (s?: UserState) => void;
  setLogoUrl: (logoUrl: string) => void;
  setSelectedAccount: (account: Account | null) => void;
  resetAccountStore: () => void;
};

const defaultValues: AccountStore = {
  logoUrl: '',
  userState: undefined,
  selectedAccount: undefined,
};

const useAccountStore = create<AccountStore & Setter>((set) => ({
  ...defaultValues,
  selectedAccount: getLocalData(LOCAL_STORAGE_KEYS.selectedAccount),

  setSelectedAccount: (account) => {
    setLocalData(LOCAL_STORAGE_KEYS.selectedAccount, account);
    set({ selectedAccount: account });
  },
  setLogoUrl: (logoUrl) => set({ logoUrl }),
  setUserState: (state) => set({ userState: state }),
  resetAccountStore: () => {
    removeLocalData(LOCAL_STORAGE_KEYS.selectedAccount);
    set(defaultValues);
  },
}));

export default useAccountStore;
