const convertObjectListToArrayList = (objectList) => {
  const keys = Object.keys(objectList[0]);
  const keyMap = {};
  keys.forEach((key, index) => {
    keyMap[key] = index;
  });

  const arrayList = objectList.map((obj) => {
    return keys.map((key) => obj[key]);
  });

  return { arrayList, keyMap };
};

export default convertObjectListToArrayList;
