import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { e } from 'mathjs';
import * as React from 'react';

const ITEM_HEIGHT = 48;

const MoreMenu = ({ actions, data, setActionLoading }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton id="long-button" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: '20ch',
          },
        }}
      >
        {actions.map(({ label, onClick }) => (
          <MenuItem
            key={label}
            onClick={async (e) => {
              e.stopPropagation();
              setActionLoading(true);
              await onClick(data);
              setActionLoading(false);
              handleClose();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MoreMenu;
