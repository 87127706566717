import { Box, Tooltip, Badge } from '@mui/material';
import { useEffect } from 'react';

import API from '@/services/API';

const DuplicateFilesDetector = ({
  hash,
  hasDuplicateFile,
  setHasDuplicateFile,
}) => {
  const data = API.getBasicQuery(
    'documents/duplicateFilesDetector',
    `hash=${hash}`
  ).data?.data;

  useEffect(() => {
    if (!hasDuplicateFile && data && data.length > 0) {
      setHasDuplicateFile(true);
    }
  }, [data, hasDuplicateFile, setHasDuplicateFile]);

  const openDuplicateDocuments = () => {
    const queryString = `id=${data.map((item) => item.str_id).join(',')}`;
    const url = `/documents?${queryString}`;
    window.open(url, '_blank');
  };

  return data ? (
    <Box
      onClick={openDuplicateDocuments}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        cursor: 'pointer',
      }}
    >
      <Tooltip
        title={`Detected ${data.length} uploads of this file. Click to view details.`}
        arrow
      >
        <Badge
          badgeContent={data.length}
          color="error"
          sx={{
            '& .MuiBadge-dot': {
              borderRadius: '50%',
              height: '20px',
              width: '20px',
            },
            marginRight: '10px',
          }}
        />
      </Tooltip>
    </Box>
  ) : null;
};

export default DuplicateFilesDetector;
