import {
  Add,
  DeleteOutline,
  DownloadOutlined,
  Edit,
  LaunchOutlined,
  PlayArrow,
  SyncOutlined,
} from '@mui/icons-material';
import { Alert, Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import CommonFormatter from 'common/Formatter';
import { numberOrDefault } from 'common/helpers';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { BasicDialog, FileDialogPreview } from '@/common';
import { PDF_IMG_TYPES } from '@/common/preview/model';
import UploadOverrideFile from '@/components/documents/DocumentsView/DocumentOverrideFile';
import ExtractMethod from '@/components/documents/DocumentsView/ExtractMethod';
import UpdateProcessData from '@/components/documents/DocumentsView/UpdateProcessData';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import { UIStateContext } from '@/contexts/UIStateProvider';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import usePreviewParams from '@/contexts/usePreviewParams';
import useStorageSignedUrl from '@/contexts/useStorageSignedUrl';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import UILabels from '@/services/UILabels';
import useAccountStore from '@/store/accountStore';
import { useSetOriginFile } from '@/store/excelStore';
import { DocumentPreviewKeys } from '@/types';
import ActionDialog from './ActionDialog';
import useSnackbar from '@/contexts/useSnackbar';

const DocumentsView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const exportPoster = API.getMutation('statement_data/export', 'POST', {
    rawData: true,
  });

  const { showPreview, setShowPreview, previewId, setPreviewPath } =
    usePreviewParams();

  const {
    role: [role],
  } = useContext(UIStateContext);

  const syncStatement = API.getMutation(
    'data_processing/sync/benefit-point/statements',
    'POST'
  );
  const viewSettings = accountSettings?.pages_settings?.documents;

  const { data: companies } = API.getBasicQuery('companies');
  const { data: documentProfiles } = API.getBasicQuery('document_profiles');

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const { showSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showExtract, setShowExtract] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [processLoading, setProcessLoading] = useState<number | ''>('');
  const [activeRow, setActiveRow] = useState<any>();

  const [showEdit, setShowEdit] = useState<boolean | null>(null);

  const [sync, setSync] = useState({ documentId: '', show: false, count: 0 });

  const [rowData, setRowData] = useState<any>({});
  const { setLoadingConfig } = useContext(LoadingContext);

  const setUploadedFile = useSetOriginFile();
  const { getSignedUrl } = useStorageSignedUrl();
  const { file, downloadFile } = useDownloadStorageFile();

  const documentsDelete = API.getMutation('documents', 'DELETE');
  const documentPutter = API.getMutation('documents', 'PUT');
  const { data: activeRowData, isLoading: isActiveRowLoading } =
    API.getBasicQuery(
      'extractions',
      `document_id=${activeRow?.id}`,
      !!activeRow?.id
    );

  const queryClient = useQueryClient();

  const { selectedAccount } = useAccountStore();
  const mode = selectedAccount?.accountMode;
  const labels = new UILabels(mode);

  // Hard coded for now, will be dynamic later
  const enableAccountId = ['W4kSrayZvmh26pGfYVrGE', 'fFF86XAy2Cu97xxra8lgA'];
  const isRiskTag =
    enableAccountId.includes(selectedAccount?.accountId || '') ||
    selectedAccount?.accountName?.toLowerCase().includes('risktag');

  const filePathFormatter = useCallback(
    (filename, row) =>
      filename ? (
        <Tooltip title={filename} enterNextDelay={1000}>
          <Button
            style={{
              width: 'max-content',
              maxWidth: 240,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
            }}
            onClick={async () => {
              setPreviewPath(row.str_id, DocumentPreviewKeys.ORIGINAL);
              setShowPreview(true);
            }}
          >
            <span
              style={{
                maxWidth: 240,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
              }}
            >
              {filename}
            </span>
          </Button>
        </Tooltip>
      ) : (
        '--'
      ),
    [setPreviewPath, setShowPreview]
  );

  const syncToBenefit = async (data) => {
    try {
      setLoadingConfig({
        loading: true,
        message: 'Syncing...',
      });
      const ret = await syncStatement.mutateAsync(data);
      if (ret.success === false) {
        showSnackbar(`Sync failed: ${ret.message || ret.statusText}`, 'error');
      } else {
        const { actualCreated, create, invalid, grouped } = ret;
        let tips: string[] = [];
        if (grouped === actualCreated && create.length != grouped) {
          tips = [
            `${actualCreated} commission entries posted.`,
            '',
            grouped !== create.length
              ? `${create.length} commissions entries merged into ${actualCreated}.`
              : '',
            `Only one commission entry allowed per policy.`,
          ];
        } else {
          tips.push(
            `${actualCreated} statement ${actualCreated > 1 ? 'entries' : 'entry'} created`
          );
        }
        // if not equal, show the tips in a yellow background
        showSnackbar(
          <Alert
            severity={
              grouped !== actualCreated || invalid?.length > 0
                ? 'warning'
                : 'success'
            }
          >
            Synced statmentID: {ret.statementId}
            <br />
            {tips.map((tip, index) => (
              <div key={index}>{tip ? tip : <br />}</div>
            ))}
            {invalid?.length > 0 && <br />}
            {invalid?.length > 0
              ? `${invalid.length} statement ${invalid.length > 1 ? 'reports' : 'report'} invalid`
              : null}
          </Alert>
        );
      }
    } catch (error: any) {
      showSnackbar(error.message || error, 'error');
    } finally {
      setLoadingConfig({
        loading: false,
        message: '',
      });
    }
  };

  const exportData = useCallback(
    async (data) => {
      const params = {
        document_id: data.str_id,
        company_str_id: data.company_str_id,
      };
      try {
        setLoadingConfig({
          loading: true,
          message: 'Exporting...',
        });
        const res = await exportPoster.mutateAsync(params);
        setLoadingConfig({
          loading: false,
          message: '',
        });
        const blob = await res.blob();
        saveAs(blob, `${data.filename}`);
      } catch (error: any) {
        setLoadingConfig({
          loading: false,
          message: '',
        });
        showSnackbar(error.message || error, 'error');
      }
    },
    [exportPoster, setLoadingConfig]
  );

  const deleteData = async (row) => {
    try {
      setLoadingConfig({
        loading: true,
        message: 'Deleting...',
      });
      await documentsDelete.mutateAsync({ id: row.id });
      queryClient.invalidateQueries();
      setLoadingConfig({
        loading: false,
        message: '',
      });
      showSnackbar(`Deleted ${row.filename}`, 'success');
    } catch (error: any) {
      setLoadingConfig({
        loading: false,
        message: '',
      });
      showSnackbar(error.message || error, 'error');
    }
  };

  /**
   * Extraxt data from file, if file is image or pdf, show an select option to choose which method to extract data (1. Google Document AI, 2. ExtractTable)
   * @param {object} row documents table row
   */
  useEffect(() => {
    if (isActiveRowLoading) {
      return;
    }
    if (activeRow) {
      setProcessLoading(activeRow.id);
    }
    const extractData = async (_row) => {
      const filepath = activeRow?.override_file_path
        ? activeRow?.override_file_path
        : activeRow?.file_path;
      const filePreviewType = activeRow?.override_file_path
        ? 'override'
        : 'original';

      const signedUrl = await getSignedUrl(filepath);
      if (signedUrl) {
        try {
          const file = await downloadFile({
            document_str_id: activeRow.str_id,
            file_preview_type: filePreviewType,
            endpoint: 'documents',
          });
          if (!file) {
            throw new Error('Failed to download file');
          }
          setRowData(() => {
            return {
              ...activeRow,
              url: signedUrl,
              extractions: activeRowData,
            };
          });
          setUploadedFile(file);
          setProcessLoading('');
          if (PDF_IMG_TYPES.includes(file.type)) {
            setShowExtract(true);
          } else {
            setOpen(true);
          }
          setActiveRow(undefined);
        } catch (error: any) {
          setActiveRow(undefined);
          setProcessLoading('');
          showSnackbar(error?.message || error, 'error');
        }
      }
    };
    extractData(activeRowData);
  }, [
    activeRowData,
    isActiveRowLoading,
    activeRow,
    processLoading,
    getSignedUrl,
    downloadFile,
    setUploadedFile,
  ]);

  const markProcessed = useCallback(
    async (row) => {
      const { company_str_id, file_type, id } = row;
      setProcessLoading(row.id);
      const res = await documentPutter.mutateAsync({
        company_str_id,
        file_type,
        id,
        status: 'processed',
      });
      setProcessLoading('');
      if (res.error) {
        showSnackbar(res.error, 'error');
      } else {
        queryClient.invalidateQueries();
      }
    },
    [documentPutter, queryClient]
  );

  const overrideFilePathFormatter = useCallback(
    (s, row) =>
      s ? (
        <Tooltip title={s} enterNextDelay={1000}>
          <Button
            onClick={async () => {
              setPreviewPath(row.str_id, DocumentPreviewKeys.OVERRIDE);
              setShowPreview(true);
            }}
          >
            <span
              style={{
                maxWidth: 240,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
              }}
            >
              {s}
            </span>
          </Button>
        </Tooltip>
      ) : (
        <Box sx={{ ml: 4 }}>
          <Tooltip title="Add override file" placement="right">
            <IconButton
              onClick={() => {
                setRowData(row);
                setShowUploadModal(true);
              }}
            >
              <Add sx={{ height: 18 }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    [setPreviewPath, setShowPreview]
  );

  const dataDesc = useMemo(
    () => ({
      label: labels.getLabel('documents', 'title'),
      table: 'documents',
      fields: {
        filename: {
          label: 'File',
          enabled: true,
          copyable: true,
          formatter: filePathFormatter,
        },
        override_filename: {
          label: 'Override file',
          enabled: true,
          copyable: true,
          formatter: overrideFilePathFormatter,
          access: 'admin',
        },
        type: {
          label: 'Type',
          enabled: true,
          formatter: Formatter.documentType,
        },
        companies: {
          label: 'Company',
          enabled: true,
          formatter: Formatter.getLinkChipFormatter(
            'company_name',
            'str_id',
            '/companies?id='
          ),
        },
        statement_data: {
          label: 'Records',
          disableSort: true,
          enabled: true,
          formatter: (val, row) => {
            const groupedCountInfoStrList: string[] = [];
            const groupedCommissionInfoStrList: string[] = [];
            if (val.groupedCountInfo) {
              Object.entries(val.groupedCountInfo).forEach(([key, value]) => {
                if (key !== 'NO_STATUS') {
                  groupedCountInfoStrList.push(`${key}: ${value}`);
                }
              });
            }
            if (val.groupedCommissionInfo) {
              Object.entries(val.groupedCommissionInfo).forEach(
                ([key, value]) => {
                  if (key !== 'NO_STATUS') {
                    groupedCommissionInfoStrList.push(
                      `${key}: ${Formatter.currency(value)}`
                    );
                  }
                }
              );
            }
            return (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '4px',
                    height: isRiskTag ? 90 : 'auto',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    {!!val.total_count && (
                      <Box
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span>{+val.total_count}</span>
                        {groupedCountInfoStrList.length > 0 && (
                          <span
                            style={{
                              color: '#555',
                              fontSize: 13,
                              marginLeft: 4,
                            }}
                          >
                            ({groupedCountInfoStrList.toString()})
                          </span>
                        )}
                      </Box>
                    )}
                    {!!val.total_commission && (
                      <Box
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span>{Formatter.currency(val.total_commission)}</span>
                        {groupedCommissionInfoStrList.length > 0 && (
                          <span
                            style={{
                              color: '#555',
                              fontSize: 13,
                              marginLeft: 4,
                            }}
                          >
                            ({groupedCommissionInfoStrList.join(', ')})
                          </span>
                        )}
                      </Box>
                    )}
                    {!val.total_commission && !val.total_count && '0'}
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {(val.total_commission > 0 || val.total_count > 0) && (
                      <>
                        <IconButton
                          component={Link}
                          to={`/${row.type === 'statement' ? 'commissions' : 'policies'}?q=${row.str_id}`}
                          target="_blank"
                          sx={{
                            opacity: 0.5,
                            '&:hover': { opacity: 1 },
                            color: '#2196f3',
                          }}
                        >
                          <LaunchOutlined />
                        </IconButton>
                        {isRiskTag && (
                          <>
                            {row?.statement_data?.total_count > 0 && (
                              <Tooltip
                                title={`${row?.sync_id ? `The document has been synced with statementId: ${row?.sync_id}, you can sync again to update` : `${row?.statement_data?.total_count} statement entries will be synced to BenefitPoint`}`}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() =>
                                    setSync({
                                      documentId: row.str_id,
                                      show: true,
                                      count:
                                        row?.statement_data?.total_count || 0,
                                    })
                                  }
                                  size="small"
                                  sx={{
                                    marginLeft: '4px',
                                    opacity: 0.5,
                                    '&:hover': { opacity: 1 },
                                    color: '#2196f3',
                                  }}
                                >
                                  <SyncOutlined
                                    color={row.sync_id ? 'disabled' : 'primary'}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => exportData(row)}
                              size="small"
                              sx={{
                                opacity: 0.5,
                                '&:hover': { opacity: 1 },
                                color: '#2196f3',
                              }}
                            >
                              <DownloadOutlined />
                            </IconButton>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </>
            );
          },
        },
        bank_total_amount: {
          label: 'Commission totals',
          enabled: true,
          disableSort: true,
          formatter: (v, row) => {
            const statementData = row.statement_data;
            const bankTotalAmount = numberOrDefault(
              row.bank_total_amount,
              null,
              { toFixed: 2 }
            );
            const totalCommissionAmount = numberOrDefault(
              statementData.total_commission,
              null,
              { toFixed: 2 }
            );
            const statementTotalAmount = numberOrDefault(
              row.imports?.[0]?.statement_total_amount,
              null,
              { toFixed: 2 }
            );

            if (row.status === 'new') {
              return `Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}`;
            }

            const bankAndTotalCmsIsEqual = isEqual(
              bankTotalAmount,
              totalCommissionAmount
            );
            const statementAndCmsTotalIsEqual = isEqual(
              totalCommissionAmount,
              statementTotalAmount
            );

            let matchesNode = <></>;

            if (
              bankAndTotalCmsIsEqual &&
              statementAndCmsTotalIsEqual &&
              bankTotalAmount !== null
            ) {
              const tip =
                'Bank total, statement total, and commission records all match';
              matchesNode = (
                <Tooltip title={tip} placement="right">
                  <span>{`✅ ${Formatter.currency(bankTotalAmount)}`}</span>
                </Tooltip>
              );
            } else if (bankAndTotalCmsIsEqual && totalCommissionAmount) {
              const tip = (
                <span>
                  Bank total and commissions match
                  <br />
                  (Statement total not available)
                </span>
              );
              matchesNode = (
                <Tooltip title={tip} placement="right">
                  <span>{`✅ ${Formatter.currency(bankTotalAmount)}`}</span>
                </Tooltip>
              );
            } else if (statementAndCmsTotalIsEqual && totalCommissionAmount) {
              const tip =
                'Statement total and commission records match (Bank total not available)';
              matchesNode = (
                <Tooltip title={tip} placement="right">
                  <span>
                    {`✅ ${Formatter.currency(statementTotalAmount)}`}
                  </span>
                </Tooltip>
              );
            } else if (
              !bankTotalAmount &&
              !statementTotalAmount &&
              !totalCommissionAmount
            ) {
              matchesNode = <span>0</span>;
            } else if (
              totalCommissionAmount &&
              !statementTotalAmount &&
              !bankTotalAmount
            ) {
              matchesNode = (
                <Tooltip
                  title={
                    <span>
                      Validation not available.
                      <br />
                      Bank total and/or statement total required.
                    </span>
                  }
                  placement="right"
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ fontSize: 12 }}>ℹ️</Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span>
                        {`Commissions: ${Formatter.currency(totalCommissionAmount)}`}
                      </span>
                    </Box>
                  </Box>
                </Tooltip>
              );
            } else {
              matchesNode = (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box sx={{ fontSize: 12 }}>❌</Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span>{`Commissions: ${Formatter.currency(totalCommissionAmount)}`}</span>
                    <span>{`Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}`}</span>
                    <span>{`Statement total: ${Formatter.currency(statementTotalAmount) || 'n/a'}`}</span>
                  </Box>
                </Box>
              );
            }
            return <Box sx={{ whiteSpace: 'nowrap' }}>{matchesNode}</Box>;
          },
        },
        created_by: {
          label: 'Imported total',
          enabled: true,
          disableSort: true,
          formatter: (v, row) => {
            const list = row.imports;
            if (list && list.length) {
              const target = list[0];
              return Formatter.currency(target?.summed_total_amount);
            }
          },
          access: 'admin',
        },
        status: {
          label: 'Status',
          enabled: true,
          formatter: (val) =>
            Formatter.statusChip(val, {
              mapping: { new: 'yellow', processed: 'green' },
            }),
        },
        method: {
          label: 'Method',
          enabled: true,
          access: 'admin',
          formatter: (val: string, row) =>
            val ? (
              <Chip
                label={val}
                component={Link}
                to={`/documents/profiles?id=${row.profile_str_id}`}
              />
            ) : null,
        },
        notes: {
          label: 'Notes',
          enabled: true,
        },
        imported_at: {
          label: 'Imported at',
          enabled: true,
          access: 'admin',
          formatter: (s: string, row) => {
            if (!s) {
              return '';
            }
            const uploadToImportTime = dayjs(s).diff(
              dayjs(row.created_at),
              'milliseconds'
            );
            const uploadedInRes = `${CommonFormatter.duration(uploadToImportTime, { truncate: 'seconds' })}`;
            return `${Formatter.date(s, {
              format: 'MM/DD/YYYY hh:mmA',
            })} (${uploadedInRes})`;
          },
        },
        created_at: {
          label: 'Uploaded at',
          enabled: true,
          formatter: (s: string) => {
            return Formatter.date(s, {
              format: 'MM/DD/YYYY hh:mmA',
            });
          },
          readOnly: true,
        },
      },
      actions:
        role === 'admin'
          ? [
              {
                id: 'process',
                label: 'Process',
                type: 'iconButton',
                icon: <PlayArrow />,
                onClick: (row) => setActiveRow(row),
              },
              {
                id: 'edit',
                label: 'Edit',
                type: 'iconButton',
                icon: <Edit />,
                onClick: (row) => {
                  setRowData(row);
                  setShowEdit(true);
                },
              },
              {
                id: 'delete',
                label: 'Delete',
                type: 'iconButton',
                icon: <DeleteOutline />,
                enabled: (row) => row.status === 'new',
                onClick: (row) => {
                  setRowData(row);
                  setShowConfirm(true);
                },
              },
              {
                id: 'mark_processed',
                label: 'Mark as processed',
                enabled: (row) => row.status === 'new',
                onClick: async (row) => await markProcessed(row),
              },
            ]
          : [
              {
                id: 'edit',
                label: 'Edit',
                type: 'iconButton',
                icon: <Edit />,
                onClick: (row) => {
                  setRowData(row);
                  setShowEdit(true);
                },
              },
              {
                id: 'delete',
                label: 'Delete',
                type: 'iconButton',
                icon: <DeleteOutline />,
                enabled: (row) => row.status === 'new',
                onClick: (row) => {
                  setRowData(row);
                  setShowConfirm(true);
                },
              },
            ],
      queryChips: {
        all: {
          id: 'all',
          label: 'All',
          query: {},
        },
        new: {
          id: 'new',
          label: 'New',
          query: {
            status: 'new',
          },
        },
        processed: {
          id: 'processed',
          label: 'Processed',
          query: {
            status: 'processed',
          },
        },
      },
    }),
    [
      exportData,
      filePathFormatter,
      isRiskTag,
      labels,
      markProcessed,
      overrideFilePathFormatter,
      role,
    ]
  );

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  const onConfirmMethod = (method) => {
    setRowData((prev) => ({ ...prev, method }));
    setShowExtract(false);
    setOpen(true);
  };

  const onSetShowEdit = (isShow, action = 'cancel') => {
    setShowEdit(isShow);
    if (action === 'save') {
      queryClient.invalidateQueries();
    }
  };

  const extraActions = [
    {
      type: 'dateRange',
      label: 'Uploaded at',
      value: {
        startDate: null,
        endDate: null,
      },
    },
  ];

  return (
    <>
      <EnhancedDataView
        dataSpec={dataDesc}
        hideAdd
        hideSelectedCount
        enableMultiSelect={false}
        enableEdit={false}
        actionsEnabled={() => true}
        // @ts-ignore
        actions={dataDesc.actions}
        // @ts-ignore
        extraActions={extraActions}
      />
      {open && (
        <UpdateProcessData
          open={open}
          rowData={rowData}
          setRowData={setRowData}
          handleClose={(arg) => {
            queryClient.invalidateQueries();
            setOpen(arg);
          }}
        />
      )}
      {showUploadModal && (
        <UploadOverrideFile
          open={showUploadModal}
          setOpen={setShowUploadModal}
          uploadedRow={rowData}
        />
      )}
      {showExtract && (
        <ExtractMethod
          showExtract={showExtract}
          onClose={() => setShowExtract(false)}
          onConfirm={onConfirmMethod}
          uploadedRow={rowData}
        />
      )}
      {showPreview && (
        <FileDialogPreview
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          fileId={previewId}
        />
      )}
      {showConfirm && (
        <BasicDialog
          open={showConfirm}
          title="Delete document"
          bodyComponent={
            <Alert severity="warning">
              Are you sure you want to delete {rowData.filename}?
            </Alert>
          }
          onClose={(isOk) => {
            if (isOk) {
              deleteData(rowData);
            }
            setShowConfirm(false);
          }}
        />
      )}
      {showEdit && (
        <ActionDialog
          open={showEdit}
          setOpen={onSetShowEdit}
          rowData={rowData}
          companies={companies?.data}
          documentProfiles={documentProfiles?.data}
        />
      )}
      <BasicDialog
        open={sync.show}
        title="Sync data"
        bodyComponent={
          <Alert severity="warning">
            Are you sure you want to sync {sync.count} statement entries to
            BenefitPoint?
          </Alert>
        }
        onClose={(isOk) => {
          if (isOk) {
            syncToBenefit({ documentId: sync.documentId });
          }

          setSync({ ...sync, show: false });
        }}
        positiveLabel="Sync"
      />
    </>
  );
};

export default DocumentsView;
