import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { LoadingContext } from 'contexts/LoadingContext';
import { useNavigate } from 'react-router-dom';

import useDashboard from './dashboardHook';
import API from '@/services/API';
import { Roles } from '@/types';
import useSnackbar from '@/contexts/useSnackbar';

const SubPageCreator = ({ sharedWidgets, closeAddWidgetDialog }) => {
  const {
    predefinedDashboardName,
    predefinedWidgetSettings,
    setPredefinedDashboardName,
    setPredefinedWidgetSettings,
  } = useDashboard();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const [dashboardWidgetsAdmin, setDashboardWidgetsAdmin] = useState(
    predefinedWidgetSettings
      ? predefinedWidgetSettings[Roles.ACCOUNT_ADMIN]
      : []
  );
  const [dashboardWidgetsProducer, setDashboardWidgetsProducer] = useState(
    predefinedWidgetSettings ? predefinedWidgetSettings[Roles.PRODUCER] : []
  );
  const [dashboardWidgetsDataSpecialist, setDashboardWidgetsDataSpecialist] =
    useState(
      predefinedWidgetSettings
        ? predefinedWidgetSettings[Roles.DATA_SPECIALIST]
        : []
    );
  const [dashboardName, setDashboardName] = useState<string>(
    predefinedDashboardName ?? ''
  );
  const { setLoadingConfig } = useContext(LoadingContext);
  useEffect(() => {
    setDashboardName(predefinedDashboardName ?? '');
  }, [predefinedDashboardName]);

  useEffect(() => {
    console.log(JSON.stringify(predefinedWidgetSettings));
    setDashboardWidgetsAdmin(
      predefinedWidgetSettings
        ? predefinedWidgetSettings[Roles.ACCOUNT_ADMIN]
        : []
    );
    setDashboardWidgetsProducer(
      predefinedWidgetSettings ? predefinedWidgetSettings[Roles.PRODUCER] : []
    );
    setDashboardWidgetsDataSpecialist(
      predefinedWidgetSettings
        ? predefinedWidgetSettings[Roles.DATA_SPECIALIST]
        : []
    );
  }, [predefinedWidgetSettings]);

  const saveWidgetSettingPoster = API.getMutation(
    'insights/accountWidgetsSettings',
    'POST'
  );

  const saveWidgetSetting = async () => {
    setLoadingConfig({
      loading: true,
      message: 'Saving widget setting...',
    });
    const data = {
      adminWidgetsSettings: dashboardWidgetsAdmin,
      producerWidgetsSettings: dashboardWidgetsProducer,
      dataSpecialistWidgetsSettings: dashboardWidgetsDataSpecialist,
      dashboardName,
    };
    const res = await saveWidgetSettingPoster.mutateAsync(data);

    setLoadingConfig({
      loading: false,
    });
    if (!res || res.error) {
      showSnackbar('Failed to create dashboard', 'error');
      return;
    }
    setPredefinedDashboardName(null);
    setPredefinedWidgetSettings(null);
    navigate(`/insights/${res.name}`);
  };

  const handleFieldChange = (value, name) => {
    if (name === 'admin') {
      setDashboardWidgetsAdmin(value);
    } else if (name === 'producer') {
      setDashboardWidgetsProducer(value);
    } else {
      setDashboardWidgetsDataSpecialist(value);
    }
  };

  return (
    <Box sx={{ padding: 2, minWidth: '400px' }}>
      <FormControl fullWidth>
        <Typography variant="caption">Dashboard name</Typography>
        <Input
          onChange={(e) => setDashboardName(e.target.value)}
          value={dashboardName}
        />
      </FormControl>
      <Typography variant="h6">Choose widgets</Typography>
      <Box padding={2}>
        <Typography variant="caption">Admin</Typography>
        <Select
          value={dashboardWidgetsAdmin}
          style={{ width: '100%' }}
          label="Widgets"
          multiple
          onChange={(e) => handleFieldChange(e.target.value, 'admin')}
        >
          {sharedWidgets?.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="caption">Producer</Typography>

        <Select
          value={dashboardWidgetsProducer}
          label="Widgets"
          style={{ width: '100%' }}
          multiple
          onChange={(e) => handleFieldChange(e.target.value, 'producer')}
        >
          {sharedWidgets?.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="caption">Data specialist</Typography>

        <Select
          value={dashboardWidgetsDataSpecialist}
          label="Widgets"
          style={{ width: '100%' }}
          multiple
          onChange={(e) => handleFieldChange(e.target.value, 'dataSpecialist')}
        >
          {sharedWidgets?.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </Select>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            borderBottom: '1px solid #e0e0e0',
            position: 'fixed',
            bottom: '0',
            width: '100%',
            background: 'inherit',
          }}
        >
          <Box>
            <Button variant="contained" onClick={saveWidgetSetting}>
              Save
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '10px' }}
              onClick={closeAddWidgetDialog}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SubPageCreator;
