import { Box, InputAdornment } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
function CustomInputAdornment(props) {
  const { endAdornment, children, ...other } = props;
  return (
    <InputAdornment
      {...other}
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
      {endAdornment}
    </InputAdornment>
  );
}
const BasicDatePicker = ({
  label,
  value,
  setValue,
  sx = {},
  InputProps = {},
  disabled = false,
  ...otherParamas
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={<Box sx={{ lineHeight: 1 }}>{label}</Box>}
        timezone={'UTC'}
        value={
          ![null, undefined].includes(value)
            ? dayjs.utc(value).startOf('date')
            : null
        }
        onChange={(newValue) => {
          setValue(newValue?.format('YYYY-MM-DD') ?? null);
        }}
        {...otherParamas}
        slots={{
          inputAdornment: otherParamas.endAdornment
            ? CustomInputAdornment
            : undefined,
        }}
        slotProps={{
          textField: {
            size: 'small',
            sx: {
              justifyContent: 'start',
              width: 180,
              pl: 0,
              '.MuiInputBase-input': {
                py: 0.75,
                pl: 1.5,
              },
              ...sx,
            },
            InputLabelProps: { sx: value !== null ? { top: 3 } : {} },
            InputProps: { ...InputProps },
          },
          inputAdornment: {
            sx: { ml: 0 },
            endAdornment: otherParamas.endAdornment,
          },
          openPickerButton: {
            sx: { p: 0.5 },
          },
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};
export default BasicDatePicker;
