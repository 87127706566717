import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { API_PATHS } from './path';

const MAX_RETRIES = 10;
export const useLogin = (params?: LoginBody) => {
  return useQuery<{ response: LoginResponse }>({
    queryKey: [API_PATHS.login, params],
    queryFn: async () => {
      return axios.post(API_PATHS.login, params).then((res) => res.data);
    },
    enabled: !!params,
    retry: MAX_RETRIES,
    retryDelay(failureCount) {
      const seconds = Math.min(Math.pow(2, failureCount), 30) * 1000;
      return seconds;
    },
  });
};
