import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Tabs, Tab } from '@mui/material';
import { useState } from 'react';

import API from '@/services/API';

const ReleaseView = () => {
  const { data } = API.getBasicQuery('release/changelogs');
  const [activeTab, setActiveTab] = useState<string>('release');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const tabs = ['release', 'api', 'web', 'common'];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        defaultValue="release"
        sx={{ ml: 4 }}
      >
        {tabs.map((tab) => (
          <Tab key={tab} label={tab.toUpperCase()} value={tab} />
        ))}
      </Tabs>
      <div
        style={{
          padding: 16,
          marginLeft: 32,
          overflowY: 'auto',
          width: '100%',
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {data?.[activeTab]}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default ReleaseView;
