import { createContext, useState, useMemo } from 'react';

interface LoadingConfigProps {
  loading: boolean;
  message?: string;
  delayToClose?: number;
  lock?: boolean;
  unlock?: boolean;
  allowClose?: boolean;
}

export const LoadingContext = createContext({
  loadingConfig: {
    loading: false,
    message: '',
    delayToClose: 0,
    lock: false,
    unlock: false,
    allowClose: false,
  },
  setLoadingConfig: (e: LoadingConfigProps) => {},
});

const LoadingProvider = ({ children }) => {
  const [loadingConfig, setLoadingConfig] = useState({
    loading: false,
    message: '',
    delayToClose: 0,
  });

  const store = useMemo(() => {
    return {
      setLoadingConfig,
      loadingConfig,
    };
  }, [loadingConfig]) as any;

  return (
    <LoadingContext.Provider value={store}>{children}</LoadingContext.Provider>
  );
};

export default LoadingProvider;
