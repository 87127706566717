import getIndices from '@/common/tools/getIndices';

const parallelArray = (
  array: string[][],
  colPattern: string,
  colNames: string[],
  tag: string
): string[][] => {
  const patternColumnIndex = getIndices(array, [colPattern])[0];
  const columnsToProcess = getIndices(array, colNames);
  const updatedArray = array.map((row) => [...row]);

  // Store the mapping of row indices and character indices
  const patternMappings: [number, number][] = [];

  for (let rowIndex = 1; rowIndex < updatedArray.length; rowIndex++) {
    // Remove all whitespace characters from the pattern column value
    const patternValue = updatedArray[rowIndex][patternColumnIndex].replace(
      /\s+/g,
      ''
    );

    // Check if the pattern value contains the specified tag
    if (patternValue.includes(tag)) {
      // Split the pattern value by the tag
      const splitParts = patternValue.split(tag);

      // Process each part of the split result
      splitParts.forEach((part, partIndex) => {
        if (part) {
          updatedArray[rowIndex][patternColumnIndex] = part;
          patternMappings.push([rowIndex, partIndex]);
        }
      });
    }
  }

  // Process the columns based on the pattern mappings
  columnsToProcess.forEach((columnIndex) => {
    patternMappings.forEach(([rowIndex, partIndex]) => {
      // Clean up the column value by removing unwanted spaces around $ and %
      const cleanedValue = updatedArray[rowIndex][columnIndex]
        .trim()
        .replace(/\$\s+/g, '$')
        .replace(/\s+%/g, '%');

      // Split the cleaned value by spaces
      const valueParts = cleanedValue.split(' ');

      // Update the cell with the appropriate part based on the partIndex
      updatedArray[rowIndex][columnIndex] =
        valueParts[partIndex] || valueParts[0];
    });
  });

  return updatedArray;
};

export const desc_parallelArray = `
Filter the array based on a column pattern.
eg:
parallelArray([['a', 'b', 'c'],['100 total', '1 2', '1 2'],['total 100', '1 2', '1 2']], 'a', ['b', 'c'], 'total') => [['a', 'b', 'c'],['100', '1', '1'],['100', '2', '2']];
`;

export default parallelArray;
