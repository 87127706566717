import { create } from 'zustand';

import { Roles } from '@/types';
import { getLocalData, setLocalData } from '@/utils/localStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/account';

interface RoleStore {
  userRole: Roles | null;
  setUserRole: (userRole: number | null) => void;
}

const useRoleStore = create<RoleStore>((set) => ({
  userRole: getLocalData(LOCAL_STORAGE_KEYS.userRole),
  setUserRole: (userRole) => {
    setLocalData(LOCAL_STORAGE_KEYS.userRole, userRole);
    set({ userRole: userRole });
  },
}));

export default useRoleStore;
