import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';

import ProcessorPlayground from './ProcessorPlayground';
import { IProcessorModalProps } from '.';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';

const ProcessorModal = ({
  open,
  handleCancel,
  rowData,
}: IProcessorModalProps) => {
  const [uploading, setUploading] = useState(false);
  const [showSavingMsg, setShowSavingMsg] = useState(false);
  const { data: documents, isLoading } = API.getBasicQuery('documents');

  const playgroundRef = useRef<any>();
  const { setLoadingConfig } = useContext(LoadingContext);

  useEffect(() => {
    setLoadingConfig({
      loading: isLoading,
      message: 'Loading...',
    });
  }, [isLoading]);

  const onSubmit = async () => {
    setUploading(true);
    const result = await (
      playgroundRef.current as unknown as { submit: () => Promise<boolean> }
    )?.submit();
    setUploading(false);
    if (result) {
      handleCancel();
    }
  };

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{ background: 'transparent', p: 1 }}
      onClose={handleCancel}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Box className="flex items-center">
          <Box className="pl-2">
            Processor editor{' '}
            {showSavingMsg ? (
              <span className="text-gray-500 text-sm pl-1">
                Saving to local...
              </span>
            ) : (
              ''
            )}
          </Box>
        </Box>
      </DialogTitle>
      <IconButton
        className="group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600"
        onClick={handleCancel}
      >
        <Close className="group-hover:rotate-180 transition-all origin-center" />
      </IconButton>
      <Divider />

      <DialogContent
        sx={{
          p: 0,
          backgroundColor: '#fff',
          borderRadius: '4px',
        }}
      >
        <ProcessorPlayground
          ref={playgroundRef}
          rowData={rowData}
          documentList={documents}
          setShowSavingMsg={setShowSavingMsg}
        />
      </DialogContent>

      <DialogActions sx={{ pt: 0, pb: 2, px: 2 }}>
        <Button onClick={handleCancel}>Cancel</Button>
        <LoadingButton
          onClick={onSubmit}
          loading={uploading}
          variant="contained"
          sx={{ width: '100px' }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProcessorModal;
