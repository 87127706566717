import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material';

import API from '@/services/API';
import EditViewReport from '../molecules/EditViewReport';
import { useAccountStore } from '@/store';
import ReportHistory from './ReportHistory';
import ReportData from '../molecules/ReportData';
import SearchBox from '../molecules/SearchBox';

const SnapshotReport = (): JSX.Element => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({});

  const { selectedAccount } = useAccountStore();
  const [newName, setNewName] = useState<string>('');
  const [newNotes, setNewNotes] = useState<string>('');
  const [newAccess, setNewAccess] = useState<string>('');
  const [usersList, setUsersList] = useState<string[]>([]);
  const [approver, setApprover] = useState<string | null>('');
  const [status, setStatus] = useState<string | null>('');
  const [open, setOpen] = useState(false);
  const [fetchAccountUsers, setFetchAccountUsers] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const initialGrouping = searchParams.get('grouping') || 'none';
  const [grouping, setGrouping] = useState(initialGrouping);
  const initialView = searchParams.get('view') || 'prod-view';
  const [view, setView] = useState(initialView);
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  const { data: accountUsers, isLoading: accountUsersLoading } =
    API.getBasicQuery(
      // @ts-ignore
      `users/get_account_users?accId=${selectedAccount.accountId}`,
      '',
      fetchAccountUsers
    );

  const {
    data: selectedSnapshotReport,
    isLoading,
    refetch,
  } = API.getBasicQuery(
    // @ts-ignore
    `saved_reports/reports?report_id=${id}&grouping=${grouping}&view=${view}`
  );

  const applySearch = (query: string) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = originalData.filter((row: any) => {
      const rowString = Object.values(row)
        .map((value) => {
          if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value);
          }
          return value;
        })
        .join(' ')
        .toLowerCase();
      return rowString.includes(lowerCaseQuery);
    });

    setFilteredData(filtered);
  };

  useEffect(() => {
    if (selectedSnapshotReport?.snapshot_data && !isLoading) {
      setFilteredData(selectedSnapshotReport?.snapshot_data.data?.data);
      setOriginalData(selectedSnapshotReport?.snapshot_data.data?.data);

      setNewName(selectedSnapshotReport.name);
      setNewNotes(selectedSnapshotReport.notes);
      setNewAccess(selectedSnapshotReport.access);
      setUsersList(selectedSnapshotReport.users_white_list);
      setApprover(selectedSnapshotReport.reviewed_by);
      setStatus(selectedSnapshotReport.status);
    }
    setFetchAccountUsers(true);
  }, [selectedSnapshotReport, isLoading]);

  // On initial load, check if there is a search query in the URL after data is loaded
  useEffect(() => {
    const query = searchParams.get('q') ?? '';
    if (query) {
      applySearch(query);
    }
  }, [searchParams, originalData]);

  const handleGroupingChange = (event, newGrouping: string | null) => {
    if (newGrouping !== null) {
      setGrouping(newGrouping);
      const params = new URLSearchParams(searchParams);
      params.set('grouping', newGrouping);
      setSearchParams(params);
      refetch();
    }
  };

  const handleViewChange = (event, newView) => {
    const params = new URLSearchParams(searchParams);
    params.set('view', newView);
    setSearchParams(params);
    setView(newView);
  };

  return (
    <>
      <Box
        sx={{
          overflowY: 'scroll',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mx: 2,
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="h5">{newName}</Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(true);
                }}
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 400, textTransform: 'capitalize' }}
              >
                {selectedSnapshotReport?.snapshot_data.reportPage} data
              </Typography>
              <Tooltip title={showInfo ? 'Hide Details' : 'View Details'}>
                <IconButton
                  onClick={() => setShowInfo(!showInfo)}
                  color="primary"
                >
                  {showInfo ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            </Box>
            <SearchBox id="snapshot_reports" />
          </Box>

          {showInfo && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, mr: 0.5 }}>
                      Approver:
                    </Typography>
                    <Typography>
                      {
                        selectedSnapshotReport?.users_reports_reviewed_byTousers
                          ?.email
                      }
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, mr: 0.5 }}>
                      Status:
                    </Typography>
                    <Typography>{status}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, mr: 0.5 }}>
                      Notes:
                    </Typography>
                    <Typography>{newNotes}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, mr: 0.5 }}>
                      Agent payout status:
                    </Typography>
                    <Typography>
                      {
                        selectedSnapshotReport?.snapshot_data.data
                          ?.contactPayableStatus
                      }
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, mr: 0.5 }}>
                      Agent bank info:
                    </Typography>
                    <Typography>
                      {
                        selectedSnapshotReport?.snapshot_data.data
                          ?.contactBankInfo
                      }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, mr: 0.5 }}>
                      Created at:
                    </Typography>
                    <Typography>
                      {new Date(
                        selectedSnapshotReport?.created_at
                      ).toLocaleString()}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, mr: 0.5 }}>
                      Access:
                    </Typography>
                    <Typography>{newAccess}</Typography>
                  </Box>
                  {accountUsers && !accountUsersLoading && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ fontWeight: 500, mr: 0.5 }}>
                        Users:
                      </Typography>
                      <Box>
                        {usersList.length > 0
                          ? usersList.map((user) => {
                              const userObj = accountUsers?.find(
                                (u) => u.str_id === user
                              );
                              return (
                                <Typography key={userObj?.str_id}>
                                  {userObj?.email}
                                </Typography>
                              );
                            })
                          : null}
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        <ReportData
          isLoading={isLoading}
          selectedSnapshotReport={selectedSnapshotReport}
          filteredData={filteredData}
          setOpenHistory={setOpenHistory}
          handleGroupingChange={handleGroupingChange}
          grouping={grouping}
          view={view}
          handleViewChange={handleViewChange}
          refetch={refetch}
        />
      </Box>
      {selectedSnapshotReport ? (
        <>
          <EditViewReport
            open={open}
            setOpen={setOpen}
            type="Report"
            name={selectedSnapshotReport.name}
            notes={selectedSnapshotReport.notes}
            access={selectedSnapshotReport.access}
            usersWhiteList={selectedSnapshotReport.users_white_list}
            approver={selectedSnapshotReport.reviewed_by}
            status={selectedSnapshotReport.status}
            created_by={selectedSnapshotReport.created_by}
            allData={selectedSnapshotReport}
            updateName={setNewName}
            updateNotes={setNewNotes}
            updateAccess={setNewAccess}
            updateUsersList={setUsersList}
            updateApprover={setApprover}
            updateStatus={setStatus}
            isOwner={selectedSnapshotReport.isOwner ? true : false}
            id={selectedSnapshotReport.id}
          />
          <ReportHistory
            open={openHistory}
            setOpen={setOpenHistory}
            reportId={selectedSnapshotReport.str_id}
          />
        </>
      ) : null}
    </>
  );
};

export default SnapshotReport;
