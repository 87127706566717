import { MoreVert } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useGate } from 'statsig-react';

import BasicTable from '@/components/molecules/BasicTable';
import { exportCsv } from '@/services/helpers';

interface WidgetWrapperProps {
  displayName: string;
  onEdit?: () => void;
  onDelete?: () => void;
  children: React.ReactNode;
  sharedWidget?: boolean;
  onCopy?: (data: any) => void;
  data?: any;
  widgetData?: any;
  type?: string;
}

const WidgetWrapper = ({
  displayName,
  onEdit,
  onDelete,
  sharedWidget,
  children,
  onCopy,
  data: _data,
  widgetData,
  type,
}: WidgetWrapperProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [view, setView] = useState('widget');
  const { value: superAdmin } = useGate('widgetsuperadmin');

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let headers;
  let data;
  if (type === 'chart-donut') {
    headers = Object.keys(_data?.series?.[0]?.data?.[0] || {});
    data = _data?.series?.[0]?.data?.map((row) => {
      return Object.values(row);
    });
  } else if (type === 'chart-bar') {
    headers = ['key', 'value'];
    data = _data?.xAxis.data.map((key, i) => [
      key,
      _data?.series?.[0].data?.[i],
    ]);
  } else if (type === 'chart-line') {
    headers = ['key', 'value'];
    data = _data?.xAxis.data.map((key, i) => [
      key,
      _data?.series?.[0].data?.[i],
    ]);
  } else if (type === 'table-array') {
    headers = _data ? _data[0] : [];
    data = _data ? _data.slice(1) : [];
  } else if (type === 'h5') {
    headers = [displayName];
    console.log(_data);
    data = _data.toString();
  }

  return (
    <Card style={{ height: '100%', overflowY: 'scroll' }}>
      <CardContent sx={{ pb: 0 }}>
        <Grid
          container
          justifyContent="space-between"
          className="grid-item__title"
        >
          <Grid item style={{ width: '80%' }} className="dragHandle">
            <Typography gutterBottom variant="body1">
              {displayName}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={handleMenuClick}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {(!sharedWidget || superAdmin) && [
                onEdit && (
                  <MenuItem
                    key="edit"
                    onClick={() => {
                      handleMenuClose();
                      onEdit();
                    }}
                  >
                    Edit
                  </MenuItem>
                ),
                onDelete && (
                  <MenuItem
                    key="delete"
                    onClick={() => {
                      handleMenuClose();
                      onDelete();
                    }}
                  >
                    Delete
                  </MenuItem>
                ),
              ]}
              {onCopy && (
                <MenuItem
                  key="copy"
                  onClick={() => {
                    handleMenuClose();
                    onCopy(widgetData.spec);
                  }}
                >
                  Create copy
                </MenuItem>
              )}
              {typeof type === 'string' &&
                ['chart-donut', 'chart-bar'].includes(type) && [
                  <MenuItem
                    key="toggleView"
                    onClick={() => {
                      setView(view === 'table' ? 'widget' : 'table');
                    }}
                  >
                    {view === 'table' ? 'Widget view' : 'Table view'}
                  </MenuItem>,
                ]}
              <MenuItem
                key="export"
                onClick={() => {
                  exportCsv(headers, data, 'Fintary-Export.csv');
                }}
              >
                Export
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        {view === 'widget' && children}
        {view === 'table' && (
          <BasicTable headers={headers} rows={data} formatters={{}} />
        )}
      </CardContent>
    </Card>
  );
};

export default WidgetWrapper;
