import { javascript } from '@codemirror/lang-javascript';
import {
  CameraAlt,
  EditNote,
  History,
  Launch,
  MenuBook,
  PlayArrow,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import Formatter from 'common/Formatter';
import localforage from 'localforage';
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useInterval } from 'react-use';
import { getFilenameFromPath } from 'common/helpers';

import {
  BasicDialog,
  CommentDrawer,
  FileDialogPreview,
  FilterSelect,
} from '@/common';
import { tool, toolDesc } from '@/common/tools';
import useCommonData from '@/components/UploadModal/processFlow/hoc/useCommonData';
import { LoadingContext } from '@/contexts/LoadingContext';
import usePreviewParams from '@/contexts/usePreviewParams';
import { auth } from '@/firebase';
import API from '@/services/API';
import Spreadsheet from '@/services/Spreadsheet';
import HistoryDialog from '@/views/ProcessorPlayground/HistoryDialog';
import ResultTable from '@/views/ProcessorPlayground/ResultTable';
import ReviewerSelector from '@/views/ProcessorPlayground/ReviewerSelector';
import { codeTheme } from '@/views/ProcessorPlayground/config';
import { DocumentPreviewKeys } from '@/types';
import {
  documentAICode,
  extractTableCode,
  spreadSheetCode,
} from '@/views/ProcessorPlayground/defaultCode';
import {
  IDocumentModel,
  IExtractionData,
  IProcessorPlaygroundProps,
  IProcessorType,
  IResultProps,
} from '.';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import useSnackbar from '@/contexts/useSnackbar';

function ProcessorPlayground(
  { rowData, setShowSavingMsg, documentList }: IProcessorPlaygroundProps,
  ref
) {
  const [currentPrview, setCurrentPrview] = useState<IExtractionData>();
  const [selectExtraction, setSelectExtraction] = useState<number | string>('');
  const [currentCode, setCurrentCode] = useState('');
  const [currentResult, setCurrentResult] = useState<IResultProps | string>('');
  const [showChangeCodeDialog, setShowChangeCodeDialog] = useState(false);
  const { setLoadingConfig } = useContext(LoadingContext);
  const [historyList, setHistoryList] = useState<any[]>([]);
  const [showHistoryList, setShowHistoryList] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reviewer, setReviewer] = useState({
    uid: null,
    first_name: null,
    last_name: null,
    email: null,
  });

  const [openNotes, setOpenNotes] = useState(false);
  const [sheetList, setSheetList] = useState<string[]>([]);
  const [spreadsheetData, setSpreadsheetData] = useState<any>();

  const { showSnackbar } = useSnackbar();

  const [processorTemplateList] = useState([
    {
      label: 'Extract Table Template',
      value: extractTableCode,
    },
    {
      label: 'Document AI Template',
      value: documentAICode,
    },
    {
      label: 'Spreadsheet Template',
      value: spreadSheetCode,
    },
  ]);

  const [formData, setFormData] = useState({
    name: '',
    type: '',
    method: '',
    company_id: '',
    access: '',
    status: '',
    reviewer_str_id: '' as string | null,
    notes: '' as string | null,
    document: '',
    sheetName: '',
    inner_name: '',
    owner: '',
  });

  const [disabeOption, setDisableOption] = useState({
    excelDocument: false,
    extractData: false,
  });

  const [showJson, setShowJson] = useState(false);

  const { showPreview, setShowPreview, previewId, setPreviewPath } =
    usePreviewParams();

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();
  const [anchorSnaphotEl, setAnchorSnapshotEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();

  // processors
  const extractPutter = API.getMutation('extractions', 'PUT');
  const processorPoster = API.getMutation('processors', 'POST');
  const processorPatcher = API.getMutation('processors', 'PATCH');
  const { data: documentProfiles = [] } =
    API.getBasicQuery('document_profiles');
  const { data: owners = [] } = API.getBasicQuery('users/get_fintary_admins');
  const documentProfilePutter = API.getMutation('document_profiles', 'PUT');

  const { data: curProcessorData, isLoading: isProcessorLoading } =
    API.getBasicQuery(`processors/${rowData?.id}`, '', !!rowData?.id);

  const { data: curExtractionData, isLoading: isCurExtractionLoading } =
    API.getBasicQuery(
      `extractions/${selectExtraction}`,
      '',
      !!selectExtraction
    );

  const [
    { isLoading: isCompaniesLoading, data: extractionsLists = [] },
    { isLoading: isExtractionLoading, data: companiesList = [] },
  ] = API.getBasicQueryAll(['extractions', 'companies'], '');

  const { data: libProcessorList } = API.getBasicQuery(
    'processors',
    `snapshot=${
      rowData?.processor_status.includes('::')
        ? rowData?.processor_status.split('::')[0]
        : rowData?.str_id
    }`
  );

  const { downloadFile } = useDownloadStorageFile();

  const { fields } = useCommonData(formData.type as any, null);

  const extractionsList = (extractionsLists as IExtractionData[]) || [];

  const spreadsheetDocuments = useMemo(() => {
    if (!documentList || !documentList.count) {
      return [];
    }
    return documentList.data
      .filter((doc) => doc?.file_type === 'spreadsheet')
      .map((doc) => {
        // Load a new field to show the label in the select
        const { override_file_path, filename } = doc;
        if (!override_file_path) {
          doc.label = filename;
        } else {
          const _filename = override_file_path.split('/').pop();
          const [_h, ...s] = _filename?.split('-') || [];
          const file = s.join('-');
          doc.label = file;
        }
        return doc;
      });
  }, [documentList]);

  const handleFabClick = () => {
    setOpenNotes(true);
  };

  const delExtractFields = (res) => {
    if (!res) return '';
    const tables = res.Tables?.map((t) => {
      delete t.TableConfidence;
      delete t.TableCoordinates;
      return t;
    });
    const lines = res.Lines?.map((l) => {
      const _l = l.LinesArray.map((item) => {
        delete item.WordsArray;
        return item;
      });
      l.LinesArray = _l;
      return l;
    });
    res.Tables = tables;
    res.Lines = lines;
    return res;
  };

  useEffect(() => {
    if (reviewer.uid) {
      setFormData((prev) => {
        return {
          ...prev,
          reviewer_str_id: reviewer.uid,
        };
      });
    }
  }, [reviewer]);

  useEffect(() => {
    if (rowData) {
      setSelectExtraction(rowData.extractionsid);
    } else {
      setSelectExtraction('');
    }
  }, [rowData]);

  // when edit
  useEffect(() => {
    const doAction = async () => {
      if (curProcessorData && curExtractionData) {
        const _company_str_id = curProcessorData.company_id;
        setFormData({
          company_id: formData.company_id || _company_str_id,
          type: formData.type || curProcessorData.type,
          name: formData.name || curProcessorData.name,
          access: curProcessorData.access,
          method: curProcessorData.method,
          status: curProcessorData.status,
          reviewer_str_id: curProcessorData.reviewer_str_id,
          notes: curProcessorData.notes,
          document: curProcessorData.document_str_id,
          sheetName: curProcessorData.suggest_for,
          inner_name: formData.inner_name,
          owner: curProcessorData.owner,
        });
        setCurrentCode(curProcessorData?.processor);
        // extraction data
        if (curExtractionData) {
          setCurrentPrview(JSON.parse(curExtractionData?.output || ''));
          // setSelectExtraction(rowData.extractionsid);
          setCurrentResult('');
        } else if (curProcessorData.document_str_id) {
          spreadsheetChange(curProcessorData.document_str_id);
        }
        // If rowData.extractions has value, disable the excel document select, else disable the extract data select
        setDisableOption({
          excelDocument: !!curExtractionData,
          extractData: !curExtractionData,
        });
      } else {
        if (!curProcessorData && curExtractionData) {
          setCurrentPrview(JSON.parse(curExtractionData.output));
          setCurrentResult('');

          setFormData({
            ...formData,
            company_id: curExtractionData.documents.company_str_id || '',
            type: curExtractionData.documents.type || '',
            name: '',
            access: 'account',
            method: curExtractionData.method || '',
            notes: '',
            document: '',
          });
          setDisableOption((prev) => {
            return {
              ...prev,
              excelDocument: true,
            };
          });
        } else if (curProcessorData && !curExtractionData) {
          spreadsheetChange(curProcessorData.document_str_id);
          setCurrentCode(curProcessorData.processor);
          setFormData({
            company_id: curProcessorData.company_id,
            type: curProcessorData.type,
            name: curProcessorData.name,
            access: curProcessorData.access,
            method: curProcessorData.method,
            status: curProcessorData.status,
            reviewer_str_id: curProcessorData.reviewer_str_id,
            notes: curProcessorData.notes,
            document: curProcessorData.document_str_id,
            sheetName: curProcessorData.suggest_for,
            inner_name: curProcessorData.inner_name,
            owner: curProcessorData.owner,
          });
        } else if (
          !selectExtraction &&
          !formData.document &&
          !curProcessorData
        ) {
          setFormData({
            company_id: '',
            type: '',
            name: '',
            access: 'account',
            method: '',
            status: '',
            reviewer_str_id: '',
            notes: '',
            document: formData.document,
            sheetName: '',
            inner_name: '',
            owner: '',
          });
        }
      }
    };

    doAction();
  }, [
    curProcessorData,
    isProcessorLoading,
    curExtractionData,
    isCurExtractionLoading,
    rowData,
    selectExtraction,
  ]);

  useEffect(() => {
    if (!rowData && (selectExtraction || formData.document)) {
      const code =
        formData.method === 'extractTable'
          ? extractTableCode
          : formData.method === 'documentAI'
            ? documentAICode
            : spreadSheetCode;

      if (!currentCode || !rowData) {
        setCurrentCode(code);
      } else {
        // Show confirm dialog
        setShowChangeCodeDialog(true);
      }
    }
  }, [formData.method]);

  useEffect(() => {
    if (formData.company_id) {
      const targetCompany = companiesList?.data?.find(
        (c) => c.str_id === formData.company_id
      );
      let processorName = '';
      if (targetCompany) {
        processorName = `${targetCompany.company_name}_${formData.type}_${formData?.method}`;
      }
      const _inner = targetCompany ? processorName : rowData?.inner_name || '';
      setFormData((prev) => {
        return {
          ...prev,
          name: rowData?.name || processorName,
          inner_name: _inner,
        };
      });
    }
  }, [formData.company_id, formData.method, formData.type]);

  useInterval(() => {
    const localFn = async () => {
      const preCodeList = (await localforage.getItem('seedData')) as string;
      const seedData = {
        ...formData,
        selectExtraction,
        processor: currentCode,
        created_at: new Date().toISOString(),
      };
      const dbKey = `${selectExtraction}-${seedData.type}-${seedData.method}`;
      if (preCodeList && selectExtraction) {
        let preList = JSON.parse(preCodeList);
        // Temp remove old data structure
        if (Array.isArray(preList)) {
          localforage.removeItem('seedData');
          preList = {};
        }

        const target = preList[dbKey];
        if (target) {
          // Compare the lastest one, if same, do nothing
          const { created_at: _c1, ...rest } = target[0];
          const { created_at: _c2, ...rest2 } = seedData;
          if (
            JSON.stringify(rest) === JSON.stringify(rest2) ||
            !seedData.processor
          ) {
            return;
          }
          preList[dbKey].unshift(seedData);
          // If the target length is more than 50, remove the last one
          if (preList[dbKey].length > 50) {
            preList[dbKey].pop();
          }
        } else {
          // add it
          preList[dbKey] = [seedData];
        }
        setShowSavingMsg(true);
        localforage.setItem('seedData', JSON.stringify(preList));
        setTimeout(() => {
          setShowSavingMsg(false);
        }, 1000);
      } else {
        // create it
        localforage.setItem(
          'seedData',
          JSON.stringify({
            [dbKey]: [seedData],
          })
        );
      }
    };
    localFn();
  }, 30000);

  const onClickShowPreview = () => {
    let fileStrId = '';
    if (selectExtraction) {
      const target = extractionsList.find(
        (item) => item.id === selectExtraction
      );
      if (target) {
        fileStrId = target.documents.str_id;
      }
    } else if (formData.document) {
      const target = spreadsheetDocuments.find(
        (item) => item.str_id === formData.document
      );
      if (target) {
        fileStrId = target.str_id;
      }
    }

    if (fileStrId) {
      setPreviewPath(fileStrId, DocumentPreviewKeys.ORIGINAL);
      setShowPreview(true);
    }
  };

  const spreadsheetChange = (value: IDocumentModel | string) => {
    const readFile = async (path: string) => {
      setLoadingConfig({
        loading: true,
      });
      const filename = path.split('/').pop() || '';
      if (typeof value === 'string') return;
      const file = await downloadFile({
        document_str_id: value.str_id,
        file_preview_type: 'original',
        endpoint: 'documents',
      });
      setLoadingConfig({
        loading: false,
      });
      const res = await Spreadsheet.loadSpreadsheet(file);
      setSpreadsheetData(res);
      const sheets = res?.getSheets();
      if (sheets && sheets.length) {
        const _sheet = rowData?.suggest_for || sheets[0];
        const data = res.getJson(_sheet) as any;
        setSheetList(sheets);
        setFormDataValue('sheetName', _sheet);
        return data as any;
      }
      return null;
    };

    // if value is a string, we need to find the document object
    let docObj = value;
    if (typeof value === 'string') {
      docObj = ((spreadsheetDocuments || []) as IDocumentModel[]).find(
        (item) => item.str_id === value
      ) as IDocumentModel;
    }

    if (docObj) {
      readFile((docObj as IDocumentModel)!.file_path).then((fileData) => {
        setCurrentPrview(fileData);
      });
      setFormData((prev) => {
        return {
          ...prev,
          company_id: (docObj as IDocumentModel)!.companies?.str_id,
          type: (docObj as IDocumentModel)!.type,
          method: 'spreadsheet',
          document: (docObj as IDocumentModel).str_id,
        };
      });
      // disable extract data select
      setDisableOption((prev) => {
        return {
          ...prev,
          extractData: true,
        };
      });
      setCurrentResult('');
    } else {
      console.log('not found');
    }
  };

  const setFormDataValue = useCallback(
    (key: string, value: any) => {
      setFormData((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    },
    [formData]
  );

  const onCodeChange = useCallback(
    (value: string) => {
      setCurrentCode(value);
    },
    [currentCode]
  );

  const onChangeSheetName = (e: any) => {
    const sheetName = e.target.value;
    const data = spreadsheetData.getJson(sheetName) as any;
    setCurrentPrview(data);
    setFormDataValue('sheetName', e.target.value);
  };

  const execCode = useCallback(() => {
    try {
      if (currentCode) {
        const resultFn = eval(`${currentCode}`);
        const currentDocument = !formData.document
          ? documentList.data.find((item) => {
              return (
                item.extractions &&
                item.extractions.length &&
                item.extractions.some(
                  (extraction) => extraction.id === selectExtraction
                )
              );
            })
          : documentList.data.find((item) => {
              return item.str_id === formData.document;
            });
        const libs = {
          document: currentDocument,
          tools: tool,
        };
        setCurrentResult(resultFn(currentPrview, libs));
      }
    } catch (error) {
      setCurrentResult(`eval error: ${error}`);
    }
  }, [currentCode, currentPrview, currentResult]);

  useEffect(() => {
    if (
      !isCompaniesLoading &&
      !isExtractionLoading &&
      currentPrview &&
      typeof currentResult !== 'string'
    ) {
      checkValid();
    }
  }, [currentResult]);

  const checkValid = useCallback(() => {
    const isValid = currentCode && typeof currentResult !== 'string';

    // Valid for multiple spreadsheet (Medical Mutual)
    if (isValid && currentResult?.version === 'Multiple-Spreadsheet') {
      return true;
    }

    if (!isValid) {
      showSnackbar(
        'Error: Please run the code first or check the form data',
        'error'
      );
      return false;
    }
    // Check the result is valid
    if (typeof currentResult === 'string' || !currentResult) {
      return true;
    }
    const resKeys = Object.keys(currentResult);
    const validKeys = ['fields', 'data', 'version', 'sheet'];
    const notValidKeys = resKeys.filter((key) => !validKeys.includes(key));
    if (notValidKeys.length) {
      showSnackbar(
        `Error: Please check the result key: [${notValidKeys.toString()}] is valid`,
        'error'
      );
      return false;
    }

    const resHeader = (currentResult as IResultProps).fields;
    const validHeaders = Object.keys(fields);
    const requiredHeaders = Object.keys(fields).filter(
      (key) => fields[key].required && fields[key].enabled
    );

    const notValidHeader = resHeader.filter(
      (item) => !validHeaders.includes(item)
    );
    if (notValidHeader.length) {
      showSnackbar(
        `Error: Invalid headers: ${notValidHeader.join(', ')}`,
        'error'
      );
      return false;
    }

    const missingRequiredHeaders = requiredHeaders.filter(
      (item) => !resHeader.includes(item)
    );
    if (missingRequiredHeaders.length) {
      showSnackbar(
        `Error: Missing required headers: ${missingRequiredHeaders.join(', ')}`,
        'error'
      );
    }
    return true;
  }, [currentCode, currentResult, selectExtraction, formData]);

  // Clear data
  const clearData = useCallback(() => {
    setCurrentCode('');
    setCurrentResult('');
    setCurrentPrview(undefined);
    setSelectExtraction('');
    setFormData({
      company_id: '',
      type: '',
      name: '',
      access: '',
      method: '',
      status: '',
      reviewer_str_id: '',
      notes: '',
      document: '',
      sheetName: '',
      inner_name: '',
      owner: '',
    });
    setDisableOption({
      excelDocument: false,
      extractData: false,
    });
  }, []);

  /**
   * create processor snapshot
   * @param isComfirm is ok or cancel
   */
  const onCloseCreateTip = async (isComfirm) => {
    const isExcelDocument = formData.method === 'spreadsheet';
    const processorParam = {
      ...formData,
      processor: currentCode,
      extractionsid: !isExcelDocument ? selectExtraction : '',
      company_id: formData.company_id,
      document_str_id: formData.document,
      suggest_for: formData.sheetName,
    };
    // Reflect.deleteProperty(processorParam, 'method');
    Reflect.deleteProperty(processorParam, 'document');

    if (!processorParam.extractionsid) {
      Reflect.deleteProperty(processorParam, 'extractionsid');
    }
    if (isComfirm) {
      // 1. create a new processor with snapshot tag
      const snapshotTag =
        rowData?.processor_status === 'processed'
          ? `${rowData?.str_id}::${new Date().toISOString()}`
          : `${rowData?.processor_status}::${new Date().toISOString()}`;
      const createRes = await processorPoster.mutateAsync({
        processor_status: snapshotTag,
        type: rowData?.type,
        status: rowData?.status,
        reviewer_str_id: rowData?.reviewer_str_id,
        processor: rowData?.processor,
        notes: rowData?.notes,
        name: rowData?.name,
        inner_name: rowData?.inner_name,
        file_type: rowData?.file_type,
        extractionsid: rowData?.extractionsid,
        extract_str_ids: rowData?.extract_str_ids,
        extract_ids: rowData?.extract_ids,
        document_str_id: rowData?.document_str_id,
        company_id: rowData?.company_id,
        access: rowData?.access,
        method: rowData?.method,
        owner: rowData?.owner,
        suggest_for: formData.sheetName,
        profile_str_id: rowData?.profile_str_id,
      } as any);

      if (createRes.error) {
        showSnackbar(createRes.error, 'error');
        return false;
      }

      // 2. update the old processor with new code
      const res = await processorPatcher.mutateAsync({
        ...processorParam,
        id: rowData?.id,
        updated_by: auth.currentUser?.uid,
      } as any);

      if (res.error) {
        showSnackbar(res.error, 'error');
        return false;
      }
    } else {
      clearData();
    }
    return true;
  };

  const submit = useCallback(async () => {
    const targetCompany = { str_id: formData.company_id };

    const isValid = checkValid();
    if (!isValid || !targetCompany) {
      return false;
    }
    try {
      setLoadingConfig({
        loading: true,
      });

      const isExcelDocument = formData.method === 'spreadsheet';

      const _company_id = targetCompany?.str_id;
      if (_company_id) {
        const processorParam = {
          ...formData,
          processor: currentCode,
          extractionsid: !isExcelDocument ? selectExtraction : '',
          company_id: _company_id,
          document_str_id: formData.document,
          suggest_for: formData.sheetName,
        };
        Reflect.deleteProperty(processorParam, 'sheetName');
        Reflect.deleteProperty(processorParam, 'document');

        if (!processorParam.extractionsid) {
          Reflect.deleteProperty(processorParam, 'extractionsid');
        }

        if (rowData && rowData.processor_status === 'new') {
          if (
            formData.status === 'approved' &&
            auth.currentUser?.uid !== rowData?.reviewer_str_id
          ) {
            showSnackbar(
              'Error: The processor is approved, if you want to edit it please change the status to "draft" or "in review".',
              'error'
            );
            return false;
          } else {
            // Get the processor str_id and push it to document_profiles table's processor_str_ids field.
            const docProfile = (documentProfiles.data as any[]).find(
              (item) => item.carrier_name === formData.company_id
            );
            const res = await processorPatcher.mutateAsync({
              ...processorParam,
              id: rowData.id,
              updated_by: auth.currentUser?.uid,
              profile_str_id: docProfile?.str_id,
            } as any);
            if (res.error) {
              showSnackbar(res.error, 'error');
              return false;
            }

            if (docProfile) {
              const strIds = [
                ...new Set([res.str_id, ...docProfile.processor_str_ids]),
              ];
              await documentProfilePutter.mutateAsync({
                id: docProfile.id,
                processor_str_ids: strIds,
                status: formData.status,
              });
            }
          }
        } else {
          if (rowData && rowData.processor_status !== 'new') {
            // setShowCreateTip(true);
            const res = await onCloseCreateTip(true);
            setLoadingConfig({
              loading: false,
              delayToClose: 0,
            });
            return res;
          }
          let fileType = '';
          if (isExcelDocument) {
            fileType = 'spreadsheet';
          } else {
            fileType = curExtractionData?.documents.file_type || '';
          }
          const res = await processorPoster.mutateAsync({
            ...processorParam,
            file_type: fileType,
          } as any);
          if (res.error) {
            showSnackbar(res.error, 'error');
            setLoadingConfig({
              loading: false,
              delayToClose: 1000,
            });
            return false;
          }
          const docProfile = (documentProfiles.data as any[]).find(
            (item) => item.carrier_name === res.company_id
          );
          if (docProfile) {
            const strIds = [
              ...new Set([res.str_id, ...docProfile.processor_str_ids]),
            ];
            await documentProfilePutter.mutateAsync({
              id: docProfile.id,
              processor_str_ids: strIds,
              status: formData.status,
            });
          }
        }
      }
      if (!isExcelDocument) {
        const extractionParam = {
          output_format: 'Success',
          id: selectExtraction,
          method: formData.method,
        };
        // TODO: This action requires make authenticated user
        await extractPutter.mutateAsync(extractionParam as any);
      }
      setLoadingConfig({
        loading: false,
        delayToClose: 1000,
      });

      // Clear data
      clearData();
      return true;
    } catch (error) {
      setLoadingConfig({
        loading: false,
        delayToClose: 1000,
      });
      showSnackbar(`Error: ${error}`, 'error');
      return false;
    }
  }, [currentCode, currentResult, selectExtraction, formData]);

  const showHistory = useCallback(() => {
    const localFn = async () => {
      const preCodeList = (await localforage.getItem('seedData')) as string;
      if (preCodeList) {
        const preList = JSON.parse(preCodeList);
        const dbKey = `${selectExtraction}-${formData.type}-${formData.method}`;
        const targetList = preList[dbKey];
        if (targetList && targetList.length) {
          setHistoryList(
            targetList.sort((a, b) => a.created_at - b.created_at)
          );
          setShowHistoryList(true);
        } else {
          showSnackbar('Error: No history data for this extraction', 'error');
        }
      }
    };
    localFn();
  }, [formData.company_id, selectExtraction, formData.name]);

  useImperativeHandle(ref, () => {
    return {
      submit,
    };
  });

  return (
    <Box className="flex flex-col w-full h-full p-4 overflow-hidden">
      <Box className="h-15 flex mb-2 gap-1">
        {!disabeOption.excelDocument && (
          <>
            <FilterSelect
              id="id-document"
              options={spreadsheetDocuments || []}
              fullWidth
              valueKey="str_id"
              value={formData.document}
              onChange={(data) => {
                spreadsheetChange(data?.data as IDocumentModel);
              }}
              label="Excel/CSV document"
              getOptionLabel={(value) => {
                let docObj = value;
                if (typeof value === 'string') {
                  docObj = spreadsheetDocuments?.find(
                    (item) => item.str_id === value
                  );
                }
                return getFilenameFromPath(docObj?.label);
              }}
              renderOptionItem={(item) => (
                <Box
                  display="flex"
                  alignItems="left"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <div className="flex-1 mr-2">
                    {getFilenameFromPath(item?.label)}
                  </div>
                  <div className="mx-1 text-sm text-gray-400">
                    {`${item.status} - ${Formatter.date(item.created_at, true, 'MM/DD/YYYY hh:mm:A')}`}
                  </div>
                </Box>
              )}
            />

            {sheetList && sheetList.length ? (
              <FormControl fullWidth>
                <InputLabel id="id-extract">Sheet Name</InputLabel>
                <Select
                  onChange={onChangeSheetName}
                  id="id-sheet"
                  fullWidth
                  label="Sheet name"
                  value={formData.sheetName || ''}
                >
                  {sheetList?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )) || []}
                </Select>
              </FormControl>
            ) : (
              ''
            )}
          </>
        )}
        {!disabeOption.extractData && extractionsList && (
          <FormControl fullWidth>
            <InputLabel id="id-extract">Extraction</InputLabel>
            <Select
              onChange={(e) => setSelectExtraction(e.target.value)}
              id="id-extract"
              fullWidth
              label="Extraction"
              disabled={disabeOption.extractData}
              value={String(selectExtraction)}
            >
              <MenuItem key="" value=""></MenuItem>
              {(extractionsList || [])
                ?.sort(
                  (ia, ib) =>
                    +new Date(ib?.created_at) - +new Date(ia?.created_at)
                )
                .map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <div className="flex-1 mr-2">
                        {getFilenameFromPath(
                          item?.documents?.override_file_path ||
                            item?.documents?.file_path
                        )}
                      </div>
                      <div className="mx-1 text-sm text-gray-400">
                        {`${item?.status} - ${item?.method} - ${Formatter.date(item.created_at, true, 'MM/DD/YYYY hh:mm:A')}`}
                      </div>
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth>
          <InputLabel>Company</InputLabel>
          <Select
            onChange={(e) => setFormDataValue('company_id', e.target.value)}
            fullWidth
            value={formData.company_id}
            label="Company"
          >
            {companiesList?.data
              ?.filter((item) => item)
              ?.map((item) => (
                <MenuItem key={item.str_id} value={item.str_id}>
                  {item.access === 'global'
                    ? `${item.company_name} (Fintary)`
                    : item.company_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Document type</InputLabel>
          <Select
            onChange={(e) => setFormDataValue('type', e.target.value)}
            value={formData.type}
            autoWidth
            label="Document type"
          >
            <MenuItem key="statement" value="statement">
              Statement
            </MenuItem>
            <MenuItem key="report" value="report">
              Report
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Extraction method</InputLabel>
          <Select
            onChange={(e) => setFormDataValue('method', e.target.value)}
            fullWidth
            value={formData.method}
            defaultValue={formData.method}
            label="Extraction method"
          >
            <MenuItem key="extractTable" value="extractTable">
              Extract Table
            </MenuItem>
            <MenuItem key="documentAI" value="documentAI">
              Google Document AI
            </MenuItem>
            <MenuItem key="spreadsheet" value="spreadsheet">
              Spreadsheet
            </MenuItem>
            <MenuItem key="adobeExtract" value="adobeExtract">
              Adobe Extract
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Name"
          fullWidth
          variant="outlined"
          value={formData.name}
          onChange={(e) => setFormDataValue('name', e.target.value)}
        />

        <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
          <InputLabel>Owner</InputLabel>
          <Select
            id="select-sheet"
            value={formData.owner}
            label="Owner"
            onChange={(e) => setFormDataValue('owner', e.target.value)}
          >
            {(owners || []).map((c) => (
              <MenuItem value={c.uid} key={c.uid}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}
                >
                  <Box sx={{ flex: 1 }}>{Formatter.contact(c)}</Box>
                  <Box sx={{ color: '#666' }}>{c.email}</Box>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Access</InputLabel>
          <Select
            onChange={(e) => setFormDataValue('access', e.target.value)}
            fullWidth
            value={formData.access}
            label="Access"
          >
            <MenuItem key="account" value="account">
              Account
            </MenuItem>
            <MenuItem key="global" value="global">
              Global
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>

          {auth.currentUser?.uid === rowData?.reviewer_str_id ? (
            <Select
              onChange={(e) => setFormDataValue('status', e.target.value)}
              fullWidth
              value={formData.status}
              label="Status"
            >
              <MenuItem key="draft" value="draft" disabled>
                Draft
              </MenuItem>
              <MenuItem key="inreview" value="in_review" disabled>
                In review
              </MenuItem>
              <MenuItem key="approved" value="approved">
                Approved
              </MenuItem>
              <MenuItem key="need to update" value="need_update">
                Need to update
              </MenuItem>
            </Select>
          ) : (
            <Select
              onChange={(e) => {
                setFormDataValue('status', e.target.value);
                if (e.target.value === 'in_review') {
                  setOpenModal(true);
                }
              }}
              fullWidth
              value={formData.status}
              label="Status"
            >
              <MenuItem key="approved" value="approved" disabled>
                Approved
              </MenuItem>
              <MenuItem key="needsUpdate" value="need_update" disabled>
                Need to update
              </MenuItem>
              <MenuItem key="draft" value="draft">
                Draft
              </MenuItem>
              <MenuItem key="inreview" value="in_review">
                In review
              </MenuItem>
            </Select>
          )}
        </FormControl>
        <FormControl sx={{ padding: '0 12px' }}>
          <IconButton
            aria-label="processor snapshot"
            color="primary"
            onClick={(e) => setAnchorSnapshotEl(e.currentTarget)}
          >
            <CameraAlt />
          </IconButton>

          <Popover
            id="snapshot"
            open={!!anchorSnaphotEl}
            anchorEl={anchorSnaphotEl}
            onClose={() => setAnchorSnapshotEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <List dense>
              {libProcessorList?.map((item: IProcessorType) => (
                <ListItem key={item.str_id} value={item.name}>
                  <ListItemText
                    primary={
                      <Tooltip title={item.inner_name} key={item.str_id} arrow>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          flexDirection="column"
                        >
                          <div className="flex-1 mr-2">{item.name}</div>
                          <div className="mx-1 text-sm text-gray-400">
                            <Chip label={item.status} sx={{ mr: 1 }} />
                            <Chip
                              label={
                                item.processor_status.includes('::')
                                  ? new Date(
                                      item.processor_status.split('::')[1]
                                    ).toLocaleString()
                                  : item.processor_status
                              }
                              sx={{ mr: 1 }}
                            />
                          </div>
                        </Box>
                      </Tooltip>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Popover>
        </FormControl>
      </Box>
      <Box className="flex-1 flex">
        <Box className="w-1/4 mr-3 flex bg-sky-50/50 relative overflow-hidden border border-blue-400 border-solid">
          <Box className="absolute w-full h-full text-sm">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                position: 'relative',
                paddingTop: '3px',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  right: '4px',
                  top: 0,
                  zIndex: 10,
                }}
              >
                <IconButton
                  onClick={onClickShowPreview}
                  sx={{
                    opacity: 0.8,
                    '&:hover': { opacity: 1 },
                    background: 'white',
                  }}
                >
                  <Launch sx={{ width: '24px', height: '24px' }} />
                </IconButton>
              </Box>
              <Box sx={{ flex: 1, overflow: 'auto' }}>
                <CodeMirror
                  height="50%"
                  value={JSON.stringify(
                    delExtractFields(currentPrview),
                    null,
                    2
                  )}
                  width="100%"
                  readOnly={true}
                  editable={false}
                  basicSetup={{
                    lineNumbers: false,
                  }}
                  extensions={[javascript({ jsx: true })]}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="flex-1 flex flex-col relative overflow-hidden">
          <Box className="absolute top-2 right-4 z-10 flex gap-1">
            <Tooltip title="Tool library">
              <IconButton
                aria-label="tool library"
                color="primary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <MenuBook />
              </IconButton>
            </Tooltip>
            <Popover
              id="p"
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <List dense>
                {rowData && (
                  <Tooltip
                    title={JSON.stringify(rowData, null, 2)}
                    key={'document'}
                  >
                    <ListItem key="document" value="draft">
                      <ListItemText primary="Document data" />
                    </ListItem>
                  </Tooltip>
                )}
                {Object.entries(tool).map(([key]) => (
                  <Tooltip title={toolDesc[key]} key={key}>
                    <ListItem key={key} value={key}>
                      <ListItemText primary={key} />
                    </ListItem>
                  </Tooltip>
                ))}
              </List>
            </Popover>
            <Tooltip title="Show history">
              <IconButton
                aria-label="show history"
                color="primary"
                onClick={showHistory}
              >
                <History />
              </IconButton>
            </Tooltip>
            <IconButton
              aria-label="run code"
              color="primary"
              onClick={execCode}
            >
              <PlayArrow />
            </IconButton>

            <FormControl>
              <InputLabel>Template</InputLabel>
              <Select
                onChange={(e) => setCurrentCode(e.target.value)}
                value={currentCode}
                label="Template"
              >
                {processorTemplateList.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              onClick={handleFabClick}
              startIcon={<EditNote />}
            >
              Notes
            </Button>
          </Box>

          <Allotment defaultSizes={[75, 25]} vertical>
            <Allotment.Pane>
              <Box className="w-full h-full overflow-auto bg-sky-50/5 border border-blue-400 border-solid text-sm">
                <CodeMirror
                  theme={codeTheme}
                  height="100%"
                  value={currentCode}
                  width="100%"
                  extensions={[javascript({ jsx: true })]}
                  onChange={onCodeChange}
                />
              </Box>
            </Allotment.Pane>
            <Allotment.Pane>
              <Box
                className="w-full overflow-auto bg-sky-50/50 border border-blue-400 border-solid mt-3 text-sm"
                sx={{ height: 'calc(100% - 12.5px)' }}
              >
                {currentResult && typeof currentResult !== 'string' && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                      width: '100%',
                      px: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        mt: 1,
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flex: 1,
                          gap: 1,
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => setShowJson(!showJson)}
                        >
                          {showJson ? 'Show Table' : 'Show JSON'}
                        </Button>
                        <Chip
                          label={`Rows: ${
                            currentResult?.data?.length ?? 'n/a'
                          }`}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ flex: 1, height: '100%', width: '100%' }}>
                      {!showJson ? (
                        <ResultTable
                          headers={currentResult.fields}
                          rows={currentResult.data}
                        />
                      ) : (
                        <Box
                          sx={{
                            flex: 1,
                            height: '100%',
                            width: '100%',
                            overflow: 'auto',
                          }}
                        >
                          <CodeMirror
                            value={
                              typeof currentResult !== 'string'
                                ? JSON.stringify(currentResult, null, 2)
                                : currentResult
                            }
                            width="100%"
                            height="100%"
                            readOnly={true}
                            editable={false}
                            basicSetup={{
                              lineNumbers: false,
                              foldGutter: false,
                            }}
                            extensions={[javascript({ jsx: true })]}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}

                {typeof currentResult === 'string' && (
                  <Box>{currentResult}</Box>
                )}
              </Box>
            </Allotment.Pane>
          </Allotment>
        </Box>
      </Box>

      <HistoryDialog
        setShowHistoryList={setShowHistoryList}
        showHistoryList={showHistoryList}
        historyList={historyList}
        setCurrentCode={setCurrentCode}
      />
      <ReviewerSelector
        setOpen={setOpenModal}
        open={openModal}
        setReviewer={setReviewer}
      />
      {openNotes && (
        <CommentDrawer
          open={openNotes}
          setOpen={setOpenNotes}
          type="processor"
          rowData={rowData}
        />
      )}
      {showPreview && (
        <FileDialogPreview
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          fileId={previewId}
        />
      )}
      {showChangeCodeDialog && (
        <BasicDialog
          title="Change processor code"
          bodyComponent={
            <Box className="flex flex-col gap-2">
              <Typography variant="body2">
                After the method is changed, we will match you with a more
                accurate parsing template. Are you sure you want to change the
                current code?
              </Typography>
              <Typography variant="body2" className="text-sky-700">
                You can also retrieve the previous code in the history.
              </Typography>
            </Box>
          }
          open={showChangeCodeDialog}
          onClose={(isComfirm) => {
            if (isComfirm) {
              const code =
                formData.method === 'extractTable'
                  ? extractTableCode
                  : formData.method === 'documentAI'
                    ? documentAICode
                    : spreadSheetCode;
              setCurrentCode(code);
            }
            setShowChangeCodeDialog(false);
          }}
        />
      )}
    </Box>
  );
}

export default forwardRef(ProcessorPlayground);
