const isValidDateFormat = (
  dateStr: string,
  strict: boolean = false,
  allowYearless: boolean = false
): boolean => {
  // Regex patterns for different date formats
  const fullDatePattern = strict
    ? /^(?:(\d{4})[-/](\d{2})[-/](\d{2})|(\d{2})[-/](\d{2})[-/](\d{4}))$/
    : /(?:(\d{4})[-/](\d{2})[-/](\d{2})|(\d{2})[-/](\d{2})[-/](\d{4}))/;

  const yearlessDatePattern = strict
    ? /^(\d{2})[-/](\d{2})$/
    : /(\d{2})[-/](\d{2})/;

  if (allowYearless) {
    return yearlessDatePattern.test(dateStr) || fullDatePattern.test(dateStr);
  }

  return fullDatePattern.test(dateStr);
};

export const isValidDateFormat_desc = `
Check if a date string is in a valid date format.

console.log(isValidDateFormat("12/31/2023")); // true
console.log(isValidDateFormat("2023-12-31")); // true
console.log(isValidDateFormat("12/31")); // false
console.log(isValidDateFormat("12/31", false, true)); // true
console.log(isValidDateFormat("2023/12/31", true, false)); // true

console.log(isValidDateFormat("12/31/2023 jsdhs", false, false)); // true (non-strict mode)
console.log(isValidDateFormat("12/31/2023 jsdhs", true, false)); // false (strict mode)
`;

export default isValidDateFormat;
