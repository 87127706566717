type DateFormats = 'YYYYMMDD' | 'MMDDYYYY' | 'MMDDYY' | 'MM/YY' | 'MM-DD';

const splitFormatDate = (
  dateString: string,
  format: DateFormats,
  defaultYear = '2024'
) => {
  let year, month, day;
  switch (format) {
    case 'YYYYMMDD':
      year = dateString.substring(0, 4);
      month = dateString.substring(4, 6);
      day = dateString.substring(6, 8);
      break;
    case 'MMDDYYYY':
      month = dateString.substring(0, 2);
      day = dateString.substring(2, 4);
      year = dateString.substring(4, 8);
      break;
    case 'MMDDYY':
      month = dateString.substring(0, 2);
      day = dateString.substring(2, 4);
      year = '20' + dateString.substring(4, 6);
      break;
    case 'MM/YY':
      month = dateString.substring(0, 2);
      year = '20' + dateString.substring(3, 5);
      day = '01';
      break;
    case 'MM-DD':
      month = dateString.substring(0, 2);
      day = dateString.substring(3, 5);
      year = defaultYear;
      break;
    default:
      throw new Error('Unsupported date format');
  }

  if (!day) {
    day = '01';
  }

  month = month.padStart(2, '0');
  day = day.padStart(2, '0');

  return `${month}/${day}/${year}`;
};

export const desc_splitFormatDate = `
Split a date string to a specific format.
eg:
  splitFormatDate('20210101', 'YYYYMMDD') => '01/01/2021';
  splitFormatDate('01012021', 'MMDDYYYY') => '01/01/2021';
  splitFormatDate('010121', 'MMDDYY') => '01/01/2021';
  splitFormatDate('01/21', 'MM/YY') => '01/01/2023';
  splitFormatDate('01-01', 'MM-DD') => '01/01/2023';
`;

export default splitFormatDate;
