import React, { useState } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  SelectChangeEvent,
} from '@mui/material';

interface DataUpdateConfigGroupsProps {
  field: any;
  row: any;
  setter: (value: any) => void;
  dynamicSelects: string[];
}

const DataUpdateConfigGroups: React.FC<DataUpdateConfigGroupsProps> = ({
  field,
  row,
  setter,
  dynamicSelects,
}) => {
  const [newGroup, setNewGroup] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(row[field.id] || '');

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const value = event.target.value as string;
    setSelectedGroup(value);
    setter({ ...row, [field.id]: value });
  };

  const handleNewGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewGroup(event.target.value);
  };

  const handleAddNewGroup = () => {
    if (newGroup && !dynamicSelects.includes(newGroup)) {
      dynamicSelects.push(newGroup);
      setSelectedGroup(newGroup);
      setter({ ...row, [field.id]: newGroup });
      setNewGroup('');
    }
  };

  return (
    <Box sx={{ width: '50%', mb: 1 }}>
      <FormControl fullWidth sx={{ mt: 0.5 }}>
        <InputLabel>Group</InputLabel>
        <Select
          value={selectedGroup}
          onChange={handleSelectChange}
          label="Group"
        >
          {dynamicSelects?.map((group, index) => (
            <MenuItem key={index} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ mt: 0.5, ml: 1, display: 'flex', alignItems: 'center' }}>
        <TextField
          label="New Group"
          value={newGroup}
          onChange={handleNewGroupChange}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddNewGroup}
          sx={{ ml: 2 }}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
};

export default DataUpdateConfigGroups;
