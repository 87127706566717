import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from '@mui/material';

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (feedback: string) => void;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [currFeedback, setCurrFeedback] = useState<string>('');

  const handleSubmit = () => {
    onSubmit(currFeedback);
    setCurrFeedback('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Submit feedback</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ color: 'grey', mb: 2 }}>
          Let us know if you encounter issues or have suggestions.
        </Typography>
        <TextField
          autoFocus
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          label="Your feedback"
          value={currFeedback}
          onChange={(e) => setCurrFeedback(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
