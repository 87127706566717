/**
 * Select the required table data according to the mapping data
 * @param mapObj Object with key-value pair of column name and display name
 * @param arr parsed data array
 * @returns array of table headers and table data
 */
const pickData = (mapObj, arr) => {
  const [headerConf, ...mainData] = arr;
  const tableHeaders: any = [];
  const tableData: any = [];

  Object.entries(mapObj).forEach(([k, v], i) => {
    const key = k.replace(/\r\n/g, ' ').trim();
    const titleIndex = headerConf.findIndex((title) => title.includes(key));
    if (titleIndex > -1) {
      tableHeaders.push(v);

      mainData.forEach((row, idx) => {
        const temp = row[titleIndex];
        if (tableData[idx]) {
          tableData[idx].push(temp);
        } else {
          tableData[idx] = [temp];
        }
      });
    }
  });

  return {
    tableHeaders,
    tableData,
  };
};

export default pickData;
