import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import EmptyState from '@/components/EmptyState';
import { ReactComponent as ErrorIllustration } from '@/illustrations/error.svg';

const ErrorBoundary = ({ children, user }) => {
  const [hasError, setHasError] = useState(false);
  const [eventId, setEventId] = useState('');

  useEffect(() => {
    const handleError = (error, errorInfo) => {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        setEventId(eventId);
      });
      setHasError(true);
    };

    const errorListener = (error, errorInfo) => {
      handleError(error, errorInfo);
    };

    // Attach an error listener
    // errorListener has more coverage than React getDerivedStateFromError
    // In theory should be catching more errors
    window.addEventListener('error', errorListener);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('error', errorListener);
    };
  }, []);

  useEffect(() => {
    if (hasError && eventId) {
      Sentry.showReportDialog({
        eventId: eventId,
        user: {
          name: user?.name,
          email: user?.email,
        },
        onClose: () => {
          window.location.href = '/';
        },
      });
    }
  }, [hasError, eventId, user]);

  if (hasError) {
    return (
      <EmptyState image={<ErrorIllustration />} title="Something went wrong" />
    );
  }

  return children;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
