export const mapAccountKeys = (account: UserAccount['account']) => {
  return {
    accountId: account.str_id,
    accountName: account.name,
    accountMode: account.mode,
    compGridsEnabled: account.comp_grids_enabled,
    accountingTransactionsEnabled: account.accounting_transactions_enabled,
    uid: account.uid,
    whiteLabelMode: account.white_label_mode,
    logoUrl: account.logo_url,
  };
};
