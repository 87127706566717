import EnhancedDataView from '../organisms/EnhancedDataView';

const CompReportSettings = () => {
  const dataSpec = {
    label: 'Compensation report settings',
    table: 'accounts/settings/comp_reports',
    fields: {
      enable_custom_terms: {
        label: 'Custom terms enabled',
        enabled: true,
        type: 'boolean',
      },
      custom_terms_text: {
        label: 'Custom terms text',
        enabled: true,
      },
    },
  };

  return (
    <EnhancedDataView
      dataSpec={dataSpec}
      hideSelectedCount
      hideExport={true}
      enableMultiSelect={false}
    />
  );
};

export default CompReportSettings;
