import { convertArrayOfMapsToArrayOfArrays } from 'common/helpers';

import removeColsWithName, {
  desc_removeColsWithName,
} from '@/common/tools/removeColsWithName';
import mapToArray from '@/common/tools/mapToArray';
import spliceExcelArray from '@/common/tools/spliceExcelArray';
import pickData from '@/common/tools/pickData';
import findTextLine, { desc_findTextLine } from '@/common/tools/findTextLine';
import findAllTextLine, {
  desc_findAllTextLine,
} from '@/common/tools/findAllTextLine';
import convertToNumber, {
  desc_convertToNumber,
} from '@/common/tools/convertToNumber';
import getPercent, { desc_getPercent } from '@/common/tools/getPercent';
import splitFormatDate, {
  desc_splitFormatDate,
} from '@/common/tools/splitFormatDate';
import convertArrayToMap, {
  desc_convertArrayToMap,
} from '@/common/tools/convertArrayToMap';
import findTextLineIndex, {
  desc_findTextLineIndex,
} from '@/common/tools/findTextLineIndex';
import findTextLineByIndex, {
  desc_findTextLineByIndex,
} from '@/common/tools/findTextLineByIndex';
import findTextLineAllIndex, {
  desc_findTextLineAllIndex,
} from '@/common/tools/findTextLineAllIndex';
import findAllTextLineByIndex, {
  desc_findAllTextLineByIndex,
} from '@/common/tools/findAllTextLineByIndex';
import convertObjectListToArrayList from '@/common/tools/convertObjectListToArrayList';
import parallelArray, {
  desc_parallelArray,
} from '@/common/tools/parallelArray';
import fillDown, { desc_fillDown } from '@/common/tools/fillDown';
import parseDate, { desc_parseDate } from '@/common/tools/parseDate';
import convertMapToArray, {
  desc_convertMapToArray,
} from '@/common/tools/convertMapToArray';
import findTargetIn2DArray, {
  findTargetIn2DArray_desc,
} from '@/common/tools/findTargetIn2DArray';
import isValidDateFormat, { isValidDateFormat_desc } from './isValidDateFormat';

/**
 * Tool funtion list
 */
export const tool = {
  removeColsWithName,
  mapToArray,
  spliceExcelArray,
  parseDate,
  pickData,
  findTextLine,
  findAllTextLine,
  convertToNumber,
  getPercent,
  convertMapToArray,
  splitFormatDate,
  convertArrayToMap,
  convertArrayOfMapsToArrayOfArrays,
  findTextLineIndex,
  findTextLineByIndex,
  findTextLineAllIndex,
  findAllTextLineByIndex,
  convertObjectListToArrayList,
  parallelArray,
  fillDown,
  findTargetIn2DArray,
  isValidDateFormat,
};

export const toolDesc = {
  removeColsWithName: desc_removeColsWithName,
  parseDate: desc_parseDate,
  findTextLine: desc_findTextLine,
  findAllTextLine: desc_findAllTextLine,
  convertToNumber: desc_convertToNumber,
  getPercent: desc_getPercent,
  splitFormatDate: desc_splitFormatDate,
  convertArrayToMap: desc_convertArrayToMap,
  convertMapToArray: desc_convertMapToArray,
  findTextLineIndex: desc_findTextLineIndex,
  findTextLineByIndex: desc_findTextLineByIndex,
  findTextLineAllIndex: desc_findTextLineAllIndex,
  findAllTextLineByIndex: desc_findAllTextLineByIndex,
  parallelArray: desc_parallelArray,
  fillDown: desc_fillDown,
  findTargetIn2DArray: findTargetIn2DArray_desc,
  isValidDateFormat: isValidDateFormat_desc,
};

export type ToolType = typeof tool;
