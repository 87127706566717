/**
 * Splice Excel array
 */
const spliceExcelArray = (
  dataList: string[][],
  companyList: string[],
  tableDataTarget: string[]
): { [key: string]: string[][] } => {
  const result: { [key: string]: string[][] } = {};
  let currentCompany: string | null = null;
  let recording = false;
  let tempData: string[][] = [];
  let expectedColumnCount = 0;
  dataList.forEach((row) => {
    const isCompanyRow = companyList.includes(row[0]);
    if (isCompanyRow) {
      if (currentCompany && tempData.length > 0) {
        result[currentCompany] = tempData;
      }
      currentCompany = row[0];
      tempData = [];
      recording = false;
      expectedColumnCount = 0;
    } else if (tableDataTarget.some((target) => row.includes(target))) {
      recording = true;
      tempData.push(row);
      expectedColumnCount = row.length;
    } else if (recording && row.length === expectedColumnCount) {
      tempData.push(row);
    }
  });
  if (currentCompany && tempData.length > 0) {
    result[currentCompany] = tempData;
  }

  return result;
};

export default spliceExcelArray;
