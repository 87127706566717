import { Button } from '@mui/material';

export const AuthMenu = ({
  onSignUpClick,
  onSignInClick,
  performingAction,
}: Pick<BarProps, 'onSignUpClick' | 'onSignInClick' | 'performingAction'>) => {
  return (
    <>
      <Button
        color="inherit"
        variant="outlined"
        onClick={onSignUpClick}
        disabled={performingAction}
        sx={{ mr: 1 }}
      >
        Sign up
      </Button>
      <Button
        color="inherit"
        onClick={onSignInClick}
        disabled={performingAction}
      >
        Sign in
      </Button>
    </>
  );
};
