import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DocumentProfileView from '@/components/admin/AdminDocumentsView/DocumentProfileView';
import DocumentsView from '@/components/admin/AdminDocumentsView/DocumentsView';
import ProcessorsView from '@/components/admin/AdminDocumentsView/ProcessorsView';
import PromptsView from '@/components/admin/AdminDocumentsView/PromptsView';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ pt: 2 }}>
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

const SettingsView = () => {
  const tabs = [
    {
      label: 'Documents',
      path: 'documents?qc=new',
      component: <DocumentsView variant="tabbed" />,
    },
    {
      label: 'Profiles',
      path: 'profiles',
      component: <DocumentProfileView variant="tabbed" />,
    },
    {
      label: 'Processors',
      path: 'processors',
      component: <ProcessorsView variant="tabbed" />,
    },
    {
      label: 'Prompts',
      path: 'prompts',
      component: <PromptsView variant="tabbed" />,
    },
  ];

  const { tab = 'profiles' } = useParams();
  const tabPaths = tabs.map((tab) => tab.path);
  const currentTabIndex = tabPaths.indexOf(tab);
  const routedTab = currentTabIndex >= 0 ? currentTabIndex : 0;
  const [value, setValue] = useState(routedTab);
  const name = tabs[value].label;

  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    navigate(`/admin/documents/${tabs[newValue].path}`);
    setValue(newValue);
  };

  useEffect(() => {
    const currentTabIndex = tabPaths.indexOf(tab);
    if (currentTabIndex >= 0 && currentTabIndex !== value) {
      setValue(currentTabIndex);
    }
  }, [tab, tabPaths, routedTab, value]);

  return (
    <Box sx={{ width: 1, overflowY: 'scroll' }}>
      <Box sx={{ padding: '15px 25px 10px' }}>
        <Typography variant="h5">Documents</Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ mx: 1, mb: 1 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              minHeight: 32,
              height: 32,
              '.MuiTabs-flexContainer': { minHeight: 32 },
            }}
          >
            {tabs.map((tab) => (
              <Tab
                label={tab.label}
                key={tab.label}
                sx={{
                  minHeight: 24,
                  height: 24,
                  py: 0.5,
                }}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ mt: { xs: 0, sm: -9 } }}>
          {tabs.map((tab) => (
            <TabPanel value={value} index={tabs.indexOf(tab)} key={tab.label}>
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsView;
