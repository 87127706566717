export enum DataEntities {
  COMMISSIONS = 'Commissions',
  POLICIES = 'Policies',
}

export type Field = Record<string, any>;

export interface Fields {
  [DataEntities.COMMISSIONS]: Field[];
  [DataEntities.POLICIES]: Field[];
}

export interface ActionObject {
  field: string;
  value: string;
  action: string;
}
