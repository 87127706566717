export interface ShareDataModel {
  isExcel: boolean;
  sheets: string[];
  statementFields?: any[];
  reportFields?: any[];
  fields: FieldModel;
  requiredRows: string[];
  allFieldKeys: IFieldsProps[];
  fileType: string;
  errors: ErrorMsg;
  fileData: FileDataModel;
}

export interface RowMappingModel {
  [key: string]: number | string;
}

export interface FieldItemModel {
  label: string;
  options: string[];
  required: boolean;
  reconciler: boolean;
  enabled: boolean;
  normalizer?: (s: any, list?: any) => any;
  formatter?: (s: any, list?: any) => any;
  copyable: boolean;
  type: string;
  global: boolean;
  [key: string]: string | number | boolean;
}

interface FieldItemModel {
  id: number;
  str_id: string;
  state: string;
  created_at: string;
  created_by: string;
  created_proxied_by: any;
  updated_at: string;
  updated_by: string;
  updated_proxied_by: any;
  copyable: boolean;
  description: string;
  enabled_for: {
    default: boolean;
    insurance: boolean;
  };
  formatter: any;
  key: string;
  label: string;
  model: string;
  normalizer: any;
  notes: string;
  options: string[];
  matches: string[];
  required_for: {
    default: boolean;
    insurance: boolean;
  };
  type: string;
  required: boolean;
  enabled: boolean;
}

export interface FileDataModel {
  type?:
    | 'spreadsheet'
    | 'documentAI'
    | 'extractTable'
    | 'adobeExtract'
    | 'gemini'
    | 'GPT'
    | 'InvalidJSON';
  data?: any;
  extraction?: {
    id: number;
    str_id: string;
    method: string;
  };
  error?: string;
}

export interface FieldModel {
  [key: string]: FieldItemModel;
}

export enum ProcessFormKey {
  mapping = 'mapping',
  newMappingCarrier = 'newMappingCarrier',
  newMappingName = 'newMappingName',
  processor = 'processor',
  method = 'method',
  fileName = 'fileName',
  fileType = 'fileType',
  selectedSheet = 'selectedSheet',
  prompt = 'prompt',
  promptText = 'promptText',
}

export enum ProcessResultE {
  extractTable = 'extractTable',
  documentAI = 'documentAI',
  SpreadSheet = 'SpreadSheet',
  Gemini = 'Gemini',
  AdobeExtract = 'adobeExtract',
  MappingSpreadsheet = 'MappingSpreadsheet',
}

export interface ProcessDataModel {
  fields: string[];
  version: ProcessResultE | null;
  data: string[][];
  sheet?: string;
}

export interface RangeDataModel {
  index: number;
  count: number;
  fields: string[];
  data: string[][];
}

export interface ProcessorFormatModel {
  mappingOptions: RowMappingModel;
  cmsTotal?: string;
  data: {
    [key: string]: string | number | boolean | '' | null | string[];
  }[];
  rowData: {
    [key: string]: any;
  }[];
}

export interface SpreadSheetProps {
  getSheets: () => string[];
  getJson: (i: number | string) => any[];
}

export interface ErrorMsg {
  dataRows?: string;
  extraction?: string;
  upload?: string;
  mapping?: string;
  missingField?: string;
  [key: string]: string | undefined;
}

export enum ProcessMethodE {
  Processor = 'processor',
  Mapping = 'mapping',
  Gemini = 'gemini',
  InvalidJSON = 'InvalidJSON',
  Compgrid = 'compgrid',
  AdobeExtract = 'adobeExtract',
}

export enum DocumentTypeE {
  Statement = 'statement',
  Policy = 'policy',
  Report = 'report',
}

export enum ExtractMethodE {
  ExtractTable = 'extractTable',
  DocumentAI = 'documentAI',
}

export type ProcessMethod =
  | 'processor'
  | 'mapping'
  | 'compgrid'
  | 'GPT'
  | 'InvalidJSON';

export interface FieldsProps {
  id: number;
  str_id: string;
  key: string;
  model: string;
  label: string;
  options: string[];
  enabled_for: {
    default: boolean;
    insurance: boolean;
  };
  required_for: {
    default: boolean;
    insurance: boolean;
  };
  type: string;
  description: string;
  notes: any;
  formatter: any;
  normalizer: any;
  state: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;

  required?: boolean;
  enabled?: boolean;
}

interface IEnabledFor {
  default: boolean;
  insurance: boolean;
}

interface IRequiredFor {
  default: boolean;
  insurance: boolean;
}

export interface IFieldsProps {
  id: number;
  str_id: string;
  key: string;
  model: string;
  label: string;
  options: string[];
  enabled_for: IEnabledFor;
  required_for: IRequiredFor;
  type: string;
  description: string;
  notes: any;
  formatter: any;
  normalizer: any;
  state: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;

  required?: boolean;
  enabled?: boolean;
}
