import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type Props<T extends string | number> = {
  label: string;
  value?: T;
  options: { value: T; label: string }[];
  onChange?: (v: T) => void;
};
export const SelectFilter = <T extends string | number>({
  label,
  value,
  options,
  onChange,
}: Props<T>) => {
  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={(evt) => {
          const v = evt.target.value;
          onChange?.(v as T);
        }}
        sx={{ minWidth: 100 }}
      >
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
