import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/material/styles';

import authentication from '@/services/authentication';
import useSnackbar from '@/contexts/useSnackbar';

interface EmailVerificationDialogProps {
  dialogProps: {
    onClose: (callback?: () => void) => void;
    open: boolean;
    email?: string;
  };
  theme: Theme;
}

const EmailVerificationDialog = ({
  dialogProps,
  theme,
}: EmailVerificationDialogProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const { showSnackbar } = useSnackbar();

  const StyledIconButton = styled(IconButton)({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonDisabled) {
      setCountdown(60);
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isButtonDisabled]);

  useEffect(() => {
    if (countdown <= 0) {
      setIsButtonDisabled(false);
    }
  }, [countdown]);

  return (
    <Dialog
      open={dialogProps.open}
      fullWidth
      maxWidth="sm"
      onClose={() => {
        dialogProps.onClose();
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h6" component="div">
          Verify your e-mail address
        </Typography>
        <Tooltip title="Close">
          <span>
            <StyledIconButton
              onClick={(event) => {
                event.preventDefault();
                dialogProps.onClose();
              }}
              size="large"
            >
              <CloseIcon />
            </StyledIconButton>
          </span>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs>
            <Typography variant="body2">
              We need to send an e-mail to <strong>{dialogProps.email}</strong>{' '}
              to verify your e-mail address.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
          <Grid item xs>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={() => {
                setIsButtonDisabled(true);
                authentication
                  .sendEmailVerification()
                  .then(() => {
                    showSnackbar('E-mail verification sent', 'success');
                  })
                  .catch((error) => {
                    console.error(
                      'Error when sending e-mail verification',
                      error
                    );
                    Sentry.captureException(error);
                    showSnackbar('Error sending e-mail verification', 'error');
                  });
              }}
            >
              {isButtonDisabled
                ? `Resend in ${countdown}s`
                : 'Send e-mail verification'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EmailVerificationDialog;
