const findTargetIn2DArray = (
  array: string[][],
  target: string,
  findAll = false
): {
  value: string[] | undefined;
  metadata: { row: number; col: number }[] | undefined;
} => {
  if (!target) {
    return {
      value: undefined,
      metadata: undefined,
    };
  }
  const results: {
    row: number;
    col: number;
  }[] = [];
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < array[i].length; j++) {
      if (
        array[i][j]?.toLocaleLowerCase().includes(target?.toLocaleLowerCase())
      ) {
        if (findAll || results.length === 0) {
          results.push({ row: i, col: j });
        }
      }
    }
  }

  if (results.length === 0) {
    return {
      value: undefined,
      metadata: undefined,
    };
  }

  if (findAll) {
    return {
      value: results.map((result) => array[result.row][result.col]),
      metadata: results,
    };
  } else {
    return {
      value: [array[results[0].row][results[0].col]],
      metadata: [results[0]],
    };
  }
};

export const findTargetIn2DArray_desc = `
const array = [
    ["Name", "Policy Number", "Tax ID"],
    ["", "", "Statement Date: 09/30/2024"],
];

const target = "Statement Date:";
const result = findTargetIn2DArray(array, target); 
// { value: ["Statement Date: 09/30/2024"], metadata: [{ row: 1, col: 2 }] }
`;

export default findTargetIn2DArray;
