import { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Button, Snackbar } from '@mui/material';

const VersionChecker: React.FC = () => {
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const INTERVAL = 5 * 60 * 1000; // 5 minutes
  const [notify, setNotify] = useState<{ notifyTime: number }>();
  const forceAlertDelay = 12 * 1000 * 60 * 60; // 12 hours

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await axios.get('/manifest.json');
        const manifestBuildTime = response?.data?.versionInfo?.buildTime;

        if (
          manifestBuildTime &&
          __BUILD_TIME__ &&
          manifestBuildTime !== __BUILD_TIME__
        ) {
          if (!notify) {
            setNotify({ notifyTime: Date.now() });
          }
          if (notify && Date.now() - notify.notifyTime >= forceAlertDelay) {
            setShowUpdateAlert(true);
          } else {
            dispatchNotification();
          }
        }
      } catch (error) {
        console.error('Failed to check version:', error);
      }
    };
    checkVersion();
    const interval = setInterval(checkVersion, INTERVAL);
    return () => clearInterval(interval);
  }, [INTERVAL, forceAlertDelay, notify]);

  const dispatchNotification = () => {
    window.dispatchEvent(
      new CustomEvent('version-update', {
        detail: {
          buildTime: __BUILD_TIME__,
          notifyTime: Date.now(),
        },
      })
    );
  };

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') return;
    setShowUpdateAlert(false);
  };

  return (
    <Snackbar
      open={showUpdateAlert}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      sx={{ mt: -2 }}
    >
      <Alert
        onClose={handleClose}
        severity="info"
        sx={{ width: '100%' }}
        action={
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={() => {
              localStorage.setItem('triggerReload', Date.now().toString());
              window.location.reload();
            }}
            sx={{ textTransform: 'none' }}
          >
            Refresh
          </Button>
        }
      >
        A new version is available. Please refresh to update.
      </Alert>
    </Snackbar>
  );
};

export default VersionChecker;
