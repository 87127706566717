const findTextLine = (json, str) => {
  let txt = '';
  json.Lines?.find((lineItem) => {
    const item = lineItem.LinesArray.find((line) => line.Line.includes(str));
    if (item) {
      txt = item.Line;
    }
    return !!item;
  });
  return txt;
};

export const desc_findTextLine = `
Find the first line that contains the given text in the JSON object.
eg:
findTextLine(json, 'text') => 'text';
`;

export default findTextLine;
