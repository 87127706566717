import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { isValidJsonString } from 'common/helpers';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import API from '@/services/API';

const transformObjectVal = (json, fieldsMap) =>
  json.map((item) =>
    Object.fromEntries(
      Object.entries(item).map(([k, v]) => {
        if (typeof fieldsMap[k]?.strToValue === 'function') {
          const fn = fieldsMap[k].strToValue;
          return [k, fn(typeof v === 'string' ? v.trim() : v)];
        }
        return [k, typeof v === 'string' ? v.trim() : v];
      })
    )
  );

const DataBulkAdd = ({
  fields,
  params,
  handleUpdateParams,
  onCancel,
  onSave,
  btnLabel = 'Bulk add',
}) => {
  const [selectOptions, setSelectOptions] = useState({});
  const [selectedVals, setSelectedVals] = useState({});
  const [csv, setCsv] = useState('');
  const [json, setJSON] = useState('[]');

  const fieldsMap = fields.reduce((acc, field) => {
    if (['created_at', 'updated_at'].includes(field.id)) return acc;
    acc[field.id] = field;
    return acc;
  }, {});

  const requiredFields = fields.filter((field) => field.required);

  const selectFields = fields.filter((field) => field.bulkAddSelect);

  selectFields.forEach(async (field) => {
    const { data } = API.getBasicQuery(field.table);
    if (JSON.stringify(selectOptions[field.table]) !== JSON.stringify(data)) {
      setSelectOptions((prev) => ({
        ...prev,
        [field.table]: data,
      }));
    }
  });

  useEffect(() => {
    setCsv(
      `${fields
        .map((field) => field.id)
        .filter((f) => !['created_at', 'updated_at'].includes(f))
        .join(',')}\n`
    );
  }, [fields]);

  useEffect(() => {
    try {
      const csvSheet = XLSX.read(csv, {
        type: 'string',
        raw: true,
      });
      const jsonRes = XLSX.utils.sheet_to_json(
        csvSheet.Sheets[csvSheet.SheetNames[0]],
        { raw: true }
      );
      const jsonSet = new Set(jsonRes.map((item) => JSON.stringify(item)));
      setJSON(JSON.stringify(transformObjectVal(jsonRes, fieldsMap), null, 2));
    } catch (e) {
      console.error(e);
    }
  }, [csv]);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography>Bulk add entities in csv/tsv format</Typography>
      <Typography variant="caption">
        Standard CSV/TSV format, you may remove columns that you don't need, but
        these are required: (
        {`${requiredFields.map((field) => field.id).join(', ')}`})
      </Typography>
      {selectFields.length > 0 && (
        <Box>
          <Typography variant="caption">IDs for reference</Typography>
          {selectFields.map((field) => (
            <FormControl key={field.id} sx={{ mb: 1 }} fullWidth>
              <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
              <Select
                label={field.label}
                value={selectedVals?.[field.id]}
                onChange={(event) =>
                  setSelectedVals({
                    ...selectedVals,
                    [field.id]: event.target.value,
                  })
                }
              >
                {(Array.isArray(selectOptions?.[field.table]?.data)
                  ? selectOptions?.[field.table]?.data
                  : Array.isArray(selectOptions?.[field.table])
                    ? selectOptions?.[field.table]
                    : []
                )?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.id} {option?.access ? `(${option.access})` : ''}:{' '}
                    {field.optionFormatter(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </Box>
      )}
      <TextField
        label="CSV/TSV"
        multiline
        rows={8}
        value={csv}
        onChange={(event) => {
          setCsv(event.target.value);
        }}
        sx={{ width: '100%', mt: 1 }}
        fullWidth
      />
      {params && (
        <TextField
          label="Params"
          value={params}
          onChange={handleUpdateParams}
          sx={{ width: '100%', mt: 1 }}
          fullWidth
          error={!isValidJsonString(params)}
          helperText={!isValidJsonString(params) && 'Invalid JSON string'}
        />
      )}
      <TextField
        label="Entities"
        multiline
        maxRows={20}
        value={JSON.parse(json).map(JSON.stringify).join('\n')}
        sx={{ width: '100%', mt: 1 }}
        fullWidth
        disabled
      />
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={{ mr: 1 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() =>
            onSave({
              data: JSON.parse(json),
              ...(params ? { params: JSON.parse(params) } : {}),
            })
          }
          disabled={params && !isValidJsonString(params)}
        >
          {btnLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default DataBulkAdd;
