import getIndices from './getIndices';

const fillDown = (array: string[][], colNames: string[]) => {
  const indicesToProcess = getIndices(array, colNames);
  const newArray = JSON.parse(JSON.stringify(array));
  indicesToProcess.forEach((idx) => {
    let tempVal = '';
    for (let i = 1; i < newArray.length; i++) {
      newArray[i][idx]
        ? (tempVal = newArray[i][idx])
        : (newArray[i][idx] = tempVal);
    }
  });
  return newArray;
};

export const desc_fillDown = `
Automatically fill down the data from the row above.
eg:
desc_fillDown([['a', 'b', 'c'], [1, 2, 3], [ , 5, 6]], ['a']) => [['a', 'b', 'c'], [1, 2, 3], [1, 5, 6]];
`;

export default fillDown;
